import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'picInitials'
})
export class PicInitialsPipe implements PipeTransform {

  transform(name): string {
    const fullName = name.split(' ');
    let initials = '';
    if(fullName.length > 1){
      initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    } else if(fullName.length < 2){
      initials = fullName.shift().charAt(0);
    }
    return initials;
  }

}
