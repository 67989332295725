import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { AuthUtils } from './auth.utils';
import * as moment from 'moment';
import { Observable, from } from 'rxjs';
import { getIdToken, Auth } from '@angular/fire/auth';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  token: any = null;
  constructor(private authService: AuthService, public auth: Auth) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const re = /`auth`/gi;
    if (req.url.search(re) === -1 && !req.url.includes('user?profile=true')) {
      const accessToken = this.authService.token;
      if (
        this.authService.token &&
        !AuthUtils.isTokenExpired(this.authService.token)
      ) {
        return from(this.authService.getIdToken()).pipe(
          mergeMap((res) => {
            this.authService.token = res;
            localStorage.setItem('accessToken', res);

            const modifiedReq = req.clone({
              setHeaders: {
                Authorization: 'Bearer ' + this.authService.token,
              },
            });

            return next.handle(modifiedReq);
          })
        );
      } else {
        return from(this.authService.getIdToken()).pipe(
          mergeMap((res) => {
            this.authService.token = res;
            localStorage.setItem('accessToken', res);

            const modifiedReq = req.clone({
              setHeaders: {
                Authorization: 'Bearer ' + this.authService.token,
              },
            });

            return next.handle(modifiedReq);
          })
        );
      }
    } else {
      return next.handle(req);
    }
  }
}
