import { Injectable } from '@angular/core';
import { BlobServiceClient } from '@azure/storage-blob';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  public async uploadFile(content: any, keywords?: any) {
    const blob = new BlobServiceClient(environment.azure_blob.blob_credentials);
    const container = blob.getContainerClient(
      environment.azure_blob.container_name
    );
    const file_name = 'sportswriters_image_' + new Date().getTime();
    const blockBlob = container.getBlockBlobClient(file_name);

    let outputDict: any = {
      People: '',
      League: '',
      Locations: '',
      Teams: '',
      Tag: '',
      aiObject: '',
    };

    if (keywords) {
      for (let index = 0; index < keywords.length; index++) {
        for (let indx = 0; indx < keywords[index].length; indx++) {
          let keys = Object.keys(keywords[index][indx]);
          if (keys[0] === 'People') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
          if (keys[0] === 'Locations') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
          if (keys[0] === 'League') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
          if (keys[0] === 'Teams') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
          if (keys[0] === 'Tag') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
          if (keys[0] === 'aiObject') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
        }
      }
    }

    if (outputDict.People === '') {
      delete outputDict.People;
    }
    if (outputDict.League === '') {
      delete outputDict.League;
    }
    if (outputDict.Teams === '') {
      delete outputDict.Teams;
    }
    if (outputDict.Locations === '') {
      delete outputDict.Locations;
    }
    if (outputDict.Tag === '') {
      delete outputDict.Tag;
    }
    if (outputDict.aiObject === '') {
      delete outputDict.aiObject;
    }

    for (let key in outputDict) {
      if (outputDict.hasOwnProperty(key)) {
        outputDict[key] = outputDict[key].replace(/,$/, '');
      }
    }

    const sanitizedData = {};
    for (const [key, value] of Object.entries(outputDict)) {
      const sanitizedValue = String(value).replace(
        /&#x([0-9a-fA-F]+);|[^a-zA-Z0-9\s]/g,
        ' '
      );
      sanitizedData[key] = sanitizedValue;
    }
    const uploadFileResp = await blockBlob.uploadData(content, {
      blobHTTPHeaders: { blobContentType: 'image/webp' },
      tags: sanitizedData,
    });
    return environment.azure_blob.blob_image_base_url + file_name;
  }

  public async uploadFile3(
    content: any,
    metadata: any,
    keywords?: any
  ): Promise<string> {
    const blob = new BlobServiceClient(environment.azure_blob.blob_credentials);
    const container = blob.getContainerClient(
      environment.azure_blob.container_name
    );
    const file_name = 'sportswriters_image_' + new Date().getTime() + `.webp`;
    const containerClient = blob.getContainerClient(
      environment.azure_blob.container_name
    ); // Get a block blob client
    const blockBlobClient = containerClient.getBlockBlobClient(file_name);
    // Display blob name and url
    // Define the metadata that you want to upload

    if (metadata.caption) {
      metadata.caption = metadata.caption.replace(/[^0-9a-zA-Z\N{©}]+/gi, ' ');
    }

    if (metadata.description) {
      metadata.description = metadata.description.replace(
        /[^0-9a-zA-Z\s\N{©}]/gi,
        ' '
      );
    }

    let metadaata = {
      uploadedBy: metadata.uploadedBy ? metadata.uploadedBy.toString() : '',
      fileType: 'image/webp' || 'Unknown',
      fileSize: metadata.fileSize ? metadata.fileSize.toString() : '',
      height: metadata.height ? metadata.height.toString() : '',
      width: metadata.width ? metadata.width.toString() : '',
      // "altText": metadata.altText.toString() || '',
      // "caption": metadata.caption.replace(/Mandatory Credit:/g, '©').toString() || '',
      caption: metadata.caption
        ? metadata.caption.replace(/©/g, 'Mandatory Credit:').toString()
        : '',
      description: metadata.description ? metadata.description.toString() : '',
      upload_date: Date.now().toString(),
    };

    //   let metadaata={
    //     "uploadedBy": "Haider ali",
    //     "fileType": "image/webp",
    //     "fileSize": "8153026",
    //     "height": "5113",
    //     "width": "7669",
    //     "caption": "April 17  2023  Sacramento  California  USA  Sacramento Kings guard Davion Mitchell  15  celebrates against the Golden State Warriors during the fourth quarter in game two of the first round of the 2023 NBA playoffs at Golden 1 Center Kyle Terada USA TODAY Sports",
    //     "description": "NBA  Playoffs Golden State Warriors at Sacramento Kings",
    //     "upload_date": "1681798495613"
    // }

    if (metadata.height === '') {
      delete metadaata.height;
    }
    if (metadata.width === '') {
      delete metadaata.width;
    }
    if (metadata.caption === '') {
      delete metadaata.caption;
    }
    if (metadata.description === '') {
      delete metadaata.description;
    }

    let outputDict: any = {
      People: '',
      League: '',
      Locations: '',
      Teams: '',
      Tag: '',
      aiObject: '',
    };

    if (keywords) {
      for (let index = 0; index < keywords.length; index++) {
        for (let indx = 0; indx < keywords[index].length; indx++) {
          let keys = Object.keys(keywords[index][indx]);
          if (keys[0] === 'People') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
          if (keys[0] === 'Locations') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
          if (keys[0] === 'League') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
          if (keys[0] === 'Teams') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
          if (keys[0] === 'Tag') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
          if (keys[0] === 'aiObject') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
        }
      }
    }

    if (outputDict.People === '') {
      delete outputDict.People;
    }
    if (outputDict.League === '') {
      delete outputDict.League;
    }
    if (outputDict.Teams === '') {
      delete outputDict.Teams;
    }
    if (outputDict.Locations === '') {
      delete outputDict.Locations;
    }
    if (outputDict.Tag === '') {
      delete outputDict.Tag;
    }
    if (outputDict.aiObject === '') {
      delete outputDict.aiObject;
    }

    for (let key in outputDict) {
      if (outputDict.hasOwnProperty(key)) {
        outputDict[key] = outputDict[key].replace(/,$/, '');
      }
    }

    const sanitizedData = {};
    for (const [key, value] of Object.entries(outputDict)) {
      const sanitizedValue = String(value).replace(
        /&#x([0-9a-fA-F]+);|[^a-zA-Z0-9\s]/g,
        ' '
      );
      sanitizedData[key] = sanitizedValue;
    }

    //  Upload data to the blob
    const data = content;

    const uploadBlobResponse = await blockBlobClient.upload(
      data,
      parseInt(metadata.fileSize),
      {
        blobHTTPHeaders: { blobContentType: 'image/webp' },
        metadata: metadaata,
        tags: sanitizedData,
      }
    );

    // const properties = await uploadBlobResponse.getProperties();
    // console.log('metadata', properties);

    return environment.azure_blob.blob_image_base_url + file_name;
  }

  public async uploadFile2(content: any) {
    const blob = new BlobServiceClient(environment.azure_blob.blob_credentials);
    const container = blob.getContainerClient(
      environment.azure_blob.container_name
    );
    const file_name = 'sportswriters_image_' + new Date().getTime();
    const blockBlob = container.getBlockBlobClient(file_name);
    const uploadFileResp = await blockBlob.uploadData(content, {
      blobHTTPHeaders: { blobContentType: 'image/jpeg' },
    });
    return environment.azure_blob.blob_image_base_url + file_name;
  }

  //  public async image_upload(url: string) {
  //   var request = require("request-promise").defaults({ encoding: null });
  //     try {
  //       let success = false;
  //       let message = "";
  //       let access_key = process.env["AZURE_BLOB_CONNECTION_STRING"];
  //       const account = "sportswritersmedia";
  //       const blobServiceClient =
  //         BlobServiceClient.fromConnectionString(access_key);

  //       let base64 = "";
  //       let base = await request.get(url, async function (error, response, body) {
  //         if (!error && response.statusCode == 200) {
  //           var data =
  //             "data:" +
  //             response.headers["content-type"] +
  //             ";base64," +
  //             Buffer.from(body).toString("base64");
  //           console.log(data);
  //           base64 = data;
  //           success = true;
  //         } else {
  //           success = false;
  //         }
  //       });
  //       // db.connect();
  //       // const queryResult = await db.query(query);
  //       // db.end();
  //       if (success) {
  //         var rawdata = base64;
  //         var matches = rawdata.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);
  //         var type = rawdata.split(";")[0].split("/")[1];
  //         var buffer = Buffer.from(matches[2], "base64");
  //         // Create a unique name for the blob
  //         const blobName = "image_" + Date.now() + "." + type;
  //         const containerClient =
  //           blobServiceClient.getContainerClient("contentimages");
  //         // Get a block blob client
  //         const blockBlobClient = containerClient.getBlockBlobClient(blobName);
  //         // Display blob name and url
  //         // Upload data to the blob
  //         const data = buffer;
  //         const uploadBlobResponse = await blockBlobClient.upload(
  //           data,
  //           data.length
  //         );
  //         message = blockBlobClient.url;
  //       }
  //       return {
  //         success: true,
  //         message: message,
  //       };
  //     } catch (error) {
  //       return {
  //         sucess: false,
  //         message: error.message,
  //       };
  //     }
  // }

  //convert compressed image to blob type
  dataURItoBlob(dataURI, imgType?: string): Blob {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
  }

  dataURItoBlob2(dataURI, imgType?: string): Blob {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/webp' });
  }
}

export class BlobUploadForEditorService {
  loader;
  xhr: XMLHttpRequest;
  file_name: string;

  constructor(loader) {
    this.loader = loader;
    this.file_name = 'sportswriters_image_' + new Date().getTime();
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest(file);
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest(file) {
    let regex = new RegExp('[^.]+$');
    let extension = file.name.match(regex);
    this.file_name = this.file_name + '.' + extension;
    const xhr = (this.xhr = new XMLHttpRequest());
    const blob = new BlobServiceClient(environment.azure_blob.blob_credentials);
    const container = blob.getContainerClient(
      environment.azure_blob.container_name
    );
    const blockBlob = container.getBlockBlobClient(this.file_name);
    let url = blockBlob.url;
    xhr.open('PUT', url, true);
    xhr.responseType = 'json';
  }

  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
    xhr.setRequestHeader('Content-Type', file.type);

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;

      resolve({
        default: environment.azure_blob.blob_image_base_url + this.file_name,
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  async _sendRequest(file: Blob) {
    this.xhr.send(file);
  }

  encodeImageFileAsURL(file): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (event) => {
        resolve(btoa(event.target.result.toString()));
      };
    });
  }
}
