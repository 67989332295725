import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import {
  AuthGuard,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';
import { RouteGuard } from './guards/route.guard';

const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(['auth/login']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['dashboard']);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./views/auth/auth.module').then((m) => m.AuthPageModule),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToDashboard },
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./views/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'administrators',
    loadChildren: () =>
      import('./views/administrators/administrators.module').then(
        (m) => m.AdministratorsPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'leagues',
    loadChildren: () =>
      import('./views/leagues/leagues.module').then((m) => m.LeaguesPageModule),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'leagues/detail/:id',
    loadChildren: () =>
      import('./views/leagues/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'leagues/detail/:id/:token',
    loadChildren: () =>
      import('./views/leagues/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'conferences',
    loadChildren: () =>
      import('./views/conferences/conferences.module').then(
        (m) => m.ConferencesPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'conferences/detail/:id',
    loadChildren: () =>
      import('./views/conferences/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'conferences/detail/:id/:token',
    loadChildren: () =>
      import('./views/conferences/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'divisions',
    loadChildren: () =>
      import('./views/divisions/divisions.module').then(
        (m) => m.DivisionsPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'divisions/detail/:id',
    loadChildren: () =>
      import('./views/divisions/details/details.module').then(
        (m) => m.DetailsPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'divisions/detail/:id/:token',
    loadChildren: () =>
      import('./views/divisions/details/details.module').then(
        (m) => m.DetailsPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'league-conferences',
    loadChildren: () =>
      import('./views/league-conferences/league-conferences.module').then(
        (m) => m.LeagueConferencesPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'league-conferences/detail/:id',
    loadChildren: () =>
      import('./views/league-conferences/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'league-conferences/detail/:id/:token',
    loadChildren: () =>
      import('./views/league-conferences/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  {
    path: 'articles',
    loadChildren: () =>
      import('./views/articles/articles.module').then(
        (m) => m.ArticlesPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'articles/:team',
    loadChildren: () =>
      import('./views/articles/articles.module').then(
        (m) => m.ArticlesPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'articles/detail/:id',
    loadChildren: () =>
      import('./views/articles/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'articles/detail/:id/:token',
    loadChildren: () =>
      import('./views/articles/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'teams',
    loadChildren: () =>
      import('./views/teams/teams.module').then((m) => m.TeamsPageModule),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'teams/detail/:id',
    loadChildren: () =>
      import('./views/teams/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'teams/detail/:id/:token',
    loadChildren: () =>
      import('./views/teams/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'cities',
    loadChildren: () =>
      import('./views/cities/cities.module').then((m) => m.CitiesPageModule),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'cities/detail/:id',
    loadChildren: () =>
      import('./views/cities/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'cities/detail/:id/:token',
    loadChildren: () =>
      import('./views/cities/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'players',
    loadChildren: () =>
      import('./views/players/players.module').then((m) => m.PlayersPageModule),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'players/detail/:id',
    loadChildren: () =>
      import('./views/players/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'players/detail/:id/:token',
    loadChildren: () =>
      import('./views/players/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./views/users/users.module').then((m) => m.UsersPageModule),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  {
    path: 'users/detail/:id',
    loadChildren: () =>
      import('./views/users/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'users/detail/:id/:token',
    loadChildren: () =>
      import('./views/users/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  {
    path: 'analytics',
    loadChildren: () =>
      import('./views/analytics/analytics.module').then(
        (m) => m.AnalyticsPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'analytics/graphs',
    loadChildren: () =>
      import('./views/analytics/graphs/graphs.module').then(
        (m) => m.GraphsPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'analytics/usersregistered',
    loadChildren: () =>
      import('./views/analytics/usersregistered/usersregistered.module').then(
        (m) => m.UsersregisteredPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'analytics/articlesimported',
    loadChildren: () =>
      import('./views/analytics/articlesimported/articlesimported.module').then(
        (m) => m.ArticlesimportedPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'analytics/articlesimported/detail/:slug',
    loadChildren: () =>
      import('./views/analytics/articlesimported/articlesimported.module').then(
        (m) => m.ArticlesimportedPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./views/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: 'settings/faqs',
    loadChildren: () =>
      import('./views/settings/faqs/faqs.module').then((m) => m.FaqsPageModule),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  // {
  //   path: 'settings/faqs/detail/:id',
  //   loadChildren: () =>
  //     import('./views/settings/faqs/detail/detail.module').then(
  //       (m) => m.DetailPageModule
  //     ),
  //   canActivate: [AuthGuard, RouteGuard],
  //   data: { authGuardPipe: redirectUnauthorizedToLogin },
  // },
  {
    path: 'settings/articles-and-resources',
    loadChildren: () =>
      import(
        './views/settings/articles-and-resources/articles-and-resources.module'
      ).then((m) => m.ArticlesAndResourcesPageModule),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  // {
  //   path: 'settings/articles-and-resources/detail/:id',
  //   loadChildren: () =>
  //     import('./views/articles-and-resources/faqs/detail/detail.module').then(
  //       (m) => m.DetailPageModule
  //     ),
  //   canActivate: [AuthGuard, RouteGuard],
  //   data: { authGuardPipe: redirectUnauthorizedToLogin },
  // },
  {
    path: 'settings/sports',
    loadChildren: () =>
      import('./views/settings/sports/sports.module').then(
        (m) => m.SportsPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'settings/sports/detail/:id',
    loadChildren: () =>
      import('./views/settings/sports/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  {
    path: 'settings/url-listing',
    loadChildren: () =>
      import('./views/settings/url-listing/url-listing.module').then(
        (m) => m.UrlListingPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'settings/url-listing/detail/:id',
    loadChildren: () =>
      import('./views/settings/url-listing/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  {
    path: 'settings/status-listing',
    loadChildren: () =>
      import('./views/settings/status-listing/status-listing.module').then(
        (m) => m.StatusListingPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'settings/status-listing/detail/:id/:type',
    loadChildren: () =>
      import('./views/settings/status-listing/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'settings/season-listing',
    loadChildren: () =>
      import('./views/settings/season-listing/season-listing.module').then(
        (m) => m.SeasonListingPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'settings/season-listing/detail/:id',
    loadChildren: () =>
      import('./views/settings/season-listing/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  {
    path: 'settings/social-accounts',
    loadChildren: () =>
      import('./views/settings/social-accounts/social-accounts.module').then(
        (m) => m.SocialAccountsPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  {
    path: 'settings/social-accounts/detail/:id',
    loadChildren: () =>
      import('./views/settings/social-accounts/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  {
    path: 'settings/user-permissions',
    loadChildren: () =>
      import('./views/settings/user-permissions/user-permissions.module').then(
        (m) => m.UserPermissionsPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  {
    path: 'settings/user-permissions/detail/:id',
    loadChildren: () =>
      import('./views/settings/user-permissions/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  {
    path: 'settings/content-setting',
    loadChildren: () =>
      import('./views/settings/content-setting/content-setting.module').then(
        (m) => m.ContentSettingPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'settings/content-setting/detail/:id',
    loadChildren: () =>
      import('./views/settings/content-setting/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'settings/notification-listing',
    loadChildren: () =>
      import(
        './views/settings/notification-listing/notification-listing.module'
      ).then((m) => m.NotificationListingPageModule),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'settings/notification-listing/detail/:id',
    loadChildren: () =>
      import('./views/settings/notification-listing/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'settings/member-types',
    loadChildren: () =>
      import('./views/settings/member-types/member-types.module').then(
        (m) => m.MemberTypesPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'settings/member-types/detail/:id',
    loadChildren: () =>
      import('./views/settings/member-types/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'announcement',
    loadChildren: () =>
      import('./views/annoucements/annoucements.module').then(
        (m) => m.AnnoucementsPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'announcement/detail/:id',
    loadChildren: () =>
      import('./views/annoucements/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  {
    path: 'settings/checklist-setting',
    loadChildren: () =>
      import(
        './views/settings/checklist-setting/checklist-setting.module'
      ).then((m) => m.ChecklistSettingPageModule),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'settings/checklist-setting/detail/:id',
    loadChildren: () =>
      import('./views/settings/checklist-setting/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'settings/checklist-setting/detail/:id/:type',
    loadChildren: () =>
      import('./views/settings/checklist-setting/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  {
    path: 'assets/block-library',
    loadChildren: () =>
      import('./views/assets/block-library/block-library.module').then(
        (m) => m.BlockLibraryPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'assets/templates',
    loadChildren: () =>
      import('./views/assets/templates/templates.module').then(
        (m) => m.TemplatesPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  {
    path: 'assets/templates/detail/:id',
    loadChildren: () =>
      import('./views/assets/templates/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  {
    path: 'assets/blob-library',
    loadChildren: () =>
      import('./views/assets/blob-library/blob-library.module').then(
        (m) => m.BlobLibraryPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  {
    path: 'assets/blob-library/detail/:container/:id',
    loadChildren: () =>
      import('./views/assets/blob-library/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  {
    path: 'pages',
    loadChildren: () =>
      import('./views/pages/pages.module').then((m) => m.PagesPageModule),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'assets',
    loadChildren: () =>
      import('./views/assets/assets.module').then((m) => m.AssetsPageModule),
    canActivate: [AuthGuard, RouteGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  {
    path: 'sign-in',
    loadChildren: () =>
      import('./views/modal/sign-in/sign-in.module').then(
        (m) => m.SignInPageModule
      ),
  },

  {
    path: 'all-notifications',
    loadChildren: () =>
      import('./views/all-notifications/all-notifications.module').then(
        (m) => m.AllNotificationsPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
  },

  {
    path: 'profile',
    loadChildren: () =>
      import('./views/profile/profile.module').then((m) => m.ProfilePageModule),
  },

  {
    path: 'groups',
    loadChildren: () =>
      import('./views/groups/groups.module').then((m) => m.GroupsPageModule),
    canActivate: [AuthGuard, RouteGuard],
  },

  {
    path: 'groups/init-group',
    loadChildren: () =>
      import('./views/groups/init-group/init-group.module').then(
        (m) => m.InitGroupPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
  },

  {
    path: 'groups/init-group/detail/:id',
    loadChildren: () =>
      import('./views/groups/init-group/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
  },

  {
    path: 'content-blocks',
    loadChildren: () =>
      import('./views/content-blocks/content-blocks.module').then(
        (m) => m.ContentBlocksPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
  },
  {
    path: 'content-blocks/:type',
    loadChildren: () =>
      import('./views/content-blocks/content-blocks.module').then(
        (m) => m.ContentBlocksPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
  },

  {
    path: 'writers',
    loadChildren: () =>
      import('./views/writers/writers.module').then((m) => m.WritersPageModule),
    canActivate: [AuthGuard, RouteGuard],
  },

  {
    path: 'writers/detail/:id',
    loadChildren: () =>
      import('./views/writers/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
  },

  {
    path: 'help-center',
    loadChildren: () =>
      import('./views/help-center/help-center.module').then(
        (m) => m.HelpCenterPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
  },
  {
    path: 'help-center/detail/:id',
    loadChildren: () =>
      import('./views/help-center/detail/detail.module').then(
        (m) => m.DetailPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
  },

  {
    path: 'help-center/sub-topic/:id/:main_topic_id',
    loadChildren: () =>
      import('./views/help-center/sub-topic/sub-topic.module').then(
        (m) => m.SubTopicPageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
  },

  {
    path: 'help-center/view-page/:slug',
    loadChildren: () =>
      import('./views/help-center/view-page/view-page.module').then(
        (m) => m.ViewPagePageModule
      ),
    canActivate: [AuthGuard, RouteGuard],
  },

  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
