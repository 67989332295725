import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { NavController, ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api/api.service';
import { StorageService } from 'src/app/services/azure/storage/storage.service';
import { environment } from 'src/environments/environment';
import { article, source, widget } from '../articles.model';
import { imagnData, quick_edit } from './detail.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class ArticleDetailsBLL {
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  readonly isLoading$: Observable<boolean> = this.isLoading.asObservable();

  public isAddLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  readonly isAddLoading$: Observable<boolean> =
    this.isAddLoading.asObservable();

  private isDeleteLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isDeleteLoading$: Observable<boolean> =
    this.isDeleteLoading.asObservable();

  private isTemplateAddLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isTemplateAddLoading$: Observable<boolean> =
    this.isTemplateAddLoading.asObservable();

  private isTwitterAddLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isTwitterAddLoading$: Observable<boolean> =
    this.isTwitterAddLoading.asObservable();

  private isFacebookAddLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isFacebookAddLoading$: Observable<boolean> =
    this.isFacebookAddLoading.asObservable();

  private isGroupLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isGroupLoading$: Observable<boolean> =
    this.isGroupLoading.asObservable();

  private isMembersLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isMembersLoading$: Observable<boolean> =
    this.isMembersLoading.asObservable();

  private blocksLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly blocksLoading$: Observable<boolean> =
    this.blocksLoading.asObservable();
  // private article: BehaviorSubject<article_with_widgets> = new BehaviorSubject<article_with_widgets>(null);
  // readonly article$: Observable<article_with_widgets> = this.article.asObservable();

  private article: BehaviorSubject<article> = new BehaviorSubject<article>(
    null
  );
  readonly article$: Observable<article> = this.article.asObservable();

  private templates: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  readonly templates$: Observable<any> = this.templates.asObservable();

  private twitterData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  readonly twitterData$: Observable<any> = this.twitterData.asObservable();

  private contentViewer: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  readonly contentViewer$: Observable<any> = this.contentViewer.asObservable();

  private checklist: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  readonly checklist$: Observable<any> = this.checklist.asObservable();

  private contentWidgets: BehaviorSubject<widget[]> = new BehaviorSubject<
    widget[]
  >(null);
  readonly contentWidgets$: Observable<widget[]> =
    this.contentWidgets.asObservable();

  private contentSources: BehaviorSubject<source[]> = new BehaviorSubject<
    source[]
  >(null);
  readonly contentSources$: Observable<source[]> =
    this.contentSources.asObservable();

  private isWidgetLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isWidgetLoading$: Observable<boolean> =
    this.isWidgetLoading.asObservable();

  private isViewLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isViewLoading$: Observable<boolean> =
    this.isViewLoading.asObservable();

  private isImagnLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isImagnLoading$: Observable<boolean> =
    this.isImagnLoading.asObservable();

  private isPlayerLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isPlayerLoading$: Observable<boolean> =
    this.isPlayerLoading.asObservable();

  private isTeamLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isTeamLoading$: Observable<boolean> =
    this.isTeamLoading.asObservable();

  private isTemplateLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isTemplateLoading$: Observable<boolean> =
    this.isTemplateLoading.asObservable();

  private isLeagueLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isLeagueLoading$: Observable<boolean> =
    this.isLeagueLoading.asObservable();

  private isDivisionLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isDivisionLoading$: Observable<boolean> =
    this.isDivisionLoading.asObservable();

  private isConferenceLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isConferenceLoading$: Observable<boolean> =
    this.isConferenceLoading.asObservable();

  private isSportsLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isSportsLoading$: Observable<boolean> =
    this.isSportsLoading.asObservable();

  private isSearchLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isSearchLoading$: Observable<boolean> =
    this.isSearchLoading.asObservable();

  private isStatusLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isStatusLoading$: Observable<boolean> =
    this.isStatusLoading.asObservable();

  private isChecklistLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isChecklistLoading$: Observable<boolean> =
    this.isChecklistLoading.asObservable();

  private isSeasonLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isSeasonLoading$: Observable<boolean> =
    this.isSeasonLoading.asObservable();

  private templateData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    null
  );
  readonly templateData$: Observable<any[]> = this.templateData.asObservable();

  private imagnData: BehaviorSubject<imagnData[]> = new BehaviorSubject<
    imagnData[]
  >(null);
  readonly imagnData$: Observable<imagnData[]> = this.imagnData.asObservable();

  private playerData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  readonly playerData$: Observable<any[]> = this.playerData.asObservable();

  private playerData3: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    null
  );
  readonly playerData3$: Observable<any[]> = this.playerData3.asObservable();

  private seasonData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  readonly seasonData$: Observable<any[]> = this.seasonData.asObservable();

  private defaultData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  readonly defaultData$: Observable<any> = this.defaultData.asObservable();

  private teamData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  readonly teamData$: Observable<any[]> = this.teamData.asObservable();

  private leagueData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  readonly leagueData$: Observable<any[]> = this.leagueData.asObservable();

  private divisionData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    null
  );
  readonly divisionData$: Observable<any[]> = this.divisionData.asObservable();

  private conferenceData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    null
  );
  readonly conferenceData$: Observable<any[]> =
    this.conferenceData.asObservable();

  private sportsData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  readonly sportsData$: Observable<any[]> = this.sportsData.asObservable();

  private searchData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  readonly searchData$: Observable<any[]> = this.searchData.asObservable();

  private statusData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  readonly statusData$: Observable<any[]> = this.statusData.asObservable();

  private groups: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  readonly groups$: Observable<any[]> = this.groups.asObservable();

  private content_groups: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    null
  );
  readonly content_groups$: Observable<any[]> =
    this.content_groups.asObservable();

  private members: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  readonly members$: Observable<any[]> = this.members.asObservable();

  private assignee: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  readonly assignee$: Observable<any[]> = this.assignee.asObservable();

  imageData = [];

  public groupsData = [];
  public groupsContentData = [];
  constructor(
    private api: ApiService,
    private storage: StorageService,
    private toast: ToastController,
    private nav: NavController,
    private router: Router,
    private auth: AuthService,
    private http: HttpClient,
    private ionLoaderService: LoaderService
  ) {}

  get_article(version, id: string, token: any) {
    // this.isLoading.next(true);
    this.isWidgetLoading.next(true);

    let url = '';
    // if(version ==='v2'){
    //  url=   `article/v2?list=true&id=${id}`;
    // }
    // if(version ==='v3'){
    url = `article/v3?list=true&id=${id}`;
    //}

    if (token !== null && token !== undefined) {
      this.api
        .get(url, token)
        .pipe(take(1))
        .subscribe(
          (res) => {
            console.log('res from article', res);
            res['data']['blocks'] = res['data']['blocks'].filter(
              (element: any) => {
                return element.main_content !== null;
              }
            );
            res['data']['blocks'].forEach((element: any) => {
              if (element.type == 'twitter') {
                if (element.main_content) {
                  let twitter = { twitter: element.main_content };
                  element['main_content'] = twitter;
                }
              }
              if (element.type == 'video') {
                if (element.main_content) {
                  let video = { video: element.main_content };
                  element['main_content'] = video;
                }
              }

              if (element.type == 'image') {
                if (element.main_content) {
                  let image = { image: element.main_content };
                  element['main_content'] = image;
                }
              }

              if (element.type == 'person') {
                if (element.main_content) {
                  if (element.main_content) {
                    let person = { person: element.main_content };
                    element['main_content'] = person;
                  }
                }
              }
              if (element.type == 'organization') {
                if (element.main_content) {
                  let organization = { organization: element.main_content };
                  element['main_content'] = organization;
                }
              }

              if (element.type == 'other') {
                if (element.main_content) {
                  let other = { other: element.main_content };
                  element['main_content'] = other;
                }
              }
              if (element.type == 'social_comment') {
                if (element.main_content) {
                  let social_comment = { social_comment: element.main_content };
                  element['main_content'] = social_comment;
                }
              }
              if (element.type == 'number') {
                if (element.main_content) {
                  let number = { number: element.main_content };
                  element['main_content'] = number;
                }
              }

              if (element.type == 'quote') {
                if (element.main_content) {
                  let quote = { quote: element.main_content };
                  element['main_content'] = quote;
                }
              }
              if (element.type == 'text') {
                if (element.main_content) {
                  let text = { text: element.main_content };
                  element['main_content'] = text;
                }
              }
              if (element.type == 'player_card') {
                if (element.main_content) {
                  element.main_content.flip = 'inactive';
                  let player_card = { player_card: element.main_content };
                  element['main_content'] = player_card;
                }
              }
              if (element.type == 'info') {
                if (element.main_content) {
                  element.main_content.flip = 'inactive';
                  let info: any = { info: element.main_content };
                  element['main_content'] = info;
                }
              }
              if (element.type == 'stats') {
                if (element.main_content) {
                  let stats = { stats: element.main_content };
                  element['main_content'] = stats;
                }
              }
              if (element.type == 'standings') {
                if (element.main_content) {
                  let standings = { standings: element.main_content };
                  element['main_content'] = standings;
                }
              }
              if (element.type == 'chart') {
                if (element.main_content) {
                  let chart = { chart: element.main_content };
                  element['main_content'] = chart;
                }
              }
              if (element.type == 'datagrid') {
                if (element.main_content) {
                  let datagrid = { datagrid: element.main_content };
                  element['main_content'] = datagrid;
                }
              }
              if (element.type == 'map') {
                if (element.main_content) {
                  let map = { map: element.main_content };
                  element['main_content'] = map;
                }
              }

              if (element.type == 'list') {
                if (element.main_content) {
                  let list = { list: element.main_content };
                  element['main_content'] = list;
                }
              }
              if (element.type == 'upcoming_games') {
                if (element.main_content) {
                  let upcoming_games = { upcoming_games: element.main_content };
                  element['main_content'] = upcoming_games;
                }
              }
            });

            this.isLoading.next(false);
            this.isWidgetLoading.next(true);

            if (res.data.content.length > 0) {
              this.article.next(res.data.content[0]);
            }
            this.contentViewer.next(res.data.content_viewer);
            this.contentWidgets.next(res.data.blocks);
            this.contentSources.next(res.data.sources);
          },
          async (err) => {
            if (err instanceof HttpErrorResponse && err.status === 401) {
              // //this.auth.refreshtoken()
            }
            this.isLoading.next(false);
            console.log(err);
            await this.showErr(err.error.message);
          }
        );
    }
  }

  reset_sources() {
    this.contentSources.next([]);
  }

  get_checklist(token, slug?: any, id?: any) {
    this.isChecklistLoading.next(true);
    let url = `checklist?checklist=true`;
    if (id !== '-1') {
      url = url + '&content_id=' + id;
    }

    if (slug) {
      url = url + '&slug=' + slug;
    }
    if (token !== null && token !== undefined) {
      this.api
        .get(url, token)
        .pipe(
          filter((data) => data !== null && data.length !== 0),
          take(1)
        )
        .subscribe(
          (res) => {
            this.isChecklistLoading.next(false);
            if (res.data) {
              if (res.data.length > 0) {
                // res.data[0].checklists.map((obj)=>{
                //     if(obj.status_checked ==0){
                //         obj.status_checked=false;
                //     }

                // })

                this.checklist.next(res.data[0].checklists);
              }
            }
          },
          async (err) => {
            if (err instanceof HttpErrorResponse && err.status === 401) {
              // //this.auth.refreshtoken()
            }
            this.isChecklistLoading.next(false);
            console.log(err);
            await this.showErr(err.error.message);
          }
        );
    }
  }

  get_groups(token: any, article_id?: any) {
    this.isGroupLoading.next(true);

    let url = `group?list=true&page=${1}&size=${50}`;
    if (article_id) {
      url += `&content_id=${article_id}&content_assignee=true`;
    }

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          // this.groups.next(res.data);
          this.groupsData = res.data;

          this.isGroupLoading.next(false);
        },
        (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isGroupLoading.next(false);
        }
      );
  }
  get_content_groups(article_id, token: any) {
    this.isGroupLoading.next(true);

    let url = `group?group_content=true&content_id=${article_id}`;
    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          console.log('res from groups=>', res);
          // this.content_groups.next(res.data);
          this.groupsContentData = res.data;
          this.isGroupLoading.next(false);
        },
        (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isGroupLoading.next(false);
        }
      );
  }

  async add_group_content(group_content_obj: any, token: any) {
    this.isAddLoading.next(true);

    let url = `group`;

    this.api
      .postUser(url, group_content_obj, token)
      .pipe(take(1))
      .subscribe(
        async (res) => {
          this.isAddLoading.next(false);
          console.log('res', res);
          // this.group.next(res)
          const t = await this.toast.create({
            header: 'Content Assigned',
            message: `Content has been assigned to group successfully.`,
            position: 'bottom',
            duration: 5000,
            color: 'secondary',
          });
          await t.present();
          // this.router.navigate(['groups/init-group']);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          console.log('err', err);
          const t = await this.toast.create({
            header: 'Error',
            message: err.error.message,
            position: 'bottom',
            duration: 5000,
            color: 'danger',
          });
          await t.present();
          this.isAddLoading.next(false);
        }
      );
  }

  async delete_group_contents(content_arr, group_id, token: any) {
    let url = `group`;

    let object = {
      group_id: group_id,
      content_id: content_arr,
      group_content: true,
    };

    this.api
      .deleteUrl(url, object, token)
      .pipe(take(1))
      .subscribe(
        async (res) => {
          console.log('res from group contents delete call', res);
          // const t = await this.toast.create({
          //     header: 'Group Created',
          //     message: `Status has been created successfully.`,
          //     position: 'bottom',
          //     duration: 5000,
          //     color: 'secondary'
          // });
          // await t.present();
          //this.router.navigate(['settings/status-listing'])
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          console.log('err', err);
          const t = await this.toast.create({
            header: 'Error',
            message: err.error.message,
            position: 'bottom',
            duration: 5000,
            color: 'danger',
          });
          await t.present();
        }
      );
  }

  async add_group_content2(group_content_obj: any, token: any) {
    this.isAddLoading.next(true);

    let url = `group`;

    this.api
      .postUser(url, group_content_obj, token)
      .pipe(take(1))
      .subscribe(
        async (res) => {
          this.isAddLoading.next(false);
          console.log('res', res);
          // this.group.next(res)
          const t = await this.toast.create({
            header: 'Content Added',
            message: `Contents has been added successfully.`,
            position: 'bottom',
            duration: 5000,
            color: 'secondary',
          });
          await t.present();
          //   this.router.navigate(['groups/init-group']);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          console.log('err', err);
          const t = await this.toast.create({
            header: 'Error',
            message: err.error.message,
            position: 'bottom',
            duration: 5000,
            color: 'danger',
          });
          await t.present();
          this.isAddLoading.next(false);
        }
      );
  }

  async get_group_members(groupId: any, token: any) {
    this.isMembersLoading.next(true);
    let url = `group?group_member=true&member_type=creator&group_id=${groupId}`;

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        async (res) => {
          this.isMembersLoading.next(false);
          console.log('res from group members', res);
          this.members.next(res.data);
          // const t = await this.toast.create({
          //     header: 'Group Created',
          //     message: `Status has been created successfully.`,
          //     position: 'bottom',
          //     duration: 5000,
          //     color: 'secondary'
          // });
          // await t.present();
          //this.router.navigate(['settings/status-listing'])
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          console.log('err', err);
          const t = await this.toast.create({
            header: 'Error',
            message: err.error.message,
            position: 'bottom',
            duration: 5000,
            color: 'danger',
          });
          await t.present();
          this.isMembersLoading.next(false);
        }
      );
  }

  async get_content_assignee(contentId: any, groupId: any, token: any) {
    this.isMembersLoading.next(true);
    let url = `group?content_assignee=true&content_id=${contentId}&group_id=${groupId}`;

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        async (res) => {
          this.isMembersLoading.next(false);
          console.log('res from content assignee', res);
          if (res.data) {
            this.assignee.next(res.data);
          }

          // const t = await this.toast.create({
          //     header: 'Group Created',
          //     message: `Status has been created successfully.`,
          //     position: 'bottom',
          //     duration: 5000,
          //     color: 'secondary'
          // });
          // await t.present();
          //this.router.navigate(['settings/status-listing'])
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          console.log('err', err);
          const t = await this.toast.create({
            header: 'Error',
            message: err.error.message,
            position: 'bottom',
            duration: 5000,
            color: 'danger',
          });
          await t.present();
          this.isMembersLoading.next(false);
        }
      );
  }

  async assign_member_to_content(member_content_obj: any, token: any) {
    let url = `group`;
    this.api
      .postUser(url, member_content_obj, token)
      .pipe(take(1))
      .subscribe(
        async (res) => {
          console.log('res', res);
          // this.group.next(res)
          const t = await this.toast.create({
            header: 'Member Assigned',
            message: `Member has been assigned successfully to content!`,
            position: 'bottom',
            duration: 5000,
            color: 'secondary',
          });
          await t.present();
          //this.router.navigate(['settings/status-listing'])
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          console.log('err', err);
          const t = await this.toast.create({
            header: 'Error',
            message: err.error.message,
            position: 'bottom',
            duration: 5000,
            color: 'danger',
          });
          await t.present();
          this.isAddLoading.next(false);
        }
      );
  }

  async delete_assignee(assignee_obj, token: any) {
    console.log('called 11');
    this.isMembersLoading.next(true);
    let url = `group`;

    this.api
      .deleteUrl(url, assignee_obj, token)
      .pipe(take(1))
      .subscribe(
        async (res) => {
          this.isMembersLoading.next(false);
          console.log('res from content assignee delete', res);
          // const t = await this.toast.create({
          //     header: 'Group Created',
          //     message: `Status has been created successfully.`,
          //     position: 'bottom',
          //     duration: 5000,
          //     color: 'secondary'
          // });
          // await t.present();
          //this.router.navigate(['settings/status-listing'])
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          console.log('err', err);
          const t = await this.toast.create({
            header: 'Error',
            message: err.error.message,
            position: 'bottom',
            duration: 5000,
            color: 'danger',
          });
          await t.present();
          this.isMembersLoading.next(false);
        }
      );
  }

  set_checklist(token, selected?: any, unselected?: any) {
    this.isChecklistLoading.next(true);
    let url = `checklist`;
    let obj = {
      selected: {},
      unselected: {},
      content_status: true,
    };

    if (selected) {
      obj.selected = selected;
    }
    if (unselected) {
      obj.unselected = unselected;
    }

    if (token !== null && token !== undefined) {
      this.api
        .postUser(url, obj, token)
        .pipe(
          filter((data) => data !== null && data.length !== 0),
          take(1)
        )
        .subscribe(
          (res) => {
            this.isChecklistLoading.next(false);
            // if(res.data.length>0){
            //     this.checklist.next(res.data[0].checklists)
            // }
          },
          async (err) => {
            if (err instanceof HttpErrorResponse && err.status === 401) {
              // //this.auth.refreshtoken()
            }
            this.isChecklistLoading.next(false);
            console.log(err);
            await this.showErr(err.error.message);
          }
        );
    }
  }

  // set_checklist(checkArr){
  //     this.checklist.next(checkArr)
  // }

  reset_checklist() {
    this.checklist.next([]);
  }

  delete_viewer(token, content_id) {
    let l = content_id;
    let url = `content_viewer`;
    if (token !== null && token !== undefined) {
      this.api
        .delete(url, l, token)
        .pipe(
          filter((data) => data !== null && data.length !== 0),
          take(1)
        )
        .subscribe(
          (res) => {
            console.log('res', res);
          },
          async (err) => {
            if (err instanceof HttpErrorResponse && err.status === 401) {
              // //this.auth.refreshtoken()
            }
            console.log(err);
            await this.showErr(err.error.message);
          }
        );
    }
  }

  get_cropped_image(url, token) {
    const headers = {
      'Content-Type': 'application/json',
      type: 'Bearer token',
      Authorization: token,
    };
    let Url = `imagn?crop_v2=true&url=${url}`;

    return this.http.get(environment.api_url + Url, {
      headers: new HttpHeaders(headers),
    });
  }

  async create_article(article: any, image: Blob) {
    this.isAddLoading.next(true);

    // let file_name = '';
    // if (image)
    //     file_name = await this.storage.uploadFile(image);
    // article.image = file_name;

    let url = `article/v3`;
    this.api
      .post(url, article)
      .pipe(take(1))
      .subscribe(
        async (res) => {
          this.isAddLoading.next(false);
          const t = await this.toast.create({
            header: 'Post Created',
            message: `Post has been created successfully.`,
            position: 'bottom',
            duration: 5000,
            color: 'secondary',
          });
          await t.present();
          this.nav.back();
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          const t = await this.toast.create({
            header: 'Error',
            message:
              'There was an issue creating new post. Please try again shortly.',
            position: 'bottom',
            duration: 5000,
            color: 'danger',
          });
          await t.present();
          this.isAddLoading.next(false);
        }
      );
  }

  // async edit_article(article: any, image: Blob, id: string, token: any) {
  //   this.isAddLoading.next(true);

  //   let file_name = '';

  //   if (image) {
  //     file_name = await this.storage.uploadFile(image);
  //     article.image = file_name;
  //   }

  //   article.id = id;
  //   let url = `article/v3`;
  //   this.api
  //     .put(url, article, token)
  //     .pipe(take(1))
  //     .subscribe(
  //       async (res) => {
  //         this.isAddLoading.next(false);
  //         const t = await this.toast.create({
  //           header: 'Post Edit',
  //           message: `Post has been edited successfully.`,
  //           position: 'bottom',
  //           duration: 5000,
  //           color: 'secondary',
  //         });
  //         await t.present();
  //         // window.location.reload()
  //         // this.nav.back();
  //       },
  //       async (err) => {
  //         if (err instanceof HttpErrorResponse && err.status === 401) {
  //           // //this.auth.refreshtoken()
  //         }
  //         const t = await this.toast.create({
  //           header: 'Error',
  //           message: err.error.message,
  //           position: 'bottom',
  //           duration: 5000,
  //           color: 'danger',
  //         });
  //         await t.present();
  //         this.isAddLoading.next(false);
  //       }
  //     );
  // }

  async edit_article(article: any, image: Blob, id: string, token: any) {
    try {
      this.isAddLoading.next(true);
      let file_name = '';

      if (image) {
        file_name = await this.storage.uploadFile(image);
        article.image = file_name;
      }

      article.id = id;
      let url = `article/v3`;
      this.blocksLoading.next(true);
      const response = await new Promise((resolve, reject) => {
        this.api
          .put(url, article, token)
          .pipe(take(1))
          .subscribe(
            (res) => {
              res['blocks'] = res['blocks'].filter((element: any) => {
                return element.main_content !== null;
              });
              res['blocks'].forEach((element: any) => {
                if (element.type == 'twitter') {
                  if (element.main_content) {
                    let twitter = { twitter: element.main_content };
                    element['main_content'] = twitter;
                  }
                }
                if (element.type == 'video') {
                  if (element.main_content) {
                    let video = { video: element.main_content };
                    element['main_content'] = video;
                  }
                }

                if (element.type == 'image') {
                  if (element.main_content) {
                    let image = { image: element.main_content };
                    element['main_content'] = image;
                  }
                }

                if (element.type == 'person') {
                  if (element.main_content) {
                    if (element.main_content) {
                      let person = { person: element.main_content };
                      element['main_content'] = person;
                    }
                  }
                }
                if (element.type == 'organization') {
                  if (element.main_content) {
                    let organization = { organization: element.main_content };
                    element['main_content'] = organization;
                  }
                }

                if (element.type == 'other') {
                  if (element.main_content) {
                    let other = { other: element.main_content };
                    element['main_content'] = other;
                  }
                }
                if (element.type == 'social_comment') {
                  if (element.main_content) {
                    let social_comment = {
                      social_comment: element.main_content,
                    };
                    element['main_content'] = social_comment;
                  }
                }
                if (element.type == 'number') {
                  if (element.main_content) {
                    let number = { number: element.main_content };
                    element['main_content'] = number;
                  }
                }

                if (element.type == 'quote') {
                  if (element.main_content) {
                    let quote = { quote: element.main_content };
                    element['main_content'] = quote;
                  }
                }
                if (element.type == 'text') {
                  if (element.main_content) {
                    let text = { text: element.main_content };
                    element['main_content'] = text;
                  }
                }
                if (element.type == 'player_card') {
                  if (element.main_content) {
                    element.main_content.flip = 'inactive';
                    let player_card = { player_card: element.main_content };
                    element['main_content'] = player_card;
                  }
                }
                if (element.type == 'info') {
                  if (element.main_content) {
                    element.main_content.flip = 'inactive';
                    let info = { info: element.main_content };
                    element['main_content'] = info;
                  }
                }
                if (element.type == 'stats') {
                  if (element.main_content) {
                    let stats = { stats: element.main_content };
                    element['main_content'] = stats;
                  }
                }
                if (element.type == 'standings') {
                  if (element.main_content) {
                    let standings = { standings: element.main_content };
                    element['main_content'] = standings;
                  }
                }
                if (element.type == 'chart') {
                  if (element.main_content) {
                    let chart = { chart: element.main_content };
                    element['main_content'] = chart;
                  }
                }
                if (element.type == 'datagrid') {
                  if (element.main_content) {
                    let datagrid = { datagrid: element.main_content };
                    element['main_content'] = datagrid;
                  }
                }
                if (element.type == 'map') {
                  if (element.main_content) {
                    let map = { map: element.main_content };
                    element['main_content'] = map;
                  }
                }
                if (element.type == 'list') {
                  if (element.main_content) {
                    let list = { list: element.main_content };
                    element['main_content'] = list;
                  }
                }
                if (element.type == 'upcoming_games') {
                  if (element.main_content) {
                    let upcoming_games = {
                      upcoming_games: element.main_content,
                    };
                    element['main_content'] = upcoming_games;
                  }
                }
              });
              console.log('res now', res);
              this.contentWidgets.next(res.blocks);
              this.blocksLoading.next(false);
              resolve(res);
            },
            (err) => {
              this.blocksLoading.next(false);
              reject(err);
            }
          );
      });

      this.isAddLoading.next(false);

      const t = await this.toast.create({
        header: 'Post Edit',
        message: `Post has been edited successfully.`,
        position: 'bottom',
        duration: 5000,
        color: 'secondary',
      });
      await t.present();
      // window.location.reload()
      // this.nav.back();
    } catch (err) {
      if (err instanceof HttpErrorResponse && err.status === 401) {
        //this.auth.refreshtoken();
        // //this.auth.refreshtoken()
      }
      const t = await this.toast.create({
        header: 'Error',
        message:
          'There was an issue editting this post. Please try again shortly.',
        position: 'bottom',
        duration: 3000,
        color: 'danger',
      });
      await t.present();
      this.isAddLoading.next(false);
      this.blocksLoading.next(false);
    }
  }

  async delete_article(id: string, editorsPick: boolean, token: any) {
    this.isDeleteLoading.next(true);

    let l: quick_edit = {
      id: id,
      quick_edit: true,
      status: 'inactive',
      is_editors_pick: editorsPick,
    };
    let url = `article`;
    this.api
      .put(url, l, token)
      .pipe(take(1))
      .subscribe(
        async (res) => {
          this.isDeleteLoading.next(false);
          const t = await this.toast.create({
            header: 'Post Delete',
            message: `Post has been deleted successfully.`,
            position: 'bottom',
            duration: 5000,
            color: 'secondary',
          });
          await t.present();
          this.nav.back();
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          const t = await this.toast.create({
            header: 'Error',
            message:
              'There was an issue deleting this post. Please try again shortly.',
            position: 'bottom',
            duration: 5000,
            color: 'danger',
          });
          await t.present();
          this.isDeleteLoading.next(false);
        }
      );
  }

  async delete_article_permanent(id: string, token: any) {
    this.isDeleteLoading.next(true);

    let l = id;

    let url = `article`;
    this.api
      .delete(url, l, token)
      .pipe(take(1))
      .subscribe(
        async (res) => {
          this.isDeleteLoading.next(false);
          const t = await this.toast.create({
            header: 'Post Delete',
            message: `Post has been deleted successfully.`,
            position: 'bottom',
            duration: 5000,
            color: 'secondary',
          });
          await t.present();
          this.router.navigate(['articles']);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          const t = await this.toast.create({
            header: 'Error',
            message:
              'There was an issue deleting this post. Please try again shortly.',
            position: 'bottom',
            duration: 5000,
            color: 'danger',
          });
          await t.present();
          this.isDeleteLoading.next(false);
        }
      );
  }

  get_imagn_Data(searchString: string, token: any) {
    this.isImagnLoading.next(true);
    let url = `imagn?list=true&terms=${searchString}&limit=15`;
    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.imageData = [];
          res.data.forEach((element) => {
            return this.imageData.push(element[0]);
          });

          this.isImagnLoading.next(false);
          this.imagnData.next(this.imageData);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isImagnLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_groups_(searchString: string, token: any) {
    this.isGroupLoading.next(true);

    let url = `group?list=true&page=${1}&size=${50}&keyword=${searchString}`;

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          this.groups.next(res.data);
          //this.groupsData = res.data;

          this.isGroupLoading.next(false);
        },
        (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isGroupLoading.next(false);
        }
      );
  }

  get_library_Data(searchString: string, token: any) {
    this.isImagnLoading.next(true);
    let url = `gallery?list=true&image_source=imagn&keyword=${searchString}&limit=15`;
    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          console.log('res', res);
          this.imageData = [];
          res.data.forEach((element) => {
            element.previewUrl = element.url;
            element.caption = '';
            element.headline = '';
            element.fileSize = '';
            element.fileType = '';

            if (element.meta_data) {
              element.caption = element.meta_data.caption;
              element.headline = element.meta_data.description;
              element.fileType = element.meta_data.fileType;
              element.fileSize = element.meta_data.fileSize;
            }
            this.imageData.push(element);
          });
          console.log('imageData', this.imageData);
          this.isImagnLoading.next(false);
          this.imagnData.next(this.imageData);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isImagnLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_unSplash_Data(searchString: string, token: any) {
    this.isImagnLoading.next(true);
    let url = `unsplash_search_image?list=true&terms=${searchString}&limit=15`;
    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          console.log('res', res);
          this.imageData = [];
          res.data.data.forEach((element) => {
            element.previewUrl = element.urls.full;
            element.thumbnail = element.urls.thumb;
            element.caption = element.description ? element.description : '';
            element.headline = element.alt_description
              ? element.alt_description
              : '';
            element.fileSize = '';
            element.fileType = '';
            element.user = element.user;
            element.tags = element.tags;
            element.credit = element.user.name;
            if (element.meta_data) {
              element.caption = element.meta_data.caption;
              element.headline = element.meta_data.description;
              element.fileType = element.meta_data.fileType;
              element.fileSize = element.meta_data.fileSize;
            }
            this.imageData.push(element);
          });
          console.log('imageData', this.imageData);
          this.isImagnLoading.next(false);
          this.imagnData.next(this.imageData);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isImagnLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_default_val(slug: any, token: any) {
    let url = `league_season?list=true&is_default=true&is_active=true&slug=${slug}`;
    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          if (res.data.length > 0) {
            console.log('data from default api', res.data[0].seasons[0].year);
            this.defaultData.next(res.data[0].seasons[0].year);
          }

          //this.playerData.next(res.data);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          //this.isPlayerLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_player_Data(searchString: string, token: any) {
    this.isPlayerLoading.next(true);

    let url = `player?list=true&page=1&size=10&league_id`;

    url += `&keyword=${searchString}`;

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.isPlayerLoading.next(false);
          this.playerData.next(res.data);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isPlayerLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_season_Data(league: any, token: any) {
    this.isSeasonLoading.next(true);

    let url = `consumer?league_season=true`;

    url += `&slug=${league.toLocaleLowerCase()}`;

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          console.log('res from season', res);
          this.isSeasonLoading.next(false);
          if (res.data.length > 0) {
            res.data.map((obj) => {
              this.seasonData.next(obj.seasons);
            });
          }
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isSeasonLoading.next(false);
          await this.showErr(err.error.message);
        }
      );
  }

  get_sports_Data(token: any, id?: string, searchString?: string) {
    this.isSportsLoading.next(true);

    let url = `sport?list=true&drop_down=true`;

    if (id) {
      url += `&id=${id}`;
    }

    if (searchString) {
      url += `&keywords=${searchString}`;
    }

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.isSportsLoading.next(false);
          this.sportsData.next(res.data);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isSportsLoading.next(false);
          await this.showErr(err.error.message);
        }
      );
  }

  get_playerstats_Data(
    searchString: string,
    league: string,
    type: string,
    token: any
  ) {
    this.isPlayerLoading.next(true);

    let season = '2022-2023-regular';
    if (league === 'MLB') {
      season = '2023-regular';
    }

    let url = `consumer?full_table_stats_v2=true&property=passYards&sort_order=desc&page=1&size=20&season=${season}`;
    url += `&league=${league}&type=${type}`;

    url += `&player=${searchString}`;

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.isPlayerLoading.next(false);
          this.playerData.next(res.data);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isPlayerLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_playerstats(
    searchString: string,
    league: string,
    type: string,
    token: any,
    property?: string,
    season?: any
  ) {
    this.isPlayerLoading.next(true);

    if (season === '') {
      season = '2022-2023-regular';
    }

    let game_type = type;
    if (league === 'NBA') {
      if (property === 'astPerGame' || property === 'ptsPerGame') {
        game_type = 'offense';
      }
      if (property === 'fgPct') {
        game_type = 'fieldGoals';
      }
      if (property === 'rebPerGame') {
        game_type = 'rebounds';
      }
      if (property === 'blkPerGame' || property === 'stlPerGame') {
        game_type = 'defense';
      }

      if (property === 'fgPct' || property === 'fg3PtPct') {
        game_type = 'fieldGoals';
      }
      if (property === 'ftPct') {
        game_type = 'freeThrows';
      }
      if (
        property === 'offRebPerGame' ||
        property === 'defRebPerGame' ||
        property === 'rebPerGame'
      ) {
        game_type = 'rebounds';
      }
      if (property === 'astPerGame' || property === 'ptsPerGame') {
        game_type = 'offense';
      }
      if (
        property === 'blkPerGame' ||
        property === 'stlPerGame' ||
        property === 'tovPerGame'
      ) {
        game_type = 'defense';
      }
      if (property === 'plusMinus') {
        game_type = 'miscellaneous';
      }
    } else if (league === 'NFL') {
      if (
        property === 'interceptions' ||
        property === 'intYds' ||
        property === 'intTD' ||
        property === 'passesDefended'
      ) {
        game_type = 'interceptions';
      }
      if (property === 'fumForced' || property === 'fumOppRec') {
        game_type = 'fumbles';
      }
      if (
        property === 'tackleTotal' ||
        property === 'tackleSolo' ||
        property === 'tackleAst' ||
        property === 'tacklesForLoss' ||
        property === 'sacks' ||
        property === 'sackYds'
      ) {
        game_type = 'tackles';
      }
      if (
        property === 'position' ||
        property === 'team' ||
        property === 'player'
      ) {
        game_type = '';
      }

      if (
        property === 'interceptions' ||
        property === 'intYds' ||
        property === 'intTD' ||
        property === 'passesDefended'
      ) {
        game_type = 'interceptions';
      }
      if (property === 'fumForced' || property === 'fumOppRec') {
        game_type = 'fumbles';
      }
      if (
        property === 'tackleTotal' ||
        property === 'tackleSolo' ||
        property === 'tackleAst' ||
        property === 'tacklesForLoss' ||
        property === 'sacks' ||
        property === 'sackYds'
      ) {
        game_type = 'tackles';
      }
    } else if (league === 'NHL') {
      if (property === 'plusMinus') {
        game_type = 'skating';
      } else if (property === 'penalties') {
        game_type = 'penalties';
      } else {
        game_type = type;
      }
    }
    let url = `consumer?full_table_stats_v2=true&season=${season}`;
    url += `&league=${league}&type=${game_type}`;

    url += `&player=${searchString}`;

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.isPlayerLoading.next(false);
          this.playerData.next(res.data);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isPlayerLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_playerstats3(
    league: string,
    type: string,
    token: any,
    season: any,
    sort_order: any,
    property?: string
  ) {
    this.isPlayerLoading.next(true);
    let game_type = type;
    if (league === 'NBA') {
      if (property === 'astPerGame' || property === 'ptsPerGame') {
        game_type = 'offense';
      }
      if (property === 'fgPct') {
        game_type = 'fieldGoals';
      }
      if (property === 'rebPerGame') {
        game_type = 'rebounds';
      }
      if (property === 'blkPerGame' || property === 'stlPerGame') {
        game_type = 'defense';
      }

      if (property === 'fgPct' || property === 'fg3PtPct') {
        game_type = 'fieldGoals';
      }
      if (property === 'ftPct') {
        game_type = 'freeThrows';
      }
      if (
        property === 'offRebPerGame' ||
        property === 'defRebPerGame' ||
        property === 'rebPerGame'
      ) {
        game_type = 'rebounds';
      }
      if (property === 'astPerGame' || property === 'ptsPerGame') {
        game_type = 'offense';
      }
      if (
        property === 'blkPerGame' ||
        property === 'stlPerGame' ||
        property === 'tovPerGame'
      ) {
        game_type = 'defense';
      }
      if (property === 'plusMinus') {
        game_type = 'miscellaneous';
      }
    } else if (league === 'NFL') {
      if (
        property === 'interceptions' ||
        property === 'intYds' ||
        property === 'intTD' ||
        property === 'passesDefended'
      ) {
        game_type = 'interceptions';
      }
      if (property === 'fumForced' || property === 'fumOppRec') {
        game_type = 'fumbles';
      }
      if (
        property === 'tackleTotal' ||
        property === 'tackleSolo' ||
        property === 'tackleAst' ||
        property === 'tacklesForLoss' ||
        property === 'sacks' ||
        property === 'sackYds'
      ) {
        game_type = 'tackles';
      }
      if (
        property === 'position' ||
        property === 'team' ||
        property === 'player'
      ) {
        game_type = '';
      }

      if (
        property === 'interceptions' ||
        property === 'intYds' ||
        property === 'intTD' ||
        property === 'passesDefended'
      ) {
        game_type = 'interceptions';
      }
      if (property === 'fumForced' || property === 'fumOppRec') {
        game_type = 'fumbles';
      }
      if (
        property === 'tackleTotal' ||
        property === 'tackleSolo' ||
        property === 'tackleAst' ||
        property === 'tacklesForLoss' ||
        property === 'sacks' ||
        property === 'sackYds'
      ) {
        game_type = 'tackles';
      }
    } else if (league === 'NHL') {
      if (property === 'plusMinus') {
        game_type = 'skating';
      } else if (property === 'penalties') {
        game_type = 'penalties';
      } else {
        game_type = type;
      }
    }
    let url = `consumer?full_table_stats_v2=true&property=${property}&season=${season}&size=500&page=1&sort_order=${sort_order}`;
    url += `&league=${league}&type=${game_type}`;

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          console.log('res from player stats 3', res);
          this.isPlayerLoading.next(false);
          this.playerData3.next(res.data);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isPlayerLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_playerstats_team3(
    team: string,
    league: string,
    type: string,
    token: any,
    season: any,
    sort_order: any,
    property?: string
  ) {
    this.isPlayerLoading.next(true);
    let game_type = type;
    if (league === 'NBA') {
      if (property === 'astPerGame' || property === 'ptsPerGame') {
        game_type = 'offense';
      }
      if (property === 'fgPct') {
        game_type = 'fieldGoals';
      }
      if (property === 'rebPerGame') {
        game_type = 'rebounds';
      }
      if (property === 'blkPerGame' || property === 'stlPerGame') {
        game_type = 'defense';
      }

      if (property === 'fgPct' || property === 'fg3PtPct') {
        game_type = 'fieldGoals';
      }
      if (property === 'ftPct') {
        game_type = 'freeThrows';
      }
      if (
        property === 'offRebPerGame' ||
        property === 'defRebPerGame' ||
        property === 'rebPerGame'
      ) {
        game_type = 'rebounds';
      }
      if (property === 'astPerGame' || property === 'ptsPerGame') {
        game_type = 'offense';
      }
      if (
        property === 'blkPerGame' ||
        property === 'stlPerGame' ||
        property === 'tovPerGame'
      ) {
        game_type = 'defense';
      }
      if (property === 'plusMinus') {
        game_type = 'miscellaneous';
      }
    } else if (league === 'NFL') {
      if (
        property === 'interceptions' ||
        property === 'intYds' ||
        property === 'intTD' ||
        property === 'passesDefended'
      ) {
        game_type = 'interceptions';
      }
      if (property === 'fumForced' || property === 'fumOppRec') {
        game_type = 'fumbles';
      }
      if (
        property === 'tackleTotal' ||
        property === 'tackleSolo' ||
        property === 'tackleAst' ||
        property === 'tacklesForLoss' ||
        property === 'sacks' ||
        property === 'sackYds'
      ) {
        game_type = 'tackles';
      }
      if (
        property === 'position' ||
        property === 'team' ||
        property === 'player'
      ) {
        game_type = '';
      }

      if (
        property === 'interceptions' ||
        property === 'intYds' ||
        property === 'intTD' ||
        property === 'passesDefended'
      ) {
        game_type = 'interceptions';
      }
      if (property === 'fumForced' || property === 'fumOppRec') {
        game_type = 'fumbles';
      }
      if (
        property === 'tackleTotal' ||
        property === 'tackleSolo' ||
        property === 'tackleAst' ||
        property === 'tacklesForLoss' ||
        property === 'sacks' ||
        property === 'sackYds'
      ) {
        game_type = 'tackles';
      }
    } else if (league === 'NHL') {
      if (property === 'plusMinus') {
        game_type = 'skating';
      } else if (property === 'penalties') {
        game_type = 'penalties';
      } else {
        game_type = type;
      }
    }
    let url = `consumer?full_table_stats_v2=true&property=${property}&season=${season}&size=500&page=1&sort_order=${sort_order}`;
    url += `&league=${league}&type=${game_type}&team_id=${team}`;

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          console.log('res from player stats 3', res);
          this.isPlayerLoading.next(false);
          this.playerData3.next(res.data);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isPlayerLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_playerstats_div3(
    division: string,
    league: string,
    type: string,
    token: any,
    season: any,
    sort_order: any,
    property?: string
  ) {
    this.isPlayerLoading.next(true);
    let game_type = type;
    if (league === 'NBA') {
      if (property === 'astPerGame' || property === 'ptsPerGame') {
        game_type = 'offense';
      }
      if (property === 'fgPct') {
        game_type = 'fieldGoals';
      }
      if (property === 'rebPerGame') {
        game_type = 'rebounds';
      }
      if (property === 'blkPerGame' || property === 'stlPerGame') {
        game_type = 'defense';
      }

      if (property === 'fgPct' || property === 'fg3PtPct') {
        game_type = 'fieldGoals';
      }
      if (property === 'ftPct') {
        game_type = 'freeThrows';
      }
      if (
        property === 'offRebPerGame' ||
        property === 'defRebPerGame' ||
        property === 'rebPerGame'
      ) {
        game_type = 'rebounds';
      }
      if (property === 'astPerGame' || property === 'ptsPerGame') {
        game_type = 'offense';
      }
      if (
        property === 'blkPerGame' ||
        property === 'stlPerGame' ||
        property === 'tovPerGame'
      ) {
        game_type = 'defense';
      }
      if (property === 'plusMinus') {
        game_type = 'miscellaneous';
      }
    } else if (league === 'NFL') {
      if (
        property === 'interceptions' ||
        property === 'intYds' ||
        property === 'intTD' ||
        property === 'passesDefended'
      ) {
        game_type = 'interceptions';
      }
      if (property === 'fumForced' || property === 'fumOppRec') {
        game_type = 'fumbles';
      }
      if (
        property === 'tackleTotal' ||
        property === 'tackleSolo' ||
        property === 'tackleAst' ||
        property === 'tacklesForLoss' ||
        property === 'sacks' ||
        property === 'sackYds'
      ) {
        game_type = 'tackles';
      }
      if (
        property === 'position' ||
        property === 'team' ||
        property === 'player'
      ) {
        game_type = '';
      }

      if (
        property === 'interceptions' ||
        property === 'intYds' ||
        property === 'intTD' ||
        property === 'passesDefended'
      ) {
        game_type = 'interceptions';
      }
      if (property === 'fumForced' || property === 'fumOppRec') {
        game_type = 'fumbles';
      }
      if (
        property === 'tackleTotal' ||
        property === 'tackleSolo' ||
        property === 'tackleAst' ||
        property === 'tacklesForLoss' ||
        property === 'sacks' ||
        property === 'sackYds'
      ) {
        game_type = 'tackles';
      }
    } else if (league === 'NHL') {
      if (property === 'plusMinus') {
        game_type = 'skating';
      } else if (property === 'penalties') {
        game_type = 'penalties';
      } else {
        game_type = type;
      }
    }
    let url = `consumer?full_table_stats_v2=true&property=${property}&season=${season}&size=500&page=1&sort_order=${sort_order}`;
    url += `&league=${league}&type=${game_type}&divison_id=${division}`;

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          console.log('res from player stats 3', res);
          this.isPlayerLoading.next(false);
          this.playerData3.next(res.data);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isPlayerLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_playerstats_conf3(
    conference: string,
    league: string,
    type: string,
    token: any,
    season: any,
    sort_order: any,
    property?: string
  ) {
    this.isPlayerLoading.next(true);
    let game_type = type;
    if (league === 'NBA') {
      if (property === 'astPerGame' || property === 'ptsPerGame') {
        game_type = 'offense';
      }
      if (property === 'fgPct') {
        game_type = 'fieldGoals';
      }
      if (property === 'rebPerGame') {
        game_type = 'rebounds';
      }
      if (property === 'blkPerGame' || property === 'stlPerGame') {
        game_type = 'defense';
      }

      if (property === 'fgPct' || property === 'fg3PtPct') {
        game_type = 'fieldGoals';
      }
      if (property === 'ftPct') {
        game_type = 'freeThrows';
      }
      if (
        property === 'offRebPerGame' ||
        property === 'defRebPerGame' ||
        property === 'rebPerGame'
      ) {
        game_type = 'rebounds';
      }
      if (property === 'astPerGame' || property === 'ptsPerGame') {
        game_type = 'offense';
      }
      if (
        property === 'blkPerGame' ||
        property === 'stlPerGame' ||
        property === 'tovPerGame'
      ) {
        game_type = 'defense';
      }
      if (property === 'plusMinus') {
        game_type = 'miscellaneous';
      }
    } else if (league === 'NFL') {
      if (
        property === 'interceptions' ||
        property === 'intYds' ||
        property === 'intTD' ||
        property === 'passesDefended'
      ) {
        game_type = 'interceptions';
      }
      if (property === 'fumForced' || property === 'fumOppRec') {
        game_type = 'fumbles';
      }
      if (
        property === 'tackleTotal' ||
        property === 'tackleSolo' ||
        property === 'tackleAst' ||
        property === 'tacklesForLoss' ||
        property === 'sacks' ||
        property === 'sackYds'
      ) {
        game_type = 'tackles';
      }
      if (
        property === 'position' ||
        property === 'team' ||
        property === 'player'
      ) {
        game_type = '';
      }

      if (
        property === 'interceptions' ||
        property === 'intYds' ||
        property === 'intTD' ||
        property === 'passesDefended'
      ) {
        game_type = 'interceptions';
      }
      if (property === 'fumForced' || property === 'fumOppRec') {
        game_type = 'fumbles';
      }
      if (
        property === 'tackleTotal' ||
        property === 'tackleSolo' ||
        property === 'tackleAst' ||
        property === 'tacklesForLoss' ||
        property === 'sacks' ||
        property === 'sackYds'
      ) {
        game_type = 'tackles';
      }
    } else if (league === 'NHL') {
      if (property === 'plusMinus') {
        game_type = 'skating';
      } else if (property === 'penalties') {
        game_type = 'penalties';
      } else {
        game_type = type;
      }
    }
    let url = `consumer?full_table_stats_v2=true&property=${property}&season=${season}&size=500&page=1&sort_order=${sort_order}`;
    url += `&league=${league}&type=${game_type}&league_conference_id=${conference}`;

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          console.log('res from player stats 3', res);
          this.isPlayerLoading.next(false);
          this.playerData3.next(res.data);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isPlayerLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_teamstats(
    searchString: string,
    league: string,
    type: string,
    property: string,
    size: any,
    sort_order: any,
    season: any,
    pool: any,
    token: any
  ) {
    this.isPlayerLoading.next(true);

    let url = `consumer?full_table_stats_v2=true&property=${property}&sort_order=${sort_order}&page=1&size=${size}`;
    url += `&league=${league}&type=${type}`;

    url += `&team_id=${searchString}`;

    if (season !== 'Select Season') {
      url += `&season=${season}`;
    }
    if (pool !== 'Select Player Pool' && pool !== 'All Players') {
      url += `&player_pool=${pool}`;
    }
    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.isPlayerLoading.next(false);
          this.playerData.next(res);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isPlayerLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_divisionstats(
    searchString: string,
    league: string,
    type: string,
    property: string,
    size: any,
    sort_order: any,
    season: any,
    pool: any,
    token: any
  ) {
    this.isPlayerLoading.next(true);

    let url = `consumer?full_table_stats_v2=true&property=${property}&sort_order=${sort_order}&page=1&size=${size}`;
    url += `&league=${league}&type=${type}`;

    url += `&division_id=${searchString}`;
    if (season !== 'Select Season') {
      url += `&season=${season}`;
    }
    if (pool !== 'Select Player Pool' && pool !== 'All Players') {
      url += `&player_pool=${pool}`;
    }

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.isPlayerLoading.next(false);
          this.playerData.next(res);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isPlayerLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_conferencestats(
    searchString: string,
    league: string,
    type: string,
    property: string,
    size: any,
    sort_order: any,
    season: any,
    pool: any,
    token: any
  ) {
    this.isPlayerLoading.next(true);

    let url = `consumer?full_table_stats_v2=true&property=${property}&sort_order=${sort_order}&page=1&size=${size}`;
    url += `&league=${league}&type=${type}`;

    url += `&league_conference_id=${searchString}`;
    if (season !== 'Select Season') {
      url += `&season=${season}`;
    }

    if (pool !== 'Select Player Pool' && pool !== 'All Players') {
      url += `&player_pool=${pool}`;
    }
    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.isPlayerLoading.next(false);
          this.playerData.next(res);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isPlayerLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_leaguestats(
    league: string,
    type: string,
    property: string,
    size: any,
    sort_order: any,
    season: any,
    pool: any,
    token: any
  ) {
    this.isPlayerLoading.next(true);

    let url = `consumer?full_table_stats_v2=true&property=${property}&sort_order=${sort_order}&page=1&size=${size}`;
    url += `&league=${league}&type=${type}`;

    if (season !== 'Select Season') {
      url += `&season=${season}`;
    }
    if (pool !== 'Select Player Pool' && pool !== 'All Players') {
      url += `&player_pool=${pool}`;
    }

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.isPlayerLoading.next(false);
          this.playerData.next(res);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isPlayerLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_leaguestatsChart(
    team: any,
    league: string,
    type: string,
    property: string,
    size: number,
    token: any,
    season: any
  ) {
    this.isPlayerLoading.next(true);

    if (!season) {
      season = '2022-2023-regular';
    }

    let game_type = type;
    if (league === 'NBA') {
      if (property === 'astPerGame' || property === 'ptsPerGame') {
        game_type = 'offense';
      }
      if (property === 'fgPct') {
        game_type = 'fieldGoals';
      }
      if (property === 'rebPerGame') {
        game_type = 'rebounds';
      }
      if (property === 'blkPerGame' || property === 'stlPerGame') {
        game_type = 'defense';
      }

      if (property === 'fgPct' || property === 'fg3PtPct') {
        game_type = 'fieldGoals';
      }
      if (property === 'ftPct') {
        game_type = 'freeThrows';
      }
      if (
        property === 'offRebPerGame' ||
        property === 'defRebPerGame' ||
        property === 'rebPerGame'
      ) {
        game_type = 'rebounds';
      }
      if (property === 'astPerGame' || property === 'ptsPerGame') {
        game_type = 'offense';
      }
      if (
        property === 'blkPerGame' ||
        property === 'stlPerGame' ||
        property === 'tovPerGame'
      ) {
        game_type = 'defense';
      }
      if (property === 'plusMinus') {
        game_type = 'miscellaneous';
      }
    } else if (league === 'NFL') {
      if (
        property === 'interceptions' ||
        property === 'intYds' ||
        property === 'intTD' ||
        property === 'passesDefended'
      ) {
        game_type = 'interceptions';
      }
      if (property === 'fumForced' || property === 'fumOppRec') {
        game_type = 'fumbles';
      }
      if (
        property === 'tackleTotal' ||
        property === 'tackleSolo' ||
        property === 'tackleAst' ||
        property === 'tacklesForLoss' ||
        property === 'sacks' ||
        property === 'sackYds'
      ) {
        game_type = 'tackles';
      }
      if (
        property === 'position' ||
        property === 'team' ||
        property === 'player'
      ) {
        game_type = '';
      }

      if (
        property === 'interceptions' ||
        property === 'intYds' ||
        property === 'intTD' ||
        property === 'passesDefended'
      ) {
        game_type = 'interceptions';
      }
      if (property === 'fumForced' || property === 'fumOppRec') {
        game_type = 'fumbles';
      }
      if (
        property === 'tackleTotal' ||
        property === 'tackleSolo' ||
        property === 'tackleAst' ||
        property === 'tacklesForLoss' ||
        property === 'sacks' ||
        property === 'sackYds'
      ) {
        game_type = 'tackles';
      }
    } else if (league === 'NHL') {
      if (property === 'plusMinus') {
        game_type = 'skating';
      } else if (property === 'penalties') {
        game_type = 'penalties';
      } else {
        game_type = type;
      }
    }
    let url = `consumer?full_table_stats_v2=true&property=${property}&sort_order=desc&page=1&size=${size}&season=${season}`;
    url += `&league=${league}&type=${game_type}`;

    if (team && team !== 'Select Team') {
      url += `&team_id=${team}`;
    }

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.isPlayerLoading.next(false);
          this.playerData.next(res);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isPlayerLoading.next(false);
        }
      );
  }

  async delete_image_permanent(obj: any, token: any) {
    let url = `blob`;
    this.api
      .deleteUrl(url, obj, token)
      .pipe(take(1))
      .subscribe(
        async (res) => {
          console.log('called');
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
        }
      );
  }

  get_player_Image(slug, token) {
    let url = environment.api_url + '/imagn';
    url = url + `?list=true&limit=1`;
    if (slug) {
      url = url + `&terms=${slug}`;
    }
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    return this.http.get<any>(url, { headers: new HttpHeaders(headers) });
  }

  get_team_Data(searchString: string, token: any) {
    this.isTeamLoading.next(true);

    let url = `team?list=true&page=1&size=10`;

    url += `&keyword=${searchString}`;

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.isTeamLoading.next(false);
          this.teamData.next(res.data);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isTeamLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_league_Data(searchString: string, token: any) {
    this.isLeagueLoading.next(true);

    let url = `league?list=true&page=1&size=10`;

    url += `&keyword=${searchString}`;

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.isLeagueLoading.next(false);
          this.leagueData.next(res.data);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isLeagueLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_division_Data(searchString: string, token: any) {
    this.isDivisionLoading.next(true);

    let url = `division?list=true&page=1&size=10`;

    url += `&keyword=${searchString}`;

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.isDivisionLoading.next(false);
          this.divisionData.next(res.data);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isDivisionLoading.next(false);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  get_conference_Data(searchString: string, token: any) {
    this.isConferenceLoading.next(true);

    let url = `conference?league_conference=true&page=1&size=10`;

    url += `&keyword=${searchString}`;

    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.isConferenceLoading.next(false);
          this.conferenceData.next(res.data);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          await this.showErr(err.error.errors[0].message);
          this.isConferenceLoading.next(false);
        }
      );
  }

  load_more_images(searchString: string, limit: any, token: any) {
    // this.isImagnLoading.next(true);
    let url = `imagn?list=true&terms=${searchString}&limit=${limit}`;
    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          res.data.forEach((element) => {
            let found = this.imageData.find(
              (el) => el.imgId === element[0].imgId
            );

            if (!found) return this.imageData.push(element[0]);
          });

          //    this.isImagnLoading.next(false);
          this.imagnData.next(this.imageData);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          await this.showErr(err.error.errors[0].message);
          //   this.isImagnLoading.next(false);
        }
      );
  }

  load_more_library(searchString: string, limit: any, token: any) {
    // this.isImagnLoading.next(true);
    let url = `gallery?list=true&keyword=${searchString}&image_source=imagn&size=${limit}`;
    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          console.log('res from library', res);
          res.data.forEach((element: any) => {
            let found = this.imageData.find((el) => el.id === element.id);

            if (!found) {
              element.previewUrl = element.url;
              element.caption = '';
              element.headline = '';
              element.fileSize = '';
              element.fileType = '';
              if (element.meta_data) {
                element.caption = element.meta_data.caption;
                element.headline = element.meta_data.description;
                element.fileType = element.meta_data.fileType;
                element.fileSize = element.meta_data.fileSize;
              }
              return this.imageData.push(element);
            }
          });

          //    this.isImagnLoading.next(false);
          this.imagnData.next(this.imageData);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          await this.showErr(err.error.errors[0].message);
          //   this.isImagnLoading.next(false);
        }
      );
  }

  load_more_unsplash(searchString: string, limit: any, token: any) {
    // this.isImagnLoading.next(true);
    let url = `unsplash_search_image?list=true&terms=${searchString}&size=${limit}`;
    this.api
      .get(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          console.log('res from library', res);
          res.data.data.forEach((element: any) => {
            let found = this.imageData.find((el) => el.id === element.id);

            if (!found) {
              element.previewUrl = element.urls.full;
              element.thumbnail = element.urls.thumb;
              element.caption = element.description ? element.description : '';
              element.headline = element.alt_description
                ? element.alt_description
                : '';
              element.fileSize = '';
              element.fileType = '';
              element.user = element.user;
              element.tags = element.tags;
              element.credit = element.user.name;
              if (element.meta_data) {
                element.caption = element.meta_data.caption;
                element.headline = element.meta_data.description;
                element.fileType = element.meta_data.fileType;
                element.fileSize = element.meta_data.fileSize;
              }
              return this.imageData.push(element);
            }
          });

          //    this.isImagnLoading.next(false);
          this.imagnData.next(this.imageData);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          await this.showErr(err.error.errors[0].message);
          //   this.isImagnLoading.next(false);
        }
      );
  }

  reset_imagn_data() {
    this.imagnData.next([]);
  }

  reset_player_data() {
    this.playerData.next([]);
  }
  reset_player_data3() {
    this.playerData3.next([]);
  }

  reset_team_data() {
    this.teamData.next([]);
  }

  reset_league_data() {
    this.leagueData.next([]);
  }

  reset_division_data() {
    this.divisionData.next([]);
  }

  reset_conference_data() {
    this.conferenceData.next([]);
  }

  reset_article_empty() {
    this.article.next(null);
    this.contentWidgets.next([]);
  }

  getStandings(
    league_name: string,
    league_conference_id: any,
    division_id: any
  ): Observable<any> {
    let url = `consumer?standings=true&size=50&page=1`;

    if (league_name) {
      url += `&league=${league_name}`;
    }

    if (league_conference_id) {
      url += `&league_conference_id=${league_conference_id}`;
    }
    if (division_id) {
      url += `&division_id=${division_id}`;
    }

    return this.http.get<any>(environment.api_url + url);
  }

  getUpcomingGames(
    league_name: string,
    team_id: any,
    league_conference_id: any,
    division_id: any,
    schedule?: any
  ): Observable<any> {
    let url = `consumer?result=true&size=6&page=1`;

    if (schedule && schedule !== null) {
      url += `&schedule=upcomings`;
    }

    if (league_name) {
      url += `&league=${league_name}`;
    }

    if (team_id) {
      url += `&team_id=${team_id}`;
    }
    if (league_conference_id) {
      url += `&league_conference_id=${league_conference_id}`;
    }
    if (division_id) {
      url += `&division_id=${division_id}`;
    }

    return this.http.get<any>(environment.api_url + url);
  }

  search_youtube_videos(keyword, token) {
    this.isSearchLoading.next(true);
    let url = `unified_search?keyword=${keyword}&platform=youtube`;
    this.api
      .getSearch(url, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.isSearchLoading.next(false);
          this.searchData.next(res.data);
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isSearchLoading.next(false);
          console.log(err);
          await this.showErr(err.error.errors[0].message);
        }
      );
  }

  async showErr(msg) {
    const t = await this.toast.create({
      header: 'Error',
      message: msg,
      position: 'bottom',
      duration: 5000,
      color: 'danger',
    });
    await t.present();
    this.isAddLoading.next(false);
  }

  search_twitter(keyword, token) {
    this.isSearchLoading.next(true);
    let url = `unified_search?keyword=${keyword}&platform=twitter`;
    this.api.getSearch(url, token).subscribe(
      async (res) => {
        console.log('res from twitter', res);
        this.isSearchLoading.next(false);
        if (res.data.success === true) {
          this.searchData.next(res.data.data);
        } else {
          console.log('No Records Found!');
        }
      },
      async (err) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          // //this.auth.refreshtoken()
        }
        this.isSearchLoading.next(false);
        console.log(err);
        await this.showErr(err.error.errors[0].message);
      }
    );
  }

  search_team_twitter(twitter_handle, token) {
    this.isSearchLoading.next(true);
    let url = `twitter?username=${twitter_handle}&limit=10&search_type=timeline`;
    this.api.getSearch(url, token).subscribe(
      async (res) => {
        console.log('res from twitter', res);
        this.isSearchLoading.next(false);
        if (res.data.tweets) {
          this.searchData.next(res.data.tweets);
        } else {
          this.searchData.next([]);
        }
        // if (res.data.success === true) {

        // } else {
        //   console.log('No Records Found!');
        // }
      },
      async (err) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          // //this.auth.refreshtoken()
        }
        this.isSearchLoading.next(false);
        console.log(err);
        await this.showErr(err.error.errors[0].message);
      }
    );
  }

  reset_search() {
    this.searchData.next([]);
  }

  get_content_status(type, token: any) {
    this.isStatusLoading.next(true);
    try {
      let url = `status?list=true&entity_type=${type}`;

      this.api.getSearch(url, token).subscribe(
        async (res) => {
          console.log('res from status', res);
          this.isStatusLoading.next(false);
          if (res.data !== null && res.data !== undefined) {
            this.statusData.next(res.data);
          }
        },
        (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isStatusLoading.next(false);
          console.log(err);
        }
      );
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        // //this.auth.refreshtoken()
      }
      console.log(error);
      this.showErr(error.error.errors[0].message);
    }
  }

  add_template(data, token: any) {
    this.isTemplateAddLoading.next(true);
    try {
      let url = `content_template`;

      this.api.postUser(url, data, token).subscribe(
        async (res) => {
          console.log('res from template add post call ', res);
          this.isTemplateAddLoading.next(false);

          const t = await this.toast.create({
            header: 'Template Copied',
            message: `Template saved successfully!`,
            position: 'bottom',
            duration: 5000,
            color: 'secondary',
          });
          await t.present();
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isTemplateAddLoading.next(false);

          const t = await this.toast.create({
            header: 'Error',
            message: err.error.message,
            position: 'bottom',
            duration: 5000,
            color: 'danger',
          });
          await t.present();
          console.log(err);
        }
      );
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        // //this.auth.refreshtoken()
      }
      console.log(error);
      this.showErr(error.error.message);
    }
  }

  share_twitter(data, token: any) {
    this.isTwitterAddLoading.next(true);
    try {
      let url = `twitter`;

      this.api.postUser(url, data, token).subscribe(
        async (res) => {
          console.log('res from twitter share  call ', res);
          this.isTwitterAddLoading.next(false);

          const t = await this.toast.create({
            header: 'Shared on Twitter',
            message: `Post has been shared successfully on twitter!`,
            position: 'bottom',
            duration: 5000,
            color: 'secondary',
          });
          await t.present();
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // //this.auth.refreshtoken()
          }
          this.isTwitterAddLoading.next(false);

          const t = await this.toast.create({
            header: 'Error',
            message: err.error.message,
            position: 'bottom',
            duration: 5000,
            color: 'danger',
          });
          await t.present();
          console.log(err);
        }
      );
    } catch (error) {
      console.log(error);
      this.showErr(error.error.message);
    }
  }

  async share_facebook(data: any, token: any): Promise<any> {
    this.isFacebookAddLoading.next(true);

    return new Promise(async (resolve, reject) => {
      try {
        let url = `facebook`;

        this.api.postUser(url, data, token).subscribe(
          async (res) => {
            console.log('res from facebook share call', res);
            this.isFacebookAddLoading.next(false);

            const t = await this.toast.create({
              header: 'Shared on Facebook',
              message: `Post has been shared successfully on Facebook!`,
              position: 'bottom',
              duration: 5000,
              color: 'secondary',
            });
            await t.present();

            resolve(res);
          },
          async (err) => {
            this.isFacebookAddLoading.next(false);

            if (err instanceof HttpErrorResponse && err.status === 401) {
              // Implement token refresh logic here
              // this.auth.refreshtoken()
            }

            const t = await this.toast.create({
              header: 'Error',
              message: err.error?.message || 'An unexpected error occurred.',
              position: 'bottom',
              duration: 5000,
              color: 'danger',
            });
            await t.present();

            reject(err);
          }
        );
      } catch (error) {
        this.isFacebookAddLoading.next(false);

        if (error instanceof HttpErrorResponse && error.status === 401) {
          // Implement token refresh logic here
          // this.auth.refreshtoken()
        }
        console.log(error);

        const t = await this.toast.create({
          header: 'Error',
          message: error.error?.message || 'An unexpected error occurred.',
          position: 'bottom',
          duration: 5000,
          color: 'danger',
        });
        await t.present();

        reject(error);
      }
    });
  }

  show_templates(token: any) {
    this.isTemplateLoading.next(true);
    let url = `content_template?list=true&page=${0}&size=${20}`;

    this.api.get(url, token).subscribe(
      (res) => {
        this.isTemplateLoading.next(false);
        if (res.data.length > 0) {
          console.log('res data', res.data);

          this.templates.next(res.data);
        }
      },
      (err) => {
        console.log('err', err);
        this.isTemplateLoading.next(false);
      }
    );
  }

  post(url, body, token): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    return this.http.post(environment.api_url + url, body, {
      headers: new HttpHeaders(headers),
    });
  }
}
