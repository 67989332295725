import { ChangeDetectorRef, HostListener, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { pageView, pages } from './menu.pages';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private pages: BehaviorSubject<pageView[]> = new BehaviorSubject<pageView[]>(
    []
  );

  constructor(
    private menu: MenuController,
    private router: Router //  private changeDetection: ChangeDetectorRef
  ) {
    this.initPages();
  }

  public showMenu() {
    this.menu.enable(true);
  }

  public hideMenu() {
    this.menu.enable(false);
  }

  public collapseMenu() {
    if (this.menu.isOpen()) {
      this.menu.close();
    }
  }
  public controlMenu(isOpen: boolean) {
    if (isOpen == true) {
      this.hideMenu();
    } else {
      this.showMenu();
    }
  }

  public initPages() {
    let p = pages;

    this.pages.next(p);

    this.checkCurrentRoute();
  }

  public changePage(url: string) {
    this.collapseMenu();
    this.router.navigate([url], {
      replaceUrl: true,
      skipLocationChange: false,
    });
  }

  public checkCurrentRoute() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        let pages: pageView[] = [];
        let selected_page_index = null;
        let to_page_index = null;
        this.pages
          .pipe(take(1))
          .subscribe((_pages) => {
            pages = _pages;
          })
          .unsubscribe();

        pages.forEach((p, pi) => {
          if (p.selected) {
            selected_page_index = pi;
          }
          if (p.url === event['url']) {
            to_page_index = pi;
          }
          if (p.children) {
            if (event['url'].includes(p.id)) {
              to_page_index = pi;
              let urlSegments = event['url'].split('/');
              let result = urlSegments[2];
              if (event['url'].includes(result)) {
                p.children.map((child) => {
                  if (child.url.includes(result)) {
                    child.selected = true;
                  } else {
                    child.selected = false;
                  }
                });
              }
            }
          }
        });

        if (selected_page_index !== null && selected_page_index !== undefined) {
          pages[selected_page_index].selected = false;
        }

        if (to_page_index !== null && to_page_index !== undefined) {
          pages[to_page_index].selected = true;
        }
        this.pages.next(pages);
      });
  }

  public get pages$(): Observable<pageView[]> {
    return this.pages.asObservable();
  }
}
