import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private token: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.auth.auth$.subscribe((user) => {
      this.token = user.token;
    })
  }

  get(url: string,token:any): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    
    return this.http.get(environment.api_url + url, { headers: new HttpHeaders(headers) });
  }

  getSearch(url: string,token): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
 
    return this.http.get(environment.api_url + url, { headers: new HttpHeaders(headers) });
  }

  getUrl(endpoint: string): string {
    const baseUrl = environment.baseURL;
    return baseUrl + endpoint;
  }

  post(url: string, body: any): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': this.token
    };
    return this.http.post(environment.api_url + url, body, { headers: new HttpHeaders(headers) });
  }
  postUser(url: string, body: any,token:any): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    return this.http.post(environment.api_url + url, body, { headers: new HttpHeaders(headers) });
  }

  put(url: string, body: any,token:any): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    return this.http.put(environment.api_url + url, body, { headers: new HttpHeaders(headers) });
  }

  delete(url: string, id: any,token:any): Observable<any> {
    const options= {
      headers:new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
      body:{
        id:id
      }
    } 
    
   
    return this.http.delete(environment.api_url + url, options);
  }

  deleteUrl(url: string, body: any,token:any): Observable<any> {
    const options= {
      headers:new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
      body:body
    } 
    
   
    return this.http.delete(environment.api_url + url, options);
  }

  deleteUser(url: string, id: any,token:any): Observable<any> {
    const options= {
      headers:new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
      body:{
        uid:id,
        admin:true
      }
    } 
    
   
    return this.http.delete(environment.api_url + url, options);
  }

}
