import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RouteGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let url: string = state.url;
    return this.checkUserLogin(next, url);
  }
  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
  checkUserLogin(route: ActivatedRouteSnapshot, url: any) {
    let userObj = JSON.parse(localStorage.getItem('user'));
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (
      urlParams.get('old_email') !== undefined &&
      urlParams.get('old_email') !== null &&
      (urlParams.get('new_email') && urlParams.get('new_email') !== null) !==
        undefined
    ) {
      localStorage.setItem('old_email', urlParams.get('old_email'));
      localStorage.setItem('new_email', urlParams.get('new_email'));
      localStorage.setItem('token', urlParams.get('token'));
      this.authService
        .updateEmailInDB(
          urlParams.get('old_email'),
          urlParams.get('new_email'),
          urlParams.get('token')
        )
        .then(
          (data) => {
            if (route.params['token'] !== undefined) {
              if (userObj) {
                if (route.params['token'] !== userObj.email) {
                  this.authService.openModal(url, route.params['token']);
                }
              } else {
                this.authService.openModal(url, route.params['token']);
              }
            }

            if (localStorage.getItem('user') !== null) {
              let Data: any = JSON.parse(localStorage.getItem('user'));
              let data = this.parseJwt(Data.stsTokenManager.accessToken);
              if (data.role) {
                if (
                  url === '/dashboard' ||
                  url === '/profile' ||
                  url === '/help-center' ||
                  url.includes('/help-center/view-page')
                ) {
                  return true;
                }
                if (data.role && !data.role.includes('member')) {
                  if (url === '/articles' || url.includes('/articles/detail')) {
                    return true;
                  }
                }
                if (
                  data.role &&
                  (data.role.includes('super_admin') ||
                    data.role.includes('admin'))
                ) {
                  if (
                    url === '/writers' ||
                    url === '/users' ||
                    url === '/analytics/graphs' ||
                    url === '/analytics/usersregistered' ||
                    url === '/analytics/articlesimported' ||
                    url === '/pages' ||
                    url === '/cities' ||
                    url === '/players' ||
                    url === '/teams' ||
                    url === '/divisions' ||
                    url === '/league-conferences' ||
                    url === '/leagues' ||
                    url === '/content-blocks' ||
                    url === '/assets' ||
                    url === '/assets/sports' ||
                    url === '/assets/templates' ||
                    url === '/assets/blob-library' ||
                    url === '/assets/block-library' ||
                    url === '/help-center' ||
                    url.includes('/help-center/view-page')
                  ) {
                    return true;
                  }
                  if (
                    url.includes('/writers/detail') ||
                    url.includes('/users/detail') ||
                    url.includes('/cities/detail') ||
                    url.includes('/players/detail') ||
                    url.includes('/teams/detail') ||
                    url.includes('/divisions/detail') ||
                    url.includes('/analytics/articlesimported/detail') ||
                    url.includes('/analytics/articlesimported/articles') ||
                    url.includes('/league-conferences/detail') ||
                    url.includes('/leagues/detail') ||
                    url.includes('/assets/blob-library/detail') ||
                    url.includes('/assets/templates/detail') ||
                    url.includes('/help-center/detail') ||
                    url.includes('/help-center/sub-topic') ||
                    url.includes('/help-center/view-page')
                  ) {
                    return true;
                  }
                }
                if (data.role && data.role.includes('super_admin')) {
                  if (
                    url === '/conferences' ||
                    url === '/settings/season-listing' ||
                    url === '/settings/social-accounts' ||
                    url === '/settings/user-permissions' ||
                    url === '/settings/url-listing' ||
                    url === '/groups/init-group' ||
                    url === '/settings/sports' ||
                    url === '/settings/status-listing' ||
                    url === '/settings/content-setting' ||
                    url === '/settings/notification-listing' ||
                    url === '/settings/checklist-setting'
                  ) {
                    return true;
                  }
                  if (
                    url.includes('/conferences/detail') ||
                    url.includes('/settings/sports/detail') ||
                    url.includes('/settings/url-listing/detail') ||
                    url.includes('/settings/season-listing/detail') ||
                    url.includes('/settings/notification-listing/detail') ||
                    url.includes('/settings/user-permissions/detail') ||
                    url.includes('/groups/init-group/detail') ||
                    url.includes('/settings/status-listing/detail') ||
                    url.includes('/settings/content-setting/detail') ||
                    url.includes(
                      '/settings/social-accounts/detail' ||
                        url === '/settings/checklist-setting/detail'
                    ) ||
                    url.includes('/help-center/detail') ||
                    url.includes('/help-center/sub-topic') ||
                    url.includes('/help-center/view-page')
                  ) {
                    return true;
                  }
                } else {
                  this.router.navigate(['/dashboard']);
                  return false;
                }
              } else {
                this.router.navigate(['/auth/login']);
                return false;
              }
            }
          },
          (err) => {
            console.log('err', err);
          }
        );
    } else {
      if (route.params['token'] !== undefined) {
        if (userObj) {
          if (route.params['token'] !== userObj.email) {
            this.authService.openModal(url, route.params['token']);
          }
        } else {
          this.authService.openModal(url, route.params['token']);
        }
      }
      if (localStorage.getItem('user') !== null) {
        let Data: any = JSON.parse(localStorage.getItem('user'));
        let data = this.parseJwt(Data.stsTokenManager.accessToken);

        if (data.role) {
          if (
            url === '/dashboard' ||
            url === '/profile' ||
            url === '/all-notifications' ||
            url === '/announcement' ||
            url === '/help-center' ||
            url.includes('/help-center/view-page')
          ) {
            return true;
          }
          if (data.role && !data.role.includes('member')) {
            if (url === '/articles' || url.includes('/articles/detail')) {
              return true;
            }
          }
          if (
            data.role &&
            (data.role.includes('super_admin') || data.role.includes('admin'))
          ) {
            if (
              url === '/users' ||
              url === '/analytics/graphs' ||
              url === '/analytics/usersregistered' ||
              url === '/analytics/articlesimported' ||
              url === '/pages' ||
              url === '/cities' ||
              url === '/players' ||
              url === '/teams' ||
              url === '/divisions' ||
              url === '/league-conferences' ||
              url === '/leagues' ||
              url === '/content-blocks' ||
              url === '/assets' ||
              url === '/assets/sports' ||
              url === '/assets/templates' ||
              url === '/assets/blob-library' ||
              url === '/assets/block-library' ||
              url === '/help-center' ||
              url.includes('/help-center/view-page')
            ) {
              return true;
            }
            if (
              url.includes('/users/detail') ||
              url.includes('/cities/detail') ||
              url.includes('/players/detail') ||
              url.includes('/teams/detail') ||
              url.includes('/divisions/detail') ||
              url.includes('/analytics/articlesimported/detail') ||
              url.includes('/analytics/articlesimported/articles') ||
              url.includes('/league-conferences/detail') ||
              url.includes('/leagues/detail') ||
              url.includes('/assets/blob-library/detail') ||
              url.includes('/assets/templates/detail') ||
              url.includes('/help-center/detail') ||
              url.includes('/help-center/sub-topic') ||
              url.includes('/help-center/view-page')
            ) {
              return true;
            }
          }
          if (data.role && data.role.includes('super_admin')) {
            if (
              url === '/conferences' ||
              url === '/settings/season-listing' ||
              url === '/settings/social-accounts' ||
              url === '/settings/user-permissions' ||
              url === '/settings/url-listing' ||
              url === '/groups/init-group' ||
              url === '/settings/status-listing' ||
              url === '/settings/content-setting' ||
              url === '/settings/checklist-setting' ||
              url === '/settings/member-types'
            ) {
              return true;
            }
            if (
              url.includes('/conferences/detail') ||
              url.includes('/settings/sports/detail') ||
              url.includes('/settings/url-listing/detail') ||
              url.includes('/settings/season-listing/detail') ||
              url.includes('/settings/user-permissions/detail') ||
              url.includes('/groups/init-group/detail') ||
              url.includes('/settings/status-listing/detail') ||
              url.includes('/settings/content-setting/detail') ||
              url.includes(
                '/settings/social-accounts/detail' ||
                  url === '/settings/checklist-setting/detail' ||
                  url === '/announcement/detail'
              ) ||
              url.includes('/settings/member-types/detail') ||
              url.includes('/help-center/detail') ||
              url.includes('/help-center/sub-topic') ||
              url.includes('/help-center/view-page')
            ) {
              return true;
            }
          } else {
            this.router.navigate(['/dashboard']);
            return false;
          }
        } else {
          this.router.navigate(['/auth/login']);
          return false;
        }
      }
    }
  }
}
