import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapServiceService {
  lat = 0;
  long = 0;
  mapTypeId = google.maps.MapTypeId.ROADMAP;
  zoom = 18;
  constructor() {}

  changeLatitude(event) {
    // this.mapOptions = google.maps.MapOptions = {
    //   center: { lat: this.lat, lng: this.long },
    //   zoom: 14,
    // };
    console.log('lat', this.lat);
    console.log('long', this.long);

    // this.marker = {
    //   position: { lat: this.lat, lng: this.long },
    // };
  }

  MapClick(event) {
    this.lat = event.coords.lat;
    this.long = event.coords.lng;
  }
  MapTypeChange(event) {
    if (event === 'roadmap') {
      this.mapTypeId = google.maps.MapTypeId.ROADMAP;
    }
    if (event === 'hybrid') {
      this.mapTypeId = google.maps.MapTypeId.HYBRID;
    }
    if (event === 'satellite') {
      this.mapTypeId = google.maps.MapTypeId.SATELLITE;
    }
  }

  zoomChange(event) {
    this.zoom = event;
  }
}
