import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.page.html',
  styleUrls: ['./sign-in.page.scss'],
})
export class SignInPage implements OnInit {

  email:any;
  url:any;
  password=new FormControl('');
  
  
  constructor(private authService:AuthService,private modalCtrl:ModalController) {
    
   }

  ngOnInit() {
    
  }

  login(){
    var afterWithout = this.url.substr(0, this.url.lastIndexOf("/"));
    let obj={
      email:this.email,
      password:this.password.value}
    this.authService.login(obj,afterWithout)
   }

   dismiss(){
    this.modalCtrl.dismiss()
   }

   setPassword(event){

    this.password.setValue(event.target.value)

   }

}
