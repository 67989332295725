import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import {
  ChangeDetectorRef,
  ElementRef,
  Injectable,
  ViewChild,
} from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api/api.service';
import { environment } from 'src/environments/environment';
import { ChartOptions } from '../../detail.page';
import { SwiperComponent } from 'swiper/angular';
import { ArticleDetailsBLL } from '../../detail.bll';
import { AuthService } from 'src/app/services/auth/auth.service';
import { imagnData } from '../../detail.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { team } from 'src/app/views/teams/teams.model';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertController,
  LoadingController,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { TeamsBLL } from 'src/app/views/teams/teams.bll';
import * as document_editor from '@ckeditor/ckeditor5-build-decoupled-document';
import {
  StorageService,
  BlobUploadForEditorService,
} from 'src/app/services/azure/storage/storage.service';
import { DragulaService } from 'ng2-dragula';
import { DomSanitizer } from '@angular/platform-browser';
import { NgTinyUrlService } from 'ng-tiny-url';
import { Clipboard } from '@angular/cdk/clipboard';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { article, source, widget } from '../../../articles.model';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { GoogleMapServiceService } from '../googleMapService/google-map-service.service';
@Injectable({
  providedIn: 'root',
})
export class DetailServiceService {
  widgetType: any = '';
  showAddBlock: boolean = false;
  edit_IV: boolean = true;
  preview: boolean = false;
  show_panels: boolean = true;
  isDraggable: boolean = true;
  //Article Variables//
  public article_id: string;
  isAdd: boolean = false;
  isEdit: boolean = false;

  changesSaved = false;

  lmt = 15;
  identifier;
  max_height = 100;
  //Cancel Editing
  isConfirmationToCancelOpen: boolean = false;
  isConfirmationToDeleteOpen: boolean = false;

  isShowTemplatesOpen: boolean = false;
  isConfirmationToDeletePerOpen: boolean = false;
  isConfirmationToShareFacebookOpen: boolean = false;
  isConfirmationToKickUserOpen: boolean = false;
  isConfirmationToShareTwitterOpen: boolean = false;
  isConfirmationToDeleteBlockOpen: boolean = false;
  isConfirmationToEditPerOpen: boolean = false;
  Title = '';
  dateObj: any = null;
  MetaDropdown: boolean = false;

  ImageDropdown: boolean = false;
  socialDropdown: boolean = false;
  checklistDropdown: boolean = true;
  TagsDropdown: boolean = false;
  PublishingDropdown: boolean = true;
  ContentDetailsDropdown: boolean = false;
  SourceDropdown: boolean = false;

  GroupDropdown: boolean = false;
  images = [];
  localimages = [];

  public editor = document_editor;

  htmlEditorConfig = {
    mediaEmbed: {
      previewsInData: true,
    },
  };

  imageSourceTab: string = 'imagn';
  twitterSourceTab: string = 'top';

  twitter_search_box: boolean = true;

  twitter_teams_arr: any = [];

  //Observables & Subscriptions

  isLoading$: Observable<boolean>;
  blocksLoading$: Observable<boolean>;
  isWidgetLoading$: Observable<boolean>;
  isAddLoading$: Observable<boolean>;
  isDeleteLoading$: Observable<boolean>;
  isTwitterAddLoading$: Observable<boolean>;

  isFacebookAddLoading$: Observable<boolean>;

  isTemplateAddLoading$: Observable<boolean>;

  article_data: any;
  group_social: boolean = false;

  isImagnLoading$: Observable<boolean>;

  isChecklistLoading: Observable<boolean>;

  isTemplateLoading: boolean = false;

  imagnData$: Observable<imagnData[]>;
  checklist$: Observable<any[]>;
  checklists: any = [];
  selected: any;
  unselected: any;
  isComplete: boolean = true;
  checklistInComplete: boolean = true;
  templates$: Observable<any[]>;
  templates: any = null;
  teams$: Observable<team[]>;

  groups$: Observable<any[]>;
  filteredOptions: Observable<any[]>;
  groups: any = [];
  selected_group: any = null;
  content_groups$: Observable<any[]>;
  content_groups: any = [];
  selectOptions = {
    header: 'Select a group',
  };
  searchTerm: '';
  GroupContentAddObj = {
    group_id: '',
    content_id: [],
    group_content: true,
  };
  delete_group_id = null;

  public post_sub: Subscription;
  public content_sub: Subscription;

  initialValues: article = null;
  article$: Observable<article>;
  widgets$: Observable<widget[]>;
  contentSources$: Observable<source[]>;

  members$: Observable<any[]>;
  members: any = [];
  assignee$: Observable<any[]>;
  isTeamsLoading$: Observable<boolean>;

  isGroupLoading$: Observable<boolean>;

  isMembersLoading$: Observable<boolean>;

  contentViewer$: Observable<any[]>;

  customColor: string = '#989b84';
  //Form
  form: FormGroup;
  file: Blob;
  widgetFile: Blob;
  imageStr: string;

  contentViewer: any = null;

  //Widgets
  isWidgetSelectOpen: boolean = false;
  isWidgetSourceSelectOpen: boolean = false;
  isNumberSelectOpen: boolean = false;
  isSocialCommentSelectOpen = false;

  // 'images', 'number'
  widgetTypeArr = [
    'twitter',
    'video',
    'quote',
    'image',
    'wikipedia',
    'number',
    'social_comment',
    'text',
    'player_card',
    'info',
    'stats',
    'standings',
    'chart',
    'datagrid',
    'map',
    'list',
    'upcoming_games',
  ];

  immersiveViewWidgetArr: any = [];
  totalRecordsImmersiveBlockArr: any;
  widgetSourceArr = ['person', 'organization', 'other'];
  numberSourceArr = ['twitter', 'video', 'image'];
  socialCommentSourceArr = ['reddit', 'twitter'];
  _widgetType = 'twitter';
  openAddNewWidgetModal: boolean = false;

  ID: number;
  name: string = '';

  Widget_author: string = '';
  pubname: string = '';
  description: any = '';
  url: string = '';
  youtubeObj: any;
  //NEW BLOCK VARIABLES//
  sportsKey: any;
  screenWidth: any;
  twitterUrl: string = '';
  youtubeUrl: string = '';
  is_text: boolean = false;
  is_title: boolean = true;
  is_extract: boolean = true;
  is_spotlight: boolean = false;
  showCaptions: boolean = true;

  spotlightSelection: any = 'select spotlight';
  similarrankLists: any = [];
  leagueLeaderLists: any = [];
  spotlightPlayerSlug: any = '';
  isPlayerLoading$: Observable<boolean>;
  isSeasonLoading$: Observable<boolean>;
  isTeamLoading$: Observable<boolean>;
  isLeagueLoading$: Observable<boolean>;
  isDivisionLoading$: Observable<boolean>;
  isConferenceLoading$: Observable<boolean>;
  isSportsLoading$: Observable<boolean>;
  isSearchLoading$: Observable<boolean>;
  isStatusLoading$: Observable<boolean>;

  statusData$: Observable<any[]>;
  seasonData$: Observable<any[]>;

  defaultData$: Observable<any>;
  consumerUrl: any = environment.consumerUrl;

  years: any = [];
  seasonS: any = [];
  complete_seasons: any = [];
  searchData$: Observable<any[]>;
  sportData$: Observable<any[]>;
  playerData$: Observable<any[]>;
  playerData3$: Observable<any[]>;
  teamData$: Observable<any[]>;
  leagueData$: Observable<any[]>;
  divisionData$: Observable<any[]>;
  conferenceData$: Observable<any[]>;

  @ViewChild('chart') chartObj: ChartComponent;
  public chartOptions: Partial<any>;
  playerKeyword: any = '';
  playerKeyword1: any;
  playerKeyword2: any;
  playerKeyword3: any;
  playerKeyword4: any;

  playerKey1: any = false;
  playerKey2: any = false;
  playerKey3: any = false;

  playerOverlay: boolean = false;
  playerOverlay1: boolean = false;
  playerOverlay2: boolean = false;
  playerOverlay3: boolean = false;
  playerOverlay4: boolean = false;

  playerStatsInfo: any = {
    data: [],
    page: 1,
    size: 10,
    totalRecords: 0,
  };

  playerStatsObj: any = {
    data: [],
    page: 1,
    size: 10,
    totalRecords: 0,
  };
  playerInfo: any;
  playerName: string = null;
  playerNumber: string = null;
  playerHeight: string = null;
  playerWeight: string = null;
  playerPosition: string = null;
  playerAge: string = null;
  playerTeam: string = null;
  playerLeague: string = null;
  playerTeam_color_1: string = null;
  playerTeam_color_2: string = null;
  playerBirth: string = null;

  teamKeyword: any;
  teamOverlay: boolean = false;
  teamInfo: any;
  teamName: string = null;
  teamFounded: string = null;
  teamTotalTitles: string = null;
  teamLastTitle: string = null;
  teamStadium: string = null;
  teamLoc: string = null;
  teamCapacity: string = null;
  teamHeadCoach: string = null;

  leagueKeyword: any;
  leagueOverlay: boolean = false;
  leagueInfo: any;
  leagueName: string = null;
  leagueFounded: string = null;
  leagueTotalTitles: string = null;
  leagueLastTitle: string = null;
  leagueRunnerUp: string = null;
  leagueResults: string = null;
  leagueID: any = null;

  divisionKeyword: any;
  divisionOverlay: boolean = false;
  divisionInfo: any;
  divisionName: string = null;
  divisionFounded: string = null;
  divisionTotalTitles: string = null;
  divisionLastTitle: string = null;

  standings: any = {
    data: [],
  };
  upcomings: any = {
    data: [],
  };
  conferenceKeyword: any;
  conferenceOverlay: boolean = false;
  conferenceInfo: any;
  conferenceName: string = null;
  conferenceFounded: string = null;
  conferenceRunnerUp: string = null;
  conferenceLastTitle: string = null;
  conferenceResult: string = null;

  //Unified Search Variables//
  searchLinkVar: any = '';
  searchLinkVar2: any = '';

  //Unified Search Variables//

  OBJID: any;
  pagesizes: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  gameSortVal = 'desc';
  sorting = [
    {
      val: 'asc',
      name: 'Ascending',
    },
    {
      val: 'desc',
      name: 'Descending',
    },
  ];

  seasons = [];
  widgetCardArr = [
    {
      name: 'Team Card',
      type: 'team_card',
    },
    {
      name: 'Division Card',
      type: 'division_card',
    },
    {
      name: 'League Card',
      type: 'league_card',
    },
    {
      name: 'Conference Card',
      type: 'conference_card',
    },
  ];

  statType = [
    {
      name: 'Receiving',
      type: 'receiving',
      property: 'recYards',
    },
    {
      name: 'Rushing',
      type: 'rushing',
      property: 'rushYards',
    },
    {
      name: 'Passing',
      type: 'passing',
      property: 'passYards',
    },
    {
      name: 'Tackles',
      type: 'tackles',
      property: 'tackleTotal',
    },
    {
      name: 'Tackles',
      type: 'tackles',
      property: 'sacks',
    },
    {
      name: 'Interceptions',
      type: 'interceptions',
      property: 'interceptions',
    },
  ];

  widgetStatsArr = [
    {
      name: 'Player Statistics',
      type: 'player_stats',
    },
    {
      name: 'Team Statistics',
      type: 'team_stats',
    },
    {
      name: 'Division Statistics',
      type: 'division_stats',
    },
    {
      name: 'Conference Statistics',
      type: 'conference_stats',
    },

    {
      name: 'League Statistics',
      type: 'league_stats',
    },
  ];

  widgetStandingsArr = [
    {
      name: 'Team Standings',
      type: 'team_standings',
    },
    {
      name: 'Division Standings',
      type: 'division_standings',
    },
    {
      name: 'Conference Standings',
      type: 'conference_standings',
    },
  ];

  widgetUpcomingArr = [
    {
      name: 'Team',
      type: 'team_upcoming',
    },
    {
      name: 'Division',
      type: 'division_upcoming',
    },
    {
      name: 'Conference',
      type: 'conference_upcoming',
    },
  ];

  leagues = [
    {
      name: 'NFL',
      value: 'NFL',
    },
    {
      name: 'NHL',
      value: 'NHL',
    },
    {
      name: 'NBA',
      value: 'NBA',
    },
    {
      name: 'MLB',
      value: 'MLB',
    },
  ];
  chartTypes = [
    {
      name: 'Bar',
      value: 'bar',
    },
    {
      name: 'Donut',
      value: 'donut',
    },
    {
      name: 'Pie',
      value: 'pie',
    },
    {
      name: 'Table',
      value: 'table',
    },
  ];
  chartVal: any = 'bar';

  chartData: any = null;
  tableData: any = [];

  Teams: any = [];
  Divisions: any = [];
  Conference_leagues: any = [];
  divisions: any = [];

  Players: any = [];

  leagueGameType: string = null;

  gameType1 = [
    {
      name: 'OverAll',
      type: 'offense',
    },
  ];
  gameType2 = [
    {
      name: 'Scoring',
      type: 'scoring',
    },
    {
      name: 'Goal Tending',
      type: 'goaltending',
    },
  ];
  gameType3 = [
    {
      name: 'Passing',
      type: 'passing',
    },
    {
      name: 'Rushing',
      type: 'rushing',
    },
    {
      name: 'Receiving',
      type: 'receiving',
    },
    {
      name: 'Scoring',
      type: 'scoring',
    },
    {
      name: 'Defense',
      type: 'tackles,interceptions,fumbles',
    },
  ];
  gameType4 = [
    {
      name: 'Batting',
      type: 'batting',
    },
    {
      name: 'Pitching',
      type: 'pitching',
    },
  ];

  LeaguegameProperty1 = [
    {
      name: 'Position',
      property: 'position',
    },
    {
      name: 'Games Played',
      property: 'gamesplayed',
    },
    {
      name: 'Points Per Game',
      property: 'ptsPerGame',
    },

    {
      name: 'Mins per Game',
      property: 'minminutespergame',
    },
    {
      name: 'Field Goals',
      property: 'fgPct',
    },
    {
      name: '3p%',
      property: 'fg3PtPct',
    },
    {
      name: 'Free Throws',
      property: 'ftPct',
    },
    {
      name: 'Offensive Rebounds',
      property: 'offRebPerGame',
    },
    {
      name: 'Defensive Rebounds',
      property: 'defRebPerGame',
    },
    {
      name: 'Rebounds per Game',
      property: 'rebPerGame',
    },
    {
      name: 'Ast Per Game',
      property: 'astPerGame',
    },
    {
      name: 'Blk Per Game',
      property: 'blkPerGame',
    },
    {
      name: 'Stl Per Game',
      property: 'stlPerGame',
    },
    {
      name: 'Tov Per Game',
      property: 'tovPerGame',
    },
    {
      name: 'Plus Minus',
      property: 'plusMinus',
    },
  ];
  LeaguegameProperty21 = [
    {
      name: 'Position',
      property: 'position',
    },
    {
      name: 'Games Played',
      property: 'gamesplayed',
    },
    {
      name: 'Goals',
      property: 'goals',
    },
    {
      name: 'assists',
      property: 'assists',
    },
    {
      name: 'Points',
      property: 'points',
    },
    {
      name: 'hatTricks',
      property: 'hatTricks',
    },
    {
      name: 'plusMinus',
      property: 'plusMinus',
    },
    {
      name: 'penalties',
      property: 'penalties',
    },
    {
      name: 'penaltyMinutes',
      property: 'penaltyMinutes',
    },
    {
      name: 'pointspergame',
      property: 'pointspergame',
    },
    {
      name: 'powerplayGoals',
      property: 'powerplayGoals',
    },
    {
      name: 'powerplayAssists',
      property: 'powerplayAssists',
    },
    {
      name: 'shorthandedGoals',
      property: 'shorthandedGoals',
    },
    {
      name: 'shorthandedAssists',
      property: 'shorthandedAssists',
    },
  ];
  LeaguegameProperty22 = [
    {
      name: 'Position',
      property: 'position',
    },
    {
      name: 'Games Played',
      property: 'gamesplayed',
    },
    {
      name: 'wins',
      property: 'wins',
    },
    {
      name: 'losses',
      property: 'losses',
    },
    {
      name: 'overtimeLosses',
      property: 'overtimeLosses',
    },
    {
      name: 'Goals Against Average',
      property: 'goalsAgainstAverage',
    },
    {
      name: 'goalsAgainst',
      property: 'goalsAgainst',
    },
    {
      name: 'saves',
      property: 'saves',
    },
    {
      name: 'savePercentage',
      property: 'savePercentage',
    },
    {
      name: 'shutouts',
      property: 'shutouts',
    },
  ];
  LeaguegameProperty31 = [
    {
      name: 'Position',
      property: 'position',
    },
    {
      name: 'Games Played',
      property: 'gamesplayed',
    },
    {
      name: 'Pass Comp',
      property: 'passCompletions',
    },
    {
      name: 'Pass Attempts',
      property: 'passAttempts',
    },
    {
      name: 'Pass Pct',
      property: 'passPct',
    },
    {
      name: 'Pass Yards',
      property: 'passYards',
    },
    {
      name: 'Pass Yards Per Att',
      property: 'passYardsPerAtt',
    },
    {
      name: 'Yards per game',
      property: 'yardspergame',
    },
    {
      name: 'Pass TD',
      property: 'passTD',
    },
    {
      name: 'Pass Int',
      property: 'passInt',
    },
    {
      name: 'Pass Sacks',
      property: 'passSacks',
    },
    {
      name: 'Pass TDPct',
      property: 'passTDPct',
    },
    {
      name: 'Pass IntPct',
      property: 'passIntPct',
    },
    {
      name: 'QBR',
      property: 'qbRating',
    },
  ];
  LeaguegameProperty32 = [
    {
      name: 'Team',
      property: 'team',
    },
    {
      name: 'Position',
      property: 'position',
    },
    {
      name: 'Games Played',
      property: 'gamesplayed',
    },
    {
      name: 'Rush Attempts',
      property: 'rushAttempts',
    },
    {
      name: 'Rushing Yards',
      property: 'rushYards',
    },

    {
      name: 'Rush TD',
      property: 'rushTD',
    },
    {
      name: 'Rush Avg',
      property: 'rushAverage',
    },
    {
      name: 'Yards per game',
      property: 'yardspergame',
    },
    {
      name: 'Rush Lng',
      property: 'rushLng',
    },
    {
      name: 'Rush Fumbles',
      property: 'rushFumbles',
    },
  ];
  LeaguegameProperty33 = [
    {
      name: 'Team',
      property: 'team',
    },
    {
      name: 'Position',
      property: 'position',
    },
    {
      name: 'Games Played',
      property: 'gamesplayed',
    },
    {
      name: 'Targets',
      property: 'targets',
    },
    {
      name: 'Receiving',
      property: 'receptions',
    },
    {
      name: 'Rec Yards',
      property: 'recYards',
    },
    {
      name: 'Rec Average',
      property: 'recAverage',
    },
    {
      name: 'Yards per game',
      property: 'yardspergame',
    },
    {
      name: 'Rec TD',
      property: 'recTD',
    },
    {
      name: 'Rec Lng',
      property: 'recLng',
    },
    {
      name: 'Rec 1stDowns',
      property: 'rec1stDowns',
    },
    {
      name: 'Rec 20Plus',
      property: 'rec20Plus',
    },
    {
      name: 'Rec 40Plus',
      property: 'rec40Plus',
    },
    {
      name: 'Rec Fumbles',
      property: 'recFumbles',
    },
  ];
  LeaguegameProperty34 = [
    {
      name: 'Team',
      property: 'team',
    },
    {
      name: 'Position',
      property: 'position',
    },
    {
      name: 'Games Played',
      property: 'gamesplayed',
    },
    {
      name: 'Scoring',
      property: 'scoring',
    },

    {
      name: 'Rush',
      property: 'rush',
    },
    {
      name: 'Rec',
      property: 'rec',
    },
    {
      name: 'Kr',
      property: 'kr',
    },
    {
      name: 'Pr',
      property: 'pr',
    },
    {
      name: 'Fum',
      property: 'fum',
    },
    {
      name: 'Int',
      property: 'Int',
    },
    {
      name: 'PA ATT',
      property: 'pa_att',
    },

    {
      name: 'PA M',
      property: 'pa_m',
    },

    {
      name: 'FGA',
      property: 'fga',
    },
    {
      name: 'FGM',
      property: 'fgm',
    },
    {
      name: '2 pt',
      property: 'two_pt',
    },
    {
      name: 'Sfty',
      property: 'sfty',
    },
  ];

  LeaguegameProperty35 = [
    {
      name: 'Team',
      property: 'team',
    },
    {
      name: 'Position',
      property: 'position',
    },
    {
      name: 'Games Played',
      property: 'gamesplayed',
    },
    {
      name: 'tackling Total',
      property: 'tackleTotal',
    },
    {
      name: 'tackling Solo',
      property: 'tackleSolo',
    },
    {
      name: 'tackling Ast',
      property: 'tackleAst',
    },
    {
      name: 'tackling For Loss',
      property: 'tacklesForLoss',
    },
    {
      name: 'Sacks',
      property: 'sacks',
    },
    {
      name: 'Sack Yds',
      property: 'sackYds',
    },
    {
      name: 'Interceptions',
      property: 'interceptions',
    },
    {
      name: 'intYds',
      property: 'intYds',
    },
    {
      name: 'intTD',
      property: 'intTD',
    },
    {
      name: 'passesDefended',
      property: 'passesDefended',
    },
    {
      name: 'fumForced',
      property: 'fumForced',
    },
    {
      name: 'fumOppRec',
      property: 'fumOppRec',
    },
  ];

  LeaguegameProperty41 = [
    {
      name: 'Position',
      property: 'position',
    },
    {
      name: 'gamesPlayed',
      property: 'gamesplayed',
    },
    {
      name: 'atBats',
      property: 'atBats',
    },
    {
      name: 'runs',
      property: 'runs',
    },
    {
      name: 'hits',
      property: 'hits',
    },
    {
      name: 'secondBaseHits',
      property: 'secondBaseHits',
    },
    {
      name: 'thirdBaseHits',
      property: 'thirdBaseHits',
    },
    {
      name: 'homeruns',
      property: 'homeruns',
    },
    {
      name: 'runsBattedIn',
      property: 'runsBattedIn',
    },
    {
      name: 'stolenBases',
      property: 'stolenBases',
    },
    {
      name: 'batterWalks',
      property: 'batterWalks',
    },
    {
      name: 'batterStrikeouts',
      property: 'batterStrikeouts',
    },
    {
      name: 'battingAvg',
      property: 'battingAvg',
    },
    {
      name: 'batterOnBasePct',
      property: 'batterOnBasePct',
    },
    {
      name: 'batterSluggingPct',
      property: 'batterSluggingPct',
    },

    {
      name: 'batterOnBasePlusSluggingPct',
      property: 'batterOnBasePlusSluggingPct',
    },
  ];

  LeaguegameProperty42 = [
    {
      name: 'Position',
      property: 'position',
    },
    {
      name: 'gamesPlayed',
      property: 'gamesplayed',
    },
    {
      name: 'Wins',
      property: 'wins',
    },
    {
      name: 'losses',
      property: 'losses',
    },
    {
      name: 'earnedRunAvg',
      property: 'earnedRunAvg',
    },
    {
      name: 'saves',
      property: 'saves',
    },
    {
      name: 'saveOpportunities',
      property: 'saveOpportunities',
    },
    {
      name: 'inningsPitched',
      property: 'inningsPitched',
    },
    {
      name: 'hitsAllowed',
      property: 'hitsAllowed',
    },
    {
      name: 'runsAllowed',
      property: 'runsAllowed',
    },
    {
      name: 'earnedRunsAllowed',
      property: 'earnedRunsAllowed',
    },
    {
      name: 'homerunsAllowed',
      property: 'homerunsAllowed',
    },
    {
      name: 'pitcherWalks',
      property: 'pitcherWalks',
    },
    {
      name: 'pitcherStrikeouts',
      property: 'pitcherStrikeouts',
    },
    {
      name: 'pitchingAvg',
      property: 'pitchingAvg',
    },
    {
      name: 'walksAndHitsPerInningPitched',
      property: 'walksAndHitsPerInningPitched',
    },
  ];

  checkedList: any = [];
  public checkeds = 5;
  public limit = 5;
  NBACheckboxes = [
    {
      name: 'team',
      checked: true,
    },
    {
      name: 'position',
      checked: true,
    },
    {
      name: 'gp',
      checked: true,
    },
    {
      name: 'mpg',
      checked: true,
    },
    {
      name: 'fg%',
      checked: true,
    },
    {
      name: '3p%',
      checked: false,
    },
    {
      name: 'ft%',
      checked: false,
    },
    {
      name: 'orb',
      checked: false,
    },
    {
      name: 'drb',
      checked: false,
    },
    {
      name: 'reb',
      checked: false,
    },
    {
      name: 'ast',
      checked: false,
    },
    {
      name: 'blk',
      checked: false,
    },
    {
      name: 'stl',
      checked: false,
    },
    {
      name: 'tov',
      checked: false,
    },
    {
      name: 'pts',
      checked: false,
    },
    {
      name: '+/-',
      checked: false,
    },
  ];

  NHLCheckboxes1 = [
    {
      name: 'team',
      checked: true,
    },
    {
      name: 'position',
      checked: true,
    },
    {
      name: 'gp',
      checked: true,
    },
    {
      name: 'w',
      checked: true,
    },
    {
      name: 'losses',
      checked: true,
    },
    {
      name: 'otl',
      checked: false,
    },
    {
      name: 'gaaverage',
      checked: false,
    },
    {
      name: 'gagainst',
      checked: false,
    },
    {
      name: 'saves',
      checked: false,
    },
    {
      name: 'svpct',
      checked: false,
    },
    {
      name: 'so',
      checked: false,
    },
  ];

  NHLCheckboxes2 = [
    {
      name: 'team',
      checked: true,
    },
    {
      name: 'position',
      checked: true,
    },
    {
      name: 'gp',
      checked: true,
    },
    {
      name: 'goals',
      checked: true,
    },
    {
      name: 'assists',
      checked: true,
    },
    {
      name: 'pts',
      checked: false,
    },
    {
      name: 'hattricks',
      checked: false,
    },
    {
      name: '+/-',
      checked: false,
    },
    {
      name: 'pen',
      checked: false,
    },
    {
      name: 'pim',
      checked: false,
    },
    {
      name: 'p/g',
      checked: false,
    },
    {
      name: 'pp (g)',
      checked: false,
    },
    {
      name: 'pp (a)',
      checked: false,
    },
    {
      name: 'sh (g)',
      checked: false,
    },
    {
      name: 'sh (a)',
      checked: false,
    },
  ];

  NFLCheckboxes1 = [
    {
      name: 'team',
      checked: true,
    },
    {
      name: 'position',
      checked: true,
    },
    {
      name: 'gp',
      checked: true,
    },
    {
      name: 'comp',
      checked: true,
    },
    {
      name: 'att',
      checked: true,
    },
    {
      name: 'pct',
      checked: false,
    },
    {
      name: 'yds',
      checked: false,
    },
    {
      name: 'y/a',
      checked: false,
    },
    {
      name: 'y/g',
      checked: false,
    },
    {
      name: 'td',
      checked: false,
    },
    {
      name: 'int',
      checked: false,
    },
    {
      name: 'sk',
      checked: false,
    },
    {
      name: 'td%',
      checked: false,
    },
    {
      name: 'int%',
      checked: false,
    },
    {
      name: 'qbr',
      checked: false,
    },
  ];

  NFLCheckboxes2 = [
    {
      name: 'team',
      checked: true,
    },
    {
      name: 'position',
      checked: true,
    },
    {
      name: 'gp',
      checked: true,
    },
    {
      name: 'att',
      checked: true,
    },
    {
      name: 'yds',
      checked: true,
    },
    {
      name: 'td',
      checked: false,
    },
    {
      name: 'avg',
      checked: false,
    },
    {
      name: 'y/g',
      checked: false,
    },
    {
      name: 'lng',
      checked: false,
    },
    {
      name: 'fum',
      checked: false,
    },
  ];

  NFLCheckboxes3 = [
    {
      name: 'team',
      checked: true,
    },
    {
      name: 'position',
      checked: true,
    },
    {
      name: 'gp',
      checked: true,
    },
    {
      name: 'tgt',
      checked: true,
    },
    {
      name: 'rec',
      checked: true,
    },
    {
      name: 'yds',
      checked: false,
    },
    {
      name: 'avg',
      checked: false,
    },
    {
      name: 'y/g',
      checked: false,
    },
    {
      name: 'td',
      checked: false,
    },
    {
      name: 'lng',
      checked: false,
    },
    {
      name: '1stdown',
      checked: false,
    },
    {
      name: '20+',
      checked: false,
    },
    {
      name: '40+',
      checked: false,
    },
    {
      name: 'fum',
      checked: false,
    },
  ];
  NFLCheckboxes4 = [
    {
      name: 'team',
      checked: true,
    },
    {
      name: 'position',
      checked: true,
    },
    {
      name: 'gp',
      checked: true,
    },
    {
      name: 'pts',
      checked: true,
    },
    {
      name: 'rush',
      checked: true,
    },
    {
      name: 'rec',
      checked: false,
    },
    {
      name: 'kr',
      checked: false,
    },
    {
      name: 'pr',
      checked: false,
    },
    {
      name: 'fum',
      checked: false,
    },
    {
      name: 'int',
      checked: false,
    },
    {
      name: 'pa att',
      checked: false,
    },
    {
      name: 'pa m',
      checked: false,
    },
    {
      name: 'fga',
      checked: false,
    },
    {
      name: 'fgm',
      checked: false,
    },
    {
      name: '2 pt',
      checked: false,
    },
    {
      name: 'sfty',
      checked: false,
    },
  ];

  NFLCheckboxes5 = [
    {
      name: 'team',
      checked: true,
    },
    {
      name: 'position',
      checked: true,
    },
    {
      name: 'gp',
      checked: true,
    },
    {
      name: 't (total)',
      checked: true,
    },
    {
      name: 't (solo)',
      checked: true,
    },
    {
      name: 't (ast)',
      checked: false,
    },
    {
      name: 't (tfl)',
      checked: false,
    },
    {
      name: 's (total)',
      checked: false,
    },
    {
      name: 'S (yds)',
      checked: false,
    },
    {
      name: 'i (int)',
      checked: false,
    },
    {
      name: 'i (yds)',
      checked: false,
    },
    {
      name: 'i (td)',
      checked: false,
    },
    {
      name: 'i (pd)',
      checked: false,
    },
    {
      name: 'f (ff)',
      checked: false,
    },
    {
      name: 'f (rec)',
      checked: false,
    },
  ];

  MLBCheckboxes1 = [
    {
      name: 'team',
      checked: true,
    },
    {
      name: 'position',
      checked: true,
    },
    {
      name: 'gp',
      checked: true,
    },
    {
      name: 'ab',
      checked: true,
    },
    {
      name: 'runs',
      checked: true,
    },
    {
      name: 'h',
      checked: false,
    },
    {
      name: '2b',
      checked: false,
    },
    {
      name: '3b',
      checked: false,
    },
    {
      name: 'homeruns',
      checked: false,
    },
    {
      name: 'rbi',
      checked: false,
    },
    {
      name: 'sb',
      checked: false,
    },
    {
      name: 'bb',
      checked: false,
    },
    {
      name: 'so',
      checked: false,
    },
    {
      name: 'avg',
      checked: false,
    },
    {
      name: 'obp',
      checked: false,
    },
    {
      name: 'slg',
      checked: false,
    },
    {
      name: 'ops',
      checked: false,
    },
  ];

  MLBCheckboxes2 = [
    {
      name: 'team',
      checked: true,
    },
    {
      name: 'position',
      checked: true,
    },
    {
      name: 'gp',
      checked: true,
    },
    {
      name: 'w',
      checked: true,
    },
    {
      name: 'l',
      checked: true,
    },
    {
      name: 'era',
      checked: false,
    },
    {
      name: 'saves',
      checked: false,
    },
    {
      name: 'svo',
      checked: false,
    },
    {
      name: 'ip',
      checked: false,
    },
    {
      name: 'hitsallowed',
      checked: false,
    },
    {
      name: 'runsallowed',
      checked: false,
    },
    {
      name: 'earnedrunsallowed',
      checked: false,
    },
    {
      name: 'hr',
      checked: false,
    },
    {
      name: 'bb',
      checked: false,
    },
    {
      name: 'so',
      checked: false,
    },
    {
      name: 'avg',
      checked: false,
    },
    {
      name: 'whip',
      checked: false,
    },
  ];
  StatsBlockCheckboxes = [];
  size: any = 10;
  gameTypeVal: string = null;
  playerPool: string = 'All Players';
  seasonVal: string = 'Regular';
  yearVal: string = '2022';
  gamePropertyVal: string = null;
  leagueStatVal: any = null;
  leagueVal: string = 'Select League';
  teamVal: string = 'Select Team';
  playerVal1: string = null;
  playerVal2: string = null;
  divisionVal: string = 'Select Division';
  conferenceVal: string = 'Select Conference';
  property: string;
  widgetCardType: string = 'Select Block Card Type';
  widgetStatType: string = 'Select Content Block Stats';
  widgetStandingType: string = 'Select Content Block Standing';
  widgetUpcomingType: string = 'Select Content Block Upcoming';
  graphValues: any = [];
  key: any = '';
  graphNames: any = [];

  chartShow: boolean = false;

  league_id: any;
  conference_id: any;

  //NEW BLOCK VARIABLES//
  imageType: string = 'gallery';
  _meta_description: string = '';
  content_id: number;
  _type: string;
  _image_url: string;
  created_at: any;
  timezone: any;
  modified_at: Date;
  _status: string;
  _keywords: string;
  widget_id: number;
  display_order: string;
  number: number = null;

  widgetSource: string = 'other';
  numberSource: string = '';
  socialCommentSource: string = 'Select Social Url Type';
  isWidgetsExpanded: boolean = false;
  embedYoutubeLink: string;
  widgetArr = [];
  removedBlocksArr: any = [];
  Immersive_view_widgetArr: any = [];
  templateBlockArr: any = [];
  localWidgetArr = [];
  sourceArr = [];
  accordiansExpanded: string[] = [];

  INX: any = null;
  ImmersiveSocialCommentObj = null;
  INDEX: any = null;
  SecondINDEX: any = null;
  embeddedURLTest;

  //edit widget
  isEditWidget: boolean = false;

  // image upload
  image_keyword: string = '';
  showList: boolean = false;
  showImagnOverlay: boolean = false;

  AddIndex: number = -2;
  selectedId: any = null;
  showPlayerOverlay: boolean = false;
  uploadImage: boolean = false;
  widgetImage: string = '';

  postType: boolean = true;
  postType_v2: boolean = false;
  // Team tags
  isTeamSelectOpen: boolean = false;
  keywordToSearchTeam: string = '';
  keywordToSearchGroup: string = '';
  selectedTeams = [];
  public readonly searchSubject = new Subject<string | undefined>();
  searchSubscription;

  // widget section
  showWidgetMenu: boolean = false;

  uploadFromImagn: boolean = false;

  copyTemplateModal: boolean = false;
  blockcheckall: boolean = true;
  templateCopyType: string = 'blocks';
  //generated slug
  generated_slug: string;

  // convert comma seprated strs to arr

  personArr: string[] = [];
  keywordsArrChange$ = new Subject<any>();
  keywordsArr: string[] = [];
  metaDescriptionArr: string[] = [];
  orgStr = '';
  perStr = '';
  keyStr = '';
  metaStr = '';

  token: any;

  contentSources;

  tableHeaders = ['Image Url', 'Date', 'Words', 'Source', 'Title', 'Url'];
  detailBox: boolean = false;

  dummy_Assignee: any = null;
  checklist: any = [];
  files: File[] = [];
  isSaved: boolean = false;
  user: any;
  profileDetails: any = null;
  selectedOptionIndex: number = 0;
  allStatus: any = [];
  immersive_view: boolean = false;

  blockkeyword: string = null;
  zoom_val: number = 10;
  ZoomScale: number = null;
  screenW: number = 0;
  contentView = 'tablet';

  blocktypes = ['new', 'templates', 'library', 'removed'];
  blocktypeSelected: any = 'new';
  plt: boolean = true;
  time: number = 0;
  widget: any = null;

  last_div_id;
  last_div_transform: boolean = false;
  scrollContainer = null;

  orderedlistStyles = [
    {
      id: '10571',
      color: '#fff',
      background: '#087A3F',
      shape: 'round',
      type: 'ordered',
    },

    {
      id: '10572',
      color: '#fff',
      background: '#FFC52D',
      shape: 'round',
      type: 'ordered',
    },

    {
      id: '10573',
      color: '#fff',
      background: '#000',
      shape: 'round',
      type: 'ordered',
    },

    {
      id: '10574',
      color: '#fff',
      background: '#314c88',
      shape: 'round',
      type: 'ordered',
    },

    {
      id: '10575',
      color: '#fff',
      background: '#087A3F',
      shape: 'square',
      type: 'ordered',
    },

    {
      id: '10576',
      color: '#fff',
      background: '#FFC52D',
      shape: 'square',
      type: 'ordered',
    },

    {
      id: '10577',
      color: '#fff',
      background: '#000',
      shape: 'square',
      type: 'ordered',
    },
    {
      id: '10578',
      color: '#fff',
      background: '#314c88',
      shape: 'square',
      type: 'ordered',
    },
  ];
  unorderedlistStyles = [
    {
      id: '10671',
      color: '#000',
      background: '#087A3F',
      type: 'unordered',
      icon_name: 'checkmark-outline',
    },
    {
      id: '10672',
      color: '#000',
      background: '#087A3F',
      type: 'unordered',
      icon_name: 'close-outline',
    },
    {
      id: '10673',
      color: '#000',
      background: '#087A3F',
      type: 'unordered',
      icon_name: 'football-outline',
    },

    {
      id: '10674',
      color: '#000',
      background: '#087A3F',
      type: 'unordered',
      icon_name: 'baseball-outline',
    },
    {
      id: '10675',
      color: '#000',
      background: '#087A3F',
      type: 'unordered',
      icon_name: 'basketball-outline',
    },
    {
      id: '10676',
      color: '#000',
      background: '#087A3F',
      type: 'unordered',
      icon_name: 'american-football-outline',
    },
    {
      id: '10677',
      color: '#000',
      background: '#087A3F',
      type: 'unordered',
      icon_name: 'caret-forward-outline',
    },
    {
      id: '10678',
      color: '#000',
      background: '#087A3F',
      type: 'unordered',
      icon_name: 'checkbox-outline',
    },
    {
      id: '10679',
      color: '#000',
      background: '#087A3F',
      type: 'unordered',
      icon_name: 'checkmark-circle-outline',
    },
    {
      id: '10680',
      color: '#000',
      background: '#087A3F',
      type: 'unordered',
      icon_name: 'stop-outline',
    },
    {
      id: '10681',
      color: '#000',
      background: '#087A3F',
      type: 'unordered',
      icon_name: 'trophy-outline',
    },
  ];
  bulletTypeSelected: any = '10571';
  selectedText: any = '';
  editItemActive: boolean = false;
  editListIcon: boolean = false;
  item_obj = null;
  list: any = [];
  listTypeSelected: any = 'ps-lt';
  listView: boolean = false;
  selectedImg: any = null;
  draggingNewBlock: boolean = false;
  dragObject: any = null;

  wiki_title: any = null;
  wiki_thumbnail: any = null;
  wiki_sections: any = [];
  selected_sections: any = [];
  wiki_loading: boolean = false;
  wiki_dropdown_show: boolean = false;
  wiki_dropdown_data: any = [];
  wiki_extract: any = null;
  wiki_url: any = null;
  wiki_source_title: any = null;
  wiki_keyword: any = null;

  //data grid variables//
  numOfColumns = 5;
  selectedColumns: any = [];
  numOfRows = 4;
  columnColor = 'blue';
  columnsArr: string[] = ['column1', 'column2', 'column3', 'column4'];
  showGrid: boolean = false;
  dataGridArr: any = [
    {
      id: 1009,
      column1: '',
      column2: '',
      column3: '',
      column4: '',
    },
    {
      id: 1008,
      column1: '',
      column2: '',
      column3: '',
      column4: '',
    },
    {
      id: 1007,
      column1: '',
      column2: '',
      column3: '',
      column4: '',
    },
    {
      id: 1006,
      column1: '',
      column2: '',
      column3: '',
      column4: '',
    },
  ];
  main_dataGridArr: any = [];

  propertyMappings: any = {
    passCompletions: 'passCompletions',
    gamesplayed: 'gamesplayed',
    passAttempts: 'passAttempts',
    passPct: 'passPct',
    passYards: 'passYards',
    passYardsPerAtt: 'passYardsPerAtt',
    yardspergame: 'yardspergame',
    passTD: 'passTD',
    passInt: 'passInt',
    passSacks: 'passSacks',
    passTDPct: 'passTDPct',
    passIntPct: 'passIntPct',
    qbRating: 'qbRating',
    rushAttempts: 'rushAttempts',
    rushYards: 'rushYards',
    rushTD: 'rushTD',
    rushAverage: 'rushAverage',
    rushLng: 'rushLng',
    rushFumbles: 'rushFumbles',
    targets: 'targets',
    receptions: 'receptions',
    recYards: 'recYards',
    recAverage: 'recAverage',
    recTD: 'recTD',
    recLng: 'recLng',
    rec1stDowns: 'rec1stDowns',
    rec20Plus: 'rec20Plus',
    rec40Plus: 'rec40Plus',
    recFumbles: 'recFumbles',
    scoring: 'scoring',
    rush: 'rush',
    rec: 'rec',
    kr: 'kr',
    pr: 'pr',
    fum: 'fum',
    int: 'int',
    pa_att: 'pa_att',
    pa_m: 'pa_m',
    fga: 'fga',
    fgm: 'fgm',
    two_pt: 'two_pt',
    sfty: 'sfty',
    tackleTotal: 'tackleTotal',
    tackleSolo: 'tackleSolo',
    tackleAst: 'tackleAst',
    tackleForLoss: 'tackleForLoss',
    sacks: 'sacks',
    sackYds: 'sackYds',
    interceptions: 'interceptions',
    intYds: 'intYds',
    intTD: 'intTD',
    passesDefended: 'passesDefended',
    fumForced: 'fumForced',
    fumOppRec: 'fumOppRec',
    goals: 'goals',
    assists: 'assists',
    points: 'points',
    hatTricks: 'hatTricks',
    plusMinus: 'plusMinus',
    penalties: 'penalties',
    penaltyMinutes: 'penaltyMinutes',
    pointspergame: 'pointspergame',
    powerplayGoals: 'powerplayGoals',
    powerplayAssists: 'powerplayAssists',
    shorthandedGoals: 'shorthandedGoals',
    shorthandedAssists: 'shorthandedAssists',
    wins: 'wins',
    losses: 'losses',
    overtimeLosses: 'overtimeLosses',
    saves: 'saves',
    goalsAgainstAverage: 'goalsAgainstAverage',
    goalsAgainst: 'goalsAgainst',
    savePercentage: 'savePercentage',
    shutouts: 'shutouts',
    minminutespergame: 'minminutespergame',
    fgPct: 'fgPct',
    fg3PtPct: 'fg3PtPct',
    ftPct: 'ftPct',
    offRebPerGame: 'offRebPerGame',
    defRebPerGame: 'defRebPerGame',
    rebPerGame: 'rebPerGame',
    astPerGame: 'astPerGame',
    blkPerGame: 'blkPerGame',
    stlPerGame: 'stlPerGame',
    tovPerGame: 'tovPerGame',
    atBats: 'atBats',
    runs: 'runs',
    hits: 'hits',
    secondBaseHits: 'secondBaseHits',
    thirdBaseHits: 'thirdBaseHits',
    homeruns: 'homeruns',
    runsBattledIn: 'runsBattledIn',
    stolenBases: 'stolenBases',
    batterWalks: 'batterWalks',
    batterStrikeouts: 'batterStrikeouts',
    battingAvg: 'battingAvg',
    batterOnBasePct: 'batterOnBasePct',
    batterSluggingPct: 'batterSluggingPct',
    batterOnBasePlusSluggingPct: 'batterOnBasePlusSluggingPct',
    earnedRunAvg: 'earnedRunAvg',
    saveOpportunities: 'saveOpportunities',
    inningsPitched: 'inningsPitched',
    hitsAllowed: 'hitsAllowed',
    runsAllowed: 'runsAllowed',
    earnedRunsAllowed: 'earnedRunsAllowed',
    homeRunsAllowed: 'homeRunsAllowed',
    pitcherWalks: 'pitcherWalks',
    pitcherStrikeouts: 'pitcherStrikeouts',
    pitcherAvg: 'pitcherAvg',
    walksAndHitsPerInningPitched: 'walksAndHitsPerInningPitched',
  };

  color_column: any = '';

  edit_icon_show: boolean = false;
  dataGridtype: any = 'Blank Table';
  click_block: boolean = false;
  new_obj: any = null;
  twitter_username: any = '';
  validation_obj: any = {
    is_extract: true,
    wiki_extract: null,
    wiki_url: null,
    wiki_source_title: null,
    wiki_sections: [],
    wiki_keyword: null,
    isWidgetSelectOpen: false,
    keywordToSearchTeam: '',
    twitter_teams_arr: [],
    twitter_search_box: true,
    wiki_title: null,
    wiki_thumbnail: null,
    wiki_dropdown_data: [],
    wiki_dropdown_show: false,
    color_column: '',
    name: '',
    Widget_author: '',
    pubname: '',
    number: null,
    url: '',
    listView: false,
    image_keyword: '',
    _meta_description: null,
    _keywords: null,
    description: '',
    selectedImg: null,
    searchLinkVar2: '',
    searchLinkVar: '',
    _image_url: null,
    images: [],
    files: [],
    lat: 0,
    long: 0,
    zoom: 18,
    editItemActive: false,
    selectedText: '',
    item_obj: null,
    list: [],
    dateObj: null,
    playerStatsInfo: {
      data: [],
      page: 1,
      size: 10,
      totalRecords: 0,
    },
    playerStatsObj: {
      data: [],
      page: 1,
      size: 10,
      totalRecords: 0,
    },
    playerKeyword: '',
    playerKeyword1: null,
    playerKeyword2: null,
    playerKey1: false,
    playerKey2: false,
    playerName: null,
    playerNumber: null,
    playerTeam: null,
    playerLeague: null,
    playerHeight: null,
    playerWeight: null,
    playerPosition: null,
    playerAge: null,
    playerBirth: null,
    teamKeyword: '',
    teamInfo: null,
    teamName: null,
    teamFounded: null,
    teamTotalTitles: null,
    teamLastTitle: null,
    teamStadium: null,
    teamLoc: null,
    teamCapacity: null,
    teamHeadCoach: null,
    leagueKeyword: '',
    leagueInfo: null,
    leagueName: null,
    leagueFounded: null,
    leagueTotalTitles: null,
    leagueLastTitle: null,
    leagueRunnerUp: null,
    leagueResults: null,
    divisionKeyword: '',
    divisionInfo: null,
    divisionName: null,
    divisionFounded: null,
    divisionTotalTitles: null,
    divisionLastTitle: null,
    is_spotlight: false,
    spotlightPlayerSlug: '',
    gamePropertyVal: 'Select Sort',
    gameTypeVal: 'Select Stat Type',
    leagueVal: 'Select League',
    divisionVal: 'Select Division',
    conferenceVal: 'Select Conference',
    teamVal: 'Select Team',
    playerVal1: 'Select Player 1',
    playerVal2: 'Select Player 2',
    gameSortVal: 'desc',
    leagueStatVal: null,
    standings: {
      data: [],
    },
    upcomings: {
      data: [],
    },
    sportsKey: null,
    leagueGameType: 'Select League Game Type',
    widgetUpcomingType: 'Select Content Block Upcoming',
    widgetStandingType: 'Select Content Block Standing',
  };

  characterCount: any = 0;
  old_post_value: any = '';
  old_description_value: any = '';
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    public nav: NavController,
    private bll: ArticleDetailsBLL,
    private teams_bll: TeamsBLL,
    private fb: FormBuilder,
    private auth: AuthService,
    private storage: StorageService,
    public dragulaService: DragulaService,
    private sanitizer: DomSanitizer,
    private alertCtrl: AlertController,
    private router: Router,
    private toast: ToastController,
    private clipboard: Clipboard,
    private tinyUrl: NgTinyUrlService,
    private loadCtrl: LoadingController,
    public platform: Platform,
    public popoverCtrl: PopoverController,
    private ionLoaderService: LoaderService,
    private googleMapService: GoogleMapServiceService
  ) {}
  selectWidgetType(widget) {
    this.widgetType = widget;
  }

  get_blocks(page: number, size: number, token: any, keyword?: any) {
    let params = new HttpParams();
    params = params.set('size', size);
    params = params.set('page', page);
    params = params.set('keyword', keyword);
    let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    if (token !== undefined || token !== null) {
      const url = environment.api_url + 'content?content_blocks=true';
      return this.http.get<any>(url, { headers, params });
    }
  }

  get_templates(page: number, size: number, token: any, keyword?: any) {
    let params = new HttpParams();
    params = params.set('size', size);
    params = params.set('page', page);
    params = params.set('keyword', keyword);
    let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    if (token !== undefined || token !== null) {
      const url = environment.api_url + 'content_template?list=true';
      return this.http.get<any>(url, { headers, params });
    }
  }
  edit_block(block) {
    this.showAddBlock = true;
    this.edit_IV = false;
    //this.widgetType = block.type;
  }

  editWidget(w: any, index) {
    console.log('w', w);

    this.selectedId = w.id;
    this.chartShow = true;
    this.editItemActive = false;
    this.selectedText = '';
    this.playerKeyword = '';
    this.playerOverlay = false;
    this.playerOverlay1 = false;
    this.playerOverlay2 = false;
    this.playerOverlay3 = false;
    this.playerOverlay4 = false;
    this.playerKey1 = false;
    this.playerKey2 = false;
    this.playerKeyword1 = null;
    this.playerKeyword2 = null;

    this.teamKeyword = '';
    this.divisionKeyword = '';
    this.conferenceKeyword = '';
    this.searchLinkVar2 = '';
    this.searchLinkVar = '';
    this.leagueKeyword = '';
    if (this.immersive_view) {
      this.showAddBlock = true;
      this.edit_IV = false;
    } else {
      this.showWidgetMenu = true;
    }

    this.twitter_search_box = true;
    this.twitter_teams_arr = [];
    this.bll.reset_search();
    this.keywordToSearchTeam = '';

    this.INX = index;
    this.image_keyword = '';
    this.bll.reset_imagn_data();
    this.is_title = w.is_title;
    this.is_extract = w.is_extract ? w.is_extract : true;

    this.is_text = w.is_text;
    this.isEdit = false;

    this.widget_id = w.id;
    this.description = w.display_text ? this.regex(w.display_text) : '';
    if (w.source === 'wikipedia') {
      this.wiki_sections = [];
      this.wiki_keyword = null;
      this.widgetType = w.source;
      this.widgetSource = w.type;

      if (w.type === 'person') {
        // this.wiki_extract = w.main_content.person.wiki_extract
        //   ? w.main_content.person.wiki_extract
        //   : null;
        this.url = w.url ? w.url : '';
        if (w.main_content.person && w.main_content.person.data.length > 0) {
          this.description = w.main_content.person.data[0].description
            ? this.regex(w.main_content.person.data[0].description)
            : '';
        } else {
          this.description = '';
        }
      }

      if (w.type === 'organization') {
        // this.wiki_extract = w.main_content.organization.wiki_extract
        //   ? w.main_content.organization.wiki_extract
        //   : null;
        this.url = w.url ? w.url : '';
        if (
          w.main_content.organization &&
          w.main_content.organization.data.length > 0
        ) {
          this.description = w.main_content.organization.data[0].description
            ? this.regex(w.main_content.organization.data[0].description)
            : '';
        } else {
          this.description = '';
        }
      }

      if (w.type === 'other') {
        // this.wiki_extract = w.main_content.other.wiki_extract
        //   ? w.main_content.other.wiki_extract
        //   : null;
        this.url = w.url ? w.url : '';
        if (w.main_content.other && w.main_content.other.data.length > 0) {
          this.description = w.main_content.other.data[0].description
            ? this.regex(w.main_content.other.data[0].description)
            : '';
        } else {
          this.description = '';
        }
      }
    } else {
      this.widgetType = w.type;
      this.widgetSource = w.source;
    }

    if (w.type === 'image') {
      if (w.main_content !== null) {
        var imagesArr = [];
        this.images = [];
        this.files = [];
        this.localimages = [];
        this.showCaptions = w.main_content?.image.showCaptions;
        imagesArr = w.main_content?.image?.image;
        this.imageType = w.main_content?.image?.type;
        imagesArr.map((img) => {
          let a = {
            previewUrl: img.url,
            caption: img.description,
            credit: img.source,
            position: null,
          };
          this.images.push(a);
          this.localimages.push(a);
        });
      }
    }

    if (w.type === 'social_comment') {
      if (w.main_content.social_comment) {
        this.socialCommentSource = w.main_content?.social_comment.source
          ? w.main_content?.social_comment.source
          : w.source;
        if (w.main_content.social_comment.date.includes('Z')) {
          const date = new Date(w.main_content.social_comment.date);

          // Extract date and time components
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const day = date.getDate().toString().padStart(2, '0');
          const hours = date.getHours().toString().padStart(2, '0');
          const minutes = date.getMinutes().toString().padStart(2, '0');
          const seconds = date.getSeconds().toString().padStart(2, '0');

          // Format components into datetime-local string
          const formattedString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

          // Output formatted datetime string
          this.dateObj = formattedString;
        } else {
          this.dateObj = w.main_content.social_comment.date;
        }

        if (w.source === 'reddit') {
          this.description = w.main_content.social_comment.comment
            ? w.main_content.social_comment.comment
            : '';
        } else {
          this.description = w.main_content.social_comment.comment
            ? w.main_content.social_comment.comment
            : '';
        }
        this.url = w.main_content.social_comment.url
          ? w.main_content.social_comment.url
          : '';
      }
    }

    if (w.type === 'number') {
      this.number = w.main_content.number.number;
    }

    if (w.type === 'player_card') {
      this.playerInfo = w.main_content?.player_card;
      this.playerTeam = w.main_content?.player_card.team;
      this.playerLeague = w.main_content.player_card.league
        ? w.main_content.player_card.league
        : null;
      this.playerTeam_color_1 = w.main_content?.player_card.team_color_1;
      this.playerTeam_color_2 = w.main_content?.player_card.team_color_2;
      this.playerName = w.main_content?.player_card.name;
      this.playerNumber = w.main_content?.player_card.number;
      this.playerHeight = this.regex(w.main_content?.player_card.height);
      this.playerWeight = w.main_content?.player_card.weight;
      this.playerPosition = w.main_content?.player_card.position;

      if (w.main_content?.player_card.wiki_url) {
        this.wiki_url = w.main_content?.player_card.wiki_url
          ? w.main_content?.player_card.wiki_url
          : null;
        this.wiki_source_title = w.main_content?.player_card.wiki_source_title
          ? w.main_content?.player_card.wiki_source_title
          : null;
      }

      if (w.main_content?.player_card.dob) {
        const dob = w.main_content?.player_card.dob;

        if (moment(dob, 'MM-DD-YYYY', true).isValid()) {
          // If it's a valid date, calculate age and assign to playerBirth
          const age = this.calculateAge(
            moment(dob).local().format('MM-DD-YYYY')
          );
          this.playerBirth = age.toString();
        } else {
          // If it's not a valid date, assign directly to playerBirth
          this.playerBirth = this.calculateAge(dob).toString();
        }
      }
    }

    if (w.type === 'info' && w.main_content.info.type === 'team') {
      this.teamInfo = w.main_content.info;
      this.teamName = w.main_content.info.name;
      this.teamFounded = w.main_content.info.date_found;
      this.teamTotalTitles = w.main_content.info.championships;
      this.teamLastTitle = w.main_content.info.last_championship;
      this.teamStadium = w.main_content.info.stadium;
      this.teamLoc = w.main_content.info.city;
      this.teamCapacity = w.main_content.info.stadium_capacity;
      this.teamHeadCoach = w.main_content.info.head_coach;
      this.widgetCardType = 'team_card';
    }

    if (w.type === 'info' && w.main_content.info.type === 'league') {
      this.leagueInfo = w.main_content.info;
      this.leagueName = w.main_content.info.name;
      this.leagueFounded = w.main_content.info.date_found;
      this.leagueTotalTitles = w.main_content.info.most_league_titles;
      this.leagueLastTitle = w.main_content.info.champion;
      this.leagueRunnerUp = w.main_content.info.runner_up;
      this.leagueResults = w.main_content.info.last_league_result;
      this.widgetCardType = 'league_card';
    }

    if (w.type === 'info' && w.main_content.info.type === 'division') {
      this.divisionInfo = w.main_content.info;
      this.divisionName = w.main_content.info.name;
      this.divisionFounded = w.main_content.info.date_found;
      this.divisionTotalTitles = w.main_content.info.most_titles;
      this.divisionLastTitle = w.main_content.info.champion;
      this.widgetCardType = 'division_card';
    }

    if (w.type === 'info' && w.main_content.info.type === 'conference') {
      this.conferenceInfo = w.main_content.info;
      this.conferenceName = w.main_content.info.name;
      this.conferenceFounded = w.main_content.info.date_found;
      this.conferenceLastTitle = w.main_content.info.champion;
      this.conferenceRunnerUp = w.main_content.info.runner_up;
      this.conferenceResult = w.main_content.info.last_conference_result;
      this.widgetCardType = 'conference_card';
    }
    this.checkedList = [];

    if (w.type === 'stats') {
      this.playerStatsInfo.data = [];
      this.playerStatsObj.data = [];
      w.main_content.stats.obj.data.map((obj) => {
        this.playerStatsObj.data.push(obj);
      });

      if (this.playerStatsObj.data && this.playerStatsObj.data.length > 0) {
        // this.seasonVal = this.playerStatsObj.data[0].type;
        // if (this.playerStatsObj.data[0].type !== undefined) {
        //   const delimiterPos =
        //     this.playerStatsObj.data[0].type.lastIndexOf('-');
        //   this.yearVal = this.playerStatsObj.data[0].type.substring(
        //     0,
        //     delimiterPos
        //   );
        //   this.seasonVal = this.playerStatsObj.data[0].type.substring(
        //     delimiterPos + 1
        //   );
        // }
      }
      // w.main_content.stats.obj.data.map(obj => {
      //   this.playerStatsInfo.data.push(obj);
      // });
      // w.main_content.stats.obj.data.map((obj)=>{
      //   console.log('obj',obj)
      //   this.playerStatsInfo.data.push(obj);
      //   this.playerStatsObj.data.push(obj);
      // })
      this.widgetStatType = w.main_content.stats.stat_type;

      this.playerStatsInfo.totalRecords = w.main_content.stats.obj.data.length;
      this.playerStatsObj.totalRecords = w.main_content.stats.obj.data.length;

      this.leagueVal = w.main_content.stats.league;
      this.gameTypeVal = w.main_content.stats.type;
      this.gamePropertyVal = w.main_content.stats.property;
      this.checkedList = w.main_content.stats.filters;
      this.size = w.main_content.stats.size ? w.main_content.stats.size : 10;

      if (this.widgetStatType === 'player_stats') {
        w.main_content.stats.obj.data.map((player, index) => {
          if (index === 0) {
            this.playerKeyword1 = player.player_name;
            this.playerKey1 = true;
          } else {
            this.playerKeyword2 = player.player_name;
            this.playerKey2 = true;
          }
        });
      }
      if (this.widgetStatType === 'team_stats') {
        this.teamVal = w.main_content.stats.team_id;
        this.OBJID = w.main_content.stats.team_id;
        if (this.leagueVal === 'NFL') {
          this.leagueID = 55;
          this.getTeamList(null);
        }
        if (this.leagueVal === 'NHL') {
          this.leagueID = 40;
          this.getTeamList(null);
        }
        if (this.leagueVal === 'NBA') {
          this.leagueID = 42;
          this.getTeamList(null);
        }
        if (this.leagueVal === 'MLB') {
          this.leagueID = 43;
          this.getTeamList(null);
        }

        this.is_spotlight = w.main_content.stats.is_spotlight;
        this.spotlightSelection = w.main_content.stats.spotlightSelection;
        this.spotlightPlayerSlug = w.main_content.stats.spotlightSlug;
      }
      if (this.widgetStatType === 'division_stats') {
        this.divisionVal = w.main_content.stats.division_id;
        this.OBJID = w.main_content.stats.division_id;
        this.getTeamList(null);
        this.is_spotlight = w.main_content.stats.is_spotlight;
        this.spotlightSelection = w.main_content.stats.spotlightSelection;
        this.spotlightPlayerSlug = w.main_content.stats.spotlightSlug;
      }
      if (this.widgetStatType === 'conference_stats') {
        this.conferenceVal = w.main_content.stats.conference_id;
        this.OBJID = w.main_content.stats.conference_id;
        this.getTeamList(null);
        this.is_spotlight = w.main_content.stats.is_spotlight;
        this.spotlightSelection = w.main_content.stats.spotlightSelection;
        this.spotlightPlayerSlug = w.main_content.stats.spotlightSlug;
      }

      if (this.widgetStatType === 'league_stats') {
        this.is_spotlight = w.main_content.stats.is_spotlight;
        this.spotlightSelection = w.main_content.stats.spotlightSelection;
        this.spotlightPlayerSlug = w.main_content.stats.spotlightSlug;
      }

      // this.widgetStatType= w.main_content.stats.stattype

      this.StatsBlockCheckboxes = [];
      let statsBlockcheckbox = [];
      if (this.leagueVal === 'NFL' && this.gameTypeVal === 'passing') {
        this.NFLCheckboxes1.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passCompletions' &&
            checkbox.name === 'comp'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passPct' &&
            checkbox.name === 'pct'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passAttempts' &&
            checkbox.name === 'att'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passYards' &&
            checkbox.name === 'yds'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passYardsPerAtt' &&
            checkbox.name === 'y/a'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'yardspergame' &&
            checkbox.name === 'y/g'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passTD' &&
            checkbox.name === 'td'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passInt' &&
            checkbox.name === 'int'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passSacks' &&
            checkbox.name === 'sk'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passTDPct' &&
            checkbox.name === 'td%'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passIntPct' &&
            checkbox.name === 'int%'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'qbRating' &&
            checkbox.name === 'qbr'
          ) {
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NFL' && this.gameTypeVal === 'rushing') {
        this.NFLCheckboxes2.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushAttempts' &&
            checkbox.name === 'att'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushYards' &&
            checkbox.name === 'yds'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushTD' &&
            checkbox.name === 'td'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushAverage' &&
            checkbox.name === 'avg'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'yardspergame' &&
            checkbox.name === 'y/g'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushLng' &&
            checkbox.name === 'lng'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushFumbles' &&
            checkbox.name === 'fum'
          ) {
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NFL' && this.gameTypeVal === 'receiving') {
        this.NFLCheckboxes3.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'targets' &&
            checkbox.name === 'tgt'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'receptions' &&
            checkbox.name === 'rec'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'recYards' &&
            checkbox.name === 'yds'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'recAverage' &&
            checkbox.name === 'avg'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'yardspergame' &&
            checkbox.name === 'y/g'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'recTD' &&
            checkbox.name === 'td'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'recLng' &&
            checkbox.name === 'lng'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rec1stDowns' &&
            checkbox.name === '1stdown'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rec20Plus' &&
            checkbox.name === '20+'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rec40Plus' &&
            checkbox.name === '40+'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'recFumbles' &&
            checkbox.name === 'fum'
          ) {
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NFL' && this.gameTypeVal === 'scoring') {
        this.NFLCheckboxes4.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'scoring' &&
            checkbox.name === 'pts'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rush' &&
            checkbox.name === 'rush'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rec' &&
            checkbox.name === 'rec'
          ) {
            checkbox.checked = true;
          } else if (this.gamePropertyVal === 'kr' && checkbox.name === 'kr') {
            checkbox.checked = true;
          } else if (this.gamePropertyVal === 'pr' && checkbox.name === 'pr') {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fum' &&
            checkbox.name === 'fum'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'Int' &&
            checkbox.name === 'int'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'Pa_att' &&
            checkbox.name === 'pa att'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'Pa_m' &&
            checkbox.name === 'pa m'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fga' &&
            checkbox.name === 'fga'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fgm' &&
            checkbox.name === 'fgm'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'two_pt' &&
            checkbox.name === '2 pt'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'sfty' &&
            checkbox.name === 'sfty'
          ) {
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (
        this.leagueVal === 'NFL' &&
        this.gameTypeVal === 'tackles,interceptions,fumbles'
      ) {
        this.NFLCheckboxes5.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'tackleTotal' &&
            checkbox.name === 't (total)'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'tackleSolo' &&
            checkbox.name === 't (solo)'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'tackleAst' &&
            checkbox.name === 't (ast)'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'tackleForLoss' &&
            checkbox.name === 't (tfl)'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'sacks' &&
            checkbox.name === 's (total)'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'sackYds' &&
            checkbox.name === 's (yds)'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'interceptions' &&
            checkbox.name === 'i (int)'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'intYds' &&
            checkbox.name === 'i (yds)'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'intTD' &&
            checkbox.name === 'i (td)'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passesDefended' &&
            checkbox.name === 'i (pd)'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fumForced' &&
            checkbox.name === 'f (ff)'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fumOppRec' &&
            checkbox.name === 'f (rec)'
          ) {
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NBA' && this.gameTypeVal === 'offense') {
        this.NBACheckboxes.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'minminutespergame' &&
            checkbox.name === 'mpg'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fgpct' &&
            checkbox.name === 'fg%'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fg3PtPct' &&
            checkbox.name === '3p%'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'ftPct' &&
            checkbox.name === 'ft%'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'offRebPerGame' &&
            checkbox.name === 'orb'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'defRebPerGame' &&
            checkbox.name === 'drb'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rebPerGame' &&
            checkbox.name === 'reb'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'astPerGame' &&
            checkbox.name === 'ast'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'blkPerGame' &&
            checkbox.name === 'blk'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'stlPerGame' &&
            checkbox.name === 'stl'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'tovPerGame' &&
            checkbox.name === 'tov'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'plusMinus' &&
            checkbox.name === '+/-'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'ptsPerGame' &&
            checkbox.name === 'pts'
          ) {
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'MLB' && this.gameTypeVal === 'batting') {
        this.MLBCheckboxes1.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'atBats' &&
            checkbox.name === 'ab'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'runs' &&
            checkbox.name === 'runs'
          ) {
            checkbox.checked = true;
          } else if (this.gamePropertyVal === 'h' && checkbox.name === 'hits') {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'secondBaseHits' &&
            checkbox.name === '2b'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'thirdBaseHits' &&
            checkbox.name === '3b'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'homeruns' &&
            checkbox.name === 'homeruns'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'runsBattedIn' &&
            checkbox.name === 'rbi'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'stolenBases' &&
            checkbox.name === 'sb'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'batterWalks' &&
            checkbox.name === 'bb'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'batterStrikeouts' &&
            checkbox.name === 'so'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'battingAvg' &&
            checkbox.name === 'avg'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'batterOnBasePct' &&
            checkbox.name === 'obp'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'batterSluggingPct' &&
            checkbox.name === 'sly'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'batterOnBasePlusSluggingPct' &&
            checkbox.name === 'ops'
          ) {
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'MLB' && this.gameTypeVal === 'pitching') {
        this.MLBCheckboxes2.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.checked = true;
          } else if (this.gamePropertyVal === 'wins' && checkbox.name === 'w') {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'losses' &&
            checkbox.name === 'l'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'earnedRunAvg' &&
            checkbox.name === 'era'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'saves' &&
            checkbox.name === 'saves'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'saveOpportunities' &&
            checkbox.name === 'svo'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'inningsPitched' &&
            checkbox.name === 'ip'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'hitsAllowed' &&
            checkbox.name === 'hitsallowed'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'runsAllowed' &&
            checkbox.name === 'runsallowed'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'earnedRunsAllowed' &&
            checkbox.name === 'earnedrunsallowed'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'homerunsAllowed' &&
            checkbox.name === 'hr'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'pitcherWalks' &&
            checkbox.name === 'bb'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'pitcherStrikeouts' &&
            checkbox.name === 'so'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'pitchingAvg' &&
            checkbox.name === 'avg'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'walksandHitsPerInningPitched' &&
            checkbox.name === 'whip'
          ) {
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NHL' && this.gameTypeVal === 'goaltending') {
        this.NHLCheckboxes1.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.checked = true;
          } else if (this.gamePropertyVal === 'wins' && checkbox.name === 'w') {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'losses' &&
            checkbox.name === 'losses'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'overtimeLosses' &&
            checkbox.name === 'otl'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'goalsAgainstAverage' &&
            checkbox.name === 'gaaverage'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'goalsAgainst' &&
            checkbox.name === 'gagainst'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'saves' &&
            checkbox.name === 'saves'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'savePercentage' &&
            checkbox.name === 'svpct'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'shutouts' &&
            checkbox.name === 'so'
          ) {
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NHL' && this.gameTypeVal === 'scoring') {
        this.NHLCheckboxes2.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'goals' &&
            checkbox.name === 'goals'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'assists' &&
            checkbox.name === 'assists'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'points' &&
            checkbox.name === 'pts'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'hatTricks' &&
            checkbox.name === 'hattricks'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'plusMinus' &&
            checkbox.name === '+/-'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'penalties' &&
            checkbox.name === 'pen'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'penaltyMinutes' &&
            checkbox.name === 'pim'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'pointspergame' &&
            checkbox.name === 'p/g'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'powerplayGoals' &&
            checkbox.name === 'pp (g)'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'powerplayAssists' &&
            checkbox.name === 'pp (a)'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'shorthandedGoals' &&
            checkbox.name === 'sh (g)'
          ) {
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'shorthandedAssists' &&
            checkbox.name === 'sh (a)'
          ) {
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }

      this.StatsBlockCheckboxes.map((obj) => {
        if (this.checkedList.includes(obj.name)) {
          obj.checked = true;
        } else {
          obj.checked = false;
        }
      });

      if (
        w.main_content.stats.is_spotlight === true &&
        w.main_content.stats.spotlightSelection === 'league-leader'
      ) {
        this.getPlayersForStats3();
      }
    }

    if (w.type === 'map') {
      this.googleMapService.lat = w.main_content.map.latitude;
      this.googleMapService.long = w.main_content.map.longitude;
      this.googleMapService.zoom = w.main_content.map.zoom
        ? w.main_content.map.zoom
        : 18;
      this.googleMapService.mapTypeId = w.main_content.map.mapTypeId
        ? w.main_content.map.mapTypeId
        : google.maps.MapTypeId.ROADMAP;
    }

    if (w.type === 'list') {
      this.list = [];
      w.main_content.list.listarray.map((list) => {
        this.list.push(list);
      });
    }

    if (w.type === 'standings') {
      this.widgetStandingType = w.main_content.standings.standing_type;
      this.leagueVal = w.main_content.standings.league;
      this.conferenceVal = w.main_content.standings.league_conference;
      this.divisionVal = w.main_content.standings.division;
      this.teamVal = w.main_content.standings.team;
      this.standings = w.main_content.standings.standing;
      if (this.leagueVal === 'NFL') {
        this.leagueID = 55;
        this.getTeamList(null);
      }
      if (this.leagueVal === 'NHL') {
        this.leagueID = 40;
        this.getTeamList(null);
      }
      if (this.leagueVal === 'NBA') {
        this.leagueID = 42;
        this.getTeamList(null);
      }
      if (this.leagueVal === 'MLB') {
        this.leagueID = 43;
        this.getTeamList(null);
      }
    }

    if (w.type === 'upcoming_games') {
      this.widgetUpcomingType = w.main_content.upcoming_games.upcoming_type;
      this.leagueGameType = w.main_content.upcoming_games.league_game_type;
      this.leagueVal = w.main_content.upcoming_games.league;
      this.conferenceVal = w.main_content.upcoming_games.league_conference;
      this.divisionVal = w.main_content.upcoming_games.division;
      this.teamVal = w.main_content.upcoming_games.team;
      this.sportsKey = w.main_content.upcoming_games.my_sports_key;
      this.upcomings = w.main_content.upcoming_games.upcomings;
      if (this.leagueVal === 'NFL') {
        this.leagueID = 55;
        this.getTeamList(null);
      }
      if (this.leagueVal === 'NHL') {
        this.leagueID = 40;
        this.getTeamList(null);
      }
      if (this.leagueVal === 'NBA') {
        this.leagueID = 42;
        this.getTeamList(null);
      }
      if (this.leagueVal === 'MLB') {
        this.leagueID = 43;
        this.getTeamList(null);
      }
    }

    if (w.type === 'chart') {
      this.chartVal = w.main_content.chart.charttype
        ? w.main_content.chart.charttype
        : 'bar';
      this.leagueVal = w.main_content.chart.league;
      this.gameTypeVal = w.main_content.chart.type;
      this.gamePropertyVal = w.main_content.chart.property;
      this.graphValues = w.main_content.chart.dataobj;
      this.graphNames = w.main_content.chart.nameobj;
      this.size = w.main_content.chart.size ? w.main_content.chart.size : 10;
      this.chartOptions = w.main_content.chart.chartOptions;
      this.tableData = w.main_content.chart.nameobj.map((name, index) => ({
        name,
        score: w.main_content.chart.dataobj[index],
      }));

      if (w.main_content.chart.season) {
        this.seasonVal = w.main_content.chart.season;
      }

      if (w.main_content.chart.year) {
        this.yearVal = w.main_content.chart.year;
      }
      if (w.main_content.chart.all_seasons) {
        this.complete_seasons = w.main_content.chart.all_seasons;
      }
      if (w.main_content.chart.team) {
        this.teamVal = w.main_content.chart.team;
      }

      setTimeout(() => {
        this.checkPost();
      }, 1000);
    }

    if (w.type === 'datagrid') {
      let columnsArr: any = [];
      this.dataGridArr = [];
      let selectedColumns: any = [];
      w.main_content.datagrid.columnsArr.map((a) => {
        columnsArr.push(a);
      });
      w.main_content.datagrid.selectedColumns.map((a) => {
        selectedColumns.push(a);
      });

      this.dataGridArr = this.deepCopy(w.main_content.datagrid.dataGridArr);

      this.columnsArr = columnsArr;

      // dataGridArr.map((obj) => {
      //   this.dataGridArr.push(obj);
      // });
      this.columnColor = w.main_content.datagrid.columnColor;
      this.numOfColumns = w.main_content.datagrid.numOfColumns;
      this.numOfRows = w.main_content.datagrid.numOfRows;
      this.selectedColumns = selectedColumns;
      this.showGrid = w.main_content.datagrid.showGrid;
      this.color_column = w.main_content.datagrid.color_column;

      if (w.main_content.datagrid.season) {
        this.seasonVal = w.main_content.datagrid.season;
      }

      if (w.main_content.datagrid.year) {
        this.yearVal = w.main_content.datagrid.year;
      }
      if (w.main_content.datagrid.all_seasons) {
        this.complete_seasons = w.main_content.datagrid.all_seasons;
      }
      if (w.main_content.datagrid.team) {
        this.teamVal = w.main_content.datagrid.team;
      }
    }

    this.name = this.regex(w.title);
    this.Widget_author = w.author ? this.regex(w.author) : '';

    if (
      w.type === 'social_comment' &&
      w.main_content.social_comment &&
      w.main_content.social_comment.source === 'twitter'
    ) {
      this.Widget_author = w.main_content.social_comment.username
        ? w.main_content.social_comment.username
        : '';
    }
    if (
      w.type === 'social_comment' &&
      w.main_content.social_comment &&
      w.main_content.social_comment.source === 'reddit'
    ) {
      this.Widget_author = w.main_content.social_comment.name
        ? w.main_content.social_comment.name
        : '';
    }

    if (w.type === 'quote') {
      this.description = w.main_content.quote.text
        ? this.regex(w.main_content.quote.text)
        : '';
      if (w.main_content.quote.speaker) {
        this.Widget_author = this.regex(w.main_content.quote.speaker);
      }
      if (w.main_content.quote.pubName) {
        this.pubname = this.regex(w.main_content.quote.pubName);
      }
      if (w.main_content.quote.url) {
        this.url = w.main_content.quote.url;
      }
    }
    if (w.type === 'text') {
      this.description = w.main_content.text.text
        ? this.regex(w.main_content.text.text)
        : '';
    }

    if (w.type === 'twitter') {
      if (w.main_content.twitter && w.main_content.twitter.url) {
        this.url = w.main_content.twitter.url ? w.main_content.twitter.url : '';
      }
    }
    if (w.type === 'video') {
      if (w.main_content.video && w.main_content.video.url) {
        this.url = w.main_content.video.url ? w.main_content.video.url : '';
      }
      if (w.main_content.video.title) {
        this.name = w.main_content.video.title;
      }
      if (w.main_content.video) {
        this.youtubeObj = w.main_content.video;
      }
    }
    if (w.type) this._meta_description = w.meta_description;
    this._keywords = w.keywords;
    this.content_id = w.content_id;
    this._image_url = w.image_url;

    // let checkCurrentTime = new Date(w.created_at);
    // let momentCurrentTime = moment(checkCurrentTime).local().format('YYYY-MM-DD HH:mm:ss');
    // this.created_at = checkCurrentTime;
    // this.created_at = moment(w.created_at).format();
    // console.log('created_at',this.created_at);
    // let checkCurrentTime = new Date(w.created_at);
    // console.log('timezone check time: ', checkCurrentTime);
    // console.log('moment converted time: ', momentCurrentTime);
    this.modified_at = w.modified_at;
    this._status = w.status;

    if (w.display_order) {
      this.display_order = w.display_order;
    } else {
      const index: any = this.widgetArr.findIndex((item) => item.id === w.id);

      if (index !== -1) {
        this.display_order = index;
      }
    }

    this.isEditWidget = true;
    this.lmt = 15;
    this.bll.reset_imagn_data();

    this.validation_obj.is_extract = this.is_extract;
    this.validation_obj.wiki_extract = this.wiki_extract;
    this.validation_obj.wiki_url = this.wiki_url;
    this.validation_obj.wiki_source_title = this.wiki_source_title;
    this.validation_obj.wiki_sections = this.wiki_sections;
    this.validation_obj.wiki_keyword = this.wiki_keyword;
    this.validation_obj.isWidgetSelectOpen = this.isWidgetSelectOpen;
    this.validation_obj.keywordToSearchTeam = this.keywordToSearchTeam;
    this.validation_obj.twitter_teams_arr = this.twitter_teams_arr;
    this.validation_obj.twitter_search_box = this.twitter_search_box;
    (this.validation_obj.wiki_title = this.wiki_title),
      (this.validation_obj.wiki_thumbnail = this.wiki_thumbnail);
    this.validation_obj.wiki_dropdown_data = this.wiki_dropdown_data;
    this.validation_obj.wiki_dropdown_show = this.wiki_dropdown_show;
    this.validation_obj.color_column = this.color_column;
    this.validation_obj.name = this.name;
    this.validation_obj.Widget_author = this.Widget_author;
    this.validation_obj.pubname = this.pubname;
    this.validation_obj.number = this.number;
    this.validation_obj.url = this.url;
    this.validation_obj.listView = this.listView;
    this.validation_obj.image_keyword = this.image_keyword;
    this.validation_obj._meta_description = this._meta_description;
    this.validation_obj._keywords = this._keywords;
    this.validation_obj.description = this.description;
    this.validation_obj.selectedImg = this.selectedImg;
    this.validation_obj.searchLinkVar2 = this.searchLinkVar2;
    this.validation_obj.searchLinkVar = this.searchLinkVar;
    this.validation_obj._image_url = this._image_url;
    this.validation_obj.images = this.images;
    this.validation_obj.files = this.files;
    this.validation_obj.lat = this.googleMapService.lat;
    this.validation_obj.long = this.googleMapService.long;
    this.validation_obj.zoom = this.googleMapService.zoom;
    this.validation_obj.editItemActive = this.editItemActive;
    this.validation_obj.selectedText = this.selectedText;
    this.validation_obj.item_obj = this.item_obj;
    this.validation_obj.list = this.list;
    this.validation_obj.dateObj = this.dateObj;
    this.validation_obj.playerStatsInfo = this.playerStatsInfo;
    this.validation_obj.playerStatsObj = this.playerStatsObj;
    this.validation_obj.playerKeyword = this.playerKeyword;
    this.validation_obj.playerKeyword1 = this.playerKeyword1;
    this.validation_obj.playerKeyword2 = this.playerKeyword2;
    this.validation_obj.playerKey1 = this.playerKey1;
    this.validation_obj.playerKey2 = this.playerKey2;
    this.validation_obj.playerName = this.playerName;
    this.validation_obj.playerNumber = this.playerNumber;
    this.validation_obj.playerTeam = this.playerTeam;
    this.validation_obj.playerLeague = this.playerLeague;
    this.validation_obj.playerHeight = this.playerHeight;
    this.validation_obj.playerWeight = this.playerWeight;
    this.validation_obj.playerPosition = this.playerPosition;
    this.validation_obj.playerAge = this.playerAge;
    this.validation_obj.playerBirth = this.playerBirth;
    this.validation_obj.teamKeyword = this.teamKeyword;
    this.validation_obj.teamInfo = this.teamInfo;
    this.validation_obj.teamName = this.teamName;
    this.validation_obj.teamFounded = this.teamFounded;
    this.validation_obj.teamTotalTitles = this.teamTotalTitles;
    this.validation_obj.teamLastTitle = this.teamLastTitle;
    this.validation_obj.teamStadium = this.teamStadium;
    this.validation_obj.teamLoc = this.teamLoc;
    this.validation_obj.teamCapacity = this.teamCapacity;
    this.validation_obj.teamHeadCoach = this.teamHeadCoach;
    this.validation_obj.leagueKeyword = this.leagueKeyword;
    this.validation_obj.leagueInfo = this.leagueInfo;
    this.validation_obj.leagueName = this.leagueName;
    this.validation_obj.leagueFounded = this.leagueFounded;
    this.validation_obj.leagueTotalTitles = this.leagueTotalTitles;
    this.validation_obj.leagueLastTitle = this.leagueLastTitle;
    this.validation_obj.leagueRunnerUp = this.leagueRunnerUp;
    this.validation_obj.leagueResults = this.leagueResults;
    this.validation_obj.divisionKeyword = this.divisionKeyword;
    this.validation_obj.divisionInfo = this.divisionInfo;
    this.validation_obj.divisionName = this.divisionName;
    this.validation_obj.divisionFounded = this.divisionFounded;
    this.validation_obj.divisionTotalTitles = this.divisionTotalTitles;
    this.validation_obj.divisionLastTitle = this.divisionLastTitle;
    this.validation_obj.is_spotlight = this.is_spotlight;
    this.validation_obj.spotlightPlayerSlug = this.spotlightPlayerSlug;
    this.validation_obj.gamePropertyVal = this.gamePropertyVal;
    this.validation_obj.gameTypeVal = this.gameTypeVal;
    this.validation_obj.leagueVal = this.leagueVal;
    this.validation_obj.divisionVal = this.divisionVal;
    this.validation_obj.conferenceVal = this.conferenceVal;
    this.validation_obj.teamVal = this.teamVal;
    this.validation_obj.playerVal1 = this.playerVal1;
    this.validation_obj.playerVal2 = this.playerVal2;
    this.validation_obj.gameSortVal = this.gameSortVal;
    this.validation_obj.leagueStatVal = this.leagueStatVal;
    this.validation_obj.standings = this.standings;
    this.validation_obj.upcomings = this.upcomings;
    this.validation_obj.sportsKey = this.sportsKey;
    this.validation_obj.leagueGameType = this.leagueGameType;
    this.validation_obj.widgetUpcomingType = this.widgetUpcomingType;
    this.validation_obj.widgetStandingType = this.widgetStandingType;

    this.characterCount = this.countVisibleCharacters(this.description);
  }

  deepCopy(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      const copy: any[] = [];
      for (let i = 0; i < obj.length; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    const copy: { [key: string]: any } = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        copy[key] = this.deepCopy(obj[key]);
      }
    }
    return copy;
  }

  regex(p) {
    if (p !== null) {
      if (p.includes('&apos;')) {
        let arr = /(&apos;)/g;
        return p.replace(arr, "'");
      } else {
        return p;
      }
    } else {
      return p;
    }
  }

  regexRevert(p) {
    if (p !== null) {
      if (p.includes("'")) {
        let arr = /(')/g;
        return p.replace(arr, '&apos;');
      } else {
        return p;
      }
    } else {
      return p;
    }
  }

  getTeamList(event) {
    if (event !== null) {
      this.teamVal = 'Select Team';
    }
    if (
      this.widgetStatType === 'league_stats' ||
      this.widgetStatType === 'team_stats' ||
      this.widgetStatType === 'conference_stats' ||
      this.widgetStatType === 'division_stats' ||
      this.widgetStatType === 'player_stats' ||
      this.widgetType === 'standings' ||
      this.widgetType === 'upcoming_games' ||
      this.widgetType === 'chart' ||
      this.widgetType === 'datagrid'
    ) {
      if (this.widgetStatType === 'player_stats') {
        this.playerKeyword1 = null;
        this.playerKeyword2 = null;
        this.playerKey2 = false;
        this.playerKey1 = false;
        this.playerStatsObj.data = [];
        this.playerStatsObj.totalRecords = 0;
        this.gameTypeVal = 'Select Stat Type';
      }

      if (this.leagueVal === 'NFL') {
        this.leagueID = 55;
        this.get_t(this.leagueID);
        this.get_conference_league(this.leagueID);
        this.get_d(this.leagueID, this.conferenceVal);
        this.bll.get_season_Data(this.leagueVal.toUpperCase(), this.auth.token);
        this.bll.get_default_val(this.leagueVal.toLowerCase(), this.auth.token);
      }
      if (this.leagueVal === 'NHL') {
        this.leagueID = 40;
        this.get_t(this.leagueID);
        this.get_conference_league(this.leagueID);
        this.get_d(this.leagueID, this.conferenceVal);
        this.bll.get_season_Data(this.leagueVal.toUpperCase(), this.auth.token);
        this.bll.get_default_val(this.leagueVal.toLowerCase(), this.auth.token);
      }
      if (this.leagueVal === 'NBA') {
        this.leagueID = 42;
        this.get_t(this.leagueID);
        this.get_conference_league(this.leagueID);
        this.get_d(this.leagueID, this.conferenceVal);
        this.bll.get_season_Data(this.leagueVal.toUpperCase(), this.auth.token);
        this.bll.get_default_val(this.leagueVal.toLowerCase(), this.auth.token);
      }
      if (this.leagueVal === 'MLB') {
        this.leagueID = 43;
        this.get_t(this.leagueID);
        this.get_conference_league(this.leagueID);
        this.get_d(this.leagueID, this.conferenceVal);
        this.bll.get_season_Data(this.leagueVal.toUpperCase(), this.auth.token);
        this.bll.get_default_val(this.leagueVal.toLowerCase(), this.auth.token);
      }
    }
  }

  get_t(league_id: any) {
    let url = `team?list=true&page=1&size=126`;

    url += `&league_id=${league_id}`;

    this.get(url)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.Teams = res.data;
        },
        (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            //this.auth.refreshtoken();
            setTimeout(() => {
              this.get_t(league_id);
            }, 1000);
          }
        }
      );
  }

  get_d(league_id: any, conference_league_id?: any) {
    let url = `division?list=true&page=1&size=126`;

    url += `&league_conference_id=${conference_league_id}`;
    url += `&league_id=${league_id}`;

    this.get(url)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.Divisions = res.data;
        },
        (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            //this.auth.refreshtoken();
            setTimeout(() => {
              this.get_d(league_id, conference_league_id);
            }, 1000);
          }
        }
      );
  }

  get_conference_league(league_id: any) {
    let url = `conference?league_conference=true&page=1&size=126`;

    url += `&league_id=${league_id}`;

    this.get(url)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.Conference_leagues = res.data;
        },
        (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            //this.auth.refreshtoken();
            setTimeout(() => {
              this.get_conference_league(league_id);
            }, 1000);
          }
        }
      );
  }

  get(url: string): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.auth.token,
    };
    return this.http.get(environment.api_url + url, {
      headers: new HttpHeaders(headers),
    });
  }

  getPlayersForStats3() {
    this.bll.reset_player_data3();
    let seasona = '';
    if (this.yearVal && this.seasonVal) {
      this.complete_seasons.map((obj) => {
        if (
          obj.display_name === this.seasonVal &&
          obj.display_year === this.yearVal
        ) {
          seasona = obj.year;
        }
      });
      if (seasona === '') {
        seasona = this.yearVal + '-' + this.seasonVal.toLowerCase();
      }
    }
    if (this.widgetStatType === 'team_stats') {
      this.bll.get_playerstats_team3(
        this.teamVal,
        this.leagueVal,
        this.gameTypeVal,
        this.auth.token,
        seasona,
        this.gameSortVal,
        this.gamePropertyVal
      );
    }
    if (this.widgetStatType === 'division_stats') {
      this.bll.get_playerstats_div3(
        this.divisionVal,
        this.leagueVal,
        this.gameTypeVal,
        this.auth.token,
        seasona,
        this.gameSortVal,
        this.gamePropertyVal
      );
    }
    if (this.widgetStatType === 'conference_stats') {
      this.bll.get_playerstats_conf3(
        this.conferenceVal,
        this.leagueVal,
        this.gameTypeVal,
        this.auth.token,
        seasona,
        this.gameSortVal,
        this.gamePropertyVal
      );
    }
    if (this.widgetStatType === 'league_stats') {
      this.bll.get_playerstats3(
        this.leagueVal,
        this.gameTypeVal,
        this.auth.token,
        seasona,
        this.gameSortVal,
        this.gamePropertyVal
      );
    }

    // this.bll.get_player_Data(this.playerKeyword1,this.leagueID)
  }

  // Article Detail Page Methods
  get_groups() {
    // this.bll.get_groups(this.article_id,this.auth.token)
    this.getGroups();
    // this.getContentGroups();
    // this.bll.get_content_groups(this.article_id,this.auth.token)
  }

  getGroups() {
    let url = `group?list=true&page=${1}&size=${50}`;
    if (this.article_id) {
      url += `&content_id=${this.article_id}&content_assignee=true`;
    }
    this.get(url)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          // this.groups.next(res.data);
          this.groups = res.data;

          if (res.data) {
            if (this.groups.length > 0) {
              this.groups.map((group) => {
                if (group.content_assigned === true) {
                  this.group_id.setValue(group.id);
                  this.selected_group = group;
                  this.changeGroup();
                }
              });
            }
          }
        },
        (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            //this.auth.refreshtoken();
            setTimeout(() => {
              this.getGroups();
            }, 1000);
          }
        }
      );
  }

  getGroups2() {
    let url = `group?list=true&page=${1}&size=${50}`;
    if (this.article_id) {
      url += `&content_id=${this.article_id}&content_assignee=true`;
    }
    this.get(url)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          // this.groups.next(res.data);
          this.groups = res.data;

          if (res.data) {
            if (this.groups.length > 0) {
              this.groups.map((group) => {
                if (group.content_assigned === true) {
                }
              });
            }
          }
        },
        (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            //this.auth.refreshtoken();
            setTimeout(() => {
              this.getGroups2();
            }, 1000);
          }
        }
      );
  }
  // getContentGroups() {
  //   let url = `group?group_content=true&content_id=${this.article_id}`;
  //       this.get(url).pipe(take(1)).subscribe((res: any) => {
  //           console.log('res from groups=>',res);
  //           this.content_groups = res.data;
  //       }, (err) => {
  //       });
  // }
  searching_groups() {
    if (!this.keywordToSearchGroup) {
      this.getGroups2();
    } else {
      // Convert the keyword to lowercase for case-insensitive comparison

      this.groups = this.groups.filter((item) =>
        item.name
          .toLowerCase()
          .includes(this.keywordToSearchGroup.toLowerCase())
      );
    }
  }

  assignGroupToContent(event) {}

  async imageWidgetFileChanged(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      let file_name = '';
      file_name = await this.storage.uploadFile(file);

      this.widgetFile = file;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this._image_url = file_name;
      };
    }
  }

  getImageUrlFromFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject('Invalid file');
        return;
      }

      const reader = new FileReader();

      reader.onload = (event: any) => {
        resolve(event.target.result);
      };

      reader.onerror = (event: any) => {
        reject('Error reading file');
      };

      reader.readAsDataURL(file);
    });
  }

  async onSelect(event) {
    this.files.push(...event.addedFiles);

    let file_name = '';

    this.files.map((img, index) => {
      let metaObj = {
        uploadedBy: this.user.name,
        fileType: event.addedFiles[index].type,
        fileSize: event.addedFiles[index].size,
        height: '',
        width: '',
        altText: '',
        caption: '',
        description: '',
      };

      this.getImageUrlFromFile(event.addedFiles[index])
        .then((url) => {
          file_name = url;

          this.images.push({
            previewUrl: file_name,
            fileSize: event.addedFiles[index].size,
            height: '',
            width: '',
            altText: '',
            caption: '',
            description: '',
          });
        })
        .catch((error) => {
          console.error(error);
        });
    });

    // file_name = await this.storage.uploadFile3(event.addedFiles[0], metaObj);
  }

  async onSelect2(event) {
    if (this.param === null) {
      this.files.push(...event.addedFiles);

      let file_name = '';

      this.ionLoaderService.simpleLoader('Updating Image');
      this.files.map(async (img) => {
        file_name = await this.storage.uploadFile(img);

        this.imageStr = file_name;
        this.image.setValue(this.imageStr);

        this.bll.get_cropped_image(file_name, this.auth.token).subscribe(
          (data: any) => {
            this.ionLoaderService.dismissLoader();
            this.thumbnail.setValue(data.data.message);
            this.uploadFromImagn = false;
            this.image_keyword = '';
            this.imageSourceTab = 'imagn';
            this.resetImagnApi();
          },
          (err) => {
            this.ionLoaderService.dismissLoader();
            if (err instanceof HttpErrorResponse && err.status === 401) {
              //this.auth.refreshtoken();
              setTimeout(() => {
                this.onSelect2(event);
              }, 1000);
            }
            this.showToast(err.message);
          }
        );
      });
    } else if (this.param === 'player') {
      this.files.push(...event.addedFiles);

      let file_name = '';

      this.ionLoaderService.simpleLoader('Updating Image');
      this.files.map(async (img) => {
        file_name = await this.storage.uploadFile(img);
        setTimeout(() => {
          this.playerInfo.display_picture = file_name;
          this.uploadFromImagn = false;
          this.image_keyword = '';
          this.imageSourceTab = 'imagn';
          this.resetImagnApi();
          this.ionLoaderService.dismissLoader();
        }, 2000);
      });
    }

    // file_name = await this.storage.uploadFile3(event.addedFiles[0], metaObj);
  }

  onRemove(event, index) {
    this.files.splice(index, 1);
    this.images.splice(index, 1);
  }

  onBackButtonClicked(event) {
    if (window.confirm('Are you sure you want to leave this page?')) {
      // Handle the back button click
    } else {
      // Prevent the default behavior of the back button
      history.pushState(null, null, window.location.href);
    }
  }

  onResize(event) {
    event.target.innerWidth;
    this.screenW = window.innerWidth;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 448) {
      this.screenWidth = this.screenWidth - 729;
    }
  }
  submitted = false;

  change_article_status(status) {
    this.status.setValue(status);
  }

  getImages() {
    if (this.image_keyword && this.image_keyword !== '') {
      this.get_imagn_data();
      this.showImagnOverlay = true;
      this.isEdit = false;
    }
  }

  changeIndex(num) {
    this.AddIndex = num;
  }

  resetIndex() {
    this.AddIndex = -2;
  }

  mapObservables() {
    this.isLoading$ = this.bll.isLoading$;
    this.blocksLoading$ = this.bll.blocksLoading$;
    this.article$ = this.bll.article$;
    this.article$.subscribe((data: any) => {
      if (data) {
        if (data !== null) {
          this.article_data = data;
          if (data.group) {
            if (data.group[0].group_members) {
              let group_socials = [];
              data.group[0].group_members.map((member) => {
                if (member.member_type.includes('social')) {
                  group_socials.push(member);
                }
              });

              group_socials.map((user) => {
                if (this.profileDetails) {
                  if (user.email === this.profileDetails.email) {
                    this.group_social = true;
                  }
                }
              });
            }
          }
          if (this.profileDetails) {
            if (this.profileDetails.role.includes('super_admin')) {
              this.group_social = true;
            }
          }
        }
      } else {
        this.article_data = null;
      }
    });
    this.teams$ = this.teams_bll.teams$;
    this.groups$ = this.bll.groups$;
    this.groups$.subscribe((data) => {
      this.groups = [];
      if (data !== null) {
        data.map((group) => {
          this.groups.push(group);
        });
      }
    });
    this.content_groups$ = this.bll.content_groups$;
    this.content_groups$.subscribe((data) => {
      this.content_groups = [];
      if (data !== null) {
        data.map((group) => {
          this.content_groups.push(group);
        });
      }
    });
    this.content_groups$.subscribe((data) => {
      this.content_groups = [];
      if (data !== null) {
        data.map((group) => {
          this.content_groups.push(group);
        });
      }
    });
    this.members$ = this.bll.members$;
    this.assignee$ = this.bll.assignee$;
    this.isGroupLoading$ = this.bll.isGroupLoading$;
    this.isMembersLoading$ = this.bll.isMembersLoading$;
    this.isTeamsLoading$ = this.teams_bll.isLoading$;

    this.imagnData$ = this.bll.imagnData$;
    this.playerData$ = this.bll.playerData$;
    this.playerData3$ = this.bll.playerData3$;
    this.teamData$ = this.bll.teamData$;
    this.leagueData$ = this.bll.leagueData$;
    this.divisionData$ = this.bll.divisionData$;
    this.conferenceData$ = this.bll.conferenceData$;
    this.sportData$ = this.bll.sportsData$;
    this.searchData$ = this.bll.searchData$;
    this.statusData$ = this.bll.statusData$;

    this.searchData$.subscribe((data) => {});

    this.statusData$.subscribe((data) => {
      this.allStatus = [];
      if (data) {
        data.map((obj) => {
          this.allStatus.push(obj);
        });
        if (this.allStatus.length > 0) {
          this.allStatus.map((status, index) => {
            if (status.slug === this.status.value) {
              this.selectedOptionIndex = index;
            }
          });
        }
      }
    });
    this.seasonData$ = this.bll.seasonData$;
    this.defaultData$ = this.bll.defaultData$;

    this.seasonData$.subscribe((data) => {
      if (data !== null) {
        this.years = [];
        this.seasonS = [];
        this.complete_seasons = [];
        data.map((season) => {
          this.complete_seasons.push(season);

          const isDuplicate = this.years.some(
            (seasn) => seasn === season.display_year
          );
          if (!isDuplicate) {
            this.years.push(season.display_year);
          }

          const isDuplicate2 = this.seasonS.some(
            (seasn) => seasn === season.display_name
          );
          if (!isDuplicate2) {
            this.seasonS.push(season.display_name);
          }

          if (season.is_default) {
            this.seasonVal = season.display_name;
            this.yearVal = season.display_year;
          }
        });
      }
    });

    this.defaultData$.subscribe((data) => {
      let seasonYear = data;
      if (data !== null) {
        // let delimiterPos = seasonYear.lastIndexOf('-');
        // this.yearVal = seasonYear.substring(0, delimiterPos);
        // this.seasonVal = seasonYear.substring(delimiterPos + 1);
      }
    });

    this.templates$ = this.bll.templates$;

    this.contentViewer$ = this.bll.contentViewer$;
    this.isWidgetLoading$ = this.bll.isWidgetLoading$;
    this.widgets$ = this.bll.contentWidgets$;
    this.contentSources$ = this.bll.contentSources$;

    this.isAddLoading$ = this.bll.isAddLoading$;
    this.isDeleteLoading$ = this.bll.isDeleteLoading$;
    this.isImagnLoading$ = this.bll.isImagnLoading$;
    this.isPlayerLoading$ = this.bll.isPlayerLoading$;
    this.isSeasonLoading$ = this.bll.isSeasonLoading$;
    this.isTeamLoading$ = this.bll.isTeamLoading$;

    this.isTwitterAddLoading$ = this.bll.isTwitterAddLoading$;
    this.isFacebookAddLoading$ = this.bll.isFacebookAddLoading$;

    this.isLeagueLoading$ = this.bll.isLeagueLoading$;
    this.isDivisionLoading$ = this.bll.isDivisionLoading$;
    this.isConferenceLoading$ = this.bll.isConferenceLoading$;
    this.isSportsLoading$ = this.bll.isSportsLoading$;
    this.isSearchLoading$ = this.bll.isSearchLoading$;
    this.isStatusLoading$ = this.bll.isStatusLoading$;
    this.isTemplateAddLoading$ = this.bll.isTemplateAddLoading$;
    this.bll.checklist$.subscribe((data) => {
      this.checklists = [];
      if (data !== null) {
        data.map((obj) => {
          this.checklists.push(obj);
        });
      }
    });

    this.isChecklistLoading = this.bll.isChecklistLoading$;

    this.post_sub = this.article$.subscribe((article: article) => {
      if (article) {
        if (article.type === 'articles') {
          this.bll.get_content_status('articles', this.auth.token);
        } else {
          this.bll.get_content_status('content_experience', this.auth.token);
        }
        // this.selectedTeams = article.teams? article.teams?.split(',') : [];
        if (article.person?.includes('&comma;')) {
          let arr = /(&comma;)/g;
          let str = article.person.replace(arr, ',');
          article.person = str;
          this.personArr = article.person ? article.person?.split(',') : [];
        } else {
          this.personArr = article.person ? article.person?.split(',') : [];
        }

        if (article.keywords?.includes('&comma;')) {
          let arr = /(&comma;)/g;
          let str = article.keywords.replace(arr, ',');
          article.keywords = str;
          this.keywordsArr = article.keywords
            ? article.keywords?.split(',')
            : [];
        } else {
          this.keywordsArr = article.keywords
            ? article.keywords?.split(',')
            : [];
        }

        if (article.meta_description?.includes('&comma;')) {
          let arr = /(&comma;)/g;
          let str = article.meta_description.replace(arr, ',');
          article.meta_description = str;
          this.metaDescriptionArr = article.meta_description
            ? article.meta_description?.split(',')
            : [];
        } else {
          this.metaDescriptionArr = article.meta_description
            ? article.meta_description?.split(',')
            : [];
        }

        this.contentViewer$.subscribe((data) => {
          if (data !== null) {
            this.contentViewer = data[0];
          }
        });

        this.initialValues = article;
        if (!this.isAdd) {
          this.setValuesToForm(article);
        }
      }
    });

    this.content_sub = this.widgets$.subscribe((w: widget[]) => {
      if (w) {
        // w.map((data)=>{
        //   this.widgetArr.push(data);
        //   this.localWidgetArr.push(data)
        // })
        this.widgetArr = [];
        this.localWidgetArr = [];
        this.removedBlocksArr = [];
        this.Immersive_view_widgetArr = [];
        for (let i = 0; i < w.length; i++) {
          if (w[i].status === null) {
            w[i].status = 'published';
          }
          this.widgetArr.push(w[i]);
          if (w[i].status === 'draft') {
            this.removedBlocksArr.push(w[i]);
          }

          this.localWidgetArr.push(w[i]);
        }
        let tempArr: any = [];
        let doubleArr: any = [];
        let singleArr: any = [];
        this.widgetArr.map((wig, i) => {
          if (wig.status !== 'draft') {
            if (wig.type === 'social_comment') {
              if (wig.source === 'twitter') {
                if (this.widgetArr[i + 1] !== undefined) {
                  if (
                    this.widgetArr[i].source === this.widgetArr[i + 1].source
                  ) {
                    doubleArr.push(this.widgetArr[i]);
                    doubleArr.push(this.widgetArr[i + 1]);
                  } else {
                    if (doubleArr.length > 0) {
                      for (let i = 0; i < doubleArr.length; i++) {
                        const duplicates = doubleArr.filter(
                          (obj) => obj.id === doubleArr[i].id
                        );
                        if (duplicates.length > 1) {
                          doubleArr.splice(i + 1, 1); // remove the second object
                          i--; // decrement i to account for the removed object
                        }
                      }
                      tempArr.push({
                        arr: doubleArr,
                        type: this.widgetArr[i].type,
                        source: this.widgetArr[i].source,
                      });

                      doubleArr = [];
                    } else {
                      singleArr.push(this.widgetArr[i]);
                      tempArr.push({
                        arr: singleArr,
                        type: this.widgetArr[i].type,
                        source: this.widgetArr[i].source,
                      });
                      singleArr = [];
                    }
                  }
                } else {
                  if (doubleArr.length > 0) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }
                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });

                    doubleArr = [];
                  } else {
                    singleArr.push(this.widgetArr[i]);
                    tempArr.push({
                      arr: singleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    singleArr = [];
                  }
                }
              } else if (wig.source === 'reddit') {
                if (this.widgetArr[i + 1] !== undefined) {
                  if (
                    this.widgetArr[i].source === this.widgetArr[i + 1].source
                  ) {
                    doubleArr.push(this.widgetArr[i]);
                    doubleArr.push(this.widgetArr[i + 1]);
                  } else {
                    if (doubleArr.length > 0) {
                      for (let i = 0; i < doubleArr.length; i++) {
                        const duplicates = doubleArr.filter(
                          (obj) => obj.id === doubleArr[i].id
                        );
                        if (duplicates.length > 1) {
                          doubleArr.splice(i + 1, 1); // remove the second object
                          i--; // decrement i to account for the removed object
                        }
                      }
                      tempArr.push({
                        arr: doubleArr,
                        type: this.widgetArr[i].type,
                        source: this.widgetArr[i].source,
                      });
                      doubleArr = [];
                    } else {
                      singleArr.push(this.widgetArr[i]);
                      tempArr.push({
                        arr: singleArr,
                        type: this.widgetArr[i].type,
                        source: this.widgetArr[i].source,
                      });
                      singleArr = [];
                    }
                  }
                } else {
                  if (doubleArr.length > 0) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }
                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });

                    doubleArr = [];
                  } else {
                    singleArr.push(this.widgetArr[i]);
                    tempArr.push({
                      arr: singleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    singleArr = [];
                  }
                }
              }
            } else {
              if (doubleArr.length > 1) {
                for (let i = 0; i < doubleArr.length; i++) {
                  const duplicates = doubleArr.filter(
                    (obj) => obj.id === doubleArr[i].id
                  );
                  if (duplicates.length > 1) {
                    doubleArr.splice(i + 1, 1); // remove the second object
                    i--; // decrement i to account for the removed object
                  }
                }
                doubleArr = doubleArr.filter((obj) => obj.status !== 'draft');

                tempArr.push({
                  arr: doubleArr,
                  type: this.widgetArr[i - 1].type,
                  source: this.widgetArr[i - 1].source,
                });

                doubleArr = [];
              }
              tempArr.push(wig);
            }
          }
        });

        this.Immersive_view_widgetArr = tempArr;
      }
    });

    this.contentSources = this.contentSources$.subscribe((data: source[]) => {
      if (data) {
        this.sourceArr = data;
      }
    });
  }

  get_article() {
    this.bll.get_article('v3', this.article_id, this.auth.token);
  }

  get_teams() {
    let keyword = this.keywordToSearchTeam ? this.keywordToSearchTeam : '-1';
    this.teams_bll.get_teams(
      1,
      10,
      keyword,
      '',
      '',
      true,
      '',
      '',
      this.league_id,
      this.conference_id,
      this.auth.token
    );
  }

  onSearchQueryInput(event: Event): void {
    const searchQuery = (event.target as HTMLInputElement).value;
    this.searchSubject.next(searchQuery?.trim());
  }

  applyTeamFilter(team) {
    let T = {
      content_id: this.article_id,
      id: team.city_id,
      name: team.name,
      team_id: team.id,
    };

    let magenicVendors = this.selectedTeams.filter(
      (vendor) => vendor['team_id'] === T.team_id
    );

    if (magenicVendors.length === 0) {
      this.selectedTeams.push(T);
      this.keywordToSearchTeam = '';
      if (this.status.value === 'prep') {
        if (this.selectedTeams.length > 0) {
          this.bll.checklist$.pipe(take(2)).subscribe((data) => {
            if (data !== null) {
              // this.returnandCheckValues(6, true);
            }
          });
        }
      }
      return this.keywordsArrChange$.next(this.selectedTeams);
    }
    // this.selectedTeams.filter((i)=>{
    //   if(i.team_id !==T.team_id){
    //     console.log(i)
    // this.selectedTeams.push(T)
    // return this.keywordsArrChange$.next(this.selectedTeams)
    //   }
    // })
  }

  addTwitterTeam(team) {
    let T = {
      content_id: this.article_id,
      id: team.city_id,
      name: team.name,
      team_id: team.id,
    };

    let magenicVendors = this.twitter_teams_arr.filter(
      (vendor) => vendor['team_id'] === T.team_id
    );

    if (magenicVendors.length === 0) {
      this.twitter_teams_arr.push(T);
      this.keywordToSearchTeam = '';
    }
    this.twitter_username = team.twitter_handle;
    this.bll.search_team_twitter(team.twitter_handle, this.auth.token);
  }

  removeTeam(team) {
    this.selectedTeams = this.selectedTeams.filter((_team) => _team != team);
    if (this.status.value === 'prep') {
      if (this.selectedTeams.length === 0) {
        this.bll.checklist$.pipe(take(2)).subscribe((data) => {
          if (data !== null) {
            //  this.returnandCheckValues(6, false);
          }
        });
      }
    }
    return this.keywordsArrChange$.next(this.selectedTeams);
  }

  removeTwitterTeam(team) {
    this.twitter_teams_arr = this.twitter_teams_arr.filter(
      (_team) => _team != team
    );
    this.bll.reset_search();
  }

  onEnter(event, fieldName) {
    let id = fieldName;

    if (this.keyStr && id === 'key') {
      let magenicVendors = this.keywordsArr.filter(
        (vendor) => vendor === this.keyStr
      );

      if (magenicVendors.length === 0) {
        this.keywordsArr.push(this.keyStr.substring(0, this.keyStr.length));
        this.keyStr = '';
      }
    }
    if (this.perStr && id === 'per') {
      let magenicVendors = this.personArr.filter(
        (vendor) => vendor === this.perStr
      );

      if (magenicVendors.length === 0) {
        this.personArr.push(this.perStr.substring(0, this.perStr.length));
        this.perStr = '';
      }
    }
    if (this.metaStr && id === 'meta') {
      let magenicVendors = this.metaDescriptionArr.filter(
        (vendor) => vendor === this.metaStr
      );

      if (magenicVendors.length === 0) {
        this.metaDescriptionArr.push(
          this.metaStr.substring(0, this.metaStr.length)
        );
        this.metaStr = '';
      }
    }
  }

  remove(str, fieldName, event) {
    if (fieldName === 'key' && event.which !== 13) {
      return (this.keywordsArr = this.keywordsArr.filter((val) => val != str));
    }
    if (fieldName === 'per' && event.which != 13) {
      return (this.personArr = this.personArr.filter((val) => val != str));
    }
    if (fieldName === 'meta' && event.which !== 13) {
      return (this.metaDescriptionArr = this.metaDescriptionArr.filter(
        (val) => val != str
      ));
    }
  }

  get_imagn_data() {
    if (this.imageSourceTab === 'imagn') {
      this.bll.get_imagn_Data(this.image_keyword, this.auth.token);
    }
    if (this.imageSourceTab === 'library') {
      this.bll.get_library_Data(this.image_keyword, this.auth.token);
    }

    if (this.imageSourceTab === 'unsplash') {
      this.bll.get_unSplash_Data(this.image_keyword, this.auth.token);
    }
  }

  load_more() {
    this.lmt = this.lmt + 15;
    if (this.imageSourceTab === 'imagn') {
      this.bll.load_more_images(this.image_keyword, this.lmt, this.auth.token);
    }
    if (this.imageSourceTab === 'library') {
      this.bll.load_more_library(this.image_keyword, this.lmt, this.auth.token);
    }

    if (this.imageSourceTab === 'unsplash') {
      this.bll.load_more_unsplash(
        this.image_keyword,
        this.lmt,
        this.auth.token
      );
    }
  }

  delete_article() {
    this.bll.delete_article(
      this.article_id,
      this.is_editors_pick.value,
      this.auth.token
    );
    this.isConfirmationToDeleteOpen = false;
  }

  delete_article_permanently() {
    this.bll.delete_article_permanent(this.article_id, this.auth.token);
    this.isConfirmationToDeletePerOpen = false;
  }
  async create_article() {
    let teamArr = [];
    for (let i = 0; i < this.selectedTeams.length; i++) {
      teamArr.push({ team_id: this.selectedTeams[i].team_id });
    }

    this.image.setValue(this.imageStr);
    this.localWidgetArr = [];
    this.Immersive_view_widgetArr = [];
    this.widgetArr.map((a) => {
      this.localWidgetArr.push(a);
      this.Immersive_view_widgetArr.push(a);
    });
    let finalWidgetArr = [];
    this.localWidgetArr.forEach((block, index) => {
      let modifiedBlock = { ...block }; // Create a shallow copy of the block
      if (!this.isNumeric(modifiedBlock.id)) {
        modifiedBlock.id = null;
      }
      finalWidgetArr.push(modifiedBlock);
    });

    this.blocks.setValue(finalWidgetArr);
    this.sources.setValue(this.sourceArr);
    if (this.author.value === '') {
      this.author.setValue(null);
    }

    if (
      this.organization.value === '' ||
      this.organization.value === 'Select League'
    ) {
      this.organization.setValue(null);
    }

    // if (this.postType_v2) {
    //   this.postType
    //     ? this.type.setValue('content_v2')
    //     : this.type.setValue('articles_v2');
    // } else {
    //   this.postType
    //     ? this.type.setValue('content')
    //     : this.type.setValue('articles');
    // }

    this.teams.setValue(teamArr);

    this.person.setValue(this.personArr?.toString());
    this.meta_description.setValue(this.metaDescriptionArr?.toString());
    this.keywords.setValue(this.keywordsArr?.toString());

    // if (this.published_date.value !== null) {
    //   let date = moment(this.published_date.value).format('YYYY-MM-DD HH:mm:ss')
    //   this.published_date.setValue(date);
    // }

    if (
      this.title.value === '' ||
      this.title.value === null ||
      this.title.value === undefined
    ) {
      await this.showToast('Please add title for the article !');
      return;
    }
    if (
      this.slug.value === '' ||
      this.slug.value === null ||
      this.slug.value === undefined
    ) {
      await this.showToast('Please add slug for the article !');
      return;
    }
    if (
      this.status.value === 'published' &&
      (this.published_date.value === null ||
        this.published_date === undefined ||
        this.published_date.value === '')
    ) {
      await this.showToast('Please Add The Publish Date for the Article !');
      return;
    } else {
      this.create_article_(this.form.value, this.file);
      this.isSaved = true;
    }
  }

  create_article_(article: any, image: Blob) {
    this.bll.isAddLoading.next(true);

    // let file_name = '';
    // if (image)
    //     file_name = await this.storage.uploadFile(image);
    // article.image = file_name;

    let url = `article/v3`;
    this.postArticle(url, article)
      .pipe(take(1))
      .subscribe(
        async (res) => {
          this.bll.isAddLoading.next(false);
          const t = await this.toast.create({
            header: 'Post Created',
            message: `Post has been created successfully.`,
            position: 'bottom',
            duration: 5000,
            color: 'secondary',
          });
          await t.present();
          if (this.postType) {
            (
              await this.sendTotalTimetaken(
                'Creating Content Experience',
                res.content_id
              )
            ).subscribe(
              (data) => {
                this.article_id = res.content_id;
                this.isAdd = false;
                this.get_article();
                //this.nav.back();
              },
              async (err) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                  //this.auth.refreshtoken();
                  setTimeout(() => {
                    this.onSelect2(event);
                  }, 1000);
                }
                const t = await this.toast.create({
                  header: 'Error',
                  message: err.message,
                  position: 'bottom',
                  duration: 5000,
                  color: 'danger',
                });
                await t.present();
              }
            );
          } else {
            // this.nav.back();
          }
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            //this.auth.refreshtoken();
            setTimeout(() => {
              this.create_article_(article, image);
            }, 1000);
          }
          const t = await this.toast.create({
            header: 'Error',
            message:
              'There was an issue creating new post. Please try again shortly.',
            position: 'bottom',
            duration: 5000,
            color: 'danger',
          });
          await t.present();
          this.bll.isAddLoading.next(false);
        }
      );
  }

  postArticle(url, body): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: this.auth.token,
    };
    return this.http.post(environment.api_url + url, body, {
      headers: new HttpHeaders(headers),
    });
  }

  async sendTotalTimetaken(msg, id) {
    let url = `article/v3`;

    let body = {
      time: this.time * 1000,
      content_id: id,
      action: msg,
      content_timer: true,
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: this.auth.token,
    };
    return this.http.post(environment.api_url + url, body, {
      headers: new HttpHeaders(headers),
    });
  }

  enableOrDisableStatus(index: number): boolean {
    if (this.selectedOptionIndex === undefined) {
      return true; // Disable the option by default if no option is selected
    }

    const uncheckedExists = this.checklists.some(
      (status) => !status.status_checked
    );

    if (uncheckedExists) {
      if (index <= this.selectedOptionIndex) {
        return false; // Enable the options before the selected option
      }
      return true; // Disable the options after the selected option
    }

    if (
      index <= this.selectedOptionIndex ||
      index === this.selectedOptionIndex + 1
    ) {
      return false; // Enable the option if it's before the selectedOptionIndex or one index after it
    }
    return true; // Disable the option
  }

  async checkCheckList(): Promise<boolean> {
    this.isComplete = true;
    await this.bll.checklist$
      .pipe(take(1))
      .toPromise()
      .then((data) => {
        for (let obj of data) {
          if (!obj.status_checked) {
            this.showToast(
              'The Checklist is Incomplete, please fill out the checklist first'
            );
            this.isComplete = false;
            return;
          }
        }
      });
    return this.isComplete;
  }

  async edit_article() {
    // console.log('user email',this.user.email)
    // console.log('content viewer',this.contentViewer.email)
    // if(this.user.email!==this.contentViewer.email){
    //   let message = this.contentViewer.name +' ' +'is already editting the article!';
    //   await this.showToast(message);
    //   return
    // }

    let teamArr = [];
    for (let i = 0; i < this.selectedTeams.length; i++) {
      teamArr.push({ team_id: this.selectedTeams[i].team_id });
    }

    this.image.setValue(this.imageStr);
    this.localWidgetArr = [];
    //this.Immersive_view_widgetArr = [];
    this.widgetArr.map((a) => {
      this.localWidgetArr.push(a);
      // this.Immersive_view_widgetArr.push(a);
    });

    let finalWidgetArr = [];
    this.localWidgetArr.forEach((block, index) => {
      let modifiedBlock = { ...block }; // Create a shallow copy of the block
      if (!this.isNumeric(modifiedBlock.id)) {
        modifiedBlock.id = null;
      }
      finalWidgetArr.push(modifiedBlock);
    });

    this.blocks.setValue(finalWidgetArr);
    this.sources.setValue(this.sourceArr);
    if (this.author.value === '') {
      this.author.setValue(null);
    }

    if (
      this.organization.value === '' ||
      this.organization.value === 'Select League'
    ) {
      this.organization.setValue(null);
    }

    // if (this.postType_v2) {
    //   this.postType
    //     ? this.type.setValue('content_v2')
    //     : this.type.setValue('articles_v2');
    // } else {
    //   this.postType
    //     ? this.type.setValue('content')
    //     : this.type.setValue('articles');
    // }

    this.teams.setValue(teamArr);

    this.person.setValue(this.personArr?.toString());
    this.meta_description.setValue(this.metaDescriptionArr?.toString());
    this.keywords.setValue(this.keywordsArr?.toString());

    // if (this.published_date.value !== null) {
    //   let date = moment(this.published_date.value).format('YYYY-MM-DD HH:mm:ss')
    //   this.published_date.setValue(date);
    // }

    if (
      this.status.value === 'published' &&
      (this.published_date.value === null ||
        this.published_date === undefined ||
        this.published_date.value === '')
    ) {
      await this.showToast('Please Add The Publish Date for the Article !');
      return;
    } else {
      this.bll.edit_article(
        this.form.value,
        this.file,
        this.article_id,
        this.auth.token
      );
      this.isSaved = true;
    }
  }

  // widgets

  isAlphanumeric(input: string): boolean {
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    return alphanumericRegex.test(input);
  }

  isNumeric(input: string): boolean {
    const numericRegex = /^[0-9]+$/;
    return numericRegex.test(input);
  }

  async confirmClose() {
    const alert = await this.alertCtrl.create({
      header: 'Do you want to discard your changes?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
            this.isEditWidget = false;
            this.widgetType = '';
            this.widgetSource = 'other';
            this.numberSource = '';
            this.socialCommentSource = 'Select Social Url Type';
            this.name = '';
            this.Widget_author = '';
            this.pubname = '';
            this.ID = null;
            this.number = null;
            this.url = '';
            this.uploadImage = false;

            this.imageSourceTab = 'imagn';
            this._meta_description = null;
            this._keywords = null;
            this.description = '';
            this.image_keyword = '';
            this._image_url = null;
            this.images = [];
            this.showWidgetMenu = !this.showWidgetMenu;
          },
        },
      ],
    });

    await alert.present();
  }

  openWidgetMenu(par) {
    if (par === 'add') {
      this.validation_obj = {
        is_extract: true,
        wiki_extract: null,
        wiki_url: null,
        wiki_source_title: null,
        wiki_sections: [],
        wiki_keyword: null,
        isWidgetSelectOpen: false,
        keywordToSearchTeam: '',
        twitter_teams_arr: [],
        twitter_search_box: true,
        wiki_title: null,
        wiki_thumbnail: null,
        wiki_dropdown_data: [],
        wiki_dropdown_show: false,
        color_column: '',
        name: '',
        Widget_author: '',
        pubname: '',
        number: null,
        url: '',
        listView: false,
        image_keyword: '',
        _meta_description: null,
        _keywords: null,
        description: '',
        selectedImg: null,
        searchLinkVar2: '',
        searchLinkVar: '',
        _image_url: null,
        images: [],
        files: [],
        lat: 0,
        long: 0,
        zoom: 18,
        editItemActive: false,
        selectedText: '',
        item_obj: null,
        list: [],
        dateObj: null,
        playerStatsInfo: {
          data: [],
          page: 1,
          size: 10,
          totalRecords: 0,
        },
        playerStatsObj: {
          data: [],
          page: 1,
          size: 10,
          totalRecords: 0,
        },
        playerKeyword: '',
        playerKeyword1: null,
        playerKeyword2: null,
        playerKey1: false,
        playerKey2: false,
        playerName: null,
        playerNumber: null,
        playerTeam: null,
        playerLeague: null,
        playerHeight: null,
        playerWeight: null,
        playerPosition: null,
        playerAge: null,
        playerBirth: null,
        teamKeyword: '',
        teamInfo: null,
        teamName: null,
        teamFounded: null,
        teamTotalTitles: null,
        teamLastTitle: null,
        teamStadium: null,
        teamLoc: null,
        teamCapacity: null,
        teamHeadCoach: null,
        leagueKeyword: '',
        leagueInfo: null,
        leagueName: null,
        leagueFounded: null,
        leagueTotalTitles: null,
        leagueLastTitle: null,
        leagueRunnerUp: null,
        leagueResults: null,
        divisionKeyword: '',
        divisionInfo: null,
        divisionName: null,
        divisionFounded: null,
        divisionTotalTitles: null,
        divisionLastTitle: null,
        is_spotlight: false,
        spotlightPlayerSlug: '',
        gamePropertyVal: 'Select Sort',
        gameTypeVal: 'Select Stat Type',
        leagueVal: 'Select League',
        divisionVal: 'Select Division',
        conferenceVal: 'Select Conference',
        teamVal: 'Select Team',
        playerVal1: 'Select Player 1',
        playerVal2: 'Select Player 2',
        gameSortVal: 'desc',
        leagueStatVal: null,
        standings: {
          data: [],
        },
        upcomings: {
          data: [],
        },
        sportsKey: null,
        leagueGameType: 'Select League Game Type',
        widgetUpcomingType: 'Select Content Block Upcoming',
        widgetStandingType: 'Select Content Block Standing',
      };

      this.selectedId = null;
      this.INX = null;
      this.is_extract = true;
      this.wiki_extract = null;
      this.wiki_url = null;
      this.wiki_source_title = null;
      this.wiki_sections = [];
      this.wiki_keyword = null;
      this.isEditWidget = false;
      this.widgetType = null;
      this.widgetSource = 'other';
      this.numberSource = '';
      this.socialCommentSource = 'Select Social Url Type';
      this.isWidgetSelectOpen = false;
      this.twitter_username = '';

      this.keywordToSearchTeam = '';
      this.twitter_teams_arr = [];
      this.twitter_search_box = true;
      this.twitterSourceTab = 'top';

      this.wiki_title = null;
      this.wiki_thumbnail = null;
      this.wiki_sections = [];
      this.selected_sections = [];
      this.wiki_loading = false;
      this.wiki_dropdown_data = [];
      this.wiki_dropdown_show = false;
      this.wiki_extract = null;
      this.wiki_url = null;
      this.wiki_source_title = null;

      this.click_block = false;

      this.numOfColumns = 5;
      this.selectedColumns = [];
      this.numOfRows = 4;
      this.columnColor = 'blue';
      this.dataGridtype = 'Blank Table';
      this.selectedColumns = ['column1', 'column2', 'column3', 'column4'];
      this.columnsArr = ['column1', 'column2', 'column3', 'column4'];
      this.showGrid = false;
      this.dataGridArr = [
        {
          id: 1009,
          column1: '',
          column2: '',
          column3: '',
          column4: '',
        },
        {
          id: 1008,
          column1: '',
          column2: '',
          column3: '',
          column4: '',
        },
        {
          id: 1007,
          column1: '',
          column2: '',
          column3: '',
          column4: '',
        },
        {
          id: 1006,
          column1: '',
          column2: '',
          column3: '',
          column4: '',
        },
      ];
      this.color_column = '';

      this.name = '';
      this.Widget_author = '';
      this.pubname = '';
      this.ID = null;
      this.number = null;
      this.url = '';
      this.listView = false;
      this.uploadImage = false;
      this._meta_description = null;
      this._keywords = null;
      this.description = '';
      this.selectedImg = null;
      this.image_keyword = '';
      this.imageSourceTab = 'imagn';
      this.searchLinkVar2 = '';
      this.searchLinkVar = '';
      this._image_url = null;
      this.images = [];
      this.files = [];
      this.googleMapService.lat = 0;
      this.googleMapService.long = 0;
      this.googleMapService.zoom = 18;
      this.googleMapService.mapTypeId = google.maps.MapTypeId.ROADMAP;
      this.editItemActive = false;
      this.selectedText = '';
      this.bulletTypeSelected = '10571';
      this.selectedText = '';
      this.editItemActive = false;
      this.item_obj = null;
      this.list = [];
      this.listTypeSelected = 'ps-lt';

      this.blocktypeSelected = 'new';
      this.old_description_value = '';
      if (this.immersive_view === true) {
        this.showAddBlock = true;
        this.edit_IV = false;
        this.widgetType = '';
        // this.ionLoaderService.simpleLoader('Loading ...');

        this.immersiveViewWidgetArr = [];
        this.widgetTypeArr.map((widget) => {
          this.immersiveViewWidgetArr.push(widget);
        });
        // setTimeout(() => {
        //   this.ionLoaderService.dismissLoader();
        // }, 1000);
      } else {
        this.showWidgetMenu = true;
      }

      this.widgetCardType = null;
      this.dateObj = null;

      this.playerStatsInfo = {
        data: [],
        page: 1,
        size: 10,
        totalRecords: 0,
      };

      this.playerStatsObj = {
        data: [],
        page: 1,
        size: 10,
        totalRecords: 0,
      };
      this.playerKeyword = '';
      this.playerKeyword1 = null;
      this.playerKeyword2 = null;
      this.playerKey1 = false;
      this.playerKey2 = false;
      this.playerInfo = null;
      this.playerName = null;
      this.playerNumber = null;
      this.playerTeam = null;
      this.playerLeague = null;
      this.playerHeight = null;
      this.playerWeight = null;
      this.playerPosition = null;
      this.playerAge = null;
      this.playerBirth = null;

      this.teamKeyword = '';
      this.teamInfo = null;
      this.teamName = null;
      this.teamFounded = null;
      this.teamTotalTitles = null;
      this.teamLastTitle = null;
      this.teamStadium = null;
      this.teamLoc = null;
      this.teamCapacity = null;
      this.teamHeadCoach = null;

      this.leagueKeyword = '';
      this.leagueInfo = null;
      this.leagueName = null;
      this.leagueFounded = null;
      this.leagueTotalTitles = null;
      this.leagueLastTitle = null;
      this.leagueRunnerUp = null;
      this.leagueResults = null;

      this.divisionKeyword = '';
      this.divisionInfo = null;
      this.divisionName = null;
      this.divisionFounded = null;
      this.divisionTotalTitles = null;
      this.divisionLastTitle = null;

      this.is_spotlight = false;
      this.spotlightSelection = 'select spotlight';
      this.spotlightPlayerSlug = '';
      this.chartShow = true;
      this.imageType = 'gallery';
      this.widgetStatType = 'Select Content Block Stats';
      this.widgetCardType = 'Select Block Card Type';
      this.gamePropertyVal = 'Select Sort';
      this.gameTypeVal = 'Select Stat Type';
      this.leagueVal = 'Select League';
      this.divisionVal = 'Select Division';
      this.conferenceVal = 'Select Conference';
      this.teamVal = 'Select Team';
      this.playerVal1 = 'Select Player 1';
      this.playerVal2 = 'Select Player 2';
      this.gameSortVal = 'desc';
      this.leagueStatVal = null;
      this.standings = {
        data: [],
      };
      this.upcomings = {
        data: [],
      };
      this.sportsKey = null;
      this.leagueGameType = 'Select League Game Type';
      this.widgetUpcomingType = 'Select Content Block Upcoming';
      this.widgetStandingType = 'Select Content Block Standing';
      this.OBJID = null;
      this.size = 10;
      this.lmt = 15;
      this.bll.reset_imagn_data();
      this.bll.reset_search();
    } else if (par === 'discard') {
      this.old_description_value = '';
      this.validation_obj = {
        is_extract: true,
        wiki_extract: null,
        wiki_url: null,
        wiki_source_title: null,
        wiki_sections: [],
        wiki_keyword: null,
        isWidgetSelectOpen: false,
        keywordToSearchTeam: '',
        twitter_teams_arr: [],
        twitter_search_box: true,
        wiki_title: null,
        wiki_thumbnail: null,
        wiki_dropdown_data: [],
        wiki_dropdown_show: false,
        color_column: '',
        name: '',
        Widget_author: '',
        pubname: '',
        number: null,
        url: '',
        listView: false,
        image_keyword: '',
        _meta_description: null,
        _keywords: null,
        description: '',
        selectedImg: null,
        searchLinkVar2: '',
        searchLinkVar: '',
        _image_url: null,
        images: [],
        files: [],
        lat: 0,
        long: 0,
        zoom: 18,
        editItemActive: false,
        selectedText: '',
        item_obj: null,
        list: [],
        dateObj: null,
        playerStatsInfo: {
          data: [],
          page: 1,
          size: 10,
          totalRecords: 0,
        },
        playerStatsObj: {
          data: [],
          page: 1,
          size: 10,
          totalRecords: 0,
        },
        playerKeyword: '',
        playerKeyword1: null,
        playerKeyword2: null,
        playerKey1: false,
        playerKey2: false,
        playerName: null,
        playerNumber: null,
        playerTeam: null,
        playerLeague: null,
        playerHeight: null,
        playerWeight: null,
        playerPosition: null,
        playerAge: null,
        playerBirth: null,
        teamKeyword: '',
        teamInfo: null,
        teamName: null,
        teamFounded: null,
        teamTotalTitles: null,
        teamLastTitle: null,
        teamStadium: null,
        teamLoc: null,
        teamCapacity: null,
        teamHeadCoach: null,
        leagueKeyword: '',
        leagueInfo: null,
        leagueName: null,
        leagueFounded: null,
        leagueTotalTitles: null,
        leagueLastTitle: null,
        leagueRunnerUp: null,
        leagueResults: null,
        divisionKeyword: '',
        divisionInfo: null,
        divisionName: null,
        divisionFounded: null,
        divisionTotalTitles: null,
        divisionLastTitle: null,
        is_spotlight: false,
        spotlightPlayerSlug: '',
        gamePropertyVal: 'Select Sort',
        gameTypeVal: 'Select Stat Type',
        leagueVal: 'Select League',
        divisionVal: 'Select Division',
        conferenceVal: 'Select Conference',
        teamVal: 'Select Team',
        playerVal1: 'Select Player 1',
        playerVal2: 'Select Player 2',
        gameSortVal: 'desc',
        leagueStatVal: null,
        standings: {
          data: [],
        },
        upcomings: {
          data: [],
        },
        sportsKey: null,
        leagueGameType: 'Select League Game Type',
        widgetUpcomingType: 'Select Content Block Upcoming',
        widgetStandingType: 'Select Content Block Standing',
      };

      this.is_extract = true;
      this.wiki_extract = null;
      this.wiki_url = null;
      this.wiki_source_title = null;
      this.selectedId = null;
      this.wiki_title = null;
      this.isWidgetSelectOpen = false;
      this.wiki_thumbnail = null;
      this.wiki_sections = [];
      this.wiki_keyword = null;
      this.selected_sections = [];
      this.wiki_loading = false;
      this.wiki_dropdown_data = [];
      this.wiki_dropdown_show = false;

      this.click_block = false;
      this.twitter_username = '';

      this.keywordToSearchTeam = '';
      this.twitter_teams_arr = [];
      this.twitter_search_box = true;
      this.twitterSourceTab = 'top';

      this.numOfColumns = 5;
      this.selectedColumns = [];
      this.numOfRows = 4;
      this.columnColor = 'blue';
      this.dataGridtype = 'Blank Table';
      this.selectedColumns = ['column1', 'column2', 'column3', 'column4'];
      this.columnsArr = ['column1', 'column2', 'column3', 'column4'];
      this.showGrid = false;
      this.dataGridArr = [
        {
          id: 1009,
          column1: '',
          column2: '',
          column3: '',
          column4: '',
        },
        {
          id: 1008,
          column1: '',
          column2: '',
          column3: '',
          column4: '',
        },
        {
          id: 1007,
          column1: '',
          column2: '',
          column3: '',
          column4: '',
        },
        {
          id: 1006,
          column1: '',
          column2: '',
          column3: '',
          column4: '',
        },
      ];
      this.color_column = '';

      if (this.draggingNewBlock === true) {
        const idToRemove = this.dragObject.id;

        const indexToRemove = this.widgetArr.findIndex(
          (item) => item.id === idToRemove
        );

        if (indexToRemove !== -1) {
          this.widgetArr.splice(indexToRemove, 1);
        }

        let tempArr: any = [];
        let doubleArr: any = [];
        let singleArr: any = [];
        this.widgetArr.map((wig, i) => {
          if (wig.status !== 'draft') {
            if (wig.type === 'social_comment') {
              if (wig.source === 'twitter') {
                if (this.widgetArr[i + 1] !== undefined) {
                  if (
                    this.widgetArr[i].source === this.widgetArr[i + 1].source
                  ) {
                    doubleArr.push(this.widgetArr[i]);
                    doubleArr.push(this.widgetArr[i + 1]);
                  } else {
                    if (doubleArr.length > 0) {
                      for (let i = 0; i < doubleArr.length; i++) {
                        const duplicates = doubleArr.filter(
                          (obj) => obj.id === doubleArr[i].id
                        );
                        if (duplicates.length > 1) {
                          doubleArr.splice(i + 1, 1); // remove the second object
                          i--; // decrement i to account for the removed object
                        }
                      }
                      tempArr.push({
                        arr: doubleArr,
                        type: this.widgetArr[i].type,
                        source: this.widgetArr[i].source,
                      });

                      doubleArr = [];
                    } else {
                      singleArr.push(this.widgetArr[i]);
                      tempArr.push({
                        arr: singleArr,
                        type: this.widgetArr[i].type,
                        source: this.widgetArr[i].source,
                      });
                      singleArr = [];
                    }
                  }
                } else {
                  if (doubleArr.length > 0) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }
                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });

                    doubleArr = [];
                  } else {
                    singleArr.push(this.widgetArr[i]);
                    tempArr.push({
                      arr: singleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    singleArr = [];
                  }
                }
              } else if (wig.source === 'reddit') {
                if (this.widgetArr[i + 1] !== undefined) {
                  if (
                    this.widgetArr[i].source === this.widgetArr[i + 1].source
                  ) {
                    doubleArr.push(this.widgetArr[i]);
                    doubleArr.push(this.widgetArr[i + 1]);
                  } else {
                    if (doubleArr.length > 0) {
                      for (let i = 0; i < doubleArr.length; i++) {
                        const duplicates = doubleArr.filter(
                          (obj) => obj.id === doubleArr[i].id
                        );
                        if (duplicates.length > 1) {
                          doubleArr.splice(i + 1, 1); // remove the second object
                          i--; // decrement i to account for the removed object
                        }
                      }
                      tempArr.push({
                        arr: doubleArr,
                        type: this.widgetArr[i].type,
                        source: this.widgetArr[i].source,
                      });
                      doubleArr = [];
                    } else {
                      singleArr.push(this.widgetArr[i]);
                      tempArr.push({
                        arr: singleArr,
                        type: this.widgetArr[i].type,
                        source: this.widgetArr[i].source,
                      });
                      singleArr = [];
                    }
                  }
                } else {
                  if (doubleArr.length > 0) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }
                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });

                    doubleArr = [];
                  } else {
                    singleArr.push(this.widgetArr[i]);
                    tempArr.push({
                      arr: singleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    singleArr = [];
                  }
                }
              }
            } else {
              if (doubleArr.length > 1) {
                for (let i = 0; i < doubleArr.length; i++) {
                  const duplicates = doubleArr.filter(
                    (obj) => obj.id === doubleArr[i].id
                  );
                  if (duplicates.length > 1) {
                    doubleArr.splice(i + 1, 1); // remove the second object
                    i--; // decrement i to account for the removed object
                  }
                }
                doubleArr = doubleArr.filter((obj) => obj.status !== 'draft');

                tempArr.push({
                  arr: doubleArr,
                  type: this.widgetArr[i - 1].type,
                  source: this.widgetArr[i - 1].source,
                });

                doubleArr = [];
              }
              tempArr.push(wig);
            }
          }
        });

        this.Immersive_view_widgetArr = tempArr;
      }
      this.selectedImg = null;
      this.listView = false;
      this.playerStatsInfo = {
        data: [],
        page: 1,
        size: 10,
        totalRecords: 0,
      };

      this.editItemActive = false;
      this.selectedText = '';
      this.listTypeSelected = 'ps-lt';

      this.playerStatsObj = {
        data: [],
        page: 1,
        size: 10,
        totalRecords: 0,
      };

      if (this.immersive_view === true) {
        this.edit_IV = true;
        this.widgetType = '';
        this.widgetType = '';
        this.blockkeyword = null;
        this.blocktypeSelected = 'new';
        this.showAddBlock = false;
      }

      if (this.isEditWidget === false) {
        if (this.images.length > 0) {
          this.images.map((obj) => {
            if (obj.previewUrl.includes('admindocuments')) {
              let path = obj.previewUrl.substring(
                obj.previewUrl.indexOf('admindocuments/') +
                  'admindocuments/'.length
              );

              let object = {
                name: path,
                account: 'sportswritersmedia',
                container: 'admindocuments',
              };
              this.bll.delete_image_permanent(object, this.auth.token);
            } else if (obj.previewUrl.includes('contentimages')) {
              let path = obj.previewUrl.substring(
                obj.previewUrl.indexOf('contentimages/') +
                  'contentimages/'.length
              );
              let object = {
                name: path,
                account: 'sportswritersmedia',
                container: 'contentimages',
              };
              this.bll.delete_image_permanent(object, this.auth.token);
            }
          });
          this.images = [];
        }
      } else if (this.isEditWidget === true) {
        if (this.images.length > 0) {
          let newObjs = this.images.filter(
            (obj2) =>
              !this.localimages.some(
                (obj1) => obj1.previewUrl === obj2.previewUrl
              )
          );
          newObjs.map((image) => {
            if (image.previewUrl.includes('admindocuments')) {
              let path = image.previewUrl.substring(
                image.previewUrl.indexOf('admindocuments/') +
                  'admindocuments/'.length
              );

              let object = {
                name: path,
                account: 'sportswritersmedia',
                container: 'admindocuments',
              };
              this.bll.delete_image_permanent(object, this.auth.token);
            } else if (image.previewUrl.includes('contentimages')) {
              let path = image.previewUrl.substring(
                image.previewUrl.indexOf('contentimages/') +
                  'contentimages/'.length
              );
              let object = {
                name: path,
                account: 'sportswritersmedia',
                container: 'contentimages',
              };
              this.bll.delete_image_permanent(object, this.auth.token);
            }
          });
          this.images = [];
          this.localimages = [];
        }
      }
      this.INX = null;
      this.dateObj = null;
      this.isEditWidget = false;
      this.widgetType = '';
      this.widgetSource = 'other';
      this.numberSource = '';
      this.socialCommentSource = 'Select Social Url Type';
      this.name = '';
      this.Widget_author = '';
      this.pubname = '';
      this.searchLinkVar2 = '';
      this.searchLinkVar = '';
      this.ID = null;
      this.number = null;
      this.url = '';
      this.uploadImage = false;

      this.imageSourceTab = 'imagn';
      this._meta_description = null;
      this._keywords = null;
      this.description = '';
      this.image_keyword = '';
      this._image_url = null;

      this.isConfirmationToEditPerOpen = false;

      this.is_spotlight = false;
      this.spotlightSelection = 'select spotlight';
      this.spotlightPlayerSlug = '';
      this.playerKeyword = '';
      this.playerKeyword1 = null;
      this.playerKeyword2 = null;
      this.playerKey1 = false;
      this.playerKey2 = false;
      this.playerInfo = null;
      this.playerName = null;
      this.playerNumber = null;
      this.playerTeam = null;
      this.playerLeague = null;
      this.playerTeam_color_1 = null;
      this.playerTeam_color_2 = null;
      this.playerHeight = null;
      this.playerWeight = null;
      this.playerPosition = null;
      this.playerAge = null;
      this.playerBirth = null;

      this.teamKeyword = '';
      this.teamInfo = null;
      this.teamName = null;
      this.teamFounded = null;
      this.teamTotalTitles = null;
      this.teamLastTitle = null;
      this.teamStadium = null;
      this.teamLoc = null;
      this.teamCapacity = null;
      this.teamHeadCoach = null;

      this.leagueKeyword = '';
      this.leagueInfo = null;
      this.leagueName = null;
      this.leagueFounded = null;
      this.leagueTotalTitles = null;
      this.leagueLastTitle = null;
      this.leagueRunnerUp = null;
      this.leagueResults = null;

      this.divisionKeyword = '';
      this.divisionInfo = null;
      this.divisionName = null;
      this.divisionFounded = null;
      this.divisionTotalTitles = null;
      this.divisionLastTitle = null;

      this.chartShow = false;
      this.imageType = 'gallery';
      this.widgetStatType = null;
      this.widgetStandingType = null;
      this.standings = {
        data: [],
      };
      this.upcomings = {
        data: [],
      };

      this.googleMapService.lat = 0;
      this.googleMapService.long = 0;
      this.googleMapService.zoom = 18;
      this.googleMapService.mapTypeId = google.maps.MapTypeId.ROADMAP;
      this.bulletTypeSelected = '10571';
      this.selectedText = '';
      this.editItemActive = false;
      this.item_obj = null;
      this.list = [];
      this.listTypeSelected = 'ps-lt';

      this.sportsKey = null;
      this.leagueGameType = 'Select League Game Type';
      this.widgetUpcomingType = 'Select Content Block Upcoming';
      this.widgetStandingType = 'Select Content Block Standing';
      this.widgetCardType = null;
      this.gamePropertyVal = null;
      this.gameTypeVal = null;
      this.leagueVal = null;
      this.divisionVal = null;
      this.conferenceVal = null;
      this.teamVal = null;
      this.playerVal1 = null;
      this.playerVal2 = null;
      this.leagueStatVal = null;
      this.size = 10;
      this.OBJID = null;
      this.showWidgetMenu = false;
      this.lmt = 15;
      this.bll.reset_imagn_data();
      this.bll.reset_search();
    }
  }

  addSocialComment(block, index, par) {
    this.SecondINDEX = index;
    if (par === -1) {
      this.widgetArr.map((widget, i) => {
        if (block.id === widget.id) {
          this.INDEX = i - 1;
          this.INX = i - 1;
          this.ImmersiveSocialCommentObj = block;
        }
      });
    } else {
      this.widgetArr.map((widget, i) => {
        if (block.id === widget.id) {
          this.INDEX = i + 1;
          this.INX = i + 1;
          this.ImmersiveSocialCommentObj = block;
        }
      });
    }

    this.draggingNewBlock = true;
    let type = 'empty';
    let obj: any = {
      id: Date.now().toString(32) + Math.random().toString(16),
      main_content: {},
      author: null,
      url: '',
      title: '',
      display_text: '',
      is_title: true,
      is_text: false,
      image_url: null,
      type: type,
      source: '',
      status: 'published',
      created_at: '',
      display_order: this.INDEX,
      published_date: null,
      created_by: '',
      keywords: null,
      meta_description: null,
      content_id: this.article_id,
    };
    this.dragObject = obj;
    this.selectedId = obj.id;

    this.widgetArr.push(obj);

    this.blocktypeSelected = 'new';

    this.selectedId = obj.id;
    this.new_obj = obj;
    this.openWidgetMenu('add');
    //  this.editWidget(obj, obj.display_order);

    //Add new block variables
    //  this.selectedId = null;

    this.is_extract = true;
    this.wiki_extract = null;
    this.wiki_url = null;
    this.wiki_source_title = null;
    this.wiki_sections = [];
    this.wiki_keyword = null;

    this.widgetSource = 'other';
    this.numberSource = '';
    this.socialCommentSource = '';
    this.isWidgetSelectOpen = false;

    this.keywordToSearchTeam = '';
    this.twitter_teams_arr = [];
    this.twitter_search_box = true;
    this.twitterSourceTab = 'top';

    this.wiki_title = null;
    this.wiki_thumbnail = null;
    this.wiki_sections = [];
    this.selected_sections = [];
    this.wiki_loading = false;
    this.wiki_dropdown_data = [];
    this.wiki_dropdown_show = false;
    this.wiki_extract = null;
    this.wiki_url = null;
    this.wiki_source_title = null;
    this.numOfColumns = 5;
    this.selectedColumns = [];
    this.numOfRows = 4;
    this.columnColor = 'blue';
    this.dataGridtype = 'Blank Table';
    this.selectedColumns = ['column1', 'column2', 'column3', 'column4'];
    this.columnsArr = ['column1', 'column2', 'column3', 'column4'];
    this.showGrid = false;
    this.dataGridArr = [
      {
        id: 1009,
        column1: '',
        column2: '',
        column3: '',
        column4: '',
      },
      {
        id: 1008,
        column1: '',
        column2: '',
        column3: '',
        column4: '',
      },
      {
        id: 1007,
        column1: '',
        column2: '',
        column3: '',
        column4: '',
      },
      {
        id: 1006,
        column1: '',
        column2: '',
        column3: '',
        column4: '',
      },
    ];
    this.color_column = '';

    this.name = '';
    this.Widget_author = '';
    this.pubname = '';

    this.number = null;
    this.url = null;
    this.listView = false;
    this.uploadImage = false;
    this.image_keyword = null;
    this._meta_description = null;
    this._keywords = null;
    this.description = null;
    this.selectedImg = null;
    this.image_keyword = '';
    this.imageSourceTab = 'imagn';
    this.searchLinkVar2 = '';
    this.searchLinkVar = '';
    this._image_url = null;
    this.images = [];
    this.files = [];
    this.googleMapService.lat = 0;
    this.googleMapService.long = 0;
    this.googleMapService.zoom = 18;
    this.googleMapService.mapTypeId = google.maps.MapTypeId.ROADMAP;
    this.editItemActive = false;
    this.selectedText = '';
    this.bulletTypeSelected = '10571';
    this.selectedText = '';
    this.editItemActive = false;
    this.item_obj = null;
    this.list = [];
    this.listTypeSelected = 'ps-lt';

    this.blocktypeSelected = 'new';

    this.widgetCardType = null;
    this.dateObj = null;

    this.playerStatsInfo = {
      data: [],
      page: 1,
      size: 10,
      totalRecords: 0,
    };

    this.playerStatsObj = {
      data: [],
      page: 1,
      size: 10,
      totalRecords: 0,
    };
    this.playerKeyword = '';
    this.playerKeyword1 = null;
    this.playerKeyword2 = null;
    this.playerKey1 = false;
    this.playerKey2 = false;
    this.playerInfo = null;
    this.playerName = null;
    this.playerNumber = null;
    this.playerTeam = null;
    this.playerHeight = null;
    this.playerWeight = null;
    this.playerPosition = null;
    this.playerAge = null;
    this.playerBirth = null;

    this.teamKeyword = '';
    this.teamInfo = null;
    this.teamName = null;
    this.teamFounded = null;
    this.teamTotalTitles = null;
    this.teamLastTitle = null;
    this.teamStadium = null;
    this.teamLoc = null;
    this.teamCapacity = null;
    this.teamHeadCoach = null;

    this.leagueKeyword = '';
    this.leagueInfo = null;
    this.leagueName = null;
    this.leagueFounded = null;
    this.leagueTotalTitles = null;
    this.leagueLastTitle = null;
    this.leagueRunnerUp = null;
    this.leagueResults = null;

    this.divisionKeyword = '';
    this.divisionInfo = null;
    this.divisionName = null;
    this.divisionFounded = null;
    this.divisionTotalTitles = null;
    this.divisionLastTitle = null;

    this.is_spotlight = false;
    this.spotlightSelection = 'select spotlight';
    this.spotlightPlayerSlug = '';
    this.chartShow = true;
    this.imageType = 'gallery';
    this.widgetStatType = 'Select Content Block Stats';
    this.widgetCardType = 'Select Block Card Type';
    this.gamePropertyVal = 'Select Sort';
    this.gameTypeVal = 'Select Stat Type';
    this.leagueVal = 'Select League';
    this.divisionVal = 'Select Division';
    this.conferenceVal = 'Select Conference';
    this.teamVal = 'Select Team';
    this.playerVal1 = 'Select Player 1';
    this.playerVal2 = 'Select Player 2';
    this.gameSortVal = 'desc';
    this.leagueStatVal = null;
    this.standings = [];
    this.upcomings = [];
    this.sportsKey = null;
    this.leagueGameType = 'Select League Game Type';
    this.widgetUpcomingType = 'Select Content Block Upcoming';
    this.widgetStandingType = 'Select Content Block Standing';
    this.OBJID = null;
    this.size = 10;
    this.lmt = 15;
    this.click_block = true;
    this.bll.reset_imagn_data();
    this.bll.reset_search();
  }

  addSourceToTable() {
    let a = {
      date: '',
      image_url: '',
      source: '',
      title: '',
      url: '',
      words: '',
    };
    this.sourceArr.push(a);
  }

  removeSource(obj) {
    this.sourceArr.map((T, index) => {
      if (T === obj) {
        this.sourceArr.splice(index, 1);
      }
    });
  }

  openWidgetType(w?: string) {
    this.isWidgetSourceSelectOpen = false;
    if (w) {
      this.widgetType = w;
      this.wiki_title = null;
      this.wiki_thumbnail = null;
      this.wiki_sections = [];
      this.wiki_keyword = null;
      this.selected_sections = [];
      this.wiki_loading = false;
      this.description = '';

      this.numOfColumns = 5;
      this.selectedColumns = [];
      this.numOfRows = 4;
      this.columnColor = 'blue';
      this.dataGridtype = 'Blank Table';
      this.selectedColumns = ['column1', 'column2', 'column3', 'column4'];
      this.columnsArr = ['column1', 'column2', 'column3', 'column4'];
      this.showGrid = false;
      this.dataGridArr = [
        {
          id: 1009,
          column1: '',
          column2: '',
          column3: '',
          column4: '',
        },
        {
          id: 1008,
          column1: '',
          column2: '',
          column3: '',
          column4: '',
        },
        {
          id: 1007,
          column1: '',
          column2: '',
          column3: '',
          column4: '',
        },
        {
          id: 1006,
          column1: '',
          column2: '',
          column3: '',
          column4: '',
        },
      ];
    }
    this.isWidgetSelectOpen = !this.isWidgetSelectOpen;
    if (this.widgetType === 'chart') {
      this.createChart();
    }
  }

  openWidgetSource(w?: string) {
    this.isWidgetSelectOpen = false;
    if (w) {
      this.widgetSource = w;
    }
    this.isWidgetSourceSelectOpen = !this.isWidgetSourceSelectOpen;
  }

  openleagueSource(w?: string) {
    this.isWidgetSelectOpen = false;
    if (w) {
      this.widgetSource = w;
    }
    this.isWidgetSourceSelectOpen = !this.isWidgetSourceSelectOpen;
  }

  openNumberSource(w?: string) {
    this.isWidgetSourceSelectOpen = false;
    if (w) {
      this.numberSource = w;
    }
    this.isNumberSelectOpen = !this.isNumberSelectOpen;
  }

  openSocialCommentSource(w?: string) {
    this.isWidgetSourceSelectOpen = false;
    if (w) {
      this.socialCommentSource = w;
    }
    this.isSocialCommentSelectOpen = !this.isSocialCommentSelectOpen;
  }

  embed_youtube_link(url: string) {
    let videoID;
    if (url.includes('/watch?v=')) {
      videoID = url?.split('/watch?v=').pop();
    } else {
      videoID = url?.split('/').pop();
    }
    let iframe = document.querySelectorAll('iframe');
    iframe.forEach((i) => {
      let a = i.getAttribute('src');
      if (a !== undefined && a !== null) {
        i.setAttribute('referrerpolicy', 'strict-origin-when-cross-origin');
        i.setAttribute('class', 'w-full rounded-lg');
      }
    });

    return videoID;
    //  return this.safeSrc;
  }

  changeUrl(event) {
    this.url = '';
    setTimeout(() => {
      this.url = event;
      this.youtubeObj = null;
    }, 500);
  }

  setReferrerPolicy() {
    let iframe = document.querySelectorAll('iframe');
    iframe.forEach((i) => {
      let a = i.getAttribute('src');
      if (a !== undefined && a !== null) {
        i.setAttribute('referrerpolicy', 'strict-origin-when-cross-origin');
      }
    });
  }
  //   getTrustedYouTubeUrl(linkedVideo: any) {
  //     console.log("here", linkedVideo)
  //     return linkedVideo?.url ? this.sanitizer.bypassSecurityTrustResourceUrl(linkedVideo.url) : null;
  // };

  getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }

  toggleWidgets(bool: boolean) {
    if (this.accordiansExpanded.length > 0) {
      this.accordiansExpanded = [];
    } else {
      const tempIndex: string[] = [];
      this.widgetArr.forEach((val, i) => {
        tempIndex.push(val.id.toString());
      });
      this.accordiansExpanded = tempIndex;
    }

    this.isWidgetsExpanded = bool;
  }
  accord(event) {
    if (event.detail.value.length > 0) {
      this.accordiansExpanded = event.detail.value;
    } else {
      this.accordiansExpanded = [];
    }
  }

  async showToast(msg) {
    const t = await this.toast.create({
      header: 'Error',
      message: msg,
      position: 'bottom',
      duration: 5000,
      color: 'danger',
    });
    await t.present();
  }

  customLoader() {
    this.loadCtrl
      .create({
        message: 'Uploading images, please wait…',
        cssClass: 'loader-css-class',
        backdropDismiss: false,
      })
      .then((res) => {
        res.present();
      });
  }

  async showSuccess(msg) {
    const t = await this.toast.create({
      message: msg,
      position: 'top',
      duration: 1000,
      color: 'light',
    });
    await t.present();
  }

  async createWidget() {
    let characterCount = this.countVisibleCharacters(this.description);
    if (this.widgetType === null) {
      await this.showToast(
        'Block Type is missing, Please  Add the Block Type !'
      );
      return;
    }
    if (
      this.widgetType === 'twitter' &&
      (this.url === null || this.url === '' || this.url === undefined)
    ) {
      await this.showToast(
        'Twitter URL is missing, Please  Add the Twitter Url !'
      );
      return;
    }

    if (
      this.widgetType === 'video' &&
      (this.url === null || this.url === '' || this.url === undefined)
    ) {
      await this.showToast(
        'Youtube Link is missing, Please Add the Video Link !'
      );
      return;
    }

    if (this.widgetType === 'quote' && !this.description) {
      await this.showToast('Quote is missing, Please fill it out !');
      return;
    }

    if (this.widgetType === 'quote' && !this.description) {
      await this.showToast('Quote is missing, Please fill it out !');
      return;
    }
    if (this.widgetType === 'quote') {
      let urlValidate = this.urlValidation(this.url);

      if (urlValidate) {
        await this.showToast(
          'Quote Source URl is incorrect,Please enter a correct url !'
        );
        return;
      } else {
      }
    }
    if (this.widgetType === 'image' && this.imageType === 'Select Image Type') {
      await this.showToast('Please Select The Image Type!');
      return;
    }

    if (this.widgetType === 'image' && this.images.length < 1) {
      await this.showToast('Please Upload or Search for an Image to add!');
      return;
    }

    if (
      this.widgetType === 'wikipedia' &&
      (this.widgetSource === undefined || this.widgetSource === null)
    ) {
      await this.showToast('Please Select Widget Source!');
      return;
    }

    if (this.widgetType === 'wikipedia' && !this.description) {
      await this.showToast('Please fill out the display text!');
      return;
    }

    if (this.widgetType === 'number' && this.number === null) {
      await this.showToast('Please Enter a Number!');
      return;
    }

    if (
      this.widgetType === 'social_comment' &&
      (!this.socialCommentSource ||
        this.socialCommentSource === 'Select Social Url Type')
    ) {
      await this.showToast('Please Select a source for social comment!');
      return;
    }

    if (
      this.widgetType === 'social_comment' &&
      (this.url === null || this.url === '' || this.url === undefined)
    ) {
      await this.showToast('Link is missing, Please Add the Link !');
      return;
    }

    if (
      this.widgetType === 'social_comment' &&
      (this.url === null || this.url === '' || this.url === undefined)
    ) {
      await this.showToast('Link is missing, Please Add the Link !');
      return;
    }

    if (this.widgetType === 'social_comment' && !this.description) {
      await this.showToast('Please write the comment !');
      return;
    }

    if (this.widgetType === 'social_comment' && !this.description) {
      await this.showToast('Please write the comment !');
      return;
    }

    if (this.widgetType === 'text' && !this.description) {
      await this.showToast('Please fill the display Text field !');
      return;
    }

    if (
      this.widgetType === 'info' &&
      (this.widgetCardType === null ||
        this.widgetCardType === 'Select Block Card Type')
    ) {
      await this.showToast('Please Select Block Card Type !');
      return;
    }

    if (
      this.widgetCardType === 'team_card' &&
      this.widgetType === 'info' &&
      (this.teamInfo === null || this.teamInfo === undefined)
    ) {
      await this.showToast('No Team Data available!');
      return;
    }
    if (
      this.widgetType === 'player_card' &&
      (this.playerInfo === null || this.playerInfo === undefined)
    ) {
      await this.showToast('No Player Data available!');
      return;
    }
    if (
      this.widgetCardType === 'division_card' &&
      this.widgetType === 'info' &&
      (this.divisionInfo === null || this.divisionInfo === undefined)
    ) {
      await this.showToast('No Division Data available!');
      return;
    }
    if (
      this.widgetCardType === 'conference_card' &&
      this.widgetType === 'info' &&
      (this.conferenceInfo === null || this.conferenceInfo === undefined)
    ) {
      await this.showToast('No Conference Data available!');
      return;
    }
    if (
      this.widgetCardType === 'league_card' &&
      this.widgetType === 'info' &&
      (this.leagueInfo === null || this.leagueInfo === undefined)
    ) {
      await this.showToast('No League Data available!');
      return;
    }

    if (
      this.widgetType === 'stats' &&
      (this.widgetStatType === null ||
        this.widgetStatType === 'Select Content Block Stats')
    ) {
      await this.showToast('Please Select Content Block Stats !');
      return;
    }

    if (this.widgetType === 'stats') {
      this.playerStatsObj.data.map((obj) => {
        this.playerStatsInfo.data.push(obj);
      });
      this.playerStatsInfo.totalRecords = this.playerStatsInfo.data.length;
    }

    if (
      this.widgetType === 'stats' &&
      (this.playerStatsInfo === null ||
        this.playerStatsInfo === undefined ||
        this.playerStatsInfo.totalRecords === 0)
    ) {
      await this.showToast('No Data available!');
      return;
    }

    if (
      this.widgetType === 'standings' &&
      (this.widgetStandingType === null ||
        this.widgetStandingType === 'Select Content Block Standing')
    ) {
      await this.showToast('Please Select Content Block Standing !');
      return;
    }

    if (this.widgetType === 'standings' && this.standings.data.length < 1) {
      await this.showToast('No Data available!');
      return;
    }

    if (
      this.widgetType === 'stats' &&
      (this.leagueVal === null || this.leagueVal === 'Select League')
    ) {
      await this.showToast('Please Select League !');
      return;
    }

    if (
      this.widgetType === 'stats' &&
      (this.gameTypeVal === null || this.gameTypeVal === 'Select Stat Type')
    ) {
      await this.showToast('Please Select Stat Type !');
      return;
    }

    if (
      this.widgetType === 'stats' &&
      this.widgetStatType !== 'player_stats' &&
      (this.gamePropertyVal === null || this.gamePropertyVal === 'Select Sort')
    ) {
      await this.showToast('Please Select Sort !');
      return;
    }

    if (
      this.widgetType === 'stats' &&
      this.widgetStatType === 'division_stats' &&
      (this.divisionVal === null || this.divisionVal === 'Select Division')
    ) {
      await this.showToast('Please Select Division !');
      return;
    }

    if (
      this.widgetType === 'stats' &&
      this.widgetStatType === 'team_stats' &&
      (this.teamVal === null || this.teamVal === 'Select Team')
    ) {
      await this.showToast('Please Select Team !');
      return;
    }
    if (
      this.widgetType === 'stats' &&
      this.widgetStatType === 'conference_stats' &&
      (this.conferenceVal === null ||
        this.conferenceVal === 'Select Conference')
    ) {
      await this.showToast('Please Select Conference !');
      return;
    }

    if (
      this.widgetType === 'upcoming_games' &&
      (this.widgetUpcomingType === null ||
        this.widgetUpcomingType === 'Select Content Block Upcoming')
    ) {
      await this.showToast('Please Select Content Block Upcoming !');
      return;
    }

    if (
      this.widgetType === 'upcoming_games' &&
      (this.leagueGameType === null ||
        this.leagueGameType === '' ||
        this.leagueGameType === undefined ||
        this.leagueGameType === 'Select League Game Type')
    ) {
      await this.showToast('Please Select League Game Type !');
      return;
    }

    if (
      (this.widgetType === 'upcoming_games' ||
        this.widgetType === 'standings') &&
      (this.leagueVal === null ||
        this.leagueVal === '' ||
        this.leagueVal === undefined ||
        this.leagueVal === 'Select League')
    ) {
      await this.showToast('Please Select League !');
      return;
    }

    if (
      ((this.widgetType === 'upcoming_games' &&
        this.widgetUpcomingType === 'team_upcoming') ||
        (this.widgetType === 'standings' &&
          this.widgetStandingType === 'team_standings')) &&
      (this.teamVal === null ||
        this.teamVal === '' ||
        this.teamVal === undefined ||
        this.teamVal === 'Select Team')
    ) {
      await this.showToast('Please Select Team !');
      return;
    }

    if (
      ((this.widgetType === 'upcoming_games' &&
        this.widgetUpcomingType === 'conference_upcoming') ||
        (this.widgetType === 'standings' &&
          this.widgetStandingType === 'conference_standings')) &&
      (this.conferenceVal === null ||
        this.conferenceVal === '' ||
        this.conferenceVal === undefined ||
        this.conferenceVal === 'Select Conference')
    ) {
      await this.showToast('Please Select Conference !');
      return;
    }

    if (
      ((this.widgetType === 'upcoming_games' &&
        (this.widgetUpcomingType === 'division_upcoming' ||
          this.widgetUpcomingType === 'conference_upcoming')) ||
        (this.widgetType === 'standings' &&
          this.widgetStandingType === 'division_standings')) &&
      (this.divisionVal === null ||
        this.divisionVal === '' ||
        this.divisionVal === undefined ||
        this.divisionVal === 'Select Division')
    ) {
      await this.showToast('Please Select Division !');
      return;
    }

    if (
      this.is_spotlight === true &&
      (this.spotlightPlayerSlug === null ||
        this.spotlightPlayerSlug === undefined ||
        this.spotlightPlayerSlug === '')
    ) {
      await this.showToast('Please Select a player for spot light !');
      return;
    }

    if (this.widgetType === 'list' && this.list.length === 0) {
      await this.showToast(
        'No item added in list, Please Select a list type and add items to list!'
      );
      return;
    }

    if (
      this.widgetType === 'map' &&
      this.googleMapService.lat === 0 &&
      this.googleMapService.long === 0
    ) {
      await this.showToast(
        'Please either enter latitude and longitude for a place or search for a place'
      );
      return;
    }

    if (
      this.widgetType === 'chart' &&
      (this.graphValues.length === 0 || this.graphNames.length === 0)
    ) {
      await this.showToast(
        'Unable to save such record cause we have put an restriction that record should be there before saving the record'
      );
      return;
    }

    if (this.widgetType === 'chart') {
      let issueFound = false; // A flag to track whether an issue has been encountered

      for (const item of this.graphValues) {
        if (item === undefined || isNaN(item)) {
          issueFound = true;
          break; // Exit the loop when an issue is encountered
        }
      }

      if (issueFound) {
        await this.showToast(
          'Unable to save such record cause we have put an restriction that record should be there before saving the record'
        );
        return;
      }
    }

    if (this.widgetType === 'datagrid') {
      const keysToFind = [
        'column1',
        'column2',
        'column3',
        'column4',
        'empty column',
      ];

      if (
        keysToFind.some((key) => this.dataGridArr.some((item) => key in item))
      ) {
        await this.showToast('Empty Column ! Please Fill it up');
        return;
      }
    }

    if (
      this.widgetType === 'player_card' &&
      this.description &&
      characterCount > 450
    ) {
      await this.showToast(
        'Description has more than 450 characters ! Please reduce the number of characters used.'
      );
      return;
    }

    if (
      this.widgetType !== 'player_card' &&
      this.description &&
      this.description.length > 800
    ) {
      await this.showToast(
        'Description has more than 800 characters ! Please reduce the number of characters used.'
      );
      return;
    }

    let image_url = null;

    let image_links = null;
    if (this.widgetType === 'wikipedia' && this._image_url) {
      image_url = this._image_url;
      this.images = [];
    } else {
      image_url = null;
    }

    let src = this.widgetSource;
    let type = this.widgetType;

    let main_content;

    let id = Date.now().toString(32) + Math.random().toString(16);
    let title = this.regexRevert(this.name);
    let author = this.regexRevert(this.Widget_author);
    let pubname = this.regexRevert(this.pubname);
    let number = this.number;
    let description = this.description
      ? this.regexRevert(this.description)
      : '';

    let url = this.widgetType !== 'image' ? this.url : null;
    let img_url = image_url;
    let img_links = image_links;

    if (this.widgetType === 'twitter') {
      let main_obj = {
        data: null,
        images: [],
        id: url?.split('/').pop(),
        avatar: null,
        username: null,
        url: url,
        type: null,
        weight: null,
      };
      src = 'twitter';
      main_content = { twitter: main_obj };
    }
    if (this.widgetType === 'video') {
      src = 'youtube';
      let main_obj = {
        sHeight: null,
        videoFound: null,
        thumbnail: null,
        width: null,
        id: null,
        channel: null,
        channelId: null,
        publishTime: null,
        sourceName: 'Youtube',
        type: null,
        url: url,
        quality: null,
      };
      if (this.youtubeObj) {
        main_obj.thumbnail = this.youtubeObj.thumbnail
          ? this.youtubeObj.thumbnail
          : null;
        main_obj.id = this.youtubeObj.id ? this.youtubeObj.id : null;
        main_obj.channel = this.youtubeObj.channel
          ? this.youtubeObj.channel
          : null;
        main_obj.channelId = this.youtubeObj.channelId
          ? this.youtubeObj.channelId
          : null;
        main_obj.publishTime = this.youtubeObj.publishTime
          ? this.youtubeObj.publishTime
          : null;
        main_obj.type = this.youtubeObj.type ? this.youtubeObj.type : null;
      }
      main_content = { video: main_obj };
    }

    if (this.widgetType === 'quote' || this.widgetType === 'text') {
      let main_obj = {
        text: description,
        speaker: author,
        pubName: pubname,
        url: url,
        canonicalSpeaker: null,
      };
      if (this.widgetType === 'quote') {
        main_content = { quote: main_obj };
      } else {
        main_content = { text: main_obj };
      }
    }

    if (this.widgetType === 'image') {
      if (img_url !== null) {
        let main_obj = {
          image: [
            {
              url: img_url,
              description: null,
              source: null,
              position: null,
            },
          ],
          showCaptions: this.showCaptions,
          type: this.imageType,
        };
        main_content = { image: main_obj };
      } else {
        let imgArr = [];
        let imageObj = null;
        let newObjs = this.images.filter(
          (obj2) =>
            !this.localimages.some(
              (obj1) => obj1.previewUrl === obj2.previewUrl
            )
        );

        let uploadedImagesCount = 0;
        this.customLoader();
        if (newObjs.length > 0) {
          newObjs.map((image, index) => {
            let fileUrl = this.getBase64ImageFromUrl(image.previewUrl)
              .then(async (result: any) => {
                let file = await this.storage.dataURItoBlob2(result);

                let metaObj = {
                  uploadedBy: this.user.name,
                  fileType: image.fileExtension,
                  fileSize: image.filesize,
                  height: image.height,
                  width: image.width,
                  altText: '',
                  caption: image.caption
                    ? image.caption.replace(/Mandatory Credit:/g, '©')
                    : '',
                  description: image.headline,
                };

                //  this.imageStr = await this.storage.uploadFile(file);
                if (image.keywords) {
                  imageObj = await this.storage.uploadFile3(
                    file,
                    metaObj,
                    image.keywords[0]
                  );
                } else {
                  imageObj = await this.storage.uploadFile3(file, metaObj);
                }

                //  imageObj = await this.storage.uploadFile(file);

                image.previewUrl = imageObj;
                uploadedImagesCount++;

                if (uploadedImagesCount === newObjs.length) {
                  // this.loadCtrl.dismiss();
                }

                if (index === newObjs.length - 1) {
                  this.images.map((a) => {
                    let obj = {
                      url: a.previewUrl,
                      description: a.caption.replace(/Mandatory Credit:/g, '©'),
                      source: a.credit,
                      position: null,
                    };
                    imgArr.push(obj);
                  });
                  this.loadCtrl.dismiss();

                  let main_obj = {
                    showCaptions: this.showCaptions,
                    image: imgArr,
                    type: this.imageType,
                  };
                  main_content = { image: main_obj };

                  let date = moment().utc(this.created_at).format();
                  let widget = {
                    id: id,
                    title: title,
                    main_content: main_content,
                    author: author,
                    url: url,
                    display_text: description,
                    is_title: this.is_title,
                    is_text: this.is_text,
                    is_extract: this.is_extract,
                    image_url: img_url,
                    type: type,
                    source: src,
                    status: 'published',
                    created_at: this.created_at ? date : null,
                    display_order: this.display_order
                      ? this.display_order
                      : this.widgetArr.length,
                    keywords: this._keywords,
                    meta_description: this._meta_description,
                  };
                  if (this.INX !== null && this.INX !== undefined) {
                    if (this.immersive_view) {
                      // widget.display_order = this.INX;
                      // this.widgetArr.splice(this.INX, 0, widget);
                      // this.Immersive_view_widgetArr.splice(this.INX, 0, widget);
                      // this.Immersive_view_widgetArr.sort(
                      //   (a, b) => a.display_order - b.display_order
                      // );
                      // this.widgetArr.sort((a, b) => a.display_order - b.display_order);

                      this.widgetArr.splice(this.INX, 0, widget);
                      this.Immersive_view_widgetArr.splice(this.INX, 0, widget);
                    } else {
                      //widget.display_order = this.INX + 1;
                      this.widgetArr.splice(this.INX + 1, 0, widget);
                      this.Immersive_view_widgetArr.splice(
                        this.INX + 1,
                        0,
                        widget
                      );
                      //this.widgetArr = this.Immersive_view_widgetArr;
                    }
                  } else {
                    const objectExists = this.widgetArr.some(
                      (item) => JSON.stringify(item) === JSON.stringify(widget)
                    );

                    if (!objectExists) {
                      this.widgetArr.push(widget);
                    }

                    let tempArr: any = [];
                    let doubleArr: any = [];
                    let singleArr: any = [];
                    this.widgetArr.map((wig, i) => {
                      if (wig.status !== 'draft') {
                        if (wig.type === 'social_comment') {
                          if (wig.source === 'twitter') {
                            if (this.widgetArr[i + 1] !== undefined) {
                              if (
                                this.widgetArr[i].source ===
                                this.widgetArr[i + 1].source
                              ) {
                                doubleArr.push(this.widgetArr[i]);
                                doubleArr.push(this.widgetArr[i + 1]);
                              } else {
                                if (doubleArr.length > 0) {
                                  for (let i = 0; i < doubleArr.length; i++) {
                                    const duplicates = doubleArr.filter(
                                      (obj) => obj.id === doubleArr[i].id
                                    );
                                    if (duplicates.length > 1) {
                                      doubleArr.splice(i + 1, 1); // remove the second object
                                      i--; // decrement i to account for the removed object
                                    }
                                  }
                                  tempArr.push({
                                    arr: doubleArr,
                                    type: this.widgetArr[i].type,
                                    source: this.widgetArr[i].source,
                                  });

                                  doubleArr = [];
                                } else {
                                  singleArr.push(this.widgetArr[i]);
                                  tempArr.push({
                                    arr: singleArr,
                                    type: this.widgetArr[i].type,
                                    source: this.widgetArr[i].source,
                                  });
                                  singleArr = [];
                                }
                              }
                            } else {
                              if (doubleArr.length > 0) {
                                for (let i = 0; i < doubleArr.length; i++) {
                                  const duplicates = doubleArr.filter(
                                    (obj) => obj.id === doubleArr[i].id
                                  );
                                  if (duplicates.length > 1) {
                                    doubleArr.splice(i + 1, 1); // remove the second object
                                    i--; // decrement i to account for the removed object
                                  }
                                }
                                tempArr.push({
                                  arr: doubleArr,
                                  type: this.widgetArr[i].type,
                                  source: this.widgetArr[i].source,
                                });

                                doubleArr = [];
                              } else {
                                singleArr.push(this.widgetArr[i]);
                                tempArr.push({
                                  arr: singleArr,
                                  type: this.widgetArr[i].type,
                                  source: this.widgetArr[i].source,
                                });
                                singleArr = [];
                              }
                            }
                          } else if (wig.source === 'reddit') {
                            if (this.widgetArr[i + 1] !== undefined) {
                              if (
                                this.widgetArr[i].source ===
                                this.widgetArr[i + 1].source
                              ) {
                                doubleArr.push(this.widgetArr[i]);
                                doubleArr.push(this.widgetArr[i + 1]);
                              } else {
                                if (doubleArr.length > 0) {
                                  for (let i = 0; i < doubleArr.length; i++) {
                                    const duplicates = doubleArr.filter(
                                      (obj) => obj.id === doubleArr[i].id
                                    );
                                    if (duplicates.length > 1) {
                                      doubleArr.splice(i + 1, 1); // remove the second object
                                      i--; // decrement i to account for the removed object
                                    }
                                  }
                                  tempArr.push({
                                    arr: doubleArr,
                                    type: this.widgetArr[i].type,
                                    source: this.widgetArr[i].source,
                                  });
                                  doubleArr = [];
                                } else {
                                  singleArr.push(this.widgetArr[i]);
                                  tempArr.push({
                                    arr: singleArr,
                                    type: this.widgetArr[i].type,
                                    source: this.widgetArr[i].source,
                                  });
                                  singleArr = [];
                                }
                              }
                            } else {
                              if (doubleArr.length > 0) {
                                for (let i = 0; i < doubleArr.length; i++) {
                                  const duplicates = doubleArr.filter(
                                    (obj) => obj.id === doubleArr[i].id
                                  );
                                  if (duplicates.length > 1) {
                                    doubleArr.splice(i + 1, 1); // remove the second object
                                    i--; // decrement i to account for the removed object
                                  }
                                }
                                tempArr.push({
                                  arr: doubleArr,
                                  type: this.widgetArr[i].type,
                                  source: this.widgetArr[i].source,
                                });

                                doubleArr = [];
                              } else {
                                singleArr.push(this.widgetArr[i]);
                                tempArr.push({
                                  arr: singleArr,
                                  type: this.widgetArr[i].type,
                                  source: this.widgetArr[i].source,
                                });
                                singleArr = [];
                              }
                            }
                          }
                        } else {
                          if (doubleArr.length > 1) {
                            for (let i = 0; i < doubleArr.length; i++) {
                              const duplicates = doubleArr.filter(
                                (obj) => obj.id === doubleArr[i].id
                              );
                              if (duplicates.length > 1) {
                                doubleArr.splice(i + 1, 1); // remove the second object
                                i--; // decrement i to account for the removed object
                              }
                            }
                            doubleArr = doubleArr.filter(
                              (obj) => obj.status !== 'draft'
                            );

                            tempArr.push({
                              arr: doubleArr,
                              type: this.widgetArr[i - 1].type,
                              source: this.widgetArr[i - 1].source,
                            });

                            doubleArr = [];
                          }
                          tempArr.push(wig);
                        }
                      }
                    });

                    this.Immersive_view_widgetArr = tempArr;
                  }

                  if (this.showAddBlock) {
                    this.edit_IV = true;
                    this.showAddBlock = false;
                    this.widgetType = '';
                    this.widgetType = '';
                  }

                  this.isEditWidget = false;
                  this.showWidgetMenu = false;
                  this.widgetType = null;
                  this.OBJID = null;
                  this.checkedList = [];
                  this.INX = null;
                  this.lmt = 15;
                  this.bll.reset_imagn_data();
                }
              })
              .catch((err) => console.error(err));
          });
        } else {
          this.images.map((a) => {
            let obj = {
              url: a.previewUrl,
              description: a.caption
                ? a.caption.replace(/Mandatory Credit:/g, '©')
                : '',
              source: a.credit,
              position: null,
            };
            imgArr.push(obj);
          });

          setTimeout(() => {
            this.loadCtrl.dismiss();
          }, 1000);

          let main_obj = {
            showCaptions: this.showCaptions,
            image: imgArr,
            type: this.imageType,
          };
          main_content = { image: main_obj };

          let date = moment().utc(this.created_at).format();
          let widget = {
            id: id,
            title: title,
            main_content: main_content,
            author: author,
            url: url,
            display_text: description,
            is_title: this.is_title,
            is_text: this.is_text,
            is_extract: this.is_extract,
            image_url: img_url,
            type: type,
            source: src,
            status: 'published',
            created_at: this.created_at ? date : null,
            display_order: this.display_order
              ? this.display_order
              : this.widgetArr.length,
            keywords: this._keywords,
            meta_description: this._meta_description,
          };
          if (this.INX !== null && this.INX !== undefined) {
            if (this.immersive_view) {
              // widget.display_order = this.INX;
              // this.widgetArr.splice(this.INX, 0, widget);
              // this.Immersive_view_widgetArr.splice(this.INX, 0, widget);
              // this.Immersive_view_widgetArr.sort(
              //   (a, b) => a.display_order - b.display_order
              // );
              // this.widgetArr.sort((a, b) => a.display_order - b.display_order);

              this.widgetArr.splice(this.INX, 0, widget);
              this.Immersive_view_widgetArr.splice(this.INX, 0, widget);
            } else {
              //widget.display_order = this.INX + 1;
              this.widgetArr.splice(this.INX + 1, 0, widget);
              this.Immersive_view_widgetArr.splice(this.INX + 1, 0, widget);
              //this.widgetArr = this.Immersive_view_widgetArr;
            }
          } else {
            const objectExists = this.widgetArr.some(
              (item) => JSON.stringify(item) === JSON.stringify(widget)
            );

            if (!objectExists) {
              this.widgetArr.push(widget);
            }

            let tempArr: any = [];
            let doubleArr: any = [];
            let singleArr: any = [];
            this.widgetArr.map((wig, i) => {
              if (wig.status !== 'draft') {
                if (wig.type === 'social_comment') {
                  if (wig.source === 'twitter') {
                    if (this.widgetArr[i + 1] !== undefined) {
                      if (
                        this.widgetArr[i].source ===
                        this.widgetArr[i + 1].source
                      ) {
                        doubleArr.push(this.widgetArr[i]);
                        doubleArr.push(this.widgetArr[i + 1]);
                      } else {
                        if (doubleArr.length > 0) {
                          for (let i = 0; i < doubleArr.length; i++) {
                            const duplicates = doubleArr.filter(
                              (obj) => obj.id === doubleArr[i].id
                            );
                            if (duplicates.length > 1) {
                              doubleArr.splice(i + 1, 1); // remove the second object
                              i--; // decrement i to account for the removed object
                            }
                          }
                          tempArr.push({
                            arr: doubleArr,
                            type: this.widgetArr[i].type,
                            source: this.widgetArr[i].source,
                          });

                          doubleArr = [];
                        } else {
                          singleArr.push(this.widgetArr[i]);
                          tempArr.push({
                            arr: singleArr,
                            type: this.widgetArr[i].type,
                            source: this.widgetArr[i].source,
                          });
                          singleArr = [];
                        }
                      }
                    } else {
                      if (doubleArr.length > 0) {
                        for (let i = 0; i < doubleArr.length; i++) {
                          const duplicates = doubleArr.filter(
                            (obj) => obj.id === doubleArr[i].id
                          );
                          if (duplicates.length > 1) {
                            doubleArr.splice(i + 1, 1); // remove the second object
                            i--; // decrement i to account for the removed object
                          }
                        }
                        tempArr.push({
                          arr: doubleArr,
                          type: this.widgetArr[i].type,
                          source: this.widgetArr[i].source,
                        });

                        doubleArr = [];
                      } else {
                        singleArr.push(this.widgetArr[i]);
                        tempArr.push({
                          arr: singleArr,
                          type: this.widgetArr[i].type,
                          source: this.widgetArr[i].source,
                        });
                        singleArr = [];
                      }
                    }
                  } else if (wig.source === 'reddit') {
                    if (this.widgetArr[i + 1] !== undefined) {
                      if (
                        this.widgetArr[i].source ===
                        this.widgetArr[i + 1].source
                      ) {
                        doubleArr.push(this.widgetArr[i]);
                        doubleArr.push(this.widgetArr[i + 1]);
                      } else {
                        if (doubleArr.length > 0) {
                          for (let i = 0; i < doubleArr.length; i++) {
                            const duplicates = doubleArr.filter(
                              (obj) => obj.id === doubleArr[i].id
                            );
                            if (duplicates.length > 1) {
                              doubleArr.splice(i + 1, 1); // remove the second object
                              i--; // decrement i to account for the removed object
                            }
                          }
                          tempArr.push({
                            arr: doubleArr,
                            type: this.widgetArr[i].type,
                            source: this.widgetArr[i].source,
                          });
                          doubleArr = [];
                        } else {
                          singleArr.push(this.widgetArr[i]);
                          tempArr.push({
                            arr: singleArr,
                            type: this.widgetArr[i].type,
                            source: this.widgetArr[i].source,
                          });
                          singleArr = [];
                        }
                      }
                    } else {
                      if (doubleArr.length > 0) {
                        for (let i = 0; i < doubleArr.length; i++) {
                          const duplicates = doubleArr.filter(
                            (obj) => obj.id === doubleArr[i].id
                          );
                          if (duplicates.length > 1) {
                            doubleArr.splice(i + 1, 1); // remove the second object
                            i--; // decrement i to account for the removed object
                          }
                        }
                        tempArr.push({
                          arr: doubleArr,
                          type: this.widgetArr[i].type,
                          source: this.widgetArr[i].source,
                        });

                        doubleArr = [];
                      } else {
                        singleArr.push(this.widgetArr[i]);
                        tempArr.push({
                          arr: singleArr,
                          type: this.widgetArr[i].type,
                          source: this.widgetArr[i].source,
                        });
                        singleArr = [];
                      }
                    }
                  }
                } else {
                  if (doubleArr.length > 1) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }
                    doubleArr = doubleArr.filter(
                      (obj) => obj.status !== 'draft'
                    );

                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i - 1].type,
                      source: this.widgetArr[i - 1].source,
                    });

                    doubleArr = [];
                  }
                  tempArr.push(wig);
                }
              }
            });

            this.Immersive_view_widgetArr = tempArr;
          }

          if (this.showAddBlock) {
            this.edit_IV = true;
            this.showAddBlock = false;
            this.widgetType = '';
            this.widgetType = '';
          }

          this.isEditWidget = false;
          this.showWidgetMenu = false;
          this.widgetType = null;
          this.OBJID = null;
          this.checkedList = [];
          this.INX = null;
          this.lmt = 15;
          this.bll.reset_imagn_data();
        }
      }
    }

    if (this.widgetType === 'wikipedia') {
      src = this.widgetType;
      type = this.widgetSource;
      if (this.widgetSource === 'organization') {
        let main_obj = {
          weight: null,
          // wiki_extract: this.wiki_extract,
          name: author,
          type: type,
          source: src,
          data: [
            {
              text: title,
              url: url,
              description: description,
              image: {
                type: null,
                url: img_url,
                description: null,
                width: null,
                height: null,
              },
              weight: null,
            },
          ],
        };

        main_content = { organization: main_obj };
      }

      if (this.widgetSource === 'person' || this.widgetSource === 'other') {
        let main_obj = {
          weight: null,
          name: author,
          // wiki_extract: this.wiki_extract,
          type: type,
          source: src,
          data: [
            {
              text: author,
              url: url,
              description: description,
              image: {
                width: null,
                height: null,
                url: img_url,
              },
              weight: null,
            },
          ],
        };

        if (this.widgetSource === 'person') {
          main_content = { person: main_obj };
        } else {
          main_content = { other: main_obj };
        }
      }
    }

    if (this.widgetType === 'number') {
      let main_obj = {
        number: number,
      };
      main_content = { number: main_obj };
    }

    if (this.socialCommentSource === 'twitter') {
      let main_obj = {
        username: author,
        avatar: null,
        name: null,
        comment: description,
        date: this.dateObj,
        weight: null,
        source: 'twitter',
        url: url,
      };
      main_content = { social_comment: main_obj };
      src = 'twitter';
    }
    if (this.socialCommentSource === 'reddit') {
      let main_obj = {
        name: author,
        avatar: null,
        comment: description,
        date: this.dateObj,
        weight: null,
        source: 'reddit',
        url: url,
      };
      main_content = { social_comment: main_obj };
      src = 'reddit';
    }

    if (this.widgetType === 'player_card') {
      let main_obj = {
        flip: 'inactive',
        display_picture: this.playerInfo.display_picture,
        name: this.playerName,
        number: this.playerNumber,
        height: this.regexRevert(this.playerHeight),
        weight: this.playerWeight,
        position: this.playerPosition,
        dob: this.playerBirth,
        team: this.playerTeam,
        league: this.playerLeague,
        team_color_1: this.playerTeam_color_1,
        team_color_2: this.playerTeam_color_2,
        slug: this.playerInfo.slug,
        id: this.playerInfo.id,
        wiki_url: this.wiki_url,
        wiki_source_title: this.wiki_source_title,
      };
      main_content = { player_card: main_obj };
    }

    if (this.widgetType === 'info' && this.widgetCardType === 'team_card') {
      let main_obj = {
        name: this.teamName,
        date_found: this.teamFounded,
        championships: this.teamTotalTitles,
        last_championship: this.teamLastTitle,
        stadium: this.teamStadium,
        city: this.teamLoc,
        stadium_capacity: this.teamCapacity,
        stadium_url: this.teamInfo.stadium_url,
        head_coach: this.teamHeadCoach,
        head_coach_url: this.teamInfo.head_coach_url,
        slug: this.teamInfo.slug,
        team_color_1: this.teamInfo.team_color_1,
        team_color_2: this.teamInfo.team_color_2,
        id: this.teamInfo.id,
        type: 'team',
      };
      main_content = { info: main_obj };
    }

    if (this.widgetType === 'info' && this.widgetCardType === 'league_card') {
      let main_obj = {
        name: this.leagueName,
        date_found: this.leagueFounded,
        most_league_titles: this.leagueTotalTitles,
        champion: this.leagueLastTitle,
        runner_up: this.leagueRunnerUp,
        last_league_result: this.leagueResults,
        slug: this.leagueInfo.slug,
        id: this.leagueInfo.id,
        website: this.leagueInfo.website,
        history: this.leagueInfo.history,
        type: 'league',
      };
      main_content = { info: main_obj };
    }

    if (this.widgetType === 'info' && this.widgetCardType === 'division_card') {
      let main_obj = {
        name: this.divisionName,
        date_found: this.divisionFounded,
        most_titles: this.divisionTotalTitles,
        champion: this.divisionLastTitle,
        slug: this.divisionInfo.slug,
        id: this.divisionInfo.id,
        type: 'division',
      };
      main_content = { info: main_obj };
    }

    if (
      this.widgetType === 'info' &&
      this.widgetCardType === 'conference_card'
    ) {
      let main_obj = {
        name: this.conferenceName,
        date_found: this.conferenceFounded,
        champion: this.conferenceLastTitle,
        runner_up: this.conferenceRunnerUp,
        last_conference_result: this.conferenceResult,
        slug: this.conferenceInfo.slug,
        id: this.conferenceInfo.id,
        type: 'conference',
      };
      main_content = { info: main_obj };
    }

    if (this.widgetType === 'stats' && this.widgetStatType === 'player_stats') {
      let main_obj = {
        stat_type: this.widgetStatType,
        league: this.leagueVal,
        type: this.gameTypeVal,
        property: this.gamePropertyVal,
        obj: {
          data: [],
          page: 1,
          size: 10,
          totalRecords: 0,
        },
        filters: this.checkedList,
      };
      this.playerStatsInfo.data.map((a) => {
        main_obj.obj.data.push(a);
      });
      main_obj.obj.totalRecords = main_obj.obj.data.length;

      main_content = { stats: main_obj };
    }
    if (this.widgetType === 'stats' && this.widgetStatType === 'team_stats') {
      let main_obj = {
        stat_type: this.widgetStatType,
        league: this.leagueVal,
        type: this.gameTypeVal,
        property: this.gamePropertyVal,
        is_spotlight: this.is_spotlight,
        spotlightSelection: this.spotlightSelection,
        spotlightSlug: this.spotlightPlayerSlug,
        obj: {
          data: [],
          page: 1,
          size: 10,
          totalRecords: 0,
        },

        filters: this.checkedList,
        size: this.size,
        team_id: this.teamVal.toString(),
      };
      this.playerStatsInfo.data.map((a) => {
        main_obj.obj.data.push(a);
      });
      main_obj.obj.totalRecords = main_obj.obj.data.length;
      main_content = { stats: main_obj };
    }
    if (
      this.widgetType === 'stats' &&
      this.widgetStatType === 'division_stats'
    ) {
      let main_obj = {
        stat_type: this.widgetStatType,
        league: this.leagueVal,
        type: this.gameTypeVal,
        property: this.gamePropertyVal,
        is_spotlight: this.is_spotlight,
        spotlightSelection: this.spotlightSelection,
        spotlightSlug: this.spotlightPlayerSlug,
        obj: {
          data: [],
          page: 1,
          size: 10,
          totalRecords: 0,
        },
        filters: this.checkedList,
        size: this.size,
        division_id: this.divisionVal.toString(),
      };
      this.playerStatsInfo.data.map((a) => {
        main_obj.obj.data.push(a);
      });
      main_obj.obj.totalRecords = main_obj.obj.data.length;
      main_content = { stats: main_obj };
    }

    if (
      this.widgetType === 'stats' &&
      this.widgetStatType === 'conference_stats'
    ) {
      let main_obj = {
        stat_type: this.widgetStatType,
        league: this.leagueVal,
        type: this.gameTypeVal,
        property: this.gamePropertyVal,
        is_spotlight: this.is_spotlight,
        spotlightSelection: this.spotlightSelection,
        spotlightSlug: this.spotlightPlayerSlug,
        obj: {
          data: [],
          page: 1,
          size: 10,
          totalRecords: 0,
        },
        filters: this.checkedList,
        size: this.size,
        conference_id: this.conferenceVal.toString(),
      };
      this.playerStatsInfo.data.map((a) => {
        main_obj.obj.data.push(a);
      });
      main_obj.obj.totalRecords = main_obj.obj.data.length;
      main_content = { stats: main_obj };
    }

    if (this.widgetType === 'stats' && this.widgetStatType === 'league_stats') {
      let main_obj = {
        stat_type: this.widgetStatType,
        league: this.leagueVal,
        type: this.gameTypeVal,
        property: this.gamePropertyVal,
        is_spotlight: this.is_spotlight,
        spotlightSelection: this.spotlightSelection,
        spotlightSlug: this.spotlightPlayerSlug,
        obj: {
          data: [],
          page: 1,
          size: 10,
          totalRecords: 0,
        },
        filters: this.checkedList,
        size: this.size,
      };
      this.playerStatsInfo.data.map((a) => {
        main_obj.obj.data.push(a);
      });
      main_obj.obj.totalRecords = main_obj.obj.data.length;
      main_content = { stats: main_obj };
    }

    if (this.widgetType === 'standings') {
      let main_obj = {
        standing_type: this.widgetStandingType,
        league: this.leagueVal,
        league_conference: this.conferenceVal,
        division: this.divisionVal,
        team: this.teamVal,
        standing: this.standings,
      };
      main_content = { standings: main_obj };
    }

    if (this.widgetType === 'map') {
      let main_obj = {
        latitude: this.googleMapService.lat,
        longitude: this.googleMapService.long,
        zoom: this.googleMapService.zoom,
        mapTypeId: this.googleMapService.mapTypeId,
      };
      main_content = { map: main_obj };
    }

    if (this.widgetType === 'list') {
      let main_obj = {
        listarray: this.list,
      };
      main_content = { list: main_obj };
    }

    if (this.widgetType === 'upcoming_games') {
      let main_obj = {
        upcoming_type: this.widgetUpcomingType,
        league_game_type: this.leagueGameType,
        league: this.leagueVal,
        league_conference: this.conferenceVal,
        division: this.divisionVal,
        team: this.teamVal,
        my_sports_key: this.sportsKey,
        upcomings: this.upcomings,
      };
      main_content = { upcoming_games: main_obj };
    }

    if (this.widgetType === 'chart') {
      let key = this.gamePropertyVal;
      let chartOptions = {
        series: [
          {
            name: key,
            data: this.graphValues,
          },
        ],
        chart: {
          height: 350,
          type: 'bar',
          events: {
            mounted: (chart) => {
              chart.windowResizeHandler();
            },
          },
        },
        colors: [
          '#13598f',
          '#156147',
          '#fe4319',
          '#a62b3d',
          '#4d26d4',
          '#5ec7f7',
          '#154f0c',
          '#50065c',
          '#ffe733',
          '#cc7e6c',
        ],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        xaxis: {
          categories: this.graphNames,
          labels: {
            style: {
              colors: [
                '#13598f',
                '#156147',
                '#fe4319',
                '#a62b3d',
                '#4d26d4',
                '#5ec7f7',
                '#154f0c',
                '#50065c',
                '#ffe733',
                '#cc7e6c',
              ],
              fontSize: '12px',
            },
          },
        },
      };
      this.checkPost();
      let main_obj = {
        league: this.leagueVal,
        type: this.gameTypeVal,
        property: this.gamePropertyVal,
        dataobj: this.graphValues,
        nameobj: this.graphNames,
        size: this.size,
        charttype: this.chartVal,
        chartOptions: chartOptions,
        season: this.seasonVal,
        year: this.yearVal,
        all_seasons: this.complete_seasons,
        team: this.teamVal,
      };
      main_content = { chart: main_obj };
      this.chartShow = false;
    }

    if (this.widgetType === 'datagrid') {
      let sel_columns: any = [];
      this.selectedColumns.map((obj) => {
        sel_columns.push(obj);
      });
      let dat_grid = [];
      this.dataGridArr.map((obj) => {
        dat_grid.push(obj);
      });
      let main_obj = {
        columnsArr: this.columnsArr,
        dataGridArr: dat_grid,
        columnColor: this.columnColor,
        numOfColumns: this.numOfColumns,
        numOfRows: this.numOfRows,
        selectedColumns: sel_columns,
        showGrid: true,
        color_column: this.color_column,
        season: this.seasonVal,
        year: this.yearVal,
        all_seasons: this.complete_seasons,
        team: this.teamVal,
      };
      main_content = { datagrid: main_obj };
    }

    if (this.widgetType !== 'image') {
      let date = moment().utc(this.created_at).format();
      let widget = {
        id: id,
        title: title,
        main_content: main_content,
        author: author,
        url: url,
        display_text: description,
        is_title: this.is_title,
        is_text: this.is_text,
        is_extract: this.is_extract,
        image_url: img_url,
        type: type,
        source: src,
        status: 'published',
        created_at: this.created_at ? date : null,
        display_order: this.display_order
          ? this.display_order
          : this.widgetArr.length,
        keywords: this._keywords,
        meta_description: this._meta_description,
      };
      if (this.INX !== null && this.INX !== undefined) {
        if (this.immersive_view) {
          // widget.display_order = this.INX;
          // this.widgetArr.splice(this.INX, 0, widget);
          // this.Immersive_view_widgetArr.splice(this.INX, 0, widget);
          // this.Immersive_view_widgetArr.sort(
          //   (a, b) => a.display_order - b.display_order
          // );
          // this.widgetArr.sort((a, b) => a.display_order - b.display_order);
          if (
            this.widgetType === 'social_comment' &&
            (this.socialCommentSource === 'reddit' ||
              this.socialCommentSource === 'twitter')
          ) {
            if (this.INDEX !== null) {
              this.widgetArr.splice(this.INDEX, 0, widget);
              this.Immersive_view_widgetArr.map((block) => {
                if (
                  block.type === 'social_comment' &&
                  (block.source === 'reddit' || block.source === 'twitter')
                ) {
                  let blockArr = [];
                  block.arr.map((wig) => {
                    blockArr.push(wig);
                  });
                  blockArr.map((obj) => {
                    if (obj.id === this.ImmersiveSocialCommentObj.id) {
                      block.arr.splice(this.SecondINDEX, 0, widget);
                      return;
                    }
                  });
                }
              });
            } else {
              this.widgetArr.splice(this.INX, 0, widget);
              let tempArr: any = [];
              let doubleArr: any = [];
              let singleArr: any = [];
              this.widgetArr.map((wig, i) => {
                if (wig.status !== 'draft') {
                  if (wig.type === 'social_comment') {
                    if (wig.source === 'twitter') {
                      if (this.widgetArr[i + 1] !== undefined) {
                        if (
                          this.widgetArr[i].source ===
                          this.widgetArr[i + 1].source
                        ) {
                          doubleArr.push(this.widgetArr[i]);
                          doubleArr.push(this.widgetArr[i + 1]);
                        } else {
                          if (doubleArr.length > 0) {
                            for (let i = 0; i < doubleArr.length; i++) {
                              const duplicates = doubleArr.filter(
                                (obj) => obj.id === doubleArr[i].id
                              );
                              if (duplicates.length > 1) {
                                doubleArr.splice(i + 1, 1); // remove the second object
                                i--; // decrement i to account for the removed object
                              }
                            }
                            tempArr.push({
                              arr: doubleArr,
                              type: this.widgetArr[i].type,
                              source: this.widgetArr[i].source,
                            });

                            doubleArr = [];
                          } else {
                            singleArr.push(this.widgetArr[i]);
                            tempArr.push({
                              arr: singleArr,
                              type: this.widgetArr[i].type,
                              source: this.widgetArr[i].source,
                            });
                            singleArr = [];
                          }
                        }
                      } else {
                        if (doubleArr.length > 0) {
                          for (let i = 0; i < doubleArr.length; i++) {
                            const duplicates = doubleArr.filter(
                              (obj) => obj.id === doubleArr[i].id
                            );
                            if (duplicates.length > 1) {
                              doubleArr.splice(i + 1, 1); // remove the second object
                              i--; // decrement i to account for the removed object
                            }
                          }
                          tempArr.push({
                            arr: doubleArr,
                            type: this.widgetArr[i].type,
                            source: this.widgetArr[i].source,
                          });

                          doubleArr = [];
                        } else {
                          singleArr.push(this.widgetArr[i]);
                          tempArr.push({
                            arr: singleArr,
                            type: this.widgetArr[i].type,
                            source: this.widgetArr[i].source,
                          });
                          singleArr = [];
                        }
                      }
                    } else if (wig.source === 'reddit') {
                      if (this.widgetArr[i + 1] !== undefined) {
                        if (
                          this.widgetArr[i].source ===
                          this.widgetArr[i + 1].source
                        ) {
                          doubleArr.push(this.widgetArr[i]);
                          doubleArr.push(this.widgetArr[i + 1]);
                        } else {
                          if (doubleArr.length > 0) {
                            for (let i = 0; i < doubleArr.length; i++) {
                              const duplicates = doubleArr.filter(
                                (obj) => obj.id === doubleArr[i].id
                              );
                              if (duplicates.length > 1) {
                                doubleArr.splice(i + 1, 1); // remove the second object
                                i--; // decrement i to account for the removed object
                              }
                            }
                            tempArr.push({
                              arr: doubleArr,
                              type: this.widgetArr[i].type,
                              source: this.widgetArr[i].source,
                            });
                            doubleArr = [];
                          } else {
                            singleArr.push(this.widgetArr[i]);
                            tempArr.push({
                              arr: singleArr,
                              type: this.widgetArr[i].type,
                              source: this.widgetArr[i].source,
                            });
                            singleArr = [];
                          }
                        }
                      } else {
                        if (doubleArr.length > 0) {
                          for (let i = 0; i < doubleArr.length; i++) {
                            const duplicates = doubleArr.filter(
                              (obj) => obj.id === doubleArr[i].id
                            );
                            if (duplicates.length > 1) {
                              doubleArr.splice(i + 1, 1); // remove the second object
                              i--; // decrement i to account for the removed object
                            }
                          }
                          tempArr.push({
                            arr: doubleArr,
                            type: this.widgetArr[i].type,
                            source: this.widgetArr[i].source,
                          });

                          doubleArr = [];
                        } else {
                          singleArr.push(this.widgetArr[i]);
                          tempArr.push({
                            arr: singleArr,
                            type: this.widgetArr[i].type,
                            source: this.widgetArr[i].source,
                          });
                          singleArr = [];
                        }
                      }
                    }
                  } else {
                    if (doubleArr.length > 1) {
                      for (let i = 0; i < doubleArr.length; i++) {
                        const duplicates = doubleArr.filter(
                          (obj) => obj.id === doubleArr[i].id
                        );
                        if (duplicates.length > 1) {
                          doubleArr.splice(i + 1, 1); // remove the second object
                          i--; // decrement i to account for the removed object
                        }
                      }
                      doubleArr = doubleArr.filter(
                        (obj) => obj.status !== 'draft'
                      );

                      tempArr.push({
                        arr: doubleArr,
                        type: this.widgetArr[i - 1].type,
                        source: this.widgetArr[i - 1].source,
                      });

                      doubleArr = [];
                    }
                    tempArr.push(wig);
                  }
                }
              });

              this.Immersive_view_widgetArr = tempArr;
            }
          } else {
            this.widgetArr.splice(this.INX, 0, widget);
            this.Immersive_view_widgetArr.splice(this.INX, 0, widget);
          }
        } else {
          //widget.display_order = this.INX + 1;
          this.widgetArr.splice(this.INX + 1, 0, widget);
          this.Immersive_view_widgetArr.splice(this.INX + 1, 0, widget);
          //this.widgetArr = this.Immersive_view_widgetArr;
        }
      } else {
        const objectExists = this.widgetArr.some(
          (item) => JSON.stringify(item) === JSON.stringify(widget)
        );

        if (!objectExists) {
          this.widgetArr.push(widget);
        }

        let tempArr: any = [];
        let doubleArr: any = [];
        let singleArr: any = [];
        this.widgetArr.map((wig, i) => {
          if (wig.status !== 'draft') {
            if (wig.type === 'social_comment') {
              if (wig.source === 'twitter') {
                if (this.widgetArr[i + 1] !== undefined) {
                  if (
                    this.widgetArr[i].source === this.widgetArr[i + 1].source
                  ) {
                    doubleArr.push(this.widgetArr[i]);
                    doubleArr.push(this.widgetArr[i + 1]);
                  } else {
                    if (doubleArr.length > 0) {
                      for (let i = 0; i < doubleArr.length; i++) {
                        const duplicates = doubleArr.filter(
                          (obj) => obj.id === doubleArr[i].id
                        );
                        if (duplicates.length > 1) {
                          doubleArr.splice(i + 1, 1); // remove the second object
                          i--; // decrement i to account for the removed object
                        }
                      }
                      tempArr.push({
                        arr: doubleArr,
                        type: this.widgetArr[i].type,
                        source: this.widgetArr[i].source,
                      });

                      doubleArr = [];
                    } else {
                      singleArr.push(this.widgetArr[i]);
                      tempArr.push({
                        arr: singleArr,
                        type: this.widgetArr[i].type,
                        source: this.widgetArr[i].source,
                      });
                      singleArr = [];
                    }
                  }
                } else {
                  if (doubleArr.length > 0) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }
                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });

                    doubleArr = [];
                  } else {
                    singleArr.push(this.widgetArr[i]);
                    tempArr.push({
                      arr: singleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    singleArr = [];
                  }
                }
              } else if (wig.source === 'reddit') {
                if (this.widgetArr[i + 1] !== undefined) {
                  if (
                    this.widgetArr[i].source === this.widgetArr[i + 1].source
                  ) {
                    doubleArr.push(this.widgetArr[i]);
                    doubleArr.push(this.widgetArr[i + 1]);
                  } else {
                    if (doubleArr.length > 0) {
                      for (let i = 0; i < doubleArr.length; i++) {
                        const duplicates = doubleArr.filter(
                          (obj) => obj.id === doubleArr[i].id
                        );
                        if (duplicates.length > 1) {
                          doubleArr.splice(i + 1, 1); // remove the second object
                          i--; // decrement i to account for the removed object
                        }
                      }
                      tempArr.push({
                        arr: doubleArr,
                        type: this.widgetArr[i].type,
                        source: this.widgetArr[i].source,
                      });
                      doubleArr = [];
                    } else {
                      singleArr.push(this.widgetArr[i]);
                      tempArr.push({
                        arr: singleArr,
                        type: this.widgetArr[i].type,
                        source: this.widgetArr[i].source,
                      });
                      singleArr = [];
                    }
                  }
                } else {
                  if (doubleArr.length > 0) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }
                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });

                    doubleArr = [];
                  } else {
                    singleArr.push(this.widgetArr[i]);
                    tempArr.push({
                      arr: singleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    singleArr = [];
                  }
                }
              }
            } else {
              if (doubleArr.length > 1) {
                for (let i = 0; i < doubleArr.length; i++) {
                  const duplicates = doubleArr.filter(
                    (obj) => obj.id === doubleArr[i].id
                  );
                  if (duplicates.length > 1) {
                    doubleArr.splice(i + 1, 1); // remove the second object
                    i--; // decrement i to account for the removed object
                  }
                }
                doubleArr = doubleArr.filter((obj) => obj.status !== 'draft');

                tempArr.push({
                  arr: doubleArr,
                  type: this.widgetArr[i - 1].type,
                  source: this.widgetArr[i - 1].source,
                });

                doubleArr = [];
              }
              tempArr.push(wig);
            }
          }
        });

        this.Immersive_view_widgetArr = tempArr;
      }

      if (this.showAddBlock) {
        this.edit_IV = true;
        this.showAddBlock = false;
        this.widgetType = '';
        this.widgetType = '';
      }

      this.isEditWidget = false;
      this.showWidgetMenu = false;
      this.widgetType = null;
      this.OBJID = null;
      this.checkedList = [];
      this.INX = null;
      this.lmt = 15;
      this.bll.reset_imagn_data();
    }
  }

  countVisibleCharacters(htmlString) {
    // Create a temporary element to extract text content
    var tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;

    // Get the text content and remove white spaces
    var textContent = tempElement.innerText.replace(/\s/g, '');

    // Count the number of characters
    var characterCount = textContent.length;

    return characterCount;
  }

  updateTruncatedText(text) {
    let final_text = '';
    // Update the truncatedText whenever widget.display_text changes
    if (text) {
      const characterCount = this.countVisibleCharacters(text);

      if (characterCount <= 450) {
        // If character count is less than or equal to 500, no truncation needed
        final_text = text;
      } else {
        // Truncate the text to 500 characters
        final_text = this.limitTextTo450Characters(text);
      }
    }
    return final_text;
  }

  limitTextTo450Characters(text: string): string {
    if (text.length <= 450) {
      return text;
    } else {
      let x = text.slice(0, 450);
      x = x + '...';
      return x;
    }
  }

  countCharacters() {
    this.characterCount = this.countVisibleCharacters(this.description);
  }

  async saveWidget() {
    let characterCount = this.countVisibleCharacters(this.description);

    if (this.widgetType === null || this.widgetType === 'empty') {
      await this.showToast(
        'Block Type is missing, Please  Add the Block Type !'
      );
      return;
    }

    if (
      this.widgetType === 'twitter' &&
      (this.url === null || this.url === '' || this.url === undefined)
    ) {
      await this.showToast(
        'Twitter URL is missing, Please  Add the Twitter Url !'
      );
      return;
    }

    if (
      this.widgetType === 'video' &&
      (this.url === null || this.url === '' || this.url === undefined)
    ) {
      await this.showToast(
        'Youtube Link is missing, Please Add the Video Link !'
      );
      return;
    }

    if (this.widgetType === 'quote' && !this.description) {
      await this.showToast('Quote is missing, Please fill it out !');
      return;
    }

    if (
      this.widgetType === 'quote' &&
      (this.description === null || this.description === '')
    ) {
      await this.showToast('Quote is missing, Please fill it out !');
      return;
    }
    if (this.widgetType === 'quote') {
      let urlValidate = this.urlValidation(this.url);

      if (urlValidate) {
        await this.showToast(
          'Quote Source URl is incorrect,Please enter a correct url !'
        );
        return;
      } else {
      }
    }

    if (this.widgetType === 'image' && this.imageType === 'Select Image Type') {
      await this.showToast('Please Select The Image Type!');
      return;
    }

    if (this.widgetType === 'image' && this.images.length < 1) {
      await this.showToast('Please Upload or Search for an Image to add!');
      return;
    }

    if (
      this.widgetType === 'wikipedia' &&
      (this.widgetSource === undefined || this.widgetSource === null)
    ) {
      await this.showToast('Please Select Widget Source!');
      return;
    }

    if (this.widgetType === 'wikipedia' && !this.description) {
      await this.showToast('Please fill out the display text!');
      return;
    }

    if (
      this.widgetType === 'number' &&
      (this.number === null || this.number === 0)
    ) {
      await this.showToast('Please Enter a Number!');
      return;
    }

    if (
      this.widgetType === 'social_comment' &&
      (!this.socialCommentSource === null ||
        this.socialCommentSource === 'Select Social Url Type')
    ) {
      await this.showToast('Please Select a source for social comment!');
      return;
    }

    if (
      this.widgetType === 'social_comment' &&
      (this.url === null || this.url === '' || this.url === undefined)
    ) {
      await this.showToast('Link is missing, Please Add the Link !');
      return;
    }

    if (
      this.widgetType === 'social_comment' &&
      (this.description === null || this.description === '')
    ) {
      await this.showToast('Please write the comment !');
      return;
    }

    if (
      this.widgetType === 'text' &&
      (this.description === null || this.description === '')
    ) {
      await this.showToast('Please fill the display Text field !');
      return;
    }

    if (
      this.widgetType === 'info' &&
      (this.widgetCardType === null ||
        this.widgetCardType === 'Select Block Card Type')
    ) {
      await this.showToast('Please Select Block Card Type !');
      return;
    }

    if (
      this.widgetCardType === 'team_card' &&
      this.widgetType === 'info' &&
      (this.teamInfo === null || this.teamInfo === undefined)
    ) {
      await this.showToast('No Team Data available!');
      return;
    }
    if (
      this.widgetType === 'player_card' &&
      (this.playerInfo === null || this.playerInfo === undefined)
    ) {
      await this.showToast('No Player Data available!');
      return;
    }
    if (
      this.widgetType === 'player_card' &&
      (this.playerInfo.slug === null || this.playerInfo.slug === undefined)
    ) {
      await this.showToast('No Player available!');
      return;
    }
    if (
      this.widgetCardType === 'division_card' &&
      this.widgetType === 'info' &&
      (this.divisionInfo === null || this.divisionInfo === undefined)
    ) {
      await this.showToast('No Division Data available!');
      return;
    }
    if (
      this.widgetCardType === 'conference_card' &&
      this.widgetType === 'info' &&
      (this.conferenceInfo === null || this.conferenceInfo === undefined)
    ) {
      await this.showToast('No Conference Data available!');
      return;
    }
    if (
      this.widgetCardType === 'league_card' &&
      this.widgetType === 'info' &&
      (this.leagueInfo === null || this.leagueInfo === undefined)
    ) {
      await this.showToast('No League Data available!');
      return;
    }

    if (
      this.widgetType === 'stats' &&
      (this.widgetStatType === null ||
        this.widgetStatType === 'Select Content Block Stats')
    ) {
      await this.showToast('Please Select Content Block Stats !');
      return;
    }
    if (this.widgetType === 'stats') {
      this.playerStatsObj.data.map((obj) => {
        this.playerStatsInfo.data.push(obj);
      });
      if (this.widgetStatType === 'team_stats') {
        this.OBJID = this.playerStatsInfo.data[0].team_id;
      }
      this.playerStatsInfo.totalRecords = this.playerStatsInfo.data.length;
    }

    if (
      this.widgetType === 'stats' &&
      (this.playerStatsInfo === null ||
        this.playerStatsInfo === undefined ||
        this.playerStatsInfo.totalRecords === 0)
    ) {
      await this.showToast('No Data available!');
      return;
    }

    if (
      this.widgetType === 'standings' &&
      (this.widgetStandingType === null ||
        this.widgetStandingType === 'Select Content Block Standing')
    ) {
      await this.showToast('Please Select Content Block Standing !');
      return;
    }

    if (this.widgetType === 'standings' && this.standings.data.length < 1) {
      await this.showToast('No Data available!');
      return;
    }

    if (
      this.widgetType === 'stats' &&
      (this.leagueVal === null || this.leagueVal === 'Select League')
    ) {
      await this.showToast('Please Select League !');
      return;
    }

    if (
      this.widgetType === 'stats' &&
      (this.gameTypeVal === null || this.gameTypeVal === 'Select Stat Type')
    ) {
      await this.showToast('Please Select Stat Type !');
      return;
    }

    if (
      this.widgetType === 'stats' &&
      this.widgetStatType !== 'player_stats' &&
      (this.gamePropertyVal === null || this.gamePropertyVal === 'Select Sort')
    ) {
      await this.showToast('Please Select Sort !');
      return;
    }

    if (
      this.widgetType === 'stats' &&
      this.widgetStatType === 'division_stats' &&
      (this.divisionVal === null || this.divisionVal === 'Select Division')
    ) {
      await this.showToast('Please Select Division !');
      return;
    }

    if (
      this.widgetType === 'stats' &&
      this.widgetStatType === 'team_stats' &&
      (this.teamVal === null || this.teamVal === 'Select Team')
    ) {
      await this.showToast('Please Select Team !');
      return;
    }
    if (
      this.widgetType === 'stats' &&
      this.widgetStatType === 'conference_stats' &&
      (this.conferenceVal === null ||
        this.conferenceVal === 'Select Conference')
    ) {
      await this.showToast('Please Select Conference !');
      return;
    }

    if (
      this.widgetType === 'upcoming_games' &&
      (this.widgetUpcomingType === null ||
        this.widgetUpcomingType === 'Select Content Block Upcoming')
    ) {
      await this.showToast('Please Select Content Block Upcoming !');
      return;
    }

    if (
      this.widgetType === 'upcoming_games' &&
      (this.leagueGameType === null ||
        this.leagueGameType === '' ||
        this.leagueGameType === undefined ||
        this.leagueGameType === 'Select League Game Type')
    ) {
      await this.showToast('Please Select League Game Type !');
      return;
    }

    if (
      (this.widgetType === 'upcoming_games' ||
        this.widgetType === 'standings') &&
      (this.leagueVal === null ||
        this.leagueVal === '' ||
        this.leagueVal === undefined ||
        this.leagueVal === 'Select League')
    ) {
      await this.showToast('Please Select League !');
      return;
    }

    if (
      ((this.widgetType === 'upcoming_games' &&
        this.widgetUpcomingType === 'team_upcoming') ||
        (this.widgetType === 'standings' &&
          this.widgetStandingType === 'team_standings')) &&
      (this.teamVal === null ||
        this.teamVal === '' ||
        this.teamVal === undefined ||
        this.teamVal === 'Select Team')
    ) {
      await this.showToast('Please Select Team !');
      return;
    }

    if (
      ((this.widgetType === 'upcoming_games' &&
        this.widgetUpcomingType === 'conference_upcoming') ||
        (this.widgetType === 'standings' &&
          this.widgetStandingType === 'conference_standings')) &&
      (this.conferenceVal === null ||
        this.conferenceVal === '' ||
        this.conferenceVal === undefined ||
        this.conferenceVal === 'Select Conference')
    ) {
      await this.showToast('Please Select Conference !');
      return;
    }

    if (
      ((this.widgetType === 'upcoming_games' &&
        (this.widgetUpcomingType === 'division_upcoming' ||
          this.widgetUpcomingType === 'conference_upcoming')) ||
        (this.widgetType === 'standings' &&
          this.widgetStandingType === 'division_standings')) &&
      (this.divisionVal === null ||
        this.divisionVal === '' ||
        this.divisionVal === undefined ||
        this.divisionVal === 'Select Division')
    ) {
      await this.showToast('Please Select Division !');
      return;
    }

    if (
      this.is_spotlight === true &&
      (this.spotlightPlayerSlug === null ||
        this.spotlightPlayerSlug === undefined ||
        this.spotlightPlayerSlug === '')
    ) {
      await this.showToast('Please Select a player for spot light !');
      return;
    }

    if (this.widgetType === 'list' && this.list.length === 0) {
      await this.showToast(
        'No item added in list, Please Select a list type and add items to list!'
      );
      return;
    }

    if (
      this.widgetType === 'map' &&
      this.googleMapService.lat === 0 &&
      this.googleMapService.long === 0
    ) {
      await this.showToast(
        'Please either enter latitude and longitude for a place or search for a place'
      );
      return;
    }

    if (
      this.widgetType === 'chart' &&
      (this.graphValues.length === 0 || this.graphNames.length === 0)
    ) {
      await this.showToast(
        'Unable to save such record cause we have put an restriction that record should be there before saving the record'
      );
      return;
    }

    if (this.widgetType === 'chart') {
      let issueFound = false; // A flag to track whether an issue has been encountered

      for (const item of this.graphValues) {
        if (item === undefined || isNaN(item)) {
          issueFound = true;
          break; // Exit the loop when an issue is encountered
        }
      }

      if (issueFound) {
        await this.showToast(
          'Unable to save such record cause we have put an restriction that record should be there before saving the record'
        );
        return;
      }
    }

    if (this.widgetType === 'datagrid') {
      const keysToFind = [
        'column1',
        'column2',
        'column3',
        'column4',
        'empty column',
      ];

      if (
        keysToFind.some((key) => this.dataGridArr.some((item) => key in item))
      ) {
        await this.showToast('Empty Column ! Please Fill it up');
        return;
      }
    }

    if (
      this.widgetType === 'player_card' &&
      this.description &&
      characterCount > 450
    ) {
      await this.showToast(
        'Description has more than 450 characters ! Please reduce the number of characters used.'
      );
      return;
    }

    if (
      this.widgetType !== 'player_card' &&
      this.description &&
      this.description.length > 800
    ) {
      await this.showToast(
        'Description has more than 800 characters ! Please reduce the number of characters used.'
      );
      return;
    }

    // var re=/(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/

    // if((this.url!==''||this.url!==null)&&!re.test(this.url)){
    //   await this.showToast('URL is not in correct format !')
    //   return;
    // }

    let image_url = null;

    let image_links = null;
    if (this.widgetType === 'wikipedia' && this._image_url) {
      image_url = this._image_url;
      this.images = [];
    } else {
      image_url = null;
    }

    let src = this.widgetSource;
    let type = this.widgetType;

    let main_content;

    let title = this.regexRevert(this.name);
    let author = this.regexRevert(this.Widget_author);
    let pubname = this.regexRevert(this.pubname);
    let number = this.number;
    let description = this.description
      ? this.regexRevert(this.description)
      : '';

    let url = this.widgetType !== 'image' ? this.url : null;
    let img_url = image_url;
    let img_links = image_links;

    if (this.widgetType === 'twitter') {
      let main_obj = {
        data: null,
        images: [],
        id: url?.split('/').pop(),
        avatar: null,
        username: null,
        url: url,
        type: null,
        weight: null,
      };
      src = 'twitter';
      main_content = { twitter: main_obj };
    }
    if (this.widgetType === 'video') {
      src = 'youtube';
      let main_obj = {
        sHeight: null,
        videoFound: null,
        thumbnail: null,
        width: null,
        id: null,
        channel: null,
        channelId: null,
        publishTime: null,
        sourceName: 'Youtube',
        type: null,
        url: url,
        quality: null,
      };
      if (this.youtubeObj) {
        main_obj.thumbnail = this.youtubeObj.thumbnail
          ? this.youtubeObj.thumbnail
          : null;
        main_obj.id = this.youtubeObj.id ? this.youtubeObj.id : null;
        main_obj.channel = this.youtubeObj.channel
          ? this.youtubeObj.channel
          : null;
        main_obj.channelId = this.youtubeObj.channelId
          ? this.youtubeObj.channelId
          : null;
        main_obj.publishTime = this.youtubeObj.publishTime
          ? this.youtubeObj.publishTime
          : null;
        main_obj.type = this.youtubeObj.type ? this.youtubeObj.type : null;
      }
      main_content = { video: main_obj };
    }

    if (this.widgetType === 'quote' || this.widgetType === 'text') {
      let main_obj = {
        text: description,
        speaker: author,
        pubName: pubname,
        url: url,
        canonicalSpeaker: null,
      };
      if (this.widgetType === 'quote') {
        main_content = { quote: main_obj };
      } else {
        main_content = { text: main_obj };
      }
    }

    if (this.widgetType === 'image') {
      let imgArr = [];
      let imageObj = null;
      let newObjs = this.images.filter(
        (obj2) =>
          !this.localimages.some((obj1) => obj1.previewUrl === obj2.previewUrl)
      );

      let uploadedImagesCount = 0;
      this.customLoader();

      if (newObjs.length > 0) {
        newObjs.map((image, index) => {
          let fileUrl = this.getBase64ImageFromUrl(image.previewUrl)
            .then(async (result: any) => {
              let file = await this.storage.dataURItoBlob2(result);

              let metaObj = {
                uploadedBy: this.user.name,
                fileType: image.fileExtension,
                fileSize: image.filesize,
                height: image.height,
                width: image.width,
                altText: '',
                caption: image.caption
                  ? image.caption.replace(/Mandatory Credit:/g, '©')
                  : '',
                description: image.headline,
              };

              //  this.imageStr = await this.storage.uploadFile(file);
              if (image.keywords) {
                imageObj = await this.storage.uploadFile3(
                  file,
                  metaObj,
                  image.keywords[0]
                );
              } else {
                imageObj = await this.storage.uploadFile3(file, metaObj);
              }

              //  imageObj = await this.storage.uploadFile(file);

              image.previewUrl = imageObj;
              uploadedImagesCount++;

              if (uploadedImagesCount === newObjs.length) {
                // this.loadCtrl.dismiss();
              }

              if (index === newObjs.length - 1) {
                this.images.map((a) => {
                  let obj = {
                    url: a.previewUrl,
                    description: a.caption
                      ? a.caption.replace(/Mandatory Credit:/g, '©')
                      : '',
                    source: a.credit,
                    position: null,
                  };
                  imgArr.push(obj);
                });
                this.loadCtrl.dismiss();

                let main_obj = {
                  showCaptions: this.showCaptions,
                  image: imgArr,
                  type: this.imageType,
                };
                main_content = { image: main_obj };

                let date = moment().utc(this.created_at).format();
                let widget = {
                  id: this.widget_id,
                  title: title,
                  main_content: main_content,
                  author: author,
                  url: url,
                  display_text: this.regexRevert(description),
                  is_title: this.is_title,
                  is_text: this.is_text,
                  is_extract: this.is_extract,
                  image_url: img_url,
                  type: type,
                  source: src,
                  status: 'published',
                  created_at: this.created_at ? date : null,
                  display_order: this.display_order
                    ? this.display_order
                    : this.widgetArr.length,
                  keywords: this._keywords,
                  meta_description: this._meta_description,
                };

                this.widgetArr.map((w, index) => {
                  if (w.id == widget.id) {
                    this.widgetArr[index] = widget;
                    this.isEditWidget = false;
                    this.showWidgetMenu = false;
                    this.widgetType = null;
                    return;
                  }
                });

                this.Immersive_view_widgetArr.map((w, index) => {
                  if (w.id == widget.id) {
                    this.Immersive_view_widgetArr[index] = widget;
                    this.isEditWidget = false;
                    this.showWidgetMenu = false;
                    this.widgetType = null;
                    return;
                  }
                });

                this.draggingNewBlock = false;
                this.dragObject = null;
                this.checkedList = [];
                this.OBJID = null;
                this.INX = null;
                this.lmt = 15;
                this.bll.reset_imagn_data();
                if (this.showAddBlock) {
                  this.edit_IV = true;
                  this.showAddBlock = false;
                  this.widgetType = '';
                  this.widgetType = '';
                }
              }
            })
            .catch((err) => console.error(err));
        });
      } else {
        this.images.map((a) => {
          let obj = {
            url: a.previewUrl,
            description: a.caption.replace(/Mandatory Credit:/g, '©'),
            source: a.credit,
            position: null,
          };
          imgArr.push(obj);
        });
        setTimeout(() => {
          this.loadCtrl.dismiss();
        }, 1000);

        let main_obj = {
          showCaptions: this.showCaptions,
          image: imgArr,
          type: this.imageType,
        };
        main_content = { image: main_obj };

        let date = moment().utc(this.created_at).format();
        let widget = {
          id: this.widget_id,
          title: title,
          main_content: main_content,
          author: author,
          url: url,
          display_text: this.regexRevert(description),
          is_title: this.is_title,
          is_text: this.is_text,
          is_extract: this.is_extract,
          image_url: img_url,
          type: type,
          source: src,
          status: 'published',
          created_at: this.created_at ? date : null,
          display_order: this.display_order
            ? this.display_order
            : this.widgetArr.length,
          keywords: this._keywords,
          meta_description: this._meta_description,
        };

        this.widgetArr.map((w, index) => {
          if (w.id == widget.id) {
            this.widgetArr[index] = widget;
            this.isEditWidget = false;
            this.showWidgetMenu = false;
            this.widgetType = null;
            return;
          }
        });

        this.Immersive_view_widgetArr.map((w, index) => {
          if (w.id == widget.id) {
            this.Immersive_view_widgetArr[index] = widget;
            this.isEditWidget = false;
            this.showWidgetMenu = false;
            this.widgetType = null;
            return;
          }
        });

        this.draggingNewBlock = false;
        this.dragObject = null;
        this.checkedList = [];
        this.OBJID = null;
        this.INX = null;
        this.lmt = 15;
        this.bll.reset_imagn_data();
        if (this.showAddBlock) {
          this.edit_IV = true;
          this.showAddBlock = false;
          this.widgetType = '';
          this.widgetType = '';
        }
      }

      // this.images=[]
      // this.localimages.map((image)=>{
      // this.images.push(image);
      // })
    }

    if (this.widgetType === 'wikipedia') {
      if (!this.widgetSource) {
        this.widgetSource = 'other';
      }
      src = this.widgetType;
      type = this.widgetSource;
      if (this.widgetSource === 'organization') {
        let main_obj = {
          weight: null,
          name: author,
          // wiki_extract: this.wiki_extract,
          type: type,
          source: src,
          data: [
            {
              text: title,
              url: url,
              description: description,
              image: {
                type: null,
                url: img_url,
                description: null,
                width: null,
                height: null,
              },
              weight: null,
            },
          ],
        };

        main_content = { organization: main_obj };
      }

      if (this.widgetSource === 'person' || this.widgetSource === 'other') {
        let main_obj = {
          weight: null,
          name: author,
          // wiki_extract: this.wiki_extract,
          type: type,
          source: src,
          data: [
            {
              text: author,
              url: url,
              description: description,
              image: {
                width: null,
                height: null,
                url: img_url,
              },
              weight: null,
            },
          ],
        };

        if (this.widgetSource === 'person') {
          main_content = { person: main_obj };
        } else {
          main_content = { other: main_obj };
        }
      }
    }

    if (this.widgetType === 'number') {
      let main_obj = {
        number: number,
      };
      main_content = { number: main_obj };
    }

    if (this.socialCommentSource === 'twitter') {
      let main_obj = {
        username: author,
        avatar: null,
        name: null,
        comment: description,
        date: this.dateObj,
        weight: null,
        source: 'twitter',
        url: url,
      };
      main_content = { social_comment: main_obj };
      src = 'twitter';
    }
    if (this.socialCommentSource === 'reddit') {
      let main_obj = {
        name: author,
        avatar: null,
        comment: description,
        date: this.dateObj,
        weight: null,
        source: 'reddit',
        url: url,
      };
      main_content = { social_comment: main_obj };
      src = 'reddit';
    }

    if (this.widgetType === 'player_card') {
      let main_obj = {
        flip: 'inactive',
        display_picture: this.playerInfo.display_picture,
        name: this.playerName,
        number: this.playerNumber,
        height: this.regexRevert(this.playerHeight),
        weight: this.playerWeight,
        position: this.playerPosition,
        dob: this.playerBirth,
        team: this.playerTeam,
        league: this.playerLeague,
        team_color_1: this.playerTeam_color_1,
        team_color_2: this.playerTeam_color_2,
        slug: this.playerInfo.slug,
        id: this.playerInfo.id,
        wiki_url: this.wiki_url,
        wiki_source_title: this.wiki_source_title,
      };
      main_content = { player_card: main_obj };
    }

    if (this.widgetType === 'info' && this.widgetCardType === 'team_card') {
      let main_obj = {
        name: this.teamName,
        date_found: this.teamFounded,
        championships: this.teamTotalTitles,
        last_championship: this.teamLastTitle,
        stadium: this.teamStadium,
        city: this.teamLoc,
        stadium_capacity: this.teamCapacity,
        stadium_url: this.teamInfo.stadium_url,
        head_coach: this.teamHeadCoach,
        head_coach_url: this.teamInfo.head_coach_url,
        slug: this.teamInfo.slug,
        team_color_1: this.teamInfo.team_color_1,
        team_color_2: this.teamInfo.team_color_2,
        id: this.teamInfo.id,
        type: 'team',
      };
      main_content = { info: main_obj };
    }

    if (this.widgetType === 'info' && this.widgetCardType === 'league_card') {
      let main_obj = {
        name: this.leagueName,
        date_found: this.leagueFounded,
        most_league_titles: this.leagueTotalTitles,
        champion: this.leagueLastTitle,
        runner_up: this.leagueRunnerUp,
        last_league_result: this.leagueResults,
        slug: this.leagueInfo.slug,
        id: this.leagueInfo.id,
        website: this.leagueInfo.website,
        history: this.leagueInfo.history,
        type: 'league',
      };
      main_content = { info: main_obj };
    }

    if (this.widgetType === 'info' && this.widgetCardType === 'division_card') {
      let main_obj = {
        name: this.divisionName,
        date_found: this.divisionFounded,
        most_titles: this.divisionTotalTitles,
        champion: this.divisionLastTitle,
        slug: this.divisionInfo.slug,
        id: this.divisionInfo.id,
        type: 'division',
      };
      main_content = { info: main_obj };
    }

    if (
      this.widgetType === 'info' &&
      this.widgetCardType === 'conference_card'
    ) {
      let main_obj = {
        name: this.conferenceName,
        date_found: this.conferenceFounded,
        champion: this.conferenceLastTitle,
        runner_up: this.conferenceRunnerUp,
        last_conference_result: this.conferenceResult,
        slug: this.conferenceInfo.slug,
        id: this.conferenceInfo.id,
        type: 'conference',
      };
      main_content = { info: main_obj };
    }

    if (this.widgetType === 'stats' && this.widgetStatType === 'player_stats') {
      let main_obj = {
        stat_type: this.widgetStatType,
        league: this.leagueVal,
        type: this.gameTypeVal,
        property: this.gamePropertyVal,
        obj: {
          data: [],
          page: 1,
          size: 10,
          totalRecords: 0,
        },
        filters: this.checkedList,
      };
      this.playerStatsInfo.data.map((a) => {
        main_obj.obj.data.push(a);
      });

      main_obj.obj.totalRecords = main_obj.obj.data.length;
      main_content = { stats: main_obj };
    }
    if (this.widgetType === 'stats' && this.widgetStatType === 'team_stats') {
      let main_obj = {
        stat_type: this.widgetStatType,
        league: this.leagueVal,
        type: this.gameTypeVal,
        property: this.gamePropertyVal,
        is_spotlight: this.is_spotlight,
        spotlightSelection: this.spotlightSelection,
        spotlightSlug: this.spotlightPlayerSlug,
        obj: {
          data: [],
          page: 1,
          size: 10,
          totalRecords: 0,
        },

        filters: this.checkedList,
        size: this.size,
        team_id: this.OBJID.toString(),
      };

      this.playerStatsInfo.data.map((a) => {
        main_obj.obj.data.push(a);
      });
      main_obj.obj.totalRecords = main_obj.obj.data.length;
      main_content = { stats: main_obj };
    }
    if (
      this.widgetType === 'stats' &&
      this.widgetStatType === 'division_stats'
    ) {
      let main_obj = {
        stat_type: this.widgetStatType,
        league: this.leagueVal,
        type: this.gameTypeVal,
        property: this.gamePropertyVal,
        is_spotlight: this.is_spotlight,
        spotlightSelection: this.spotlightSelection,
        spotlightSlug: this.spotlightPlayerSlug,
        obj: {
          data: [],
          page: 1,
          size: 10,
          totalRecords: 0,
        },
        filters: this.checkedList,
        size: this.size,
        division_id: this.OBJID.toString(),
      };
      this.playerStatsInfo.data.map((a) => {
        main_obj.obj.data.push(a);
      });
      main_obj.obj.totalRecords = main_obj.obj.data.length;
      main_content = { stats: main_obj };
    }

    if (
      this.widgetType === 'stats' &&
      this.widgetStatType === 'conference_stats'
    ) {
      let main_obj = {
        stat_type: this.widgetStatType,
        league: this.leagueVal,
        type: this.gameTypeVal,
        property: this.gamePropertyVal,
        is_spotlight: this.is_spotlight,
        spotlightSelection: this.spotlightSelection,
        spotlightSlug: this.spotlightPlayerSlug,
        obj: {
          data: [],
          page: 1,
          size: 10,
          totalRecords: 0,
        },
        filters: this.checkedList,
        size: this.size,
        conference_id: this.OBJID.toString(),
      };

      this.playerStatsInfo.data.map((a) => {
        main_obj.obj.data.push(a);
      });
      main_obj.obj.totalRecords = main_obj.obj.data.length;
      main_content = { stats: main_obj };
    }

    if (this.widgetType === 'stats' && this.widgetStatType === 'league_stats') {
      let main_obj = {
        stat_type: this.widgetStatType,
        league: this.leagueVal,
        type: this.gameTypeVal,
        property: this.gamePropertyVal,
        is_spotlight: this.is_spotlight,
        spotlightSelection: this.spotlightSelection,
        spotlightSlug: this.spotlightPlayerSlug,
        obj: {
          data: [],
          page: 1,
          size: 10,
          totalRecords: 0,
        },
        filters: this.checkedList,
        size: this.size,
      };
      this.playerStatsInfo.data.map((a) => {
        main_obj.obj.data.push(a);
      });
      main_obj.obj.totalRecords = main_obj.obj.data.length;
      main_content = { stats: main_obj };
    }

    if (this.widgetType === 'standings') {
      let main_obj = {
        standing_type: this.widgetStandingType,
        league: this.leagueVal,
        league_conference: this.conferenceVal,
        division: this.divisionVal,
        team: this.teamVal,
        standing: this.standings,
      };
      main_content = { standings: main_obj };
    }

    if (this.widgetType === 'map') {
      let main_obj = {
        latitude: this.googleMapService.lat,
        longitude: this.googleMapService.long,
        zoom: this.googleMapService.zoom,
        mapTypeId: this.googleMapService.mapTypeId,
      };
      main_content = { map: main_obj };
    }

    if (this.widgetType === 'list') {
      let main_obj = {
        listarray: this.list,
      };
      main_content = { list: main_obj };
    }

    if (this.widgetType === 'upcoming_games') {
      let main_obj = {
        upcoming_type: this.widgetUpcomingType,
        league_game_type: this.leagueGameType,
        league: this.leagueVal,
        league_conference: this.conferenceVal,
        division: this.divisionVal,
        team: this.teamVal,
        my_sports_key: this.sportsKey,
        upcomings: this.upcomings,
      };
      main_content = { upcoming_games: main_obj };
    }

    if (this.widgetType === 'chart') {
      let key = this.gamePropertyVal;
      let chartOptions = {
        series: [
          {
            name: key,
            data: this.graphValues,
          },
        ],
        chart: {
          redrawOnParentResize: true,
          height: 350,
          type: 'bar',
          events: {
            mounted: (chart) => {
              chart.windowResizeHandler();
            },
          },
        },
        colors: [
          '#13598f',
          '#156147',
          '#fe4319',
          '#a62b3d',
          '#4d26d4',
          '#5ec7f7',
          '#154f0c',
          '#50065c',
          '#ffe733',
          '#cc7e6c',
        ],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        xaxis: {
          categories: this.graphNames,
          labels: {
            style: {
              colors: [
                '#13598f',
                '#156147',
                '#fe4319',
                '#a62b3d',
                '#4d26d4',
                '#5ec7f7',
                '#154f0c',
                '#50065c',
                '#ffe733',
                '#cc7e6c',
              ],
              fontSize: '12px',
            },
          },
        },
      };

      this.checkPost();

      let main_obj = {
        league: this.leagueVal,
        type: this.gameTypeVal,
        property: this.gamePropertyVal,
        dataobj: this.graphValues,
        nameobj: this.graphNames,
        charttype: this.chartVal,
        size: this.size,
        season: this.seasonVal,
        year: this.yearVal,
        all_seasons: this.complete_seasons,
        team: this.teamVal,
        chartOptions: chartOptions,
      };
      main_content = { chart: main_obj };
      this.chartShow = false;
    }

    if (this.widgetType === 'datagrid') {
      let sel_columns: any = [];
      this.selectedColumns.map((obj) => {
        sel_columns.push(obj);
      });
      let dat_grid = [];
      this.dataGridArr.map((obj) => {
        dat_grid.push(obj);
      });
      let main_obj = {
        columnsArr: this.columnsArr,
        dataGridArr: dat_grid,
        columnColor: this.columnColor,
        numOfColumns: this.numOfColumns,
        numOfRows: this.numOfRows,
        selectedColumns: sel_columns,
        showGrid: true,
        color_column: this.color_column,
        season: this.seasonVal,
        year: this.yearVal,
        all_seasons: this.complete_seasons,
        team: this.teamVal,
      };
      main_content = { datagrid: main_obj };
    }

    if (this.widgetType !== 'image') {
      let date = moment().utc(this.created_at).format();
      let widget = {
        id: this.widget_id,
        title: title,
        main_content: main_content,
        author: author,
        url: url,
        display_text: this.regexRevert(description),
        is_title: this.is_title,
        is_text: this.is_text,
        is_extract: this.is_extract,
        image_url: img_url,
        type: type,
        source: src,
        status: 'published',
        created_at: this.created_at ? date : null,
        display_order: this.display_order
          ? this.display_order
          : this.widgetArr.length,
        keywords: this._keywords,
        meta_description: this._meta_description,
      };

      this.widgetArr.map((w, index) => {
        if (w.id == this.widget_id) {
          this.widgetArr[index] = widget;
          this.isEditWidget = false;
          this.showWidgetMenu = false;
          this.widgetType = null;
          return;
        }
      });

      let tempArr: any = [];
      let doubleArr: any = [];
      let singleArr: any = [];
      this.widgetArr.map((wig, i) => {
        if (wig.status !== 'draft') {
          if (wig.type === 'social_comment') {
            if (wig.source === 'twitter') {
              if (this.widgetArr[i + 1] !== undefined) {
                if (this.widgetArr[i].source === this.widgetArr[i + 1].source) {
                  doubleArr.push(this.widgetArr[i]);
                  doubleArr.push(this.widgetArr[i + 1]);
                } else {
                  if (doubleArr.length > 0) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }
                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });

                    doubleArr = [];
                  } else {
                    singleArr.push(this.widgetArr[i]);
                    tempArr.push({
                      arr: singleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    singleArr = [];
                  }
                }
              } else {
                if (doubleArr.length > 0) {
                  for (let i = 0; i < doubleArr.length; i++) {
                    const duplicates = doubleArr.filter(
                      (obj) => obj.id === doubleArr[i].id
                    );
                    if (duplicates.length > 1) {
                      doubleArr.splice(i + 1, 1); // remove the second object
                      i--; // decrement i to account for the removed object
                    }
                  }
                  tempArr.push({
                    arr: doubleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });

                  doubleArr = [];
                } else {
                  singleArr.push(this.widgetArr[i]);
                  tempArr.push({
                    arr: singleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });
                  singleArr = [];
                }
              }
            } else if (wig.source === 'reddit') {
              if (this.widgetArr[i + 1] !== undefined) {
                if (this.widgetArr[i].source === this.widgetArr[i + 1].source) {
                  doubleArr.push(this.widgetArr[i]);
                  doubleArr.push(this.widgetArr[i + 1]);
                } else {
                  if (doubleArr.length > 0) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }
                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    doubleArr = [];
                  } else {
                    singleArr.push(this.widgetArr[i]);
                    tempArr.push({
                      arr: singleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    singleArr = [];
                  }
                }
              } else {
                if (doubleArr.length > 0) {
                  for (let i = 0; i < doubleArr.length; i++) {
                    const duplicates = doubleArr.filter(
                      (obj) => obj.id === doubleArr[i].id
                    );
                    if (duplicates.length > 1) {
                      doubleArr.splice(i + 1, 1); // remove the second object
                      i--; // decrement i to account for the removed object
                    }
                  }
                  tempArr.push({
                    arr: doubleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });

                  doubleArr = [];
                } else {
                  singleArr.push(this.widgetArr[i]);
                  tempArr.push({
                    arr: singleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });
                  singleArr = [];
                }
              }
            }
          } else {
            if (doubleArr.length > 1) {
              for (let i = 0; i < doubleArr.length; i++) {
                const duplicates = doubleArr.filter(
                  (obj) => obj.id === doubleArr[i].id
                );
                if (duplicates.length > 1) {
                  doubleArr.splice(i + 1, 1); // remove the second object
                  i--; // decrement i to account for the removed object
                }
              }
              doubleArr = doubleArr.filter((obj) => obj.status !== 'draft');

              tempArr.push({
                arr: doubleArr,
                type: this.widgetArr[i - 1].type,
                source: this.widgetArr[i - 1].source,
              });

              doubleArr = [];
            }
            tempArr.push(wig);
          }
        }
      });

      this.Immersive_view_widgetArr = tempArr;

      if (widget.type === 'social_comment') {
      } else {
        this.Immersive_view_widgetArr.map((w, index) => {
          if (w.id == widget.id) {
            this.Immersive_view_widgetArr[index] = widget;
            this.isEditWidget = false;
            this.showWidgetMenu = false;
            this.widgetType = null;
            return;
          }
        });
      }

      this.draggingNewBlock = false;
      this.dragObject = null;
      this.checkedList = [];
      this.OBJID = null;
      this.INX = null;
      this.lmt = 15;
      this.bll.reset_imagn_data();
      if (this.showAddBlock) {
        this.edit_IV = true;
        this.showAddBlock = false;
        this.widgetType = '';
        this.widgetType = '';
      }
    }

    // let widgets = this.widgetArr.filter(w => widget.id !== w.id)
    // console.log(widgets);

    // this.widgetArr = widgets;

    // this.openAddNewWidgetModal = false;

    this.selectedId = null;
  }

  removeImg(imgUrl) {
    this.images.map((T, index) => {
      if (T.previewUrl === imgUrl) {
        this.images.splice(index, 1);
        if (imgUrl.includes('admindocuments')) {
          let path = imgUrl.substring(
            imgUrl.indexOf('admindocuments/') + 'admindocuments/'.length
          );

          let object = {
            name: path,
            account: 'sportswritersmedia',
            container: 'admindocuments',
          };
          this.bll.delete_image_permanent(object, this.auth.token);
        } else if (imgUrl.includes('contentimages')) {
          let path = imgUrl.substring(
            imgUrl.indexOf('contentimages/') + 'contentimages/'.length
          );
          let object = {
            name: path,
            account: 'sportswritersmedia',
            container: 'contentimages',
          };
          this.bll.delete_image_permanent(object, this.auth.token);
        }
      }
    });
  }

  editCaption(img) {
    this.selectedImg = img;
  }
  updateCaption() {
    this.images.map((T, index) => {
      if (T.previewUrl === this.selectedImg.previewUrl) {
        T.caption = this.selectedImg.caption;
        this.popoverCtrl.dismiss();
      }
    });
  }
  dismissCaption() {
    this.popoverCtrl.dismiss();
  }

  deleteWidget(item, index) {
    this.INX = index;
    this.widget = item;
    this.isConfirmationToDeleteBlockOpen = true;
  }

  delete_block() {
    if (this.immersive_view) {
      const index = this.widgetArr.findIndex(
        (obj) => obj.id === this.widget.id
      );
      this.widgetArr.map((obj) => {
        if (obj.id === this.widget.id) {
          this.widget.status = 'draft';
          obj.status = this.widget.status;
        }
      });
      this.removedBlocksArr.push(this.widgetArr[index]);

      // this.widgetArr.splice(index, 1);

      let tempArr: any = [];
      let doubleArr: any = [];
      let singleArr: any = [];
      this.widgetArr.map((wig, i) => {
        if (wig.status !== 'draft') {
          if (wig.type === 'social_comment') {
            if (wig.source === 'twitter') {
              if (this.widgetArr[i + 1] !== undefined) {
                if (this.widgetArr[i].source === this.widgetArr[i + 1].source) {
                  doubleArr.push(this.widgetArr[i]);

                  doubleArr.push(this.widgetArr[i + 1]);
                } else {
                  if (doubleArr.length > 0) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }

                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });

                    doubleArr = [];
                  } else {
                    singleArr.push(this.widgetArr[i]);

                    tempArr.push({
                      arr: singleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    singleArr = [];
                  }
                }
              } else {
                if (doubleArr.length > 0) {
                  for (let i = 0; i < doubleArr.length; i++) {
                    const duplicates = doubleArr.filter(
                      (obj) => obj.id === doubleArr[i].id
                    );
                    if (duplicates.length > 1) {
                      doubleArr.splice(i + 1, 1); // remove the second object
                      i--; // decrement i to account for the removed object
                    }
                  }
                  tempArr.push({
                    arr: doubleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });

                  doubleArr = [];
                } else {
                  singleArr.push(this.widgetArr[i]);

                  tempArr.push({
                    arr: singleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });
                  singleArr = [];
                }
              }
            } else if (wig.source === 'reddit') {
              if (this.widgetArr[i + 1] !== undefined) {
                if (this.widgetArr[i].source === this.widgetArr[i + 1].source) {
                  doubleArr.push(this.widgetArr[i]);

                  doubleArr.push(this.widgetArr[i + 1]);
                } else {
                  if (doubleArr.length > 0) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }

                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });

                    doubleArr = [];
                  } else {
                    singleArr.push(this.widgetArr[i]);

                    tempArr.push({
                      arr: singleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    singleArr = [];
                  }
                }
              } else {
                if (doubleArr.length > 0) {
                  for (let i = 0; i < doubleArr.length; i++) {
                    const duplicates = doubleArr.filter(
                      (obj) => obj.id === doubleArr[i].id
                    );
                    if (duplicates.length > 1) {
                      doubleArr.splice(i + 1, 1); // remove the second object
                      i--; // decrement i to account for the removed object
                    }
                  }
                  tempArr.push({
                    arr: doubleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });

                  doubleArr = [];
                } else {
                  singleArr.push(this.widgetArr[i]);

                  tempArr.push({
                    arr: singleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });
                  singleArr = [];
                }
              }
            }
          } else {
            if (doubleArr.length > 1) {
              for (let i = 0; i < doubleArr.length; i++) {
                const duplicates = doubleArr.filter(
                  (obj) => obj.id === doubleArr[i].id
                );
                if (duplicates.length > 1) {
                  doubleArr.splice(i + 1, 1); // remove the second object
                  i--; // decrement i to account for the removed object
                }
              }
              doubleArr = doubleArr.filter((obj) => obj.status !== 'draft');

              tempArr.push({
                arr: doubleArr,
                type: this.widgetArr[i - 1].type,
                source: this.widgetArr[i - 1].source,
              });

              doubleArr = [];
            }
            tempArr.push(wig);
          }
        }
      });

      this.Immersive_view_widgetArr = tempArr;
    } else {
      this.removedBlocksArr.push(this.widgetArr[this.INX]);
      this.widgetArr.map((obj) => {
        if (obj.id === this.widget.id) {
          this.widget.status = 'draft';
          obj.status = this.widget.status;
        }
      });
      // this.widgetArr.splice(this.INX, 1);
      let tempArr: any = [];
      let doubleArr: any = [];
      let singleArr: any = [];
      this.widgetArr.map((wig, i) => {
        if (wig.status !== 'draft') {
          if (wig.type === 'social_comment') {
            if (wig.source === 'twitter') {
              if (this.widgetArr[i + 1] !== undefined) {
                if (this.widgetArr[i].source === this.widgetArr[i + 1].source) {
                  doubleArr.push(this.widgetArr[i]);
                  doubleArr.push(this.widgetArr[i + 1]);
                } else {
                  if (doubleArr.length > 0) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }
                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });

                    doubleArr = [];
                  } else {
                    singleArr.push(this.widgetArr[i]);
                    tempArr.push({
                      arr: singleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    singleArr = [];
                  }
                }
              } else {
                if (doubleArr.length > 0) {
                  for (let i = 0; i < doubleArr.length; i++) {
                    const duplicates = doubleArr.filter(
                      (obj) => obj.id === doubleArr[i].id
                    );
                    if (duplicates.length > 1) {
                      doubleArr.splice(i + 1, 1); // remove the second object
                      i--; // decrement i to account for the removed object
                    }
                  }
                  tempArr.push({
                    arr: doubleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });

                  doubleArr = [];
                } else {
                  singleArr.push(this.widgetArr[i]);
                  tempArr.push({
                    arr: singleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });
                  singleArr = [];
                }
              }
            } else if (wig.source === 'reddit') {
              if (this.widgetArr[i + 1] !== undefined) {
                if (this.widgetArr[i].source === this.widgetArr[i + 1].source) {
                  doubleArr.push(this.widgetArr[i]);
                  doubleArr.push(this.widgetArr[i + 1]);
                } else {
                  if (doubleArr.length > 0) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }
                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    doubleArr = [];
                  } else {
                    singleArr.push(this.widgetArr[i]);
                    tempArr.push({
                      arr: singleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    singleArr = [];
                  }
                }
              } else {
                if (doubleArr.length > 0) {
                  for (let i = 0; i < doubleArr.length; i++) {
                    const duplicates = doubleArr.filter(
                      (obj) => obj.id === doubleArr[i].id
                    );
                    if (duplicates.length > 1) {
                      doubleArr.splice(i + 1, 1); // remove the second object
                      i--; // decrement i to account for the removed object
                    }
                  }
                  tempArr.push({
                    arr: doubleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });

                  doubleArr = [];
                } else {
                  singleArr.push(this.widgetArr[i]);
                  tempArr.push({
                    arr: singleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });
                  singleArr = [];
                }
              }
            }
          } else {
            if (doubleArr.length > 1) {
              for (let i = 0; i < doubleArr.length; i++) {
                const duplicates = doubleArr.filter(
                  (obj) => obj.id === doubleArr[i].id
                );
                if (duplicates.length > 1) {
                  doubleArr.splice(i + 1, 1); // remove the second object
                  i--; // decrement i to account for the removed object
                }
              }
              doubleArr = doubleArr.filter((obj) => obj.status !== 'draft');

              tempArr.push({
                arr: doubleArr,
                type: this.widgetArr[i - 1].type,
                source: this.widgetArr[i - 1].source,
              });

              doubleArr = [];
            }
            tempArr.push(wig);
          }
        }
      });

      this.Immersive_view_widgetArr = tempArr;
    }

    this.isConfirmationToDeleteBlockOpen = false;
  }

  close_modal() {
    this.INX = null;
    this.isConfirmationToDeleteBlockOpen = false;
  }

  reorderWidgets(event) {
    // let arr = this.widgetArr
    // arr[event.detail.from] = arr.splice(event.detail.to, 1, arr[event.detail.from])[0];

    // let display_order = arr[event.detail.from].display_order
    // arr[event.detail.from].display_order = arr[event.detail.to].display_order
    // arr[event.detail.to].display_order = display_order
    // this.widgetArr = arr;
    event.detail.complete(this.widgetArr);

    this.Immersive_view_widgetArr = [];
    let tempArr: any = [];
    let doubleArr: any = [];
    let singleArr: any = [];
    this.widgetArr.map((wig, i) => {
      if (wig.status !== 'draft') {
        if (wig.type === 'social_comment') {
          if (wig.source === 'twitter') {
            if (this.widgetArr[i + 1] !== undefined) {
              if (this.widgetArr[i].source === this.widgetArr[i + 1].source) {
                doubleArr.push(this.widgetArr[i]);
                doubleArr.push(this.widgetArr[i + 1]);
              } else {
                if (doubleArr.length > 0) {
                  for (let i = 0; i < doubleArr.length; i++) {
                    const duplicates = doubleArr.filter(
                      (obj) => obj.id === doubleArr[i].id
                    );
                    if (duplicates.length > 1) {
                      doubleArr.splice(i + 1, 1); // remove the second object
                      i--; // decrement i to account for the removed object
                    }
                  }
                  tempArr.push({
                    arr: doubleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });

                  doubleArr = [];
                } else {
                  singleArr.push(this.widgetArr[i]);
                  tempArr.push({
                    arr: singleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });
                  singleArr = [];
                }
              }
            } else {
              if (doubleArr.length > 0) {
                for (let i = 0; i < doubleArr.length; i++) {
                  const duplicates = doubleArr.filter(
                    (obj) => obj.id === doubleArr[i].id
                  );
                  if (duplicates.length > 1) {
                    doubleArr.splice(i + 1, 1); // remove the second object
                    i--; // decrement i to account for the removed object
                  }
                }
                tempArr.push({
                  arr: doubleArr,
                  type: this.widgetArr[i].type,
                  source: this.widgetArr[i].source,
                });

                doubleArr = [];
              } else {
                singleArr.push(this.widgetArr[i]);
                tempArr.push({
                  arr: singleArr,
                  type: this.widgetArr[i].type,
                  source: this.widgetArr[i].source,
                });
                singleArr = [];
              }
            }
          } else if (wig.source === 'reddit') {
            if (this.widgetArr[i + 1] !== undefined) {
              if (this.widgetArr[i].source === this.widgetArr[i + 1].source) {
                doubleArr.push(this.widgetArr[i]);
                doubleArr.push(this.widgetArr[i + 1]);
              } else {
                if (doubleArr.length > 0) {
                  for (let i = 0; i < doubleArr.length; i++) {
                    const duplicates = doubleArr.filter(
                      (obj) => obj.id === doubleArr[i].id
                    );
                    if (duplicates.length > 1) {
                      doubleArr.splice(i + 1, 1); // remove the second object
                      i--; // decrement i to account for the removed object
                    }
                  }
                  tempArr.push({
                    arr: doubleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });
                  doubleArr = [];
                } else {
                  singleArr.push(this.widgetArr[i]);
                  tempArr.push({
                    arr: singleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });
                  singleArr = [];
                }
              }
            } else {
              if (doubleArr.length > 0) {
                for (let i = 0; i < doubleArr.length; i++) {
                  const duplicates = doubleArr.filter(
                    (obj) => obj.id === doubleArr[i].id
                  );
                  if (duplicates.length > 1) {
                    doubleArr.splice(i + 1, 1); // remove the second object
                    i--; // decrement i to account for the removed object
                  }
                }
                tempArr.push({
                  arr: doubleArr,
                  type: this.widgetArr[i].type,
                  source: this.widgetArr[i].source,
                });

                doubleArr = [];
              } else {
                singleArr.push(this.widgetArr[i]);
                tempArr.push({
                  arr: singleArr,
                  type: this.widgetArr[i].type,
                  source: this.widgetArr[i].source,
                });
                singleArr = [];
              }
            }
          }
        } else {
          if (doubleArr.length > 1) {
            for (let i = 0; i < doubleArr.length; i++) {
              const duplicates = doubleArr.filter(
                (obj) => obj.id === doubleArr[i].id
              );
              if (duplicates.length > 1) {
                doubleArr.splice(i + 1, 1); // remove the second object
                i--; // decrement i to account for the removed object
              }
            }
            doubleArr = doubleArr.filter((obj) => obj.status !== 'draft');

            tempArr.push({
              arr: doubleArr,
              type: this.widgetArr[i - 1].type,
              source: this.widgetArr[i - 1].source,
            });

            doubleArr = [];
          }
          tempArr.push(wig);
        }
      }
    });

    this.Immersive_view_widgetArr = tempArr;
  }

  resetFields(value) {
    this.name = '';
    this.Widget_author = '';
    this.ID = null;
    this.url = '';
    this.description = '';
  }

  change_showImagnOverlay(value: boolean) {
    this.showImagnOverlay = value;
    if (this.showImagnOverlay === true) {
      this.image_keyword = '';
      this.lmt = 15;
      this.bll.reset_imagn_data();
    }
  }

  change_image() {
    this.showImagnOverlay = false;
    // this._image_url=image.previewUrl;
    document.getElementById('image_upload_widget').click();
    this.bll.reset_imagn_data();
  }

  uploadFromGallery(param) {
    if (param === null) {
      this.showImagnOverlay = false;
      // this._image_url=image.previewUrl;
      document.getElementById('image_upload_content').click();
      this.bll.reset_imagn_data();
    } else {
      this.showImagnOverlay = false;
      // this._image_url=image.previewUrl;
      document.getElementById('player-card-image-upload').click();
      this.bll.reset_imagn_data();
    }
  }

  imgSearch(event) {
    this.url = event.target.value;
  }

  //#region UPLOAD COVER METHODS -GALLERY -IMAGN

  getImageUrl(
    urlParams: string,
    metadata: any,
    keywords?: any
  ): Observable<any> {
    let outputDict: any = {
      People: '',
      League: '',
      Locations: '',
      Teams: '',
      Tag: '',
      aiObject: '',
    };

    if (keywords) {
      for (let index = 0; index < keywords.length; index++) {
        for (let indx = 0; indx < keywords[index].length; indx++) {
          let keys = Object.keys(keywords[index][indx]);
          if (keys[0] === 'People') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
          if (keys[0] === 'Locations') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
          if (keys[0] === 'League') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
          if (keys[0] === 'Teams') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
          if (keys[0] === 'Tag') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
          if (keys[0] === 'aiObject') {
            outputDict[keys[0]] =
              outputDict[keys[0]] + ' ' + keywords[index][indx][keys[0]];
          }
        }
      }
    }

    if (outputDict.People === '') {
      delete outputDict.People;
    }
    if (outputDict.League === '') {
      delete outputDict.League;
    }
    if (outputDict.Teams === '') {
      delete outputDict.Teams;
    }
    if (outputDict.Locations === '') {
      delete outputDict.Locations;
    }
    if (outputDict.Tag === '') {
      delete outputDict.Tag;
    }
    if (outputDict.aiObject === '') {
      delete outputDict.aiObject;
    }

    for (let key in outputDict) {
      if (outputDict.hasOwnProperty(key)) {
        outputDict[key] = outputDict[key].replace(/,$/, '');
      }
    }

    const sanitizedData = {};
    for (const [key, value] of Object.entries(outputDict)) {
      const sanitizedValue = String(value).replace(
        /&#x([0-9a-fA-F]+);|[^a-zA-Z0-9\s]/g,
        ' '
      );
      sanitizedData[key] = sanitizedValue;
    }

    let body = {
      meta_data: metadata,
      tags: sanitizedData,
    };

    let url = `imagn?full=true&url=${urlParams}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: this.auth.token,
    };
    return this.http.post(environment.api_url + url, body, {
      headers: new HttpHeaders(headers),
    });
  }

  param = null;
  uploadfromImagn(param) {
    this.bll.reset_imagn_data();
    this.uploadFromImagn = true;
    this.param = param;
  }

  uploadCover(img: any) {
    this.uploadFromImagn = false;

    let metaObj: any = null;
    if (this.imageSourceTab === 'unsplash') {
      metaObj = {
        uploadedBy: this.user.name,
        fileType: img.fileExtension ? img.fileExtension : '',
        fileSize: img.filesize ? img.filesize : '',
        height: img.height ? img.height : '',
        width: img.width ? img.width : '',
        altText: '',
        caption: img.description ? img.description.toString() : '',
        description: img.alt_description ? img.alt_description.toString() : '',
      };
    } else {
      metaObj = {
        uploadedBy: this.user.name,
        fileType: img.fileExtension,
        fileSize: img.filesize,
        height: img.height,
        width: img.width,
        altText: '',
        caption: img.caption.replace(/Mandatory Credit:/g, '%C2%A9').toString(),
        description: img.headline,
      };
      metaObj.caption = metaObj.caption.replace(/\n/g, '');
    }

    if (img.downloadRest !== undefined) {
      this.ionLoaderService.simpleLoader('Updating Image ...');
      this.getImageUrl(img.downloadRest, metaObj, img.keywords[0]).subscribe(
        (data) => {
          if (data.data.success === true) {
            if (this.param === null) {
              this.imageStr = data.data.message;
              this.image.setValue(this.imageStr);
              this.thumbnail.setValue(data.compress_data.message);
              this.image_credit.setValue(img.credit);
              this.image_description.setValue(
                img.caption.replace(/Mandatory Credit:/g, '©').toString()
              );

              // this.getImageURlinAxios(img.previewUrl);
              let fileUrl = this.getBase64ImageFromUrl(img.previewUrl)
                .then(async (result: any) => {
                  let file = await this.storage.dataURItoBlob(result);

                  //   this.imageStr = await this.storage.uploadFile3(file,metaObj,img.keywords[0]);
                  //  console.log('imageObj',this.imageStr)
                  //  this.imageStr = await this.storage.uploadFile3(file,metaObj);
                  //  this.image.setValue(this.imageStr);
                  this.image_credit.setValue(img.credit);
                  this.image_description.setValue(
                    img.caption.replace(/Mandatory Credit:/g, '©')
                  );
                  this.image_keyword = '';
                  this.bll.reset_imagn_data();
                  this.ionLoaderService.dismissLoader();
                })
                .catch((err) => {
                  console.error(err);
                  this.ionLoaderService.dismissLoader();
                });
            } else {
              this.playerInfo.display_picture = data.data.message;

              // this.getImageURlinAxios(img.previewUrl);
              let fileUrl = this.getBase64ImageFromUrl(img.previewUrl)
                .then(async (result: any) => {
                  let file = await this.storage.dataURItoBlob(result);

                  //   this.imageStr = await this.storage.uploadFile3(file,metaObj,img.keywords[0]);
                  //  console.log('imageObj',this.imageStr)
                  //  this.imageStr = await this.storage.uploadFile3(file,metaObj);
                  //  this.image.setValue(this.imageStr);

                  this.image_keyword = '';
                  this.bll.reset_imagn_data();
                  this.ionLoaderService.dismissLoader();
                })
                .catch((err) => {
                  console.error(err);
                  this.ionLoaderService.dismissLoader();
                });
            }
          } else if (data.data.success === false) {
            this.showToast(data.data.message);
          }

          this.image_keyword = '';
          this.bll.reset_imagn_data();
          if (this.status.value === 'prep') {
            if (this.image.value !== '' && this.image.value !== undefined) {
              //  this.returnandCheckValues(2, true);
            } else {
              //  this.returnandCheckValues(2, false);
            }
          }
        },
        (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            //this.auth.refreshtoken();
            setTimeout(() => {
              this.uploadCover(img);
            }, 1000);
          }
          console.log('err', err);
        }
      );
    }

    if (img.urls.full !== undefined) {
      this.ionLoaderService.simpleLoader('Updating Image ...');

      // this.getImageURlinAxios(img.previewUrl);
      let fileUrl = this.getBase64ImageFromUrl(img.previewUrl)
        .then(async (result: any) => {
          let file = await this.storage.dataURItoBlob(result);

          //   this.imageStr = await this.storage.uploadFile3(file,metaObj,img.keywords[0]);
          //  console.log('imageObj',this.imageStr)
          //  this.imageStr = await this.storage.uploadFile3(file,metaObj);
          //  this.image.setValue(this.imageStr);

          this.imageStr = await this.storage.uploadFile3(file, metaObj);

          setTimeout(() => {
            this.image.setValue(this.imageStr);
            this.thumbnail.setValue(this.imageStr);
            this.image_credit.setValue(img.credit);
            this.image_description.setValue(
              img.caption.replace(/Mandatory Credit:/g, '©').toString()
            );
            this.image_keyword = '';
            this.bll.reset_imagn_data();
            this.ionLoaderService.dismissLoader();
          }, 3000);
        })
        .catch((err) => {
          console.error(err);
          this.ionLoaderService.dismissLoader();
        });
    } else {
      if (this.param === null) {
        this.imageStr = img.previewUrl;
        this.image.setValue(this.imageStr);
        this.thumbnail.setValue(this.imageStr);
        this.image_credit.setValue(img.credit);
        this.image_description.setValue(
          img.caption.replace(/Mandatory Credit:/g, '©').toString()
        );
        this.bll.reset_imagn_data();
        this.image_keyword = '';
        this.imageSourceTab = 'imagn';
      } else {
        this.playerInfo.display_picture = img.previewUrl;
        this.bll.reset_imagn_data();
        this.image_keyword = '';
        this.imageSourceTab = 'imagn';
      }
    }
  }
  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  imageUrlToBase64(urL: string) {
    return this.http
      .get(urL, {
        observe: 'body',
        responseType: 'arraybuffer',
      })
      .pipe(
        take(1),
        map((arrayBuffer) =>
          btoa(
            Array.from(new Uint8Array(arrayBuffer))
              .map((b) => String.fromCharCode(b))
              .join('')
          )
        )
      );
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  async imageFileChanged(event, param) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      this.ionLoaderService.simpleLoader('Updating Image ...');
      const [file] = event.target.files;
      let file_name = '';
      file_name = await this.storage.uploadFile(file);

      reader.readAsDataURL(file);
      reader.onload = () => {
        if (param === null) {
          this.imageStr = file_name;
          this.image.setValue(this.imageStr);
          this.ionLoaderService.dismissLoader();
          this.bll.get_cropped_image(file_name, this.auth.token).subscribe(
            (data: any) => {
              this.thumbnail.setValue(data.data.message);
              if (this.status.value === 'prep') {
                if (this.image.value !== '' && this.image.value !== undefined) {
                  //  this.returnandCheckValues(2, true);
                } else {
                  //  this.returnandCheckValues(2, false);
                }
              }
            },
            (err) => {
              this.ionLoaderService.dismissLoader();
              if (err instanceof HttpErrorResponse && err.status === 401) {
                //this.auth.refreshtoken();
                setTimeout(() => {
                  this.imageFileChanged(event, param);
                }, 1000);
              }
              this.showToast(err.message);
            }
          );
        } else {
          this.playerInfo.display_picture = file_name;
          this.ionLoaderService.dismissLoader();
        }
      };
    }
  }

  convertIntoSingleYear(str) {
    // Split the string by '-' separator
    let parts1: string[] = str.split('-');

    // Check if the string is already in the desired format
    if (parts1.length !== 2) {
      return str;
    } else {
      // Extract the required parts of the string
      let year1: string = parts1[0];

      // Construct the new string
      let newString1: string = `${year1}`;

      // Print the new string

      return newString1;
    }
  }

  async uploadWidgetImage(img) {
    let fileUrl = this.getBase64ImageFromUrl(img.previewUrl)
      .then(async (result: any) => {
        let file = await this.storage.dataURItoBlob(result);

        this._image_url = await this.storage.uploadFile(file);
      })
      .catch((err) => console.error(err));
  }

  //#endregion UPLOAD COVER METHODS -GALLERY -IMAGN

  //#region SET FORM VALUES

  setValuesToForm(article: any) {
    this.title.setValue(this.regex(article.title));
    this.Title = this.title.value;
    this.post.setValue(this.regex(article.post));
    this.image_credit.setValue(article.image_credit);
    this.image_description.setValue(article.image_description);
    this.person.setValue(this.personArr?.toString());
    this.meta_description.setValue(this.metaDescriptionArr?.toString());
    this._url.setValue(article.link);
    this.is_editors_pick.setValue(article.is_editors_pick);
    this.keywords.setValue(this.keywordsArr?.toString());
    this.city.setValue(article.city);
    this.organization.setValue(article.organization);
    this.type.setValue(article.type.split(','));
    this.status.setValue(article.status);
    this.author.setValue(article.authors);
    this.original_author.setValue(article.original_author);
    this.thumbnail.setValue(article.thumbnail);
    this.page_link.setValue(article.page_link);
    if (article.published_date !== null) {
      // let date = moment(article.published_date).format()
      // console.log('date', date)
      // this.published_date.setValue(date)
      this.published_date.setValue(article.published_date);
    }

    if (article.group) {
      if (article.group[0].assigned_creator) {
        this.assignee_id.setValue(
          article.group[0]?.assigned_creator.creator_id
        );
      }
    }

    this.teams.setValue(this.selectedTeams?.toString());
    this.slug.setValue(article.slug);
    this.source.setValue(article.source);
    this.imported_from.setValue(article.imported_from);

    if (
      this.type.value.includes('content') ||
      this.type.value.includes('team')
    ) {
      this.postType = true;
    } else {
      this.postType = false;
    }

    this.blocks.setValue(this.widgetArr);
    this.sources.setValue(this.sourceArr);
    this.imageStr = article.image;
    this.image.setValue(article.image);

    this.bll.reset_checklist();
    this.bll.get_checklist(this.auth.token, this.status.value, this.article_id);
    this.bll.checklist$
      .pipe(
        filter((data) => data !== null && data.length !== 0),
        take(2)
      )
      .subscribe((data) => {
        if (this.status.value === 'prep') {
          if (this.image.value !== '' && this.image.value !== null) {
            //  this.returnandCheckValues(2, true);
          }
          if (this.selectedTeams.length > 0) {
            //  this.returnandCheckValues(6, true);
          }
          if (
            this.organization.value !== '0: Select Sports' &&
            this.organization.value !== '' &&
            this.organization.value !== null
          ) {
            //  this.returnandCheckValues(5, true);
          }
        }

        if (this.status.value === 'content-review') {
          if (this.title.value !== '' && this.title.value !== null) {
            // this.returnandCheckValues(7, true);
          }

          const regex = / {2}/g;
          const titlespacematches = this.title.value.match(regex);
          const descspacematches = this.post.value.match(regex);

          if (titlespacematches === null || descspacematches == null) {
            const regex = /[a-zA-Z]+('[a-zA-Z]+)?/g;
            const titlespellmatches = this.title.value.match(regex);
            const descspellmatches = this.post.value.match(regex);

            if (titlespellmatches !== null && descspellmatches !== null) {
              const regex = /\b#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})\b/g;
              const titlehexmatches = this.title.value.match(regex);
              const deschexmatches = this.post.value.match(regex);

              if (titlehexmatches === null && deschexmatches === null) {
                // this.returnandCheckValues(12, true);
              }
            }
          }
        }
      });

    this.generated_slug = this.slug.value;
  }

  //#endregion SET FORM VALUES

  //#region FORM GETTERS

  get title() {
    return this.form.get('title');
  }

  get group_id() {
    return this.form.get('group_id');
  }

  get assignee_id() {
    return this.form.get('assignee_id');
  }

  get post() {
    return this.form.get('post');
  }

  get image_credit() {
    return this.form.get('image_credit');
  }
  get image_description() {
    return this.form.get('image_description');
  }

  get published_date() {
    return this.form.get('published_date');
  }
  get image() {
    return this.form.get('image');
  }

  get thumbnail() {
    return this.form.get('thumbnail');
  }

  get page_link() {
    return this.form.get('page_link');
  }

  get is_editors_pick() {
    return this.form.get('is_editors_pick');
  }

  get person() {
    return this.form.get('person');
  }
  get blocks() {
    return this.form.get('blocks');
  }

  get sources() {
    return this.form.get('sources');
  }

  get _url() {
    return this.form.get('url');
  }

  get meta_description() {
    return this.form.get('meta_description');
  }

  get keywords() {
    return this.form.get('keywords');
  }

  get city() {
    return this.form.get('city');
  }

  get organization() {
    return this.form.get('organization');
  }

  get original_author() {
    return this.form.get('original_author');
  }

  get status() {
    return this.form.get('status');
  }

  get slug() {
    return this.form.get('slug');
  }

  get author() {
    return this.form.get('author');
  }

  get teams() {
    return this.form.get('teams');
  }

  get source() {
    return this.form.get('source');
  }

  get imported_from() {
    return this.form.get('imported_from');
  }

  get type() {
    return this.form.get('type');
  }

  //#endregion FORM GETTERS

  drop(event: CdkDragDrop<any[]>, imgObj) {
    if (event.dropPoint.x < screen.width && event.dropPoint.y < screen.height) {
      let img = this.images.includes(imgObj);
      if (!img) {
        this.images.push(imgObj);
      }
    }
    // console.log('image Obj',imgObj)

    // this.images.push(imgObj)
    // if (event.previousContainer === event.container) {
    //   moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    // } else {
    //   transferArrayItem(event.previousContainer.data,
    //                     event.container.data,
    //                     event.previousIndex,
    //                     event.currentIndex);
    // }
  }

  dropBlock(event: CdkDragDrop<any[]>) {
    this.dropzone = false;
    this.inndexx = -1;
    if (event.previousContainer === event.container) {
      if (event.isPointerOverContainer === true) {
        moveItemInArray(
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
        // this.widgetArr = [];
        this.Immersive_view_widgetArr.forEach((obj) => {
          if (obj.type !== 'social_comment') {
            const index = this.widgetArr.findIndex(
              (item) => item.id === obj.id
            );

            if (index !== -1) {
              // If the object with the same id exists, replace it
              this.widgetArr[index] = obj;
            } else {
              // If not, push the new object
              this.widgetArr.push(obj);
            }
          } else if (obj.type === 'social_comment') {
            const index = this.widgetArr.findIndex(
              (item) => item.id === obj.id
            );

            if (index !== -1) {
              // If the object with the same id exists, replace it
              this.widgetArr[index] = obj;
            } else {
              // If not, push the new object
              if (!obj.arr) {
                this.widgetArr.push(obj);
              }
            }
          }
        });
      }
    } else {
      if (event.isPointerOverContainer === true) {
        if (this.blocktypeSelected === 'new') {
          this.draggingNewBlock = true;
          let type = '';
          let NewBlockArr = event.previousContainer.data;
          let ImBlockArr = [];
          let newBlockIndex = event.previousIndex;

          NewBlockArr.map((block, index) => {
            if (newBlockIndex === index) {
              type = block;
            }
          });
          let obj: any = {
            id: Date.now().toString(32) + Math.random().toString(16),
            main_content: {},
            author: null,
            url: '',
            title: '',
            display_text: '',
            is_title: true,
            is_text: false,
            image_url: null,
            type: type,
            source: '',
            status: 'published',
            created_at: '',
            display_order: null,
            published_date: null,
            created_by: '',
            keywords: null,
            meta_description: null,
            content_id: this.article_id,
          };
          if (type === 'video') {
            obj.main_content = {
              video: {
                sHeight: null,
                videoFound: true,
                thumbnail: '',
                width: null,
                id: '',
                channel: '',
                channelId: '',
                publishTime: '',
                sourceName: 'Youtube',
                type: 'video',
                url: '',
                quality: null,
              },
            };
          }
          if (type === 'image') {
            obj.main_content = {
              image: {
                image: [],
                showCaptions: true,
                type: 'gallery',
              },
            };
          }
          if (type === 'wikipedia') {
            obj.source = 'wikipedia';
            obj.type = 'other';
            obj.main_content = {
              person: {
                weight: null,
                name: '',
                type: 'other',
                source: 'wikipedia',
                data: [],
              },
            };
          }
          if (type === 'twitter') {
            obj.main_content = {
              twitter: {
                data: null,
                images: [],
                id: '',
                avatar: null,
                username: null,
                url: '',
                type: null,
                weight: null,
              },
            };
          }

          if (type === 'quote') {
            obj.main_content = {
              quote: {
                text: '',
                speaker: '',
                pubName: '',
                url: null,
                canonicalSpeaker: null,
              },
            };
          }
          if (type === 'text') {
            obj.main_content = {
              text: {
                text: '',
                speaker: '',
                pubName: '',
                url: null,
                canonicalSpeaker: null,
              },
            };
          }
          if (type === 'info') {
            obj.main_content = {
              info: {
                name: '',
                date_found: '',
                most_league_titles: '',
                champion: '',
                runner_up: '',
                last_league_result: '',
                slug: '',
                id: null,
                website: '',
                history: '',
                type: null,
                flip: 'inactive',
              },
            };
          }

          if (type === 'number') {
            obj.main_content = {
              number: {
                number: null,
              },
            };
          }
          if (type === 'stats') {
            obj.main_content = {
              stats: {
                stat_type: null,
                league: '',
                type: '',
                property: '',
                obj: {
                  data: [],
                  page: 1,
                  size: 10,
                  totalRecords: 0,
                },
                filters: ['team', 'position', 'GP', 'Comp', 'Att'],
              },
            };
          }
          if (type === 'standings') {
            obj.main_content = {
              standings: {
                standing_type: null,
                league: '',
                league_conference: null,
                division: null,
                team: '',
                standing: {
                  data: [],
                  totalRecords: 0,
                  page: 1,
                  size: 50,
                },
              },
            };
          }
          if (type === 'chart') {
            obj.main_content = {
              chart: {
                league: '',
                type: null,
                property: '',
                dataobj: [],
                nameobj: [],
                size: 10,
                chartOptions: {
                  series: [],
                  chart: {
                    height: 350,
                    type: 'bar',
                    events: {},
                  },
                  colors: [],
                  plotOptions: {
                    bar: {
                      columnWidth: '45%',
                      distributed: true,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  legend: {
                    show: false,
                  },
                  grid: {
                    show: false,
                  },
                  xaxis: {
                    categories: [],
                    labels: {
                      style: {
                        colors: [],
                        fontSize: '12px',
                      },
                    },
                    convertedCatToNumeric: false,
                  },
                },
              },
            };
          }

          if (type === 'datagrid') {
            let sel_columns: any = [];
            this.selectedColumns.map((obj) => {
              sel_columns.push(obj);
            });
            let dat_grid = [];
            this.dataGridArr.map((obj) => {
              dat_grid.push(obj);
            });
            let main_obj = {
              columnsArr: this.columnsArr,
              dataGridArr: dat_grid,
              columnColor: this.columnColor,
              numOfColumns: this.numOfColumns,
              numOfRows: this.numOfRows,
              selectedColumns: sel_columns,
              showGrid: true,
              color_column: this.color_column,
              season: this.seasonVal,
              year: this.yearVal,
              all_seasons: this.complete_seasons,
              team: this.teamVal,
            };
            obj.main_content = { datagrid: main_obj };
          }

          if (type === 'upcoming_games') {
            obj.main_content = {
              upcoming_games: {
                upcoming_type: null,
                league_game_type: '',
                league: '',
                league_conference: '',
                division: '',
                team: '',
                my_sports_key: '',
                upcomings: {
                  data: [],
                  totalRecords: 0,
                  page: 1,
                  size: 6,
                },
              },
            };
          }
          if (type === 'map') {
            obj.main_content = {
              map: {
                latitude: 0,
                longitude: 0,
                zoom: 18,
                mapTypeId: 'hybrid',
              },
            };
          }
          if (type === 'player_card') {
            obj.main_content = {
              player_card: {
                flip: 'inactive',
                display_picture: '',
                name: '',
                number: '',
                height: '',
                weight: '',
                position: '',
                dob: '',
                team: '',
                team_color_1: '',
                team_color_2: '',
                slug: null,
                id: null,
                wiki_url: this.wiki_url,
                wiki_source_title: this.wiki_source_title,
              },
            };
          }
          if (type === 'list') {
            obj.main_content = {
              list: {
                listarray: [],
              },
            };
          }
          if (type === 'social_comment') {
            obj.source = 'reddit';
            let main_obj = {
              name: '',
              avatar: null,
              comment: '',
              date: '',
              weight: null,
              source: 'reddit',
              url: '',
            };
            obj.main_content = { social_comment: main_obj };
            let newObj: any = {
              source: 'reddit',
              type: 'social_comment',
              arr: [],
            };
            newObj.arr.push(obj);

            obj = newObj;
          }

          if (type !== 'social_comment') {
            this.dragObject = obj;
          } else {
            this.dragObject = obj.arr[0];
          }

          // this.widgetArr=[]
          // this.Immersive_view_widgetArr=

          // ImBlockArr = this.insertAndShift(
          //   this.Immersive_view_widgetArr,
          //   event.currentIndex,
          //   obj
          // );

          ImBlockArr = this.insertAndShift(
            this.Immersive_view_widgetArr,
            event.currentIndex,
            obj
          );

          //  this.widgetArr = [];
          this.Immersive_view_widgetArr = ImBlockArr;
          this.Immersive_view_widgetArr.map((obj) => {
            if (obj.type !== 'social_comment') {
              if (obj.status === 'draft') {
                obj.status = 'published';
              }

              const index = this.widgetArr.findIndex(
                (item) => item.id === obj.id
              );

              if (index !== -1) {
                // If the object with the same id exists, replace it
                this.widgetArr[index] = obj;
              } else {
                const previousObjId =
                  event.currentIndex > 0
                    ? this.Immersive_view_widgetArr[event.currentIndex - 1].id
                    : null;

                // Find the index of the previous object in widgetArr
                const previousIndex = previousObjId
                  ? this.widgetArr.findIndex(
                      (item) => item.id === previousObjId
                    )
                  : -1;

                // Insert the new object after the previous object in widgetArr
                if (previousIndex !== -1) {
                  this.widgetArr.splice(previousIndex + 1, 0, obj);
                } else {
                  // If the previous object doesn't exist, push the new object to the start
                  this.widgetArr.unshift(obj);
                }
              }
              // this.widgetArr.push(obj);
            } else if (obj.type === 'social_comment') {
              obj.arr.map((block) => {
                if (block.status === 'draft') {
                  block.status = 'published';
                }

                const index = this.widgetArr.findIndex(
                  (item) => item.id === block.id
                );

                if (index !== -1) {
                  // If the block with the same id exists, replace it
                  this.widgetArr[index] = block;
                } else {
                  const previousObjId =
                    event.currentIndex > 0
                      ? this.Immersive_view_widgetArr[event.currentIndex - 1].id
                      : null;

                  // Find the index of the previous object in widgetArr
                  const previousIndex = previousObjId
                    ? this.widgetArr.findIndex(
                        (item) => item.id === previousObjId
                      )
                    : -1;

                  // Insert the new object after the previous object in widgetArr
                  if (previousIndex !== -1) {
                    this.widgetArr.splice(previousIndex + 1, 0, block);
                  } else {
                    // If the previous object doesn't exist, push the new object to the start
                    this.widgetArr.unshift(block);
                  }
                }
                // this.widgetArr.push(block);
              });
            }
          });

          if (type !== 'social_comment') {
            this.editWidget(obj, obj.display_order);
          } else {
            this.editWidget(obj.arr[0], obj.arr[0].display_order);
          }
        } else {
          transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex
          );

          if (this.blocktypeSelected === 'removed') {
            this.removedBlocksArr = this.immersiveViewWidgetArr;
          }

          if (
            this.Immersive_view_widgetArr[event.currentIndex].type ===
            'social_comment'
          ) {
            const previousObject =
              this.Immersive_view_widgetArr[event.currentIndex - 1];
            if (previousObject) {
              const index = this.widgetArr.findIndex(
                (item) => item.id === previousObject.id
              );
              this.Immersive_view_widgetArr[event.currentIndex].id =
                Date.now().toString(32) + Math.random().toString(16);
              const ab = this.insertAndShift(
                this.widgetArr,
                index + 1,
                this.Immersive_view_widgetArr[event.currentIndex]
              );

              this.widgetArr = ab;
            } else {
              this.Immersive_view_widgetArr[event.currentIndex].id =
                Date.now().toString(32) + Math.random().toString(16);
              const ab = this.insertAndShift(
                this.widgetArr,
                0,
                this.Immersive_view_widgetArr[event.currentIndex]
              );

              this.widgetArr = ab;
            }
          }

          //  this.widgetArr = [];
          this.Immersive_view_widgetArr.map((obj, index) => {
            if (obj.type !== 'social_comment') {
              if (obj.status === 'draft') {
                obj.status = 'published';
              }

              const index = this.widgetArr.findIndex(
                (item) => item.id === obj.id
              );

              if (index !== -1) {
                // If the object with the same id exists, replace it
                this.widgetArr[index] = obj;
              } else {
                // If not, push the new object
                this.widgetArr.push(obj);
              }

              // this.widgetArr.push(obj);
            } else if (obj.type === 'social_comment') {
              if (obj.status === 'draft') {
                obj.status = 'published';
              }

              const index = this.widgetArr.findIndex(
                (item) => item.id === obj.id
              );

              if (index !== -1) {
                // If the object with the same id exists, replace it
                this.widgetArr[index] = obj;
              } else {
                if (this.blocktypeSelected !== 'removed') {
                  // If not, push the new object
                  // this.widgetArr.push(obj);
                }
              }
            }

            if (obj.slug) {
              this.new_obj = obj;
              this.draggingNewBlock = true;
              this.dragObject = obj;
              this.editWidget(obj, index);
            }
          });

          if (this.blocktypeSelected === 'removed') {
            let tempArr: any = [];
            let doubleArr: any = [];
            let singleArr: any = [];
            this.widgetArr.map((wig, i) => {
              if (wig.status !== 'draft') {
                if (wig.type === 'social_comment') {
                  if (wig.source === 'twitter') {
                    if (this.widgetArr[i + 1] !== undefined) {
                      if (
                        this.widgetArr[i].source ===
                        this.widgetArr[i + 1].source
                      ) {
                        doubleArr.push(this.widgetArr[i]);

                        doubleArr.push(this.widgetArr[i + 1]);
                      } else {
                        if (doubleArr.length > 0) {
                          for (let i = 0; i < doubleArr.length; i++) {
                            const duplicates = doubleArr.filter(
                              (obj) => obj.id === doubleArr[i].id
                            );
                            if (duplicates.length > 1) {
                              doubleArr.splice(i + 1, 1); // remove the second object
                              i--; // decrement i to account for the removed object
                            }
                          }
                          tempArr.push({
                            arr: doubleArr,
                            type: this.widgetArr[i].type,
                            source: this.widgetArr[i].source,
                          });

                          doubleArr = [];
                        } else {
                          singleArr.push(this.widgetArr[i]);

                          tempArr.push({
                            arr: singleArr,
                            type: this.widgetArr[i].type,
                            source: this.widgetArr[i].source,
                          });
                          singleArr = [];
                        }
                      }
                    } else {
                      if (doubleArr.length > 0) {
                        for (let i = 0; i < doubleArr.length; i++) {
                          const duplicates = doubleArr.filter(
                            (obj) => obj.id === doubleArr[i].id
                          );
                          if (duplicates.length > 1) {
                            doubleArr.splice(i + 1, 1); // remove the second object
                            i--; // decrement i to account for the removed object
                          }
                        }
                        tempArr.push({
                          arr: doubleArr,
                          type: this.widgetArr[i].type,
                          source: this.widgetArr[i].source,
                        });

                        doubleArr = [];
                      } else {
                        singleArr.push(this.widgetArr[i]);

                        tempArr.push({
                          arr: singleArr,
                          type: this.widgetArr[i].type,
                          source: this.widgetArr[i].source,
                        });
                        singleArr = [];
                      }
                    }
                  } else if (wig.source === 'reddit') {
                    if (this.widgetArr[i + 1] !== undefined) {
                      if (
                        this.widgetArr[i].source ===
                        this.widgetArr[i + 1].source
                      ) {
                        doubleArr.push(this.widgetArr[i]);

                        doubleArr.push(this.widgetArr[i + 1]);
                      } else {
                        if (doubleArr.length > 0) {
                          for (let i = 0; i < doubleArr.length; i++) {
                            const duplicates = doubleArr.filter(
                              (obj) => obj.id === doubleArr[i].id
                            );
                            if (duplicates.length > 1) {
                              doubleArr.splice(i + 1, 1); // remove the second object
                              i--; // decrement i to account for the removed object
                            }
                          }
                          tempArr.push({
                            arr: doubleArr,
                            type: this.widgetArr[i].type,
                            source: this.widgetArr[i].source,
                          });
                          doubleArr = [];
                        } else {
                          singleArr.push(this.widgetArr[i]);

                          tempArr.push({
                            arr: singleArr,
                            type: this.widgetArr[i].type,
                            source: this.widgetArr[i].source,
                          });
                          singleArr = [];
                        }
                      }
                    } else {
                      if (doubleArr.length > 0) {
                        for (let i = 0; i < doubleArr.length; i++) {
                          const duplicates = doubleArr.filter(
                            (obj) => obj.id === doubleArr[i].id
                          );
                          if (duplicates.length > 1) {
                            doubleArr.splice(i + 1, 1); // remove the second object
                            i--; // decrement i to account for the removed object
                          }
                        }
                        tempArr.push({
                          arr: doubleArr,
                          type: this.widgetArr[i].type,
                          source: this.widgetArr[i].source,
                        });

                        doubleArr = [];
                      } else {
                        singleArr.push(this.widgetArr[i]);

                        tempArr.push({
                          arr: singleArr,
                          type: this.widgetArr[i].type,
                          source: this.widgetArr[i].source,
                        });
                        singleArr = [];
                      }
                    }
                  }
                } else {
                  if (doubleArr.length > 1) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }
                    doubleArr = doubleArr.filter(
                      (obj) => obj.status !== 'draft'
                    );

                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i - 1].type,
                      source: this.widgetArr[i - 1].source,
                    });

                    doubleArr = [];
                  }
                  tempArr.push(wig);
                }
              }
            });

            this.Immersive_view_widgetArr = tempArr;
          }

          // this.Immersive_view_widgetArr.forEach((obj) => {
          //   if (obj.type !== 'social_comment') {
          //     const index = this.widgetArr.findIndex(
          //       (item) => item.id === obj.id
          //     );

          //     if (index !== -1) {
          //       // If the object with the same id exists, replace it
          //       this.widgetArr[index] = obj;
          //     } else {
          //       // If not, push the new object
          //       this.widgetArr.push(obj);
          //     }
          //   } else if (obj.type === 'social_comment') {
          //     obj.arr.forEach((block) => {
          //       const index = this.widgetArr.findIndex(
          //         (item) => item.id === block.id
          //       );

          //       if (index !== -1) {
          //         // If the block with the same id exists, replace it
          //         this.widgetArr[index] = block;
          //       } else {
          //         // If not, push the new block
          //         this.widgetArr.push(block);
          //       }
          //     });
          //   }
          // });
        }
      }
    }
  }

  insertAndShift<T>(array: T[], index: number, item: T) {
    const newArray = [...array];

    // Use splice to insert the item at the specified index
    newArray.splice(index, 0, item);

    return newArray;
  }

  reorder(event: CdkDragDrop<any[]>) {
    // let img = this.images.includes(imgObj)
    // if (!img) {
    //   this.images.push(imgObj)

    //   var b = this.images[event.previousIndex];

    //  this.images[event.previousIndex] = this.images[event.currentIndex];
    //  this.images[event.currentIndex] = b;

    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    //}
  }

  reOrder() {
    this.isEdit = !this.isEdit;
  }

  back() {
    this.router.navigate(['articles']);
  }

  showDetails(obj) {
    this.detailBox = true;
  }

  hideDetails(obj) {
    this.detailBox = false;
  }

  get_player_data() {
    this.bll.get_player_Data(this.playerKeyword, this.auth.token);
  }

  getPlayers() {
    if (this.playerKeyword && this.playerKeyword !== '') {
      this.playerOverlay = true;
      this.get_player_data();
    } else {
      this.playerOverlay = false;
      this.bll.reset_player_data();
    }
  }

  getPlayersForStats1() {
    if (this.playerKeyword1 && this.playerKeyword1 !== null) {
      this.playerOverlay2 = false;
      this.playerOverlay1 = true;
      let seasona = '';
      if (this.yearVal && this.seasonVal) {
        this.complete_seasons.map((obj) => {
          if (
            obj.display_name === this.seasonVal &&
            obj.display_year === this.yearVal
          ) {
            seasona = obj.year;
          }
        });
        if (seasona === '') {
          seasona = this.yearVal + '-' + this.seasonVal.toLowerCase();
        }
      }
      this.bll.get_playerstats(
        this.playerKeyword1,
        this.leagueVal,
        this.gameTypeVal,
        this.auth.token,
        this.property,
        seasona
      );
      // this.bll.get_player_Data(this.playerKeyword1,this.leagueID)
    } else {
      if (this.playerOverlay1) {
        this.playerOverlay1 = false;
        this.bll.reset_player_data();
      }
    }
  }

  getPlayersForStats2() {
    if (this.playerKeyword2 && this.playerKeyword2 !== null) {
      this.playerOverlay2 = true;
      this.playerOverlay1 = false;
      let seasona = '';
      if (this.yearVal && this.seasonVal) {
        this.complete_seasons.map((obj) => {
          if (
            obj.display_name === this.seasonVal &&
            obj.display_year === this.yearVal
          ) {
            seasona = obj.year;
          }
        });
        if (seasona === '') {
          seasona = this.yearVal + '-' + this.seasonVal.toLowerCase();
        }
      }
      this.bll.get_playerstats(
        this.playerKeyword2,
        this.leagueVal,
        this.gameTypeVal,
        this.auth.token,
        this.property,
        seasona
      );
    } else {
      if (this.playerOverlay2) {
        this.playerOverlay2 = false;
        this.bll.reset_player_data();
      }
    }
  }

  getPlayersForStats4(event) {
    if (event.detail.value !== '' && event.detail.value) {
      this.playerOverlay4 = true;
      this.playerData3$.subscribe((data) => {
        this.similarrankLists = [];
        if (data !== null) {
          data.forEach((obj) => {
            if (
              obj.player_name
                .toLowerCase()
                .includes(event.detail.value.toLowerCase())
            ) {
              this.similarrankLists.push(obj);
            }
          });
        }
      });
    } else {
      this.playerOverlay4 = false;
      this.similarrankLists = [];
    }
  }

  movePrev(block: any) {
    const index = this.widgetArr.findIndex((item) => item.id === block.id);
    if (index > 0) {
      const temp = this.widgetArr[index];
      this.widgetArr[index] = this.widgetArr[index - 1];
      this.widgetArr[index - 1] = temp;
      this.Immersive_view_widgetArr = [];
      let tempArr: any = [];
      let doubleArr: any = [];
      let singleArr: any = [];
      this.widgetArr.map((wig, i) => {
        if (wig.status !== 'draft') {
          if (wig.type === 'social_comment') {
            if (wig.source === 'twitter') {
              if (this.widgetArr[i + 1] !== undefined) {
                if (this.widgetArr[i].source === this.widgetArr[i + 1].source) {
                  doubleArr.push(this.widgetArr[i]);
                  doubleArr.push(this.widgetArr[i + 1]);
                } else {
                  if (doubleArr.length > 0) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }
                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });

                    doubleArr = [];
                  } else {
                    singleArr.push(this.widgetArr[i]);
                    tempArr.push({
                      arr: singleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    singleArr = [];
                  }
                }
              } else {
                if (doubleArr.length > 0) {
                  for (let i = 0; i < doubleArr.length; i++) {
                    const duplicates = doubleArr.filter(
                      (obj) => obj.id === doubleArr[i].id
                    );
                    if (duplicates.length > 1) {
                      doubleArr.splice(i + 1, 1); // remove the second object
                      i--; // decrement i to account for the removed object
                    }
                  }
                  tempArr.push({
                    arr: doubleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });

                  doubleArr = [];
                } else {
                  singleArr.push(this.widgetArr[i]);
                  tempArr.push({
                    arr: singleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });
                  singleArr = [];
                }
              }
            } else if (wig.source === 'reddit') {
              if (this.widgetArr[i + 1] !== undefined) {
                if (this.widgetArr[i].source === this.widgetArr[i + 1].source) {
                  doubleArr.push(this.widgetArr[i]);
                  doubleArr.push(this.widgetArr[i + 1]);
                } else {
                  if (doubleArr.length > 0) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }
                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    doubleArr = [];
                  } else {
                    singleArr.push(this.widgetArr[i]);
                    tempArr.push({
                      arr: singleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    singleArr = [];
                  }
                }
              } else {
                if (doubleArr.length > 0) {
                  for (let i = 0; i < doubleArr.length; i++) {
                    const duplicates = doubleArr.filter(
                      (obj) => obj.id === doubleArr[i].id
                    );
                    if (duplicates.length > 1) {
                      doubleArr.splice(i + 1, 1); // remove the second object
                      i--; // decrement i to account for the removed object
                    }
                  }
                  tempArr.push({
                    arr: doubleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });

                  doubleArr = [];
                } else {
                  singleArr.push(this.widgetArr[i]);
                  tempArr.push({
                    arr: singleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });
                  singleArr = [];
                }
              }
            }
          } else {
            if (doubleArr.length > 1) {
              for (let i = 0; i < doubleArr.length; i++) {
                const duplicates = doubleArr.filter(
                  (obj) => obj.id === doubleArr[i].id
                );
                if (duplicates.length > 1) {
                  doubleArr.splice(i + 1, 1); // remove the second object
                  i--; // decrement i to account for the removed object
                }
              }
              doubleArr = doubleArr.filter((obj) => obj.status !== 'draft');

              tempArr.push({
                arr: doubleArr,
                type: this.widgetArr[i - 1].type,
                source: this.widgetArr[i - 1].source,
              });

              doubleArr = [];
            }
            tempArr.push(wig);
          }
        }
      });

      this.Immersive_view_widgetArr = tempArr;
      setTimeout(() => {
        this.scrollToBlock(block.id);
      }, 500);
    }
  }
  moveNext(block: any) {
    const index = this.widgetArr.findIndex((item) => item.id === block.id);
    if (index < this.widgetArr.length - 1) {
      const temp = this.widgetArr[index];
      this.widgetArr[index] = this.widgetArr[index + 1];
      this.widgetArr[index + 1] = temp;

      this.Immersive_view_widgetArr = [];
      let tempArr: any = [];
      let doubleArr: any = [];
      let singleArr: any = [];
      this.widgetArr.map((wig, i) => {
        if (wig.status !== 'draft') {
          if (wig.type === 'social_comment') {
            if (wig.source === 'twitter') {
              if (this.widgetArr[i + 1] !== undefined) {
                if (this.widgetArr[i].source === this.widgetArr[i + 1].source) {
                  doubleArr.push(this.widgetArr[i]);
                  doubleArr.push(this.widgetArr[i + 1]);
                } else {
                  if (doubleArr.length > 0) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }
                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });

                    doubleArr = [];
                  } else {
                    singleArr.push(this.widgetArr[i]);
                    tempArr.push({
                      arr: singleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    singleArr = [];
                  }
                }
              } else {
                if (doubleArr.length > 0) {
                  for (let i = 0; i < doubleArr.length; i++) {
                    const duplicates = doubleArr.filter(
                      (obj) => obj.id === doubleArr[i].id
                    );
                    if (duplicates.length > 1) {
                      doubleArr.splice(i + 1, 1); // remove the second object
                      i--; // decrement i to account for the removed object
                    }
                  }
                  tempArr.push({
                    arr: doubleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });

                  doubleArr = [];
                } else {
                  singleArr.push(this.widgetArr[i]);
                  tempArr.push({
                    arr: singleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });
                  singleArr = [];
                }
              }
            } else if (wig.source === 'reddit') {
              if (this.widgetArr[i + 1] !== undefined) {
                if (this.widgetArr[i].source === this.widgetArr[i + 1].source) {
                  doubleArr.push(this.widgetArr[i]);
                  doubleArr.push(this.widgetArr[i + 1]);
                } else {
                  if (doubleArr.length > 0) {
                    for (let i = 0; i < doubleArr.length; i++) {
                      const duplicates = doubleArr.filter(
                        (obj) => obj.id === doubleArr[i].id
                      );
                      if (duplicates.length > 1) {
                        doubleArr.splice(i + 1, 1); // remove the second object
                        i--; // decrement i to account for the removed object
                      }
                    }
                    tempArr.push({
                      arr: doubleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    doubleArr = [];
                  } else {
                    singleArr.push(this.widgetArr[i]);
                    tempArr.push({
                      arr: singleArr,
                      type: this.widgetArr[i].type,
                      source: this.widgetArr[i].source,
                    });
                    singleArr = [];
                  }
                }
              } else {
                if (doubleArr.length > 0) {
                  for (let i = 0; i < doubleArr.length; i++) {
                    const duplicates = doubleArr.filter(
                      (obj) => obj.id === doubleArr[i].id
                    );
                    if (duplicates.length > 1) {
                      doubleArr.splice(i + 1, 1); // remove the second object
                      i--; // decrement i to account for the removed object
                    }
                  }
                  tempArr.push({
                    arr: doubleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });

                  doubleArr = [];
                } else {
                  singleArr.push(this.widgetArr[i]);
                  tempArr.push({
                    arr: singleArr,
                    type: this.widgetArr[i].type,
                    source: this.widgetArr[i].source,
                  });
                  singleArr = [];
                }
              }
            }
          } else {
            if (doubleArr.length > 1) {
              for (let i = 0; i < doubleArr.length; i++) {
                const duplicates = doubleArr.filter(
                  (obj) => obj.id === doubleArr[i].id
                );
                if (duplicates.length > 1) {
                  doubleArr.splice(i + 1, 1); // remove the second object
                  i--; // decrement i to account for the removed object
                }
              }
              doubleArr = doubleArr.filter((obj) => obj.status !== 'draft');

              tempArr.push({
                arr: doubleArr,
                type: this.widgetArr[i - 1].type,
                source: this.widgetArr[i - 1].source,
              });

              doubleArr = [];
            }
            tempArr.push(wig);
          }
        }
      });

      this.Immersive_view_widgetArr = tempArr;
      setTimeout(() => {
        this.scrollToBlock(block.id);
      }, 500);
    }
  }

  scrollToBlock(id) {
    let div = document.getElementById(id.toString());

    if (this.zoom_val <= 4) {
      div.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    } else {
      div.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }

  getPlayersForLL(event) {
    if (event.detail.value !== '' && event.detail.value) {
      this.playerOverlay3 = true;
      this.leagueLeaderLists = [];
      this.playerData3$.subscribe((data) => {
        data.forEach((obj) => {
          if (
            obj.player_name
              .toLowerCase()
              .includes(event.detail.value.toLowerCase())
          ) {
            this.leagueLeaderLists.push(obj);
          }
        });
      });
    } else {
      this.playerOverlay3 = false;
      this.leagueLeaderLists = [];
    }
  }

  getPlayersList() {
    if (this.playerKeyword && this.playerKeyword !== '') {
      this.playerOverlay = true;
      this.bll.get_playerstats_Data(
        this.playerKeyword,
        this.leagueVal,
        this.gameTypeVal,
        this.auth.token
      );
    } else {
      this.playerOverlay = false;
      this.bll.reset_player_data();
    }
  }

  getTeamsList() {
    if (this.teamKeyword && this.teamKeyword !== '') {
      this.teamOverlay = true;
      this.bll.get_team_Data(this.teamKeyword, this.auth.token);
    } else {
      this.teamOverlay = false;
      this.bll.reset_team_data();
    }
  }

  getDivisionsList() {
    if (this.divisionKeyword && this.divisionKeyword !== '') {
      this.divisionOverlay = true;
      this.bll.get_division_Data(this.divisionKeyword, this.auth.token);
    } else {
      this.divisionOverlay = false;
      this.bll.reset_division_data();
    }
  }

  getConferencesList() {
    if (this.conferenceKeyword && this.conferenceKeyword !== '') {
      this.conferenceOverlay = true;
      this.bll.get_conference_Data(this.conferenceKeyword, this.auth.token);
    } else {
      this.conferenceOverlay = false;
      this.bll.reset_conference_data();
    }
  }

  getLeaguesList() {
    if (this.leagueKeyword && this.leagueKeyword !== '') {
      this.leagueOverlay = true;
      this.bll.get_league_Data(this.leagueKeyword, this.auth.token);
    } else {
      this.leagueOverlay = false;
      this.bll.reset_league_data();
    }
  }

  selectPlayer(obj) {
    this.playerOverlay = false;
    this.teamOverlay = false;
    this.divisionOverlay = false;
    this.conferenceOverlay = false;
    this.leagueOverlay = false;
    this.bll.reset_player_data();
    this.bll.reset_team_data();
    this.bll.reset_division_data();
    this.bll.reset_conference_data();
    this.bll.reset_league_data();
    this.playerTeam = obj.team_name;
    this.playerLeague = obj.league ? obj.league : null;
    this.playerTeam_color_1 = obj.team_color_1;
    this.playerTeam_color_2 = obj.team_color_2;
    this.playerInfo = obj;
    this.playerName = obj.first_name + ' ' + obj.last_name;
    this.playerNumber = obj.jersey_number;
    this.playerHeight = this.regex(obj.height);
    this.playerWeight = obj.weight;
    this.playerPosition = obj.position;
    if (obj.dob) {
      const dob = obj.dob;

      if (moment(dob, 'MM-DD-YYYY', true).isValid()) {
        // If it's a valid date, calculate age and assign to playerBirth
        const age = this.calculateAge(moment(dob).local().format('MM-DD-YYYY'));
        this.playerBirth = age.toString();
      } else {
        // If it's not a valid date, assign directly to playerBirth
        this.playerBirth = this.calculateAge(dob).toString();
      }
    }
    if (
      this.playerInfo.display_picture === null ||
      this.playerInfo.display_picture === undefined
    ) {
      this.getPlayerImage(obj.slug);
    }
  }

  changeStatsType(event) {
    this.playerStatsInfo = {
      data: [],
      page: 1,
      size: 10,
      totalRecords: 0,
    };
    this.playerStatsObj = {
      data: [],
      page: 1,
      size: 10,
      totalRecords: 0,
    };
    this.playerOverlay3 = false;
    this.playerOverlay4 = false;
    this.leagueLeaderLists = [];
    this.similarrankLists = [];
  }

  selectPlayerStats(obj, num) {
    this.playerKeyword = '';

    this.playerOverlay1 = false;
    this.playerOverlay2 = false;
    this.teamOverlay = false;
    this.divisionOverlay = false;
    this.conferenceOverlay = false;
    this.leagueOverlay = false;
    this.bll.reset_player_data();
    this.bll.reset_team_data();
    this.bll.reset_division_data();
    this.bll.reset_conference_data();
    this.bll.reset_league_data();

    if (this.leagueVal === 'NBA' && this.gameTypeVal === 'offense') {
      this.gamePropertyVal = 'ptsPerGame';
    }
    if (this.leagueVal === 'NHL' && this.gameTypeVal === 'scoring') {
      this.gamePropertyVal = 'points';
    }
    if (this.leagueVal === 'NHL' && this.gameTypeVal === 'goaltending') {
      this.gamePropertyVal = 'goalsAgainstAverage';
    }
    if (this.leagueVal === 'NFL' && this.gameTypeVal === 'passing') {
      this.gamePropertyVal = 'passYards';
    }
    if (this.leagueVal === 'NFL' && this.gameTypeVal === 'rushing') {
      this.gamePropertyVal = 'rushYards';
    }
    if (this.leagueVal === 'NFL' && this.gameTypeVal === 'receiving') {
      this.gamePropertyVal = 'receptions';
    }
    if (this.leagueVal === 'NFL' && this.gameTypeVal === 'scoring') {
      this.gamePropertyVal = 'scoring';
    }
    if (this.leagueVal === 'NFL' && this.gameTypeVal === 'defense') {
      this.gamePropertyVal = 'tackleTotal';
    }
    if (this.leagueVal === 'MLB' && this.gameTypeVal === 'batting') {
      this.gamePropertyVal = 'battingAvg';
    }
    if (this.leagueVal === 'MLB' && this.gameTypeVal === 'pitching') {
      this.gamePropertyVal = 'wins';
    }

    // this.bll.get_playerstats(obj.slug, this.leagueVal, this.gameTypeVal, this.gamePropertyVal).then
    // this.playerData$.subscribe((data) => {
    //   this.playerStatsInfo = data;
    // })

    let data = [];

    let seasona = '';
    if (this.yearVal && this.seasonVal) {
      this.complete_seasons.map((obj) => {
        if (
          obj.display_name === this.seasonVal &&
          obj.display_year === this.yearVal
        ) {
          seasona = obj.year;
        }
      });
      if (seasona === '') {
        seasona = this.yearVal + '-' + this.seasonVal.toLowerCase();
      }
    }

    if (seasona === '') {
      seasona = '2022-2023-regular';
    }

    let url = `consumer?full_table_stats_v2=true&property=${this.gamePropertyVal}`;
    url += `&league=${this.leagueVal}&type=${this.gameTypeVal}&season=${seasona}`;

    url += `&player_slug=${obj.player_slug}`;

    this.get(url)
      .pipe(take(1))
      .subscribe(
        (res) => {
          const index = this.playerStatsObj.data.findIndex(
            (elem) => elem.player_id == res.data[0].player_id
          );

          if (res.data !== undefined && res.data.length !== 0 && index == -1) {
            data = res.data;
            if (this.playerStatsInfo.data !== undefined) {
              if (num === 1 && this.playerStatsInfo.data[0]) {
                this.playerStatsObj.data[0] = data[0];
                this.playerKeyword1 = data[0].player_name;
                this.playerKey1 = true;
              } else if (num === 2 && this.playerStatsInfo.data[1]) {
                this.playerStatsObj.data[1] = data[0];
                this.playerKeyword2 = data[0].player_name;
                this.playerKey2 = true;
              } else {
                this.playerStatsObj.data.push(data[0]);
                if (num === 1) {
                  this.playerKeyword1 = data[0].player_name;
                  this.playerKey1 = true;
                } else {
                  this.playerKeyword2 = data[0].player_name;
                  this.playerKey2 = true;
                }
              }
            } else {
              this.playerStatsObj.data.push(data[0]);
              this.playerKeyword1 = data[0].player_name;
              this.playerKey1 = true;
            }

            this.playerStatsObj.totalRecords = this.playerStatsObj.data.length;

            this.playerStatsObj.data = [...this.playerStatsObj.data];

            if (
              this.widgetType === 'stats' &&
              this.widgetStandingType !== 'player_stats'
            ) {
              this.StatsBlockCheckboxes = [];
              let statsBlockcheckbox = [];

              if (this.leagueVal === 'NFL' && this.gameTypeVal === 'passing') {
                this.NFLCheckboxes1.map((checkbox) => {
                  statsBlockcheckbox.push({
                    name: checkbox.name,
                    checked: checkbox.checked,
                    val: '',
                  });
                });

                statsBlockcheckbox.forEach((checkbox) => {
                  if (
                    this.gamePropertyVal === 'position' &&
                    checkbox.name === 'position'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'gamesplayed' &&
                    checkbox.name === 'gp'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'passCompletions' &&
                    checkbox.name === 'comp'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'passPct' &&
                    checkbox.name === 'pct'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'passAttempts' &&
                    checkbox.name === 'att'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'passYards' &&
                    checkbox.name === 'yds'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'passYardsPerAtt' &&
                    checkbox.name === 'y/a'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'yardspergame' &&
                    checkbox.name === 'y/g'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'passTD' &&
                    checkbox.name === 'td'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'passInt' &&
                    checkbox.name === 'int'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'passSacks' &&
                    checkbox.name === 'sk'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'passTDPct' &&
                    checkbox.name === 'td%'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'passIntPct' &&
                    checkbox.name === 'int%'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'qbRating' &&
                    checkbox.name === 'qbr'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }

                  this.StatsBlockCheckboxes.push(checkbox);
                });
              }
              if (this.leagueVal === 'NFL' && this.gameTypeVal === 'rushing') {
                this.NFLCheckboxes2.map((checkbox) => {
                  statsBlockcheckbox.push({
                    name: checkbox.name,
                    checked: checkbox.checked,
                    val: '',
                  });
                });

                statsBlockcheckbox.forEach((checkbox) => {
                  if (
                    this.gamePropertyVal === 'team' &&
                    checkbox.name === 'team'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }
                  if (
                    this.gamePropertyVal === 'position' &&
                    checkbox.name === 'position'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'gamesplayed' &&
                    checkbox.name === 'gp'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'rushAttempts' &&
                    checkbox.name === 'att'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'rushYards' &&
                    checkbox.name === 'yds'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'rushTD' &&
                    checkbox.name === 'td'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'rushAverage' &&
                    checkbox.name === 'avg'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'yardspergame' &&
                    checkbox.name === 'y/g'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'rushLng' &&
                    checkbox.name === 'lng'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'rushFumbles' &&
                    checkbox.name === 'fum'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }

                  this.StatsBlockCheckboxes.push(checkbox);
                });
              }
              if (
                this.leagueVal === 'NFL' &&
                this.gameTypeVal === 'receiving'
              ) {
                this.NFLCheckboxes3.map((checkbox) => {
                  statsBlockcheckbox.push({
                    name: checkbox.name,
                    checked: checkbox.checked,
                    val: '',
                  });
                });

                statsBlockcheckbox.forEach((checkbox) => {
                  if (
                    this.gamePropertyVal === 'position' &&
                    checkbox.name === 'position'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'gamesplayed' &&
                    checkbox.name === 'gp'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'targets' &&
                    checkbox.name === 'tgt'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'receptions' &&
                    checkbox.name === 'rec'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'recYards' &&
                    checkbox.name === 'yds'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'recAverage' &&
                    checkbox.name === 'avg'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'yardspergame' &&
                    checkbox.name === 'y/g'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'recTD' &&
                    checkbox.name === 'td'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'recLng' &&
                    checkbox.name === 'lng'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'rec1stDowns' &&
                    checkbox.name === '1stdown'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'rec20Plus' &&
                    checkbox.name === '20+'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'rec40Plus' &&
                    checkbox.name === '40+'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'recFumbles' &&
                    checkbox.name === 'fum'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }

                  this.StatsBlockCheckboxes.push(checkbox);
                });
              }
              if (this.leagueVal === 'NFL' && this.gameTypeVal === 'scoring') {
                this.NFLCheckboxes4.map((checkbox) => {
                  statsBlockcheckbox.push({
                    name: checkbox.name,
                    checked: checkbox.checked,
                    val: '',
                  });
                });

                statsBlockcheckbox.forEach((checkbox) => {
                  if (
                    this.gamePropertyVal === 'team' &&
                    checkbox.name === 'team'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }
                  if (
                    this.gamePropertyVal === 'position' &&
                    checkbox.name === 'position'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'gamesplayed' &&
                    checkbox.name === 'gp'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'scoring' &&
                    checkbox.name === 'pts'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'rush' &&
                    checkbox.name === 'rush'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'rec' &&
                    checkbox.name === 'rec'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'kr' &&
                    checkbox.name === 'kr'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'pr' &&
                    checkbox.name === 'pr'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'fum' &&
                    checkbox.name === 'fum'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'Int' &&
                    checkbox.name === 'int'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'Pa_att' &&
                    checkbox.name === 'pa att'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'Pa_m' &&
                    checkbox.name === 'pa m'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'fga' &&
                    checkbox.name === 'fga'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'fgm' &&
                    checkbox.name === 'fgm'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'two_pt' &&
                    checkbox.name === '2 pt'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'sfty' &&
                    checkbox.name === 'sfty'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }

                  this.StatsBlockCheckboxes.push(checkbox);
                });
              }
              if (
                this.leagueVal === 'NFL' &&
                this.gameTypeVal === 'tackles,interceptions,fumbles'
              ) {
                this.NFLCheckboxes5.map((checkbox) => {
                  statsBlockcheckbox.push({
                    name: checkbox.name,
                    checked: checkbox.checked,
                    val: '',
                  });
                });

                statsBlockcheckbox.forEach((checkbox) => {
                  if (
                    this.gamePropertyVal === 'team' &&
                    checkbox.name === 'team'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }
                  if (
                    this.gamePropertyVal === 'position' &&
                    checkbox.name === 'position'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'gamesplayed' &&
                    checkbox.name === 'gp'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'tackleTotal' &&
                    checkbox.name === 't (total)'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'tackleSolo' &&
                    checkbox.name === 't (solo)'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'tackleAst' &&
                    checkbox.name === 't (ast)'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'tackleForLoss' &&
                    checkbox.name === 't (tfl)'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'sacks' &&
                    checkbox.name === 's (total)'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'sackYds' &&
                    checkbox.name === 's (yds)'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'interceptions' &&
                    checkbox.name === 'i (int)'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'intYds' &&
                    checkbox.name === 'i (yds)'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'intTD' &&
                    checkbox.name === 'i (td)'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'passesDefended' &&
                    checkbox.name === 'i (pd)'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'fumForced' &&
                    checkbox.name === 'f (ff)'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'fumOppRec' &&
                    checkbox.name === 'f (rec)'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }

                  this.StatsBlockCheckboxes.push(checkbox);
                });
              }
              if (this.leagueVal === 'NBA' && this.gameTypeVal === 'offense') {
                this.NBACheckboxes.map((checkbox) => {
                  statsBlockcheckbox.push({
                    name: checkbox.name,
                    checked: checkbox.checked,
                    val: '',
                  });
                });

                statsBlockcheckbox.forEach((checkbox) => {
                  if (
                    this.gamePropertyVal === 'team' &&
                    checkbox.name === 'team'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }
                  if (
                    this.gamePropertyVal === 'position' &&
                    checkbox.name === 'position'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'gamesplayed' &&
                    checkbox.name === 'gp'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'minminutespergame' &&
                    checkbox.name === 'mpg'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'fgpct' &&
                    checkbox.name === 'fg%'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'fg3PtPct' &&
                    checkbox.name === '3p%'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'ftPct' &&
                    checkbox.name === 'ft%'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'offRebPerGame' &&
                    checkbox.name === 'orb'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'defRebPerGame' &&
                    checkbox.name === 'drb'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'rebPerGame' &&
                    checkbox.name === 'reb'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'astPerGame' &&
                    checkbox.name === 'ast'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'blkPerGame' &&
                    checkbox.name === 'blk'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'stlPerGame' &&
                    checkbox.name === 'stl'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'tovPerGame' &&
                    checkbox.name === 'tov'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'plusMinus' &&
                    checkbox.name === '+/-'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'ptsPerGame' &&
                    checkbox.name === 'pts'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }

                  this.StatsBlockCheckboxes.push(checkbox);
                });
              }
              if (this.leagueVal === 'MLB' && this.gameTypeVal === 'batting') {
                this.MLBCheckboxes1.map((checkbox) => {
                  statsBlockcheckbox.push({
                    name: checkbox.name,
                    checked: checkbox.checked,
                    val: '',
                  });
                });

                statsBlockcheckbox.forEach((checkbox) => {
                  if (
                    this.gamePropertyVal === 'team' &&
                    checkbox.name === 'team'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }
                  if (
                    this.gamePropertyVal === 'position' &&
                    checkbox.name === 'position'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'gamesplayed' &&
                    checkbox.name === 'gp'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'atBats' &&
                    checkbox.name === 'ab'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'runs' &&
                    checkbox.name === 'runs'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'h' &&
                    checkbox.name === 'hits'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'secondBaseHits' &&
                    checkbox.name === '2b'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'thirdBaseHits' &&
                    checkbox.name === '3b'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'homeruns' &&
                    checkbox.name === 'homeruns'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'runsBattedIn' &&
                    checkbox.name === 'rbi'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'stolenBases' &&
                    checkbox.name === 'sb'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'batterWalks' &&
                    checkbox.name === 'bb'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'batterStrikeouts' &&
                    checkbox.name === 'so'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'battingAvg' &&
                    checkbox.name === 'avg'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'batterOnBasePct' &&
                    checkbox.name === 'obp'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'batterSluggingPct' &&
                    checkbox.name === 'sly'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'batterOnBasePlusSluggingPct' &&
                    checkbox.name === 'ops'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }
                  this.StatsBlockCheckboxes.push(checkbox);
                });
              }
              if (this.leagueVal === 'MLB' && this.gameTypeVal === 'pitching') {
                this.MLBCheckboxes2.map((checkbox) => {
                  statsBlockcheckbox.push({
                    name: checkbox.name,
                    checked: checkbox.checked,
                    val: '',
                  });
                });

                statsBlockcheckbox.forEach((checkbox) => {
                  if (
                    this.gamePropertyVal === 'team' &&
                    checkbox.name === 'team'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }
                  if (
                    this.gamePropertyVal === 'position' &&
                    checkbox.name === 'position'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'gamesplayed' &&
                    checkbox.name === 'gp'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'wins' &&
                    checkbox.name === 'w'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'losses' &&
                    checkbox.name === 'l'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'earnedRunAvg' &&
                    checkbox.name === 'era'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'saves' &&
                    checkbox.name === 'saves'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'saveOpportunities' &&
                    checkbox.name === 'svo'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'inningsPitched' &&
                    checkbox.name === 'ip'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'hitsAllowed' &&
                    checkbox.name === 'hitsallowed'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'runsAllowed' &&
                    checkbox.name === 'runsallowed'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'earnedRunsAllowed' &&
                    checkbox.name === 'earnedrunsallowed'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'homerunsAllowed' &&
                    checkbox.name === 'hr'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'pitcherWalks' &&
                    checkbox.name === 'bb'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'pitcherStrikeouts' &&
                    checkbox.name === 'so'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'pitchingAvg' &&
                    checkbox.name === 'avg'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'walksandHitsPerInningPitched' &&
                    checkbox.name === 'whip'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }
                  this.StatsBlockCheckboxes.push(checkbox);
                });
              }
              if (
                this.leagueVal === 'NHL' &&
                this.gameTypeVal === 'goaltending'
              ) {
                this.NHLCheckboxes1.map((checkbox) => {
                  statsBlockcheckbox.push({
                    name: checkbox.name,
                    checked: checkbox.checked,
                    val: '',
                  });
                });

                statsBlockcheckbox.forEach((checkbox) => {
                  if (
                    this.gamePropertyVal === 'team' &&
                    checkbox.name === 'team'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }
                  if (
                    this.gamePropertyVal === 'position' &&
                    checkbox.name === 'position'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'gamesplayed' &&
                    checkbox.name === 'gp'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'wins' &&
                    checkbox.name === 'w'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'losses' &&
                    checkbox.name === 'losses'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'overtimeLosses' &&
                    checkbox.name === 'otl'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'goalsAgainstAverage' &&
                    checkbox.name === 'gaaverage'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'goalsAgainst' &&
                    checkbox.name === 'gagainst'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'saves' &&
                    checkbox.name === 'saves'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'savePercentage' &&
                    checkbox.name === 'svpct'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'shutouts' &&
                    checkbox.name === 'so'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }
                  this.StatsBlockCheckboxes.push(checkbox);
                });
              }
              if (this.leagueVal === 'NHL' && this.gameTypeVal === 'scoring') {
                this.NHLCheckboxes2.map((checkbox) => {
                  statsBlockcheckbox.push({
                    name: checkbox.name,
                    checked: checkbox.checked,
                    val: '',
                  });
                });

                statsBlockcheckbox.forEach((checkbox) => {
                  if (
                    this.gamePropertyVal === 'team' &&
                    checkbox.name === 'team'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }
                  if (
                    this.gamePropertyVal === 'position' &&
                    checkbox.name === 'position'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'gamesplayed' &&
                    checkbox.name === 'gp'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'goals' &&
                    checkbox.name === 'goals'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'assists' &&
                    checkbox.name === 'assists'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'points' &&
                    checkbox.name === 'pts'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'hatTricks' &&
                    checkbox.name === 'hattricks'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'plusMinus' &&
                    checkbox.name === '+/-'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'penalties' &&
                    checkbox.name === 'pen'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'penaltyMinutes' &&
                    checkbox.name === 'pim'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'pointspergame' &&
                    checkbox.name === 'p/g'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'powerplayGoals' &&
                    checkbox.name === 'pp (g)'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'powerplayAssists' &&
                    checkbox.name === 'pp (a)'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'shorthandedGoals' &&
                    checkbox.name === 'sh (g)'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  } else if (
                    this.gamePropertyVal === 'shorthandedAssists' &&
                    checkbox.name === 'sh (a)'
                  ) {
                    checkbox.val = this.gamePropertyVal;
                    checkbox.checked = true;
                  }
                  this.StatsBlockCheckboxes.push(checkbox);
                });
              }
              this.checkedList = [];

              this.StatsBlockCheckboxes.forEach((obj: any) => {
                if (obj.checked === true) {
                  if (obj.val === '') {
                    this.checkedList.push(obj.name);
                    // Push to a separate array for checked objects with non-empty 'val'
                  }
                }
              });

              this.StatsBlockCheckboxes.forEach((obj: any) => {
                if (obj.checked === true) {
                  if (
                    obj.val === this.gamePropertyVal &&
                    this.gamePropertyVal !== ''
                  ) {
                    this.checkedList.push(obj.name); // Push to a separate array for checked objects with non-empty 'val'
                  }
                }
              });
            }
          }
        },
        (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            //this.auth.refreshtoken();
            setTimeout(() => {
              this.selectPlayerStats(obj, num);
            }, 1000);
          }
          console.log('err', err);
        }
      );
  }
  selectPlayerStats3(objt) {
    this.playerKeyword3 = null;
    this.playerOverlay3 = false;
    this.spotlightPlayerSlug = objt.player_slug;
    if (
      this.spotlightSelection === 'league-leader' &&
      this.is_spotlight === true
    ) {
      if (objt) {
        const exists = this.playerStatsObj.data.some(
          (obj) => obj.player_slug === objt.player_slug
        );
        // or: const exists = array.some(obj => compareObjects(obj, targetObject));

        if (exists) {
        } else {
          if (
            parseInt(objt.rownumber) >
            parseInt(
              this.playerStatsObj.data[this.playerStatsObj.data.length - 1]
                .rownumber
            )
          ) {
            this.playerStatsObj.data.splice(
              this.playerStatsObj.data.length - 1,
              1
            );
            this.playerStatsObj.data.push(objt);
          }
        }
      }

      this.playerStatsObj.totalRecords = this.playerStatsObj.data.length;
      this.playerStatsObj.data = [...this.playerStatsObj.data];
    }
    if (
      this.spotlightSelection === 'similar-rank' &&
      this.is_spotlight === true
    ) {
      this.spotlightPlayerSlug = objt.player_slug;
      this.similarrankLists = [];
      this.playerOverlay4 = false;
      this.playerData3$.subscribe((data) => {
        data.forEach((obj, index) => {
          if (obj.player_slug === this.spotlightPlayerSlug) {
            let dataArr = [];
            if (obj.rownumber == 1) {
              if (data[index]) {
                dataArr.push(data[index]);
              }
              if (data[index + 1]) {
                dataArr.push(data[index + 1]);
              }
              if (data[index + 2]) {
                dataArr.push(data[index + 2]);
              }
              if (data[index + 3]) {
                dataArr.push(data[index + 3]);
              }
              if (data[index + 4]) {
                dataArr.push(data[index + 4]);
              }
              if (data[index + 5]) {
                dataArr.push(data[index + 5]);
              }
              if (data[index + 6]) {
                dataArr.push(data[index + 6]);
              }
            }

            if (obj.rownumber == 2) {
              if (data[index - 1]) {
                dataArr.push(data[index - 1]);
              }
              if (data[index]) {
                dataArr.push(data[index]);
              }
              if (data[index + 1]) {
                dataArr.push(data[index + 1]);
              }
              if (data[index + 2]) {
                dataArr.push(data[index + 2]);
              }
              if (data[index + 3]) {
                dataArr.push(data[index + 3]);
              }
              if (data[index + 4]) {
                dataArr.push(data[index + 4]);
              }
              if (data[index + 5]) {
                dataArr.push(data[index + 5]);
              }
            }

            if (obj.rownumber == 3) {
              if (data[index - 2]) {
                dataArr.push(data[index - 2]);
              }
              if (data[index - 1]) {
                dataArr.push(data[index - 1]);
              }
              if (data[index]) {
                dataArr.push(data[index]);
              }
              if (data[index + 1]) {
                dataArr.push(data[index + 1]);
              }
              if (data[index + 2]) {
                dataArr.push(data[index + 2]);
              }
              if (data[index + 3]) {
                dataArr.push(data[index + 3]);
              }
              if (data[index + 4]) {
                dataArr.push(data[index + 4]);
              }
            }

            if (obj.rownumber > 3) {
              if (parseInt(obj.rownumber) === data.length) {
                if (data[index - 6]) {
                  dataArr.push(data[index - 6]);
                }
                if (data[index - 5]) {
                  dataArr.push(data[index - 5]);
                }
                if (data[index - 4]) {
                  dataArr.push(data[index - 4]);
                }
                if (data[index - 3]) {
                  dataArr.push(data[index - 3]);
                }
                if (data[index - 2]) {
                  dataArr.push(data[index - 2]);
                }
                if (data[index - 1]) {
                  dataArr.push(data[index - 1]);
                }
                if (data[index]) {
                  dataArr.push(data[index]);
                }
              }

              if (parseInt(obj.rownumber) === data.length - 1) {
                if (data[index - 5]) {
                  dataArr.push(data[index - 5]);
                }
                if (data[index - 4]) {
                  dataArr.push(data[index - 4]);
                }
                if (data[index - 3]) {
                  dataArr.push(data[index - 3]);
                }
                if (data[index - 2]) {
                  dataArr.push(data[index - 2]);
                }
                if (data[index - 1]) {
                  dataArr.push(data[index - 1]);
                }
                if (data[index]) {
                  dataArr.push(data[index]);
                }
                if (data[index + 1]) {
                  dataArr.push(data[index + 1]);
                }
              }

              if (parseInt(obj.rownumber) === data.length - 2) {
                if (data[index - 4]) {
                  dataArr.push(data[index - 4]);
                }
                if (data[index - 3]) {
                  dataArr.push(data[index - 3]);
                }
                if (data[index - 2]) {
                  dataArr.push(data[index - 2]);
                }
                if (data[index - 1]) {
                  dataArr.push(data[index - 1]);
                }
                if (data[index]) {
                  dataArr.push(data[index]);
                }
                if (data[index + 1]) {
                  dataArr.push(data[index + 1]);
                }
                if (data[index + 2]) {
                  dataArr.push(data[index + 2]);
                }
              }
              if (
                parseInt(obj.rownumber) !== data.length - 2 &&
                parseInt(obj.rownumber) !== data.length - 1 &&
                parseInt(obj.rownumber) !== data.length
              ) {
                if (data[index - 3]) {
                  dataArr.push(data[index - 3]);
                }
                if (data[index - 2]) {
                  dataArr.push(data[index - 2]);
                }
                if (data[index - 1]) {
                  dataArr.push(data[index - 1]);
                }
                if (data[index]) {
                  dataArr.push(data[index]);
                }
                if (data[index + 1]) {
                  dataArr.push(data[index + 1]);
                }
                if (data[index + 2]) {
                  dataArr.push(data[index + 2]);
                }
                if (data[index + 3]) {
                  dataArr.push(data[index + 3]);
                }
              }
            }
            this.playerStatsObj.data = dataArr;
            this.playerStatsObj.totalRecords = dataArr.length;
          }
        });
      });
    }
    //   if(obj){
    //     this.playerStatsObj.data.unshift(obj);
    //   }
    //   console.log('playerStatsObj', this.playerStatsObj.data);
    //   this.playerStatsObj.totalRecords = this.playerStatsObj.data.length;
    //   this.playerStatsObj.data = [...this.playerStatsObj.data];
    // }
    //   if(obj){
    //     data.data.push(obj)
    //   }
    //   data.data.map((obj) => {
    //     this.playerStatsObj.data.push(obj);
    //   });
    // } else if (this.is_spotlight === false) {
    //   data.data.map((obj) => {
    //     this.playerStatsObj.data.push(obj);
    //   });
    // }
  }

  selectTeamStats(obj) {
    if (this.widgetType === 'standings') {
      this.Teams.map((a) => {
        if (a.my_sportsfeed_key === this.teamVal) {
          this.divisionVal = a.division_id;
        }
      });

      this.conferenceVal = null;
      this.bll
        .getStandings(this.leagueVal, this.conferenceVal, this.divisionVal)
        .subscribe((data) => {
          this.standings = data;
        });
    } else if (this.widgetType === 'upcoming_games') {
      let upcoming;
      if (this.leagueGameType === 'upcoming') {
        upcoming = 'upcoming';
      } else {
        upcoming = null;
      }
      this.Teams.map((a) => {
        if (a.id.toString() === this.teamVal) {
          this.sportsKey = a.my_sportsfeed_key;
        }
      });
      this.bll
        .getUpcomingGames(
          this.leagueVal,
          this.teamVal,
          this.conferenceVal,
          this.divisionVal,
          upcoming
        )
        .subscribe((data) => {
          this.upcomings = data;
        });
    } else {
      // this.playerOverlay = false;
      // this.teamOverlay = false;
      // this.divisionOverlay = false;
      // this.conferenceOverlay = false;
      // this.leagueOverlay = false;
      // this.bll.reset_player_data();
      // this.bll.reset_team_data();
      // this.bll.reset_division_data();
      // this.bll.reset_conference_data();
      // this.bll.reset_league_data();
      // this.OBJID = this.teamVal;

      // let seasona = '';
      // if (this.yearVal && this.seasonVal) {
      //   seasona = this.yearVal + '-' + this.seasonVal;
      // }
      // this.bll.get_teamstats(
      //   this.teamVal,
      //   this.leagueVal,
      //   this.gameTypeVal,
      //   this.gamePropertyVal,
      //   this.size,
      //   this.gameSortVal,
      //   seasona,
      //   this.playerPool,
      //   this.auth.token
      // );
      // this.playerData$.subscribe((data: any) => {
      //   console.log('team data', data);
      //   this.playerStatsObj.data = [];
      //   if (data.data !== undefined) {
      //     data.data.map((obj) => {
      //       this.playerStatsObj.data.push(obj);
      //     });

      //     this.playerStatsObj.totalRecords = this.playerStatsObj.data.length;
      //   }
      // });
      this.changeProperty(null);
    }
  }

  getDecimalValue(val, upto): string {
    let emptyVal = 0;
    return val
      ? typeof val == 'string'
        ? parseFloat(val)?.toFixed(upto)
        : val?.toFixed(upto)
      : emptyVal.toFixed(upto);
  }

  selectDivisionStats(obj) {
    if (this.widgetType === 'standings') {
      this.teamVal = null;

      this.bll
        .getStandings(this.leagueVal, this.conferenceVal, this.divisionVal)
        .subscribe((data) => {
          this.standings = data;
        });
    } else if (this.widgetType === 'upcoming_games') {
      let upcoming;
      if (this.leagueGameType === 'upcoming') {
        upcoming = 'upcoming';
      } else {
        upcoming = null;
      }
      this.sportsKey = null;
      this.teamVal = null;
      this.bll
        .getUpcomingGames(
          this.leagueVal,
          this.teamVal,
          this.conferenceVal,
          this.divisionVal,
          upcoming
        )
        .subscribe((data) => {
          this.upcomings = data;
        });
    } else {
      // console.log('data', obj);
      // this.playerOverlay = false;
      // this.teamOverlay = false;
      // this.divisionOverlay = false;
      // this.conferenceOverlay = false;
      // this.leagueOverlay = false;
      // this.bll.reset_player_data();
      // this.bll.reset_team_data();
      // this.bll.reset_division_data();
      // this.bll.reset_conference_data();
      // this.bll.reset_league_data();
      // this.OBJID = this.divisionVal;
      // console.log('division_', this.divisionVal);
      // let seasona = '';
      // if (this.yearVal && this.seasonVal) {
      //   seasona = this.yearVal + '-' + this.seasonVal;
      // }
      // this.bll.get_divisionstats(
      //   this.divisionVal,
      //   this.leagueVal,
      //   this.gameTypeVal,
      //   this.gamePropertyVal,
      //   this.size,
      //   this.gameSortVal,
      //   seasona,
      //   this.playerPool,
      //   this.auth.token
      // );
      // this.playerData$.subscribe((data: any) => {
      //   this.playerStatsObj.data = [];
      //   if (data.data !== undefined) {
      //     data.data.map((obj) => {
      //       this.playerStatsObj.data.push(obj);
      //     });

      //     this.playerStatsObj.totalRecords = this.playerStatsObj.data.length;
      //   }
      // });
      this.changeProperty(null);
    }
  }

  selectConferenceStats(obj) {
    if (this.widgetType === 'standings') {
      this.teamVal = null;

      this.bll
        .getStandings(this.leagueVal, this.conferenceVal, this.divisionVal)
        .subscribe((data) => {
          this.standings = data;
          this.getTeamList(null);
        });
    } else if (this.widgetType === 'upcoming_games') {
      let upcoming;
      if (this.leagueGameType === 'upcoming') {
        upcoming = 'upcoming';
      } else {
        upcoming = null;
      }

      this.sportsKey = null;
      this.teamVal = null;
      this.bll
        .getUpcomingGames(
          this.leagueVal,
          this.teamVal,
          this.conferenceVal,
          this.divisionVal,
          upcoming
        )
        .subscribe((data) => {
          this.upcomings = data;
          this.getTeamList(null);
        });
    } else {
      // console.log('obj', this.conferenceVal);
      // this.playerOverlay = false;
      // this.teamOverlay = false;
      // this.divisionOverlay = false;
      // this.conferenceOverlay = false;
      // this.leagueOverlay = false;
      // this.bll.reset_player_data();
      // this.bll.reset_team_data();
      // this.bll.reset_division_data();
      // this.bll.reset_conference_data();
      // this.bll.reset_league_data();
      // this.OBJID = this.conferenceVal;
      // let seasona = '';
      // if (this.yearVal && this.seasonVal) {
      //   seasona = this.yearVal + '-' + this.seasonVal;
      // }
      // this.bll.get_conferencestats(
      //   this.conferenceVal,
      //   this.leagueVal,
      //   this.gameTypeVal,
      //   this.gamePropertyVal,
      //   this.size,
      //   this.gameSortVal,
      //   seasona,
      //   this.playerPool,
      //   this.auth.token
      // );
      // this.playerData$.subscribe((data: any) => {
      //   this.playerStatsObj.data = [];
      //   if (data.data !== undefined) {
      //     data.data.map((obj) => {
      //       this.playerStatsObj.data.push(obj);
      //     });

      //     this.playerStatsObj.totalRecords = this.playerStatsObj.data.length;
      //   }
      // });
      this.changeProperty(null);
    }
  }

  UpcomingGames(event: any) {
    let upcoming;
    if (this.leagueGameType === 'upcoming') {
      upcoming = 'upcoming';
    } else {
      upcoming = null;
    }
    this.bll
      .getUpcomingGames(
        this.leagueVal,
        this.teamVal,
        this.conferenceVal,
        this.divisionVal,
        upcoming
      )
      .subscribe((data) => {
        this.upcomings = data;
      });
  }

  isSelectOpen: boolean = false;
  divisionSlug;
  selectedDivision: string = '';

  setValues() {
    this.isSelectOpen = true;
  }

  changeDivision(division) {
    this.isSelectOpen = false;
    this.selectedDivision = division;
  }

  selectLeagueStats(obj) {
    this.playerOverlay = false;
    this.teamOverlay = false;
    this.divisionOverlay = false;
    this.conferenceOverlay = false;
    this.leagueOverlay = false;
    this.bll.reset_player_data();
    this.bll.reset_team_data();
    this.bll.reset_division_data();
    this.bll.reset_conference_data();
    this.bll.reset_league_data();
    let seasona = '';
    if (this.yearVal && this.seasonVal) {
      this.complete_seasons.map((obj) => {
        if (
          obj.display_name === this.seasonVal &&
          obj.display_year === this.yearVal
        ) {
          seasona = obj.year;
        }
      });
      if (seasona === '') {
        seasona = this.yearVal + '-' + this.seasonVal.toLowerCase();
      }
    }

    if (this.spotlightSelection === 'league-leader') {
      this.bll.get_playerstats3(
        this.leagueVal,
        this.gameTypeVal,
        this.auth.token,
        seasona,
        this.gameSortVal,
        this.gamePropertyVal
      );
    }
    this.bll.get_leaguestats(
      this.leagueVal,
      this.gameTypeVal,
      this.gamePropertyVal,
      this.size,
      this.gameSortVal,
      seasona,
      this.playerPool,
      this.auth.token
    );
    this.playerData$.subscribe((data: any) => {
      this.playerStatsObj.data = [];
      if (data.data !== undefined) {
        data.data.map((obj) => {
          this.playerStatsObj.data.push(obj);
        });

        this.playerStatsObj.totalRecords = this.playerStatsObj.data.length;
      }
    });
  }

  getPlayerImage(slug) {
    this.bll.get_player_Image(slug, this.auth.token).subscribe((data) => {
      if (data !== undefined || data !== null) {
        let imgObj = data.data[0][0];
        this.playerInfo.display_picture = imgObj.previewUrl;
      }
    });
  }

  getTeams() {
    if (this.teamKeyword && this.teamKeyword !== '') {
      this.teamOverlay = true;
      this.bll.get_team_Data(this.teamKeyword, this.auth.token);
    } else {
      this.teamOverlay = false;
      this.bll.reset_team_data();
    }
  }

  selectTeam(obj) {
    this.playerOverlay = false;
    this.teamOverlay = false;
    this.divisionOverlay = false;
    this.conferenceOverlay = false;
    this.leagueOverlay = false;
    this.bll.reset_player_data();
    this.bll.reset_team_data();
    this.bll.reset_division_data();
    this.bll.reset_conference_data();
    this.bll.reset_league_data();
    this.teamInfo = obj;
    this.teamName = obj.name;
    this.teamFounded = obj.date_found;
    this.teamTotalTitles = obj.championships;
    this.teamLastTitle = obj.last_championship;
    this.teamStadium = obj.stadium;
    this.teamLoc = obj.city;
    this.teamCapacity = obj.stadium_capacity;
    this.teamHeadCoach = obj.head_coach;
  }

  getLeagues() {
    if (this.leagueKeyword && this.leagueKeyword !== '') {
      this.leagueOverlay = true;
      this.bll.get_league_Data(this.leagueKeyword, this.auth.token);
    } else {
      this.leagueOverlay = false;
      this.bll.reset_league_data();
    }
  }

  selectLeague(obj) {
    this.playerOverlay = false;
    this.teamOverlay = false;
    this.divisionOverlay = false;
    this.conferenceOverlay = false;
    this.leagueOverlay = false;
    this.bll.reset_player_data();
    this.bll.reset_team_data();
    this.bll.reset_division_data();
    this.bll.reset_conference_data();
    this.bll.reset_league_data();
    this.leagueInfo = obj;
    this.leagueName = obj.name;
    this.leagueFounded = obj.date_found;
    this.leagueTotalTitles = obj.most_league_titles;
    this.leagueLastTitle = obj.champion;
    this.leagueRunnerUp = obj.runner_up;
    this.leagueResults = obj.last_league_result;
  }

  getDivisions() {
    if (this.divisionKeyword && this.divisionKeyword !== '') {
      this.divisionOverlay = true;
      this.bll.get_division_Data(this.divisionKeyword, this.auth.token);
    } else {
      this.divisionOverlay = false;
      this.bll.reset_division_data();
    }
  }

  selectDivision(obj) {
    this.playerOverlay = false;
    this.teamOverlay = false;
    this.divisionOverlay = false;
    this.conferenceOverlay = false;
    this.leagueOverlay = false;
    this.bll.reset_player_data();
    this.bll.reset_team_data();
    this.bll.reset_division_data();
    this.bll.reset_conference_data();
    this.bll.reset_league_data();
    this.divisionInfo = obj;
    this.divisionName = obj.name;
    this.divisionFounded = obj.date_found;
    this.divisionTotalTitles = obj.most_titles;

    this.divisionLastTitle = obj.champion;
  }

  getConferences() {
    if (this.conferenceKeyword && this.conferenceKeyword !== '') {
      this.conferenceOverlay = true;
      this.bll.get_conference_Data(this.conferenceKeyword, this.auth.token);
    } else {
      this.conferenceOverlay = false;
      this.bll.reset_conference_data();
    }
  }

  selectConference(obj) {
    this.playerOverlay = false;
    this.teamOverlay = false;
    this.divisionOverlay = false;
    this.conferenceOverlay = false;
    this.leagueOverlay = false;
    this.bll.reset_player_data();
    this.bll.reset_team_data();
    this.bll.reset_division_data();
    this.bll.reset_conference_data();
    this.bll.reset_league_data();
    this.conferenceInfo = obj;
    this.conferenceName = obj.name;
    this.conferenceFounded = obj.date_found;

    this.conferenceLastTitle = obj.champion;
    this.conferenceRunnerUp = obj.runner_up;
    this.conferenceResult = obj.last_conference_result;
  }

  getHeaderClass = ({ column }): any => {
    let colProperty = column.prop.includes('.')
      ? column.prop.split('.')
      : ['property', column.prop];
    return colProperty[colProperty.length - 1] === this.property
      ? ' selected'
      : ' not-selected';
  };

  getCellClass = ({ column }): any => {
    let colProperty = column.prop.includes('.')
      ? column.prop.split('.')
      : ['property', column.prop];
    return colProperty[colProperty.length - 1] == this.property
      ? 'bg-grey-light'
      : 'bg-grey-lighter';
  };
  getHeaderClass2 = ({ column }): any => {
    let colProperty = column.prop.includes('.')
      ? column.prop.split('.')
      : ['property', column.prop];
    return colProperty[colProperty.length - 1] === this.gamePropertyVal
      ? ' selected'
      : ' not-selected';
  };

  getCellClass2 = ({ column }): any => {
    let colProperty = column.prop.includes('.')
      ? column.prop.split('.')
      : ['property', column.prop];
    return colProperty[colProperty.length - 1] == this.gamePropertyVal
      ? 'bg-grey-light'
      : 'bg-grey-lighter';
  };
  getRowClass = (row: any) => {
    if (
      this.spotlightSelection === 'similar-rank' &&
      this.is_spotlight === true
    ) {
      if (row.player_slug === this.spotlightPlayerSlug) {
        return {
          borderTop: true,
          borderBottom: true,
          highlight: true,
        };
      }
    }
    if (
      this.spotlightSelection === 'league-leader' &&
      this.is_spotlight === true
    ) {
      if (row.player_slug === this.spotlightPlayerSlug) {
        return {
          'bg-gray-100': true,
          borderTop: true,
        };
      } else {
        return {
          'bg-grey-lighter': true,
        };
      }
    } else {
      return {
        'bg-grey-lighter': true,
      };
    }
  };

  getRowClass2 = (row: any, item: any) => {
    if (row.spotlightSlug === item.player_slug) {
      if (row.spotlightSelection === 'similar-rank') {
        return {
          borderTop: true,
          borderBottom: true,
          highlight: true,
        };
      }
      if (row.spotlightSelection === 'league-leader') {
        return {
          borderTop: true,
          'bg-gray-100': true,
        };
      }
    } else {
      return {
        'bg-grey-lighter': true,
      };
    }
  };

  getDecimalVal(val, upto) {
    let emptyVal = 0;
    return val ? val?.toFixed(upto) : emptyVal.toFixed(upto);
  }

  convertDate(date): Date {
    return new Date(date);
  }

  comaSeperatedNumb(val) {
    return val?.toLocaleString();
  }

  decimalLongNumbs(val) {
    return val?.toLocaleString(undefined, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });
  }

  checked(checkbox) {
    if (
      checkbox.checked === true &&
      !this.checkedList.includes(checkbox.name)
    ) {
      if (this.checkedList.length === 0) {
        this.checkedList.push(checkbox.name);
      } else if (this.checkedList.length > 0) {
        let secondLastIndex = this.checkedList.length - 1;

        // Insert the element into the second last position
        this.checkedList.splice(secondLastIndex, 0, checkbox.name);
      }
    } else if (checkbox.checked === false) {
      if (this.checkedList.includes(checkbox.name)) {
        this.checkedList.splice(this.checkedList.indexOf(checkbox.name), 1);
      }
    }
  }

  changeType(event) {
    this.bll.reset_player_data();
    if (this.widgetStatType === 'player_stats') {
      this.playerKey1 = false;
      this.playerKey2 = false;
      this.playerKeyword1 = null;
      this.playerKeyword2 = null;
      this.playerStatsObj.data = [];
      this.playerStatsObj.totalRecords = 0;
    }
    if (this.checkedList.length === 0) {
      this.StatsBlockCheckboxes = [];
      let statsBlockcheckbox = [];

      if (this.leagueVal === 'NFL' && this.gameTypeVal === 'passing') {
        this.NFLCheckboxes1.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passCompletions' &&
            checkbox.name === 'comp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passPct' &&
            checkbox.name === 'pct'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passAttempts' &&
            checkbox.name === 'att'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passYards' &&
            checkbox.name === 'yds'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passYardsPerAtt' &&
            checkbox.name === 'y/a'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'yardspergame' &&
            checkbox.name === 'y/g'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passTD' &&
            checkbox.name === 'td'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passInt' &&
            checkbox.name === 'int'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passSacks' &&
            checkbox.name === 'sk'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passTDPct' &&
            checkbox.name === 'td%'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passIntPct' &&
            checkbox.name === 'int%'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'qbRating' &&
            checkbox.name === 'qbr'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }

          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NFL' && this.gameTypeVal === 'rushing') {
        this.NFLCheckboxes2.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushAttempts' &&
            checkbox.name === 'att'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushYards' &&
            checkbox.name === 'yds'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushTD' &&
            checkbox.name === 'td'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushAverage' &&
            checkbox.name === 'avg'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'yardspergame' &&
            checkbox.name === 'y/g'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushLng' &&
            checkbox.name === 'lng'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushFumbles' &&
            checkbox.name === 'fum'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }

          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NFL' && this.gameTypeVal === 'receiving') {
        this.NFLCheckboxes3.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'targets' &&
            checkbox.name === 'tgt'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'receptions' &&
            checkbox.name === 'rec'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'recYards' &&
            checkbox.name === 'yds'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'recAverage' &&
            checkbox.name === 'avg'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'yardspergame' &&
            checkbox.name === 'y/g'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'recTD' &&
            checkbox.name === 'td'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'recLng' &&
            checkbox.name === 'lng'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rec1stDowns' &&
            checkbox.name === '1stdown'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rec20Plus' &&
            checkbox.name === '20+'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rec40Plus' &&
            checkbox.name === '40+'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'recFumbles' &&
            checkbox.name === 'fum'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }

          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NFL' && this.gameTypeVal === 'scoring') {
        this.NFLCheckboxes4.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'scoring' &&
            checkbox.name === 'pts'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rush' &&
            checkbox.name === 'rush'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rec' &&
            checkbox.name === 'rec'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (this.gamePropertyVal === 'kr' && checkbox.name === 'kr') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (this.gamePropertyVal === 'pr' && checkbox.name === 'pr') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fum' &&
            checkbox.name === 'fum'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'Int' &&
            checkbox.name === 'int'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'Pa_att' &&
            checkbox.name === 'pa att'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'Pa_m' &&
            checkbox.name === 'pa m'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fga' &&
            checkbox.name === 'fga'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fgm' &&
            checkbox.name === 'fgm'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'two_pt' &&
            checkbox.name === '2 pt'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'sfty' &&
            checkbox.name === 'sfty'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }

          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (
        this.leagueVal === 'NFL' &&
        (this.gameTypeVal === 'defense' ||
          this.gameTypeVal === 'tackles' ||
          this.gameTypeVal === 'interceptions' ||
          this.gameTypeVal === 'fumbles' ||
          this.gameTypeVal === 'tackles,interceptions,fumbles')
      ) {
        this.NFLCheckboxes5.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'tackleTotal' &&
            checkbox.name === 't (total)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'tackleSolo' &&
            checkbox.name === 't (solo)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'tackleAst' &&
            checkbox.name === 't (ast)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'tackleForLoss' &&
            checkbox.name === 't (tfl)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'sacks' &&
            checkbox.name === 's (total)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'sackYds' &&
            checkbox.name === 's (yds)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'interceptions' &&
            checkbox.name === 'i (int)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'intYds' &&
            checkbox.name === 'i (yds)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'intTD' &&
            checkbox.name === 'i (td)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passesDefended' &&
            checkbox.name === 'i (pd)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fumForced' &&
            checkbox.name === 'f (ff)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fumOppRec' &&
            checkbox.name === 'f (rec)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }

          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NBA' && this.gameTypeVal === 'offense') {
        this.NBACheckboxes.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'minminutespergame' &&
            checkbox.name === 'mpg'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fgpct' &&
            checkbox.name === 'fg%'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fg3PtPct' &&
            checkbox.name === '3p%'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'ftPct' &&
            checkbox.name === 'ft%'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'offRebPerGame' &&
            checkbox.name === 'orb'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'defRebPerGame' &&
            checkbox.name === 'drb'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rebPerGame' &&
            checkbox.name === 'reb'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'astPerGame' &&
            checkbox.name === 'ast'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'blkPerGame' &&
            checkbox.name === 'blk'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'stlPerGame' &&
            checkbox.name === 'stl'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'tovPerGame' &&
            checkbox.name === 'tov'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'plusMinus' &&
            checkbox.name === '+/-'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'ptsPerGame' &&
            checkbox.name === 'pts'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }

          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'MLB' && this.gameTypeVal === 'batting') {
        this.MLBCheckboxes1.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'atBats' &&
            checkbox.name === 'ab'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'runs' &&
            checkbox.name === 'runs'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (this.gamePropertyVal === 'h' && checkbox.name === 'hits') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'secondBaseHits' &&
            checkbox.name === '2b'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'thirdBaseHits' &&
            checkbox.name === '3b'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'homeruns' &&
            checkbox.name === 'homeruns'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'runsBattedIn' &&
            checkbox.name === 'rbi'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'stolenBases' &&
            checkbox.name === 'sb'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'batterWalks' &&
            checkbox.name === 'bb'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'batterStrikeouts' &&
            checkbox.name === 'so'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'battingAvg' &&
            checkbox.name === 'avg'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'batterOnBasePct' &&
            checkbox.name === 'obp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'batterSluggingPct' &&
            checkbox.name === 'sly'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'batterOnBasePlusSluggingPct' &&
            checkbox.name === 'ops'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'MLB' && this.gameTypeVal === 'pitching') {
        this.MLBCheckboxes2.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (this.gamePropertyVal === 'wins' && checkbox.name === 'w') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'losses' &&
            checkbox.name === 'l'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'earnedRunAvg' &&
            checkbox.name === 'era'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'saves' &&
            checkbox.name === 'saves'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'saveOpportunities' &&
            checkbox.name === 'svo'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'inningsPitched' &&
            checkbox.name === 'ip'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'hitsAllowed' &&
            checkbox.name === 'hitsallowed'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'runsAllowed' &&
            checkbox.name === 'runsallowed'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'earnedRunsAllowed' &&
            checkbox.name === 'earnedrunsallowed'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'homerunsAllowed' &&
            checkbox.name === 'hr'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'pitcherWalks' &&
            checkbox.name === 'bb'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'pitcherStrikeouts' &&
            checkbox.name === 'so'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'pitchingAvg' &&
            checkbox.name === 'avg'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'walksandHitsPerInningPitched' &&
            checkbox.name === 'whip'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NHL' && this.gameTypeVal === 'goaltending') {
        this.NHLCheckboxes1.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (this.gamePropertyVal === 'wins' && checkbox.name === 'w') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'losses' &&
            checkbox.name === 'losses'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'overtimeLosses' &&
            checkbox.name === 'otl'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'goalsAgainstAverage' &&
            checkbox.name === 'gaaverage'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'goalsAgainst' &&
            checkbox.name === 'gagainst'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'saves' &&
            checkbox.name === 'saves'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'savePercentage' &&
            checkbox.name === 'svpct'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'shutouts' &&
            checkbox.name === 'so'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NHL' && this.gameTypeVal === 'scoring') {
        this.NHLCheckboxes2.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'goals' &&
            checkbox.name === 'goals'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'assists' &&
            checkbox.name === 'assists'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'points' &&
            checkbox.name === 'pts'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'hatTricks' &&
            checkbox.name === 'hattricks'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'plusMinus' &&
            checkbox.name === '+/-'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'penalties' &&
            checkbox.name === 'pen'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'penaltyMinutes' &&
            checkbox.name === 'pim'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'pointspergame' &&
            checkbox.name === 'p/g'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'powerplayGoals' &&
            checkbox.name === 'pp (g)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'powerplayAssists' &&
            checkbox.name === 'pp (a)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'shorthandedGoals' &&
            checkbox.name === 'sh (g)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'shorthandedAssists' &&
            checkbox.name === 'sh (a)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      this.checkedList = [];

      this.StatsBlockCheckboxes.forEach((obj: any) => {
        if (obj.checked === true) {
          if (obj.val === '') {
            this.checkedList.push(obj.name);
            // Push to a separate array for checked objects with non-empty 'val'
          }
        }
      });

      this.StatsBlockCheckboxes.forEach((obj: any) => {
        if (obj.checked === true) {
          if (obj.val === this.gamePropertyVal && this.gamePropertyVal !== '') {
            this.checkedList.push(obj.name); // Push to a separate array for checked objects with non-empty 'val'
          }
        }
      });
    }

    if (this.widgetType === 'chart' || this.widgetType === 'datagrid') {
      this.changeProperty(null);
    }
  }

  changeProperty(event) {
    this.bll.reset_player_data();
    this.bll.reset_team_data();
    this.bll.reset_division_data();
    this.bll.reset_conference_data();
    this.bll.reset_league_data();

    this.playerOverlay = false;
    this.teamOverlay = false;
    this.divisionOverlay = false;
    this.conferenceOverlay = false;
    this.leagueOverlay = false;
    if (
      this.widgetType === 'stats' &&
      this.widgetStandingType !== 'player_stats'
    ) {
      this.StatsBlockCheckboxes = [];
      let statsBlockcheckbox = [];

      if (this.leagueVal === 'NFL' && this.gameTypeVal === 'passing') {
        this.NFLCheckboxes1.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passCompletions' &&
            checkbox.name === 'comp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passPct' &&
            checkbox.name === 'pct'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passAttempts' &&
            checkbox.name === 'att'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passYards' &&
            checkbox.name === 'yds'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passYardsPerAtt' &&
            checkbox.name === 'y/a'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'yardspergame' &&
            checkbox.name === 'y/g'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passTD' &&
            checkbox.name === 'td'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passInt' &&
            checkbox.name === 'int'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passSacks' &&
            checkbox.name === 'sk'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passTDPct' &&
            checkbox.name === 'td%'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passIntPct' &&
            checkbox.name === 'int%'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'qbRating' &&
            checkbox.name === 'qbr'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }

          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NFL' && this.gameTypeVal === 'rushing') {
        this.NFLCheckboxes2.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushAttempts' &&
            checkbox.name === 'att'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushYards' &&
            checkbox.name === 'yds'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushTD' &&
            checkbox.name === 'td'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushAverage' &&
            checkbox.name === 'avg'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'yardspergame' &&
            checkbox.name === 'y/g'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushLng' &&
            checkbox.name === 'lng'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rushFumbles' &&
            checkbox.name === 'fum'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }

          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NFL' && this.gameTypeVal === 'receiving') {
        this.NFLCheckboxes3.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'targets' &&
            checkbox.name === 'tgt'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'receptions' &&
            checkbox.name === 'rec'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'recYards' &&
            checkbox.name === 'yds'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'recAverage' &&
            checkbox.name === 'avg'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'yardspergame' &&
            checkbox.name === 'y/g'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'recTD' &&
            checkbox.name === 'td'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'recLng' &&
            checkbox.name === 'lng'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rec1stDowns' &&
            checkbox.name === '1stdown'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rec20Plus' &&
            checkbox.name === '20+'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rec40Plus' &&
            checkbox.name === '40+'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'recFumbles' &&
            checkbox.name === 'fum'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }

          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NFL' && this.gameTypeVal === 'scoring') {
        this.NFLCheckboxes4.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'scoring' &&
            checkbox.name === 'pts'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rush' &&
            checkbox.name === 'rush'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rec' &&
            checkbox.name === 'rec'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (this.gamePropertyVal === 'kr' && checkbox.name === 'kr') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (this.gamePropertyVal === 'pr' && checkbox.name === 'pr') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fum' &&
            checkbox.name === 'fum'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'Int' &&
            checkbox.name === 'int'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'Pa_att' &&
            checkbox.name === 'pa att'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'Pa_m' &&
            checkbox.name === 'pa m'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fga' &&
            checkbox.name === 'fga'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fgm' &&
            checkbox.name === 'fgm'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'two_pt' &&
            checkbox.name === '2 pt'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'sfty' &&
            checkbox.name === 'sfty'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }

          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (
        this.leagueVal === 'NFL' &&
        this.gameTypeVal === 'tackles,interceptions,fumbles'
      ) {
        this.NFLCheckboxes5.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'tackleTotal' &&
            checkbox.name === 't (total)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'tackleSolo' &&
            checkbox.name === 't (solo)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'tackleAst' &&
            checkbox.name === 't (ast)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'tackleForLoss' &&
            checkbox.name === 't (tfl)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'sacks' &&
            checkbox.name === 's (total)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'sackYds' &&
            checkbox.name === 's (yds)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'interceptions' &&
            checkbox.name === 'i (int)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'intYds' &&
            checkbox.name === 'i (yds)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'intTD' &&
            checkbox.name === 'i (td)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'passesDefended' &&
            checkbox.name === 'i (pd)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fumForced' &&
            checkbox.name === 'f (ff)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fumOppRec' &&
            checkbox.name === 'f (rec)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }

          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NBA' && this.gameTypeVal === 'offense') {
        this.NBACheckboxes.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'minminutespergame' &&
            checkbox.name === 'mpg'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fgpct' &&
            checkbox.name === 'fg%'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'fg3PtPct' &&
            checkbox.name === '3p%'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'ftPct' &&
            checkbox.name === 'ft%'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'offRebPerGame' &&
            checkbox.name === 'orb'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'defRebPerGame' &&
            checkbox.name === 'drb'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'rebPerGame' &&
            checkbox.name === 'reb'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'astPerGame' &&
            checkbox.name === 'ast'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'blkPerGame' &&
            checkbox.name === 'blk'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'stlPerGame' &&
            checkbox.name === 'stl'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'tovPerGame' &&
            checkbox.name === 'tov'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'plusMinus' &&
            checkbox.name === '+/-'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'ptsPerGame' &&
            checkbox.name === 'pts'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }

          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'MLB' && this.gameTypeVal === 'batting') {
        this.MLBCheckboxes1.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'atBats' &&
            checkbox.name === 'ab'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'runs' &&
            checkbox.name === 'runs'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (this.gamePropertyVal === 'h' && checkbox.name === 'hits') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'secondBaseHits' &&
            checkbox.name === '2b'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'thirdBaseHits' &&
            checkbox.name === '3b'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'homeruns' &&
            checkbox.name === 'homeruns'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'runsBattedIn' &&
            checkbox.name === 'rbi'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'stolenBases' &&
            checkbox.name === 'sb'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'batterWalks' &&
            checkbox.name === 'bb'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'batterStrikeouts' &&
            checkbox.name === 'so'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'battingAvg' &&
            checkbox.name === 'avg'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'batterOnBasePct' &&
            checkbox.name === 'obp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'batterSluggingPct' &&
            checkbox.name === 'sly'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'batterOnBasePlusSluggingPct' &&
            checkbox.name === 'ops'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'MLB' && this.gameTypeVal === 'pitching') {
        this.MLBCheckboxes2.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (this.gamePropertyVal === 'wins' && checkbox.name === 'w') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'losses' &&
            checkbox.name === 'l'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'earnedRunAvg' &&
            checkbox.name === 'era'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'saves' &&
            checkbox.name === 'saves'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'saveOpportunities' &&
            checkbox.name === 'svo'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'inningsPitched' &&
            checkbox.name === 'ip'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'hitsAllowed' &&
            checkbox.name === 'hitsallowed'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'runsAllowed' &&
            checkbox.name === 'runsallowed'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'earnedRunsAllowed' &&
            checkbox.name === 'earnedrunsallowed'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'homerunsAllowed' &&
            checkbox.name === 'hr'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'pitcherWalks' &&
            checkbox.name === 'bb'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'pitcherStrikeouts' &&
            checkbox.name === 'so'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'pitchingAvg' &&
            checkbox.name === 'avg'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'walksandHitsPerInningPitched' &&
            checkbox.name === 'whip'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NHL' && this.gameTypeVal === 'goaltending') {
        this.NHLCheckboxes1.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (this.gamePropertyVal === 'wins' && checkbox.name === 'w') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'losses' &&
            checkbox.name === 'losses'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'overtimeLosses' &&
            checkbox.name === 'otl'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'goalsAgainstAverage' &&
            checkbox.name === 'gaaverage'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'goalsAgainst' &&
            checkbox.name === 'gagainst'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'saves' &&
            checkbox.name === 'saves'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'savePercentage' &&
            checkbox.name === 'svpct'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'shutouts' &&
            checkbox.name === 'so'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      if (this.leagueVal === 'NHL' && this.gameTypeVal === 'scoring') {
        this.NHLCheckboxes2.map((checkbox) => {
          statsBlockcheckbox.push({
            name: checkbox.name,
            checked: checkbox.checked,
            val: '',
          });
        });

        statsBlockcheckbox.forEach((checkbox) => {
          if (this.gamePropertyVal === 'team' && checkbox.name === 'team') {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          if (
            this.gamePropertyVal === 'position' &&
            checkbox.name === 'position'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'gamesplayed' &&
            checkbox.name === 'gp'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'goals' &&
            checkbox.name === 'goals'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'assists' &&
            checkbox.name === 'assists'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'points' &&
            checkbox.name === 'pts'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'hatTricks' &&
            checkbox.name === 'hattricks'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'plusMinus' &&
            checkbox.name === '+/-'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'penalties' &&
            checkbox.name === 'pen'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'penaltyMinutes' &&
            checkbox.name === 'pim'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'pointspergame' &&
            checkbox.name === 'p/g'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'powerplayGoals' &&
            checkbox.name === 'pp (g)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'powerplayAssists' &&
            checkbox.name === 'pp (a)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'shorthandedGoals' &&
            checkbox.name === 'sh (g)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          } else if (
            this.gamePropertyVal === 'shorthandedAssists' &&
            checkbox.name === 'sh (a)'
          ) {
            checkbox.val = this.gamePropertyVal;
            checkbox.checked = true;
          }
          this.StatsBlockCheckboxes.push(checkbox);
        });
      }
      this.checkedList = [];
      this.StatsBlockCheckboxes.forEach((obj: any) => {
        if (obj.checked === true) {
          if (obj.val === '') {
            this.checkedList.push(obj.name);
            // Push to a separate array for checked objects with non-empty 'val'
          }
        }
      });

      this.StatsBlockCheckboxes.forEach((obj: any) => {
        if (obj.checked === true) {
          if (obj.val === this.gamePropertyVal && this.gamePropertyVal !== '') {
            this.checkedList.push(obj.name); // Push to a separate array for checked objects with non-empty 'val'
          }
        }
      });
    }

    if (this.widgetStatType === 'team_stats') {
      let seasona = '';
      if (this.yearVal && this.seasonVal) {
        this.complete_seasons.map((obj) => {
          if (
            obj.display_name === this.seasonVal &&
            obj.display_year === this.yearVal
          ) {
            seasona = obj.year;
          }
        });
        if (seasona === '') {
          seasona = this.yearVal + '-' + this.seasonVal.toLowerCase();
        }
      }
      if (this.spotlightSelection === 'similar-rank') {
        this.spotlightPlayerSlug = '';
        this.bll.reset_player_data();
        this.getPlayersForStats3();
      }

      if (this.spotlightSelection === 'league-leader') {
        this.spotlightPlayerSlug = '';
        this.bll.get_playerstats_team3(
          this.teamVal,
          this.leagueVal,
          this.gameTypeVal,
          this.auth.token,
          seasona,
          this.gameSortVal,
          this.gamePropertyVal
        );
      }

      this.bll.get_teamstats(
        this.teamVal,
        this.leagueVal,
        this.gameTypeVal,
        this.gamePropertyVal,
        this.size,
        this.gameSortVal,
        seasona,
        this.playerPool,
        this.auth.token
      );
      this.playerData$.subscribe((data: any) => {
        this.playerStatsObj.data = [];
        if (data.data !== undefined) {
          if (data.data.length > 0) {
            // const delimiterPos = data.data[0].type.lastIndexOf('-');
            // this.yearVal = data.data[0].type.substring(0, delimiterPos);
            // this.seasonVal = data.data[0].type.substring(delimiterPos + 1);
          }

          // if (
          //   this.spotlightSelection === 'league-leader' &&
          //   this.is_spotlight === true
          // ) {

          //   let spotlightPlayerObj = {};
          //   data.data.map((obj) => {
          //     if (obj.player_slug !== this.spotlightPlayerSlug) {
          //       this.playerStatsObj.data.push(obj);
          //     } else {
          //       spotlightPlayerObj = obj;
          //     }
          //   });
          //   this.playerStatsObj.data.push(spotlightPlayerObj);
          // }
          if (
            this.spotlightSelection === 'league-leader' &&
            this.is_spotlight === true
          ) {
            data.data.map((obj) => {
              this.playerStatsObj.data.push(obj);
            });
          }
          // if (
          //   this.spotlightSelection === 'similar-rank' &&
          //   this.is_spotlight === true
          // ) {
          //   data.data.map((obj) => {
          //     this.playerStatsObj.data.push(obj);
          //   });
          // }
          else if (this.is_spotlight === false) {
            data.data.map((obj) => {
              this.playerStatsObj.data.push(obj);
            });
          }

          this.playerStatsObj.totalRecords = this.playerStatsObj.data.length;
        }
      });
    }
    if (this.widgetStatType === 'division_stats') {
      let seasona = '';
      if (this.yearVal && this.seasonVal) {
        this.complete_seasons.map((obj) => {
          if (
            obj.display_name === this.seasonVal &&
            obj.display_year === this.yearVal
          ) {
            seasona = obj.year;
          }
        });
        if (seasona === '') {
          seasona = this.yearVal + '-' + this.seasonVal.toLowerCase();
        }
      }

      if (this.spotlightSelection === 'similar-rank') {
        this.spotlightPlayerSlug = '';
        this.bll.reset_player_data();
        this.getPlayersForStats3();
      }

      if (this.spotlightSelection === 'league-leader') {
        this.spotlightPlayerSlug = '';
        this.bll.get_playerstats_div3(
          this.divisionVal,
          this.leagueVal,
          this.gameTypeVal,
          this.auth.token,
          seasona,
          this.gameSortVal,
          this.gamePropertyVal
        );
      }

      this.OBJID = this.divisionVal;
      this.bll.get_divisionstats(
        this.divisionVal,
        this.leagueVal,
        this.gameTypeVal,
        this.gamePropertyVal,
        this.size,
        this.gameSortVal,
        seasona,
        this.playerPool,
        this.auth.token
      );
      this.playerData$.subscribe((data: any) => {
        this.playerStatsObj.data = [];
        if (data.data !== undefined) {
          if (data.data.length > 0) {
            // const delimiterPos = data.data[0].type.lastIndexOf('-');
            // this.yearVal = data.data[0].type.substring(0, delimiterPos);
            // this.seasonVal = data.data[0].type.substring(delimiterPos + 1);
          }
          // if (
          //   this.spotlightSelection === 'league-leader' &&
          //   this.is_spotlight === true
          // ) {
          //   let spotlightPlayerObj = {};
          //   data.data.map((obj) => {
          //     if (obj.player_slug !== this.spotlightPlayerSlug) {
          //       this.playerStatsObj.data.push(obj);
          //     } else {
          //       spotlightPlayerObj = obj;
          //     }
          //   });
          //   this.playerStatsObj.data.push(spotlightPlayerObj);
          // }
          if (
            this.spotlightSelection === 'league-leader' &&
            this.is_spotlight === true
          ) {
            data.data.map((obj) => {
              this.playerStatsObj.data.push(obj);
            });
          }
          // if (
          //   this.spotlightSelection === 'similar-rank' &&
          //   this.is_spotlight === true
          // ) {
          //   data.data.map((obj) => {
          //     this.playerStatsObj.data.push(obj);
          //   });
          // }
          else if (this.is_spotlight === false) {
            data.data.map((obj) => {
              this.playerStatsObj.data.push(obj);
            });
          }

          this.playerStatsObj.totalRecords = this.playerStatsObj.data.length;
        }
      });
    }
    if (this.widgetStatType === 'conference_stats') {
      let seasona = '';
      if (this.yearVal && this.seasonVal) {
        this.complete_seasons.map((obj) => {
          if (
            obj.display_name === this.seasonVal &&
            obj.display_year === this.yearVal
          ) {
            seasona = obj.year;
          }
        });
        if (seasona === '') {
          seasona = this.yearVal + '-' + this.seasonVal.toLowerCase();
        }
      }

      if (this.spotlightSelection === 'similar-rank') {
        this.spotlightPlayerSlug = '';
        this.bll.reset_player_data();
        this.getPlayersForStats3();
      }

      if (this.spotlightSelection === 'league-leader') {
        this.spotlightPlayerSlug = '';
        this.bll.get_playerstats_conf3(
          this.conferenceVal,
          this.leagueVal,
          this.gameTypeVal,
          this.auth.token,
          seasona,
          this.gameSortVal,
          this.gamePropertyVal
        );
      }
      this.OBJID = this.conferenceVal;
      this.bll.get_conferencestats(
        this.conferenceVal,
        this.leagueVal,
        this.gameTypeVal,
        this.gamePropertyVal,
        this.size,
        this.gameSortVal,
        seasona,
        this.playerPool,
        this.auth.token
      );
      this.playerData$.subscribe((data: any) => {
        this.playerStatsObj.data = [];
        if (data.data !== undefined) {
          if (data.data.length > 0) {
            // const delimiterPos = data.data[0].type.lastIndexOf('-');
            // this.yearVal = data.data[0].type.substring(0, delimiterPos);
            // this.seasonVal = data.data[0].type.substring(delimiterPos + 1);
          }
          if (
            this.spotlightSelection === 'league-leader' &&
            this.is_spotlight === true
          ) {
            data.data.map((obj) => {
              this.playerStatsObj.data.push(obj);
            });
          }
          // if (
          //   this.spotlightSelection === 'similar-rank' &&
          //   this.is_spotlight === true
          // ) {
          //   data.data.map((obj) => {
          //     this.playerStatsObj.data.push(obj);
          //   });
          // }
          else if (this.is_spotlight === false) {
            data.data.map((obj) => {
              this.playerStatsObj.data.push(obj);
            });
          }
          this.playerStatsObj.totalRecords = this.playerStatsObj.data.length;
        }
      });
    }
    if (this.widgetStatType === 'league_stats') {
      let seasona = '';
      if (this.yearVal && this.seasonVal) {
        this.complete_seasons.map((obj) => {
          if (
            obj.display_name === this.seasonVal &&
            obj.display_year === this.yearVal
          ) {
            seasona = obj.year;
          }
        });
        if (seasona === '') {
          seasona = this.yearVal + '-' + this.seasonVal.toLowerCase();
        }
      }

      if (this.spotlightSelection === 'similar-rank') {
        this.spotlightPlayerSlug = '';
        this.bll.reset_player_data();
        this.getPlayersForStats3();
      }

      if (this.spotlightSelection === 'league-leader') {
        this.spotlightPlayerSlug = '';
        this.bll.get_playerstats3(
          this.leagueVal,
          this.gameTypeVal,
          this.auth.token,
          seasona,
          this.gameSortVal,
          this.gamePropertyVal
        );
      }

      this.OBJID = this.leagueVal;
      this.bll.get_leaguestats(
        this.leagueVal,
        this.gameTypeVal,
        this.gamePropertyVal,
        this.size,
        this.gameSortVal,
        seasona,
        this.playerPool,
        this.auth.token
      );
      this.playerData$.subscribe((data: any) => {
        if (data.length !== 0) {
          this.playerStatsObj.data = [];
          if (data.data !== undefined) {
            if (data.data.length > 0) {
            }
            // if (
            //   this.spotlightSelection === 'league-leader' &&
            //   this.is_spotlight === true
            // ) {
            //   let spotlightPlayerObj = {};
            //   data.data.map((obj) => {
            //     if (obj.player_slug !== this.spotlightPlayerSlug) {
            //       this.playerStatsObj.data.push(obj);
            //     } else {
            //       spotlightPlayerObj = obj;
            //     }
            //   });
            //   this.playerStatsObj.data.push(spotlightPlayerObj);
            // }
            if (
              this.spotlightSelection === 'league-leader' &&
              this.is_spotlight === true
            ) {
              data.data.map((obj) => {
                this.playerStatsObj.data.push(obj);
              });
            }
            // if (
            //   this.spotlightSelection === 'similar-rank' &&
            //   this.is_spotlight === true
            // ) {
            //   data.data.map((obj) => {
            //     this.playerStatsObj.data.push(obj);
            //   });
            //}
            else if (this.is_spotlight === false) {
              data.data.map((obj) => {
                this.playerStatsObj.data.push(obj);
              });
            }

            this.playerStatsObj.totalRecords = this.playerStatsObj.data.length;
          }
        }
      });
    } else if (this.widgetType === 'chart' || this.widgetType === 'datagrid') {
      let seasona = '';
      if (this.yearVal && this.seasonVal) {
        this.complete_seasons.map((obj) => {
          if (
            obj.display_name === this.seasonVal &&
            obj.display_year === this.yearVal
          ) {
            seasona = obj.year;
          }
        });
        if (seasona === '') {
          seasona = this.yearVal + '-' + this.seasonVal.toLowerCase();
        }
      }
      this.bll.get_leaguestatsChart(
        this.teamVal,
        this.leagueVal,
        this.gameTypeVal,
        this.gamePropertyVal,
        this.size,
        this.auth.token,
        seasona
      );
      this.playerData$.subscribe(async (data: any) => {
        this.playerStatsInfo = {
          data: [],
          page: 1,
          size: 10,
          totalRecords: 0,
        };
        if (data.length !== 0) {
          this.playerStatsInfo = data;
          let game_type = this.gameTypeVal;
          if (this.leagueVal === 'NBA') {
            if (
              this.gamePropertyVal === 'astPerGame' ||
              this.gamePropertyVal === 'ptsPerGame'
            ) {
              game_type = 'offense';
            }
            if (this.gamePropertyVal === 'fgPct') {
              game_type = 'fieldGoals';
            }
            if (this.gamePropertyVal === 'rebPerGame') {
              game_type = 'rebounds';
            }
            if (
              this.gamePropertyVal === 'blkPerGame' ||
              this.gamePropertyVal === 'stlPerGame'
            ) {
              game_type = 'defense';
            }

            if (
              this.gamePropertyVal === 'fgPct' ||
              this.gamePropertyVal === 'fg3PtPct'
            ) {
              game_type = 'fieldGoals';
            }
            if (this.gamePropertyVal === 'ftPct') {
              game_type = 'freeThrows';
            }
            if (
              this.gamePropertyVal === 'offRebPerGame' ||
              this.gamePropertyVal === 'defRebPerGame' ||
              this.gamePropertyVal === 'rebPerGame'
            ) {
              game_type = 'rebounds';
            }
            if (
              this.gamePropertyVal === 'astPerGame' ||
              this.gamePropertyVal === 'ptsPerGame'
            ) {
              game_type = 'offense';
            }
            if (
              this.gamePropertyVal === 'blkPerGame' ||
              this.gamePropertyVal === 'stlPerGame' ||
              this.gamePropertyVal === 'tovPerGame'
            ) {
              game_type = 'defense';
            }
            if (this.gamePropertyVal === 'plusMinus') {
              game_type = 'miscellaneous';
            }
          } else if (this.leagueVal === 'NFL') {
            if (
              this.gamePropertyVal === 'interceptions' ||
              this.gamePropertyVal === 'intYds' ||
              this.gamePropertyVal === 'intTD' ||
              this.gamePropertyVal === 'passesDefended'
            ) {
              game_type = 'interceptions';
            }
            if (
              this.gamePropertyVal === 'fumForced' ||
              this.gamePropertyVal === 'fumOppRec'
            ) {
              game_type = 'fumbles';
            }
            if (
              this.gamePropertyVal === 'tackleTotal' ||
              this.gamePropertyVal === 'tackleSolo' ||
              this.gamePropertyVal === 'tackleAst' ||
              this.gamePropertyVal === 'tacklesForLoss' ||
              this.gamePropertyVal === 'sacks' ||
              this.gamePropertyVal === 'sackYds'
            ) {
              game_type = 'tackles';
            }
            if (
              this.gamePropertyVal === 'position' ||
              this.gamePropertyVal === 'team' ||
              this.gamePropertyVal === 'player'
            ) {
              game_type = '';
            }

            if (
              this.gamePropertyVal === 'interceptions' ||
              this.gamePropertyVal === 'intYds' ||
              this.gamePropertyVal === 'intTD' ||
              this.gamePropertyVal === 'passesDefended'
            ) {
              game_type = 'interceptions';
            }
            if (
              this.gamePropertyVal === 'fumForced' ||
              this.gamePropertyVal === 'fumOppRec'
            ) {
              game_type = 'fumbles';
            }
            if (
              this.gamePropertyVal === 'tackleTotal' ||
              this.gamePropertyVal === 'tackleSolo' ||
              this.gamePropertyVal === 'tackleAst' ||
              this.gamePropertyVal === 'tacklesForLoss' ||
              this.gamePropertyVal === 'sacks' ||
              this.gamePropertyVal === 'sackYds'
            ) {
              game_type = 'tackles';
            }
          } else if (this.leagueVal === 'NHL') {
            if (this.gamePropertyVal === 'plusMinus') {
              game_type = 'skating';
            } else if (this.gamePropertyVal === 'penalties') {
              game_type = 'penalties';
            } else {
              game_type = this.gameTypeVal;
            }
          }

          if (this.widgetType === 'chart') {
            this.chartShow = true;
            this.key = '';
            this.tableData = [];

            if (this.playerStatsInfo && this.playerStatsInfo.data.length > 0) {
              this.key = this.gamePropertyVal;

              this.playerStatsInfo.data.map((a: any) => {
                // if (typeof a[this.gamePropertyVal] === 'string') {
                //   this.tableData.push({
                //     name: a.player_name,
                //     score: 'no value',
                //   });
                // } else {

                if (a[this.gamePropertyVal]) {
                  this.tableData.push({
                    name: a.player_name,
                    score: a[this.gamePropertyVal],
                  });
                } else if (a[game_type.toLowerCase()]) {
                  this.tableData.push({
                    name: a.player_name,
                    score: a[game_type.toLowerCase()][this.gamePropertyVal],
                  });
                }
                //  }
              });
            }

            await this.createChart();
          } else {
            this.dataGridArr = [];
            this.columnsArr = [];
            this.selectedColumns = [];
            let index = 0;
            this.columnsArr.push('league');
            this.columnsArr.push('team');
            this.columnsArr.push('player_name');

            const key = this.propertyMappings[this.gamePropertyVal];
            data.data.map((obj) => {
              if (key) {
                const dataObj = {
                  id: obj.player_id,
                  league: obj.league,
                  team: obj.team,
                  team_slug: obj.team_slug,
                  gamesplayed: this.parseInt(obj.gamesplayed),
                  player_name: obj.player_name,
                  player_slug: obj.player_slug,
                  position: obj.position,
                };

                if (obj[this.gamePropertyVal]) {
                  dataObj[key] = this.parseInt(obj[key]);
                } else if (obj[game_type.toLowerCase()]) {
                  dataObj[key] = this.parseInt(
                    obj[game_type.toLowerCase()][this.gamePropertyVal]
                  );
                }
                this.dataGridArr.push(dataObj);

                if (index === 0) {
                  index++;
                  this.columnsArr.push(key);
                }
              }
            });
          }
        } else {
          if (this.widgetType === 'chart') {
            this.tableData = [];
            this.chartShow = false;
          } else {
            this.dataGridArr = [];
            this.columnsArr = [];
            this.selectedColumns = [];
            this.showGrid = false;
          }
        }
      });
    }
  }

  getSpotlightSelection() {
    if (this.spotlightSelection === 'similar-rank') {
      return true;
    } else {
    }
  }

  async changeChartType() {
    if (this.chartVal !== 'table') {
      this.checkPost();
    } else {
      await this.createChart();
    }
  }

  checkPost() {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = this.chartData;

    // Find the table element within the CKEditor content
    const table = tempDiv.querySelector('table');

    if (table) {
      let obj: any = [];
      let indexx = 0;

      // Iterate through table rows
      const rows = table.querySelectorAll('tr');
      rows.forEach((row) => {
        // Iterate through table cells (columns)
        const cells = row.querySelectorAll('td');
        cells.forEach((cell, index) => {
          if (index % 2 === 0) {
            obj[indexx] = { name: cells[index].innerText };
          } else {
            obj[indexx]['score'] = Number(cells[index].innerText);
            indexx++;
          }
        });

        // tableData.push(rowData); // Add row data to the array
      });
      this.playerStatsInfo = null;
      this.tableData = obj;

      this.createChart();
    } else {
      this.createChart();
    }
  }
  async createChart() {
    // let data = [21, 22, 10, 28, 16, 21, 13, 30];
    // let categories = [
    //   ['John', 'Doe'],
    //   ['Joe', 'Smith'],
    //   ['Jake', 'Williams'],
    //   ['Amber', 'Brown'],
    //   ['Peter', 'Brown'],
    //   ['Mary', 'Evans'],
    //   ['David', 'Wilson'],
    //   ['Lily', 'Roberts'],
    //   ['Mary', 'Evans'],
    //   ['David', 'Wilson'],
    // ];

    // if(data !==undefined && this.playerStatsInfo!==undefined){
    // data = [];
    // categories = [];

    this.graphValues = [];
    this.graphNames = [];
    this.tableData.map((obj) => {
      this.graphValues.push(parseFloat(obj.score));
      this.graphNames.push(obj.name);
    });

    // this.graphValues = data;
    // this.graphNames = categories;

    if (this.tableData.length > 0) {
      if (this.chartVal === 'bar') {
        this.chartOptions = {
          series: [
            {
              name: this.key,
              data: this.graphValues,
            },
          ],
          chart: {
            height: 350,
            type: 'bar',
            events: {
              mounted: (chart) => {
                chart.windowResizeHandler();
              },
            },
          },
          colors: [
            '#13598f',
            '#156147',
            '#fe4319',
            '#a62b3d',
            '#4d26d4',
            '#5ec7f7',
            '#154f0c',
            '#50065c',
            '#ffe733',
            '#cc7e6c',
          ],
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          grid: {
            show: false,
          },
          xaxis: {
            categories: this.graphNames,
            labels: {
              style: {
                colors: [
                  '#13598f',
                  '#156147',
                  '#fe4319',
                  '#a62b3d',
                  '#4d26d4',
                  '#5ec7f7',
                  '#154f0c',
                  '#50065c',
                  '#ffe733',
                  '#cc7e6c',
                ],
                fontSize: '12px',
              },
            },
          },
        };
      }

      if (this.chartVal === 'donut') {
        this.chartOptions = {
          series: this.graphValues,
          chart: {
            width: '200px',
            height: '200px',
            type: 'donut',
            events: {
              mounted: (chart) => {
                chart.windowResizeHandler();
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            type: 'gradient',
          },
          labels: this.graphNames,
          legend: {
            show: false, // Enable the legend
            position: 'bottom',
            //  customLegendItems: this.generateCustomLegendItems(),
          },
          responsive: [
            {
              // breakpoint: 480,
              options: {
                chart: {
                  width: '200px',
                  height: '200px',
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        };
      }

      if (this.chartVal === 'pie') {
        this.chartOptions = {
          series: this.graphValues,
          chart: {
            width: 380,
            type: 'pie',
            events: {
              mounted: (chart) => {
                chart.windowResizeHandler();
              },
            },
          },
          labels: this.graphNames,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
          dataLabels: {
            enabled: false,
          },
        };
      }

      if (this.chartVal === 'table') {
        let table = '<table>';
        this.tableData.forEach((row) => {
          table += `
            <tr>
              <td>${row.name}</td>
              <td>${row.score}</td>
            </tr>
          `;
        });
        table += '</table>';
        table;
        this.chartData = table;
      }
    }

    //}
  }

  renderChart(item) {
    if (item.type === 'chart') {
      const resizeEvent = new UIEvent('resize');
      window.dispatchEvent(resizeEvent);
    }
  }

  resetImagnApi() {
    this.bll.reset_imagn_data();
  }

  AddBlock(index: any) {
    this.openWidgetMenu('add');
    this.INX = index;
  }

  CloseImagnPopup() {
    this.uploadFromImagn = false;
    this.image_keyword = '';
    this.imageSourceTab = 'imagn';
    this.resetImagnApi();
  }

  opencopyTemplateModal() {
    this.copyTemplateModal = true;
    if (this.templateCopyType === 'blocks') {
      this.templateBlockArr = [];
      this.widgetArr.map((obj) => {
        this.templateBlockArr.push({
          checked: true,
          obj: obj,
        });
      });
    }
  }

  checkAll(event) {
    if (event.target.checked === true) {
      this.templateBlockArr.map((obj) => {
        obj.checked = true;
      });
    } else if (event.target.checked === false) {
      this.templateBlockArr.map((obj) => {
        obj.checked = false;
      });
    }
  }

  CloseTemplatePopup() {
    this.copyTemplateModal = false;
    // this.image_keyword = null;
    // this.resetImagnApi();
  }

  changeSegment(event) {
    if (this.templateCopyType === 'blocks') {
      this.templateBlockArr = [];
      this.widgetArr.map((obj) => {
        this.templateBlockArr.push({
          checked: true,
          obj: obj,
        });
      });
    }
  }

  copyTemplate() {
    if (this.templateCopyType === 'full') {
      this.postTemplate();
    } else if (this.templateCopyType === 'blocks') {
      let blocks: any = [
        {
          data: [],
          type: 'Twitter',
          count: 0,
        },
        {
          data: [],
          type: 'Video',
          count: 0,
        },
        {
          data: [],
          type: 'Quote',
          count: 0,
        },
        {
          data: [],
          type: 'Image',
          count: 0,
        },
        {
          data: [],
          type: 'Wikipedia',
          count: 0,
        },

        {
          data: [],
          type: 'Number',
          count: 0,
        },
        {
          data: [],
          type: 'Social Comments',
          count: 0,
        },
        {
          data: [],
          type: 'Text',
          count: 0,
        },
        {
          data: [],
          type: 'Info',
          count: 0,
        },
        {
          data: [],
          type: 'Stats',
          count: 0,
        },
        {
          data: [],
          type: 'Standings',
          count: 0,
        },
        {
          data: [],
          type: 'Chart',
          count: 0,
        },
        {
          data: [],
          type: 'Upcoming Games',
          count: 0,
        },
      ];

      let bodyData = {
        name: '',
        template: null,
        id: 0,
        status: 'Draft',
        type: 'blocks',
      };

      bodyData.name = this.title.value;
      bodyData.id = parseInt(this.article_id);

      this.templateBlockArr.forEach((obj) => {
        if (obj.checked === true) {
          if (obj.obj.type === 'twitter') {
            blocks[0].count++;
          }
          if (obj.obj.type === 'video') {
            blocks[1].count++;
          }
          if (obj.obj.type === 'quote') {
            blocks[2].count++;
          }
          if (obj.obj.type === 'image') {
            blocks[3].count++;
          }

          if (obj.obj.source === 'wikipedia') {
            blocks[4].count++;
          }
          if (obj.obj.type === 'number') {
            blocks[5].count++;
          }
          if (obj.obj.type === 'social_comment') {
            blocks[6].count++;
          }
          if (obj.obj.type === 'text') {
            blocks[7].count++;
          }

          if (obj.obj.type === 'info') {
            blocks[8].count++;
          }
          if (obj.obj.type === 'stats') {
            blocks[9].count++;
          }
          if (obj.obj.type === 'standings') {
            blocks[10].count++;
          }
          if (obj.obj.type === 'chart') {
            blocks[11].count++;
          }

          if (obj.obj.type === 'upcoming_games') {
            blocks[12].count++;
          }
        }
      });
      let formValue = {
        blocks: [],
        blockCounts: [],
        title: '',
        post: '',
        image: '',
      };
      formValue.title = this.title.value;

      blocks = blocks.filter(function (block) {
        return block.count !== 0;
      });
      formValue.blockCounts = blocks;
      this.templateBlockArr.map((block) => {
        if (block.checked === true) {
          formValue.blocks.push(block.obj);
        }
      });

      bodyData.template = formValue;

      this.bll.add_template(bodyData, this.auth.token);
      this.templateBlockArr = [];
      this.copyTemplateModal = false;
    }
  }

  getCapitalizeLetters(text) {
    return text
      ? text
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      : '';
  }

  showImageTab() {
    this.ImageDropdown = !this.ImageDropdown;
  }
  showSDTab() {
    this.socialDropdown = !this.socialDropdown;
  }
  showCheckListTab() {
    this.checklistDropdown = !this.checklistDropdown;
  }
  showTagsTab() {
    this.TagsDropdown = !this.TagsDropdown;
  }

  showContentDetailTab() {
    this.ContentDetailsDropdown = !this.ContentDetailsDropdown;
  }
  showPublishTab() {
    this.PublishingDropdown = !this.PublishingDropdown;
  }

  showMetaTab() {
    this.MetaDropdown = !this.MetaDropdown;
  }
  showSourceTab() {
    this.SourceDropdown = !this.SourceDropdown;
  }

  showGroupTab() {
    this.GroupDropdown = !this.GroupDropdown;
  }

  openAllSources() {
    for (let i = 0; i < this.sourceArr.length; i++) {
      window.open(this.sourceArr[i].url, '', 'noopener');
    }
  }

  // deleteRow(obj) {

  //   this.playerStatsObj.data.map((a, index) => {
  //     if (a.player_id === obj.player_id) {
  //       let length = this.playerStatsObj.data.length;
  //       this.playerStatsObj.data.splice(index, 1);
  //       if (index === 0) {
  //         this.playerKeyword1 = this.playerKeyword2;
  //         this.playerKeyword2 = null;
  //         if (length === 2) {
  //           this.playerKey2 = false;
  //         } else {
  //           this.playerKey1 = false;
  //         }

  //       }

  //       this.playerStatsObj.totalRecords = this.playerStatsObj.data.length
  //     }
  //   })
  //   this.playerStatsObj.data = [...this.playerStatsObj.data]
  //   this.changeDetection.detectChanges();
  //   this.changeDetection.markForCheck()

  // }

  deleteRowByPlayerName(num) {
    if (num === 1) {
      this.playerStatsObj.data.map((a, index) => {
        if (a.player_name === this.playerKeyword1) {
          let length = this.playerStatsObj.data.length;
          this.playerStatsObj.data.splice(index, 1);
          if (index === 0) {
            this.playerKeyword1 = this.playerKeyword2;
            this.playerKeyword2 = null;
            if (length === 2) {
              this.playerKey2 = false;
            } else {
              this.playerKey1 = false;
            }
          }
          this.playerStatsObj.totalRecords = this.playerStatsObj.data.length;
        }
      });
    } else {
      this.playerStatsObj.data.map((a, index) => {
        if (a.player_name === this.playerKeyword2) {
          this.playerStatsObj.data.splice(index, 1);
          if (index === 1) {
            this.playerKeyword2 = null;
            this.playerKey2 = false;
          }
          this.playerStatsObj.totalRecords = this.playerStatsObj.data.length;
        }
      });
    }

    this.playerStatsObj.data = [...this.playerStatsObj.data];
  }

  deleteRowByPlayerName3() {
    //   this.playerStatsObj.data.map((a, index) => {
    //     if (a.player_slug === this.spotlightPlayerSlug) {
    //       this.playerStatsObj.data.splice(index, 1);

    //     }
    // })
    this.spotlightPlayerSlug = '';
    this.playerKeyword3 = '';
    this.playerKeyword4 = '';
    this.playerOverlay3 = false;
    this.playerOverlay4 = false;
    this.playerStatsObj.data = [...this.playerStatsObj.data];
    this.playerStatsObj.totalRecords = this.playerStatsObj.data.length;
    this.changeProperty(null);
  }

  search_youtube_video() {
    if (this.searchLinkVar !== '') {
      this.bll.search_youtube_videos(this.searchLinkVar, this.auth.token);
    } else {
      this.bll.reset_search();
    }
  }
  search_twitter() {
    if (this.searchLinkVar2 !== '') {
      this.bll.search_twitter(this.searchLinkVar2, this.auth.token);
    } else {
      this.bll.reset_search();
    }
  }
  AddVideo(obj) {
    this.url = '';
    this.youtubeObj = null;
    let imageObj = null;
    setTimeout(async () => {
      let fileUrl = this.getBase64ImageFromUrl(obj.thumbnail).then(
        async (result: any) => {
          let file = await this.storage.dataURItoBlob(result);
          imageObj = await this.storage.uploadFile(file);
        },
        (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            //this.auth.refreshtoken();
            setTimeout(() => {
              this.AddVideo(obj);
            }, 1000);
          }
          console.log('err', err);
        }
      );
      // this.http.get('https://i.ytimg.com/vi/Sj0JYHrYu38/hqdefault.jpg', { responseType: 'arraybuffer' })
      //   .subscribe((response:any) => {
      //     const data = 'data:' + response.headers.get('content-type') + ';base64,' + btoa(
      //       new Uint8Array(response.body)
      //         .reduce((data, byte) => data + String.fromCharCode(byte), '')
      //     );
      //     base64 = data;
      //     console.log('imageObj',data)
      //     success = true;
      //     console.log('success',success)
      //   }, error => {
      //     success = false;
      //     console.log('error',error)
      //     console.log('success',success)
      //   });

      this.url = obj.url;
      this.youtubeObj = obj;
      //  })
      // obj.thumbnail=imageObj
    }, 10);

    this.name = obj.title;
    this.description = obj.description ? obj.description : '';
    this.searchLinkVar = '';
    this.bll.reset_search();
  }

  processString(inputString: string): string {
    // Truncate the string to 30 characters
    let truncatedString = inputString.slice(0, 30);

    // Remove URLs using a regular expression
    const urlRegex = /(?:https?|ftp):\/\/[\n\S]+/g;
    truncatedString = truncatedString.replace(urlRegex, '');

    return truncatedString;
  }

  AddTweet(obj) {
    this.url = '';
    setTimeout(() => {
      if (obj.url) {
        if (this.twitterSourceTab === 'top') {
          if (obj.text) {
            this.name = this.processString(obj.text);
          } else {
            this.name = '';
          }
        } else {
          this.name = this.twitter_username;
        }

        this.url = obj.url;
      }
    }, 100);

    this.description = obj.text ? obj.text : '';
    this.twitter_teams_arr = [];
    this.keywordToSearchTeam = '';
    this.searchLinkVar2 = '';
    this.bll.reset_search();
  }

  onError(event: any, item) {
    console.log('event error', event);
    if (event.playerInfo.videoData.errorCode === null) {
      // item.main_content.video.videoFound = true;
    } else {
    }
  }

  canSelectChecboxes(id) {
    if (this.status.value === 'prep') {
      if (
        (id === 2 || id === 5 || id === 6) &&
        !this.user.role.includes('super_admin')
      ) {
        return true;
      } else {
        return false;
      }
    }
    // if(this.status.value === 'content-review'){
    //   if(id ===7||id===12){
    //   return true
    //   }
    //   else{
    //     return false
    //   }
    // }
  }

  returnandCheckValues(id, val) {
    this.checklists.map((obj) => {
      if (obj.id === id) {
        obj.status_checked = val;
      }
    });

    this.selected = {
      status_checklist_id: [],
      content_id: this.article_id,
    };

    this.unselected = {
      status_checklist_id: [],
      content_id: this.article_id,
    };

    this.checklists.map((element) => {
      if (element.status_checked === true) {
        this.selected.status_checklist_id.push(element.id);
      }
      if (element.status_checked === false) {
        this.unselected.status_checklist_id.push(element.id);
      }
    });
    if (
      this.selected.status_checklist_id.length !== 0 ||
      this.unselected.status_checklist_id.length !== 0
    ) {
      this.bll.set_checklist(this.auth.token, this.selected, this.unselected);
    }

    // this.bll.checklist$
    //   .pipe(
    //     take(1)
    //   )
    //   .subscribe((data) => {
    //     // if (data !== null) {
    //     const updatedChecklist = data.map((ob) => {
    //       if (ob.id === id) {
    //         return { ...ob, val: val }; // create a new object with the updated value
    //       }
    //       return ob;
    //     });
    //     //this.bll.set_checklist(updatedChecklist);
    //     //this.checklist$ = this.bll.checklist$;

    //     // call set_checklist with the updated value
    //     // }
    //   });

    // this.checklist$=this.bll.checklist$;
  }
  checkBoxChange(event, id) {
    if (event.detail.checked === true) {
      this.returnandCheckValues(id, true);
    } else {
      this.returnandCheckValues(id, false);
    }
  }

  leagueSelect(event) {
    if (this.status.value === 'prep') {
      if (event.target.value === '0: Select Sports') {
        //  this.returnandCheckValues(5, false);
      } else {
        //  this.returnandCheckValues(5, true);
      }
    }
  }

  urlValidation(url) {
    let re =
      /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    if (url) {
      if (re.test(url)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  getVideoDateTime(timestamp) {
    const date = new Date(timestamp);
    const now = new Date();
    const diff = now.getTime() - date.getTime();

    if (diff < 0) {
      console.error('Invalid timestamp');
    } else if (diff < 1000 * 60 * 60 * 24) {
      const hoursAgo = Math.round(diff / (1000 * 60 * 60));
      return hoursAgo + ' hours ago';
    } else {
      const daysAgo = Math.round(diff / (1000 * 60 * 60 * 24));
      return daysAgo + ' days ago';
    }
  }

  changeStatus(event) {
    this.selectedOptionIndex = event.target.selectedIndex;
    this.bll.reset_checklist();
    this.bll.get_checklist(this.auth.token, this.status.value, this.article_id);

    this.bll.checklist$.subscribe((data) => {
      if (this.status.value === 'prep') {
        if (this.image.value !== '' && this.image.value !== null) {
          //  this.returnandCheckValues(2, true);
        }
        if (this.selectedTeams.length > 0) {
          //  this.returnandCheckValues(6, true);
        }
        if (
          this.organization.value !== '0: Select Sports' &&
          this.organization.value !== '' &&
          this.organization.value !== null
        ) {
          //  this.returnandCheckValues(5, true);
        }
      }

      if (this.status.value === 'content-review') {
        if (this.title.value !== '' && this.title.value !== null) {
          // this.returnandCheckValues(7, true);
        }
        const regex = / {2}/g;
        const titlespacematches = this.title.value.match(regex);
        const descspacematches = this.post.value.match(regex);

        if (titlespacematches === null || descspacematches == null) {
          const regex = /[a-zA-Z]+('[a-zA-Z]+)?/g;
          const titlespellmatches = this.title.value.match(regex);
          const descspellmatches = this.post.value.match(regex);

          if (titlespellmatches !== null && descspellmatches !== null) {
            const regex = /\b#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})\b/g;
            const titlehexmatches = this.title.value.match(regex);
            const deschexmatches = this.post.value.match(regex);

            if (titlehexmatches === null && deschexmatches === null) {
              //  this.returnandCheckValues(12, true);
            }
          }
        }
      }
    });
  }

  async copyToClipboardWithParameter(param) {
    let successful;
    if (param === 1) {
      successful = this.clipboard.copy(this.image.value);
    }
    if (param === 2) {
      successful = this.clipboard.copy(this.thumbnail.value);
    }

    if (successful) {
      await this.showSuccess('Copied To Clipboard Successfully!');
      // do something
    } else {
      // do something else
    }
  }

  SelectSeason(event) {
    if (this.widgetStatType === 'player_stats') {
      this.deleteRowByPlayerName(2);
      this.deleteRowByPlayerName(1);
    }
    this.changeProperty(null);
  }

  transformString(): string {
    if (this.isAdd) {
      if (this.status.value === null) {
        this.status.setValue('draft');
      }
    }
    if (this.status.value !== null) {
      const words = this.status.value.split('-');
      const capitalizedWords = words.map((word) => {
        const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
        return capitalizedWord.replace(/_/g, '');
      });
      return capitalizedWords.join(' ');
    }
  }

  spotlightCheck(event) {
    this.changeProperty(null);
  }
  SelectSpotlight(event) {
    if (this.spotlightSelection === 'league-leader') {
      this.size = 10;
      this.changeProperty(null);
      this.getPlayersForStats3();
    }
    if (this.spotlightSelection === 'similar-rank') {
      this.spotlightPlayerSlug = '';
      this.bll.reset_player_data();
      this.getPlayersForStats3();
    }
  }

  SelectSpotlightPlayer(event) {
    this.changeProperty(null);
  }

  changePool(event) {
    this.changeProperty(null);
  }

  postTemplate() {
    let blocks: any = [
      {
        data: [],
        type: 'Twitter',
        count: 0,
      },
      {
        data: [],
        type: 'Video',
        count: 0,
      },
      {
        data: [],
        type: 'Quote',
        count: 0,
      },
      {
        data: [],
        type: 'Image',
        count: 0,
      },
      {
        data: [],
        type: 'Wikipedia',
        count: 0,
      },

      {
        data: [],
        type: 'Number',
        count: 0,
      },
      {
        data: [],
        type: 'Social Comments',
        count: 0,
      },
      {
        data: [],
        type: 'Text',
        count: 0,
      },
      {
        data: [],
        type: 'Info',
        count: 0,
      },
      {
        data: [],
        type: 'Stats',
        count: 0,
      },
      {
        data: [],
        type: 'Standings',
        count: 0,
      },
      {
        data: [],
        type: 'Chart',
        count: 0,
      },
      {
        data: [],
        type: 'Upcoming Games',
        count: 0,
      },
    ];

    let bodyData = {
      name: '',
      template: null,
      id: 0,
      status: 'Draft',
      type: 'full',
    };

    bodyData.name = this.title.value;
    bodyData.id = parseInt(this.article_id);

    this.widgetArr.forEach((obj) => {
      if (obj.type === 'twitter') {
        blocks[0].count++;
      }
      if (obj.type === 'video') {
        blocks[1].count++;
      }
      if (obj.type === 'quote') {
        blocks[2].count++;
      }
      if (obj.type === 'image') {
        blocks[3].count++;
      }

      if (obj.source === 'wikipedia') {
        blocks[4].count++;
      }
      if (obj.type === 'number') {
        blocks[5].count++;
      }
      if (obj.type === 'social_comment') {
        blocks[6].count++;
      }
      if (obj.type === 'text') {
        blocks[7].count++;
      }

      if (obj.type === 'info') {
        blocks[8].count++;
      }
      if (obj.type === 'stats') {
        blocks[9].count++;
      }
      if (obj.type === 'standings') {
        blocks[10].count++;
      }
      if (obj.type === 'chart') {
        blocks[11].count++;
      }

      if (obj.type === 'upcoming_games') {
        blocks[12].count++;
      }
    });
    let formValue: any;
    formValue = this.form.value;

    delete formValue.blocks;

    blocks = blocks.filter(function (block) {
      return block.count !== 0;
    });

    formValue.blockCounts = blocks;
    formValue.blocks = this.widgetArr;

    bodyData.template = formValue;

    this.bll.add_template(bodyData, this.auth.token);
    this.templateBlockArr = [];
    this.copyTemplateModal = false;
  }

  showTemplate() {
    this.isShowTemplatesOpen = true;
    this.bll.show_templates(this.auth.token);
    this.isTemplateLoading = true;
    this.templates$.subscribe((data) => {
      this.templates = data;
      this.isTemplateLoading = false;
    });
  }

  applyTemplate(obj) {
    this.isShowTemplatesOpen = false;
    this.widgetArr = [];
    this.localWidgetArr = [];
    this.Immersive_view_widgetArr = [];
    for (let i = 0; i < obj.blocks.length; i++) {
      this.widgetArr.push(obj.blocks[i]);
      this.localWidgetArr.push(obj.blocks[i]);
      this.Immersive_view_widgetArr.push(obj.blocks[i]);
    }
    this.setValuesToForm(obj);
  }

  twitterShare() {
    let obj = {
      media_url: '',
      text: '',
      url: '',
      hashtag: '',
    };

    let articleurl = '';

    if (this.type.value.includes('content')) {
      articleurl = environment.consumerUrl + 'experiences/' + this.slug.value;
    } else {
      articleurl = environment.consumerUrl + 'article/' + this.slug.value;
    }

    this.tinyUrl.shorten(articleurl).subscribe((res) => {
      obj.media_url = this.image.value;
      obj.text = this.stripandFilterText() + '...';
      obj.url = res;
      obj.hashtag = '#sportswriters';

      this.bll.share_twitter(obj, this.auth.token);
      this.isConfirmationToShareTwitterOpen = false;
      // https://tinyurl.com/8wa5w2o;
    });
  }

  facebook_share_loading: boolean = false;

  facebookShare() {
    let obj = {
      media_url: '',
      text: '',
      url: '',
    };

    let articleurl = '';

    if (this.type.value.includes('content')) {
      articleurl = environment.consumerUrl + 'experiences/' + this.slug.value;
    } else {
      articleurl = environment.consumerUrl + 'article/' + this.slug.value;
    }
    this.tinyUrl.shorten(articleurl).subscribe((res) => {
      obj.media_url = this.image.value;
      obj.text = this.stripandFilterText() + '...';
      obj.url = res;

      this.facebook_share_loading = true;

      this.bll.share_facebook(obj, this.auth.token).then(
        (res) => {
          console.log('Share Facebook Success:', res);
          this.isConfirmationToShareFacebookOpen = false;
          this.facebook_share_loading = false;
          // Handle the successful response here
        },
        (err) => {
          console.error('Share Facebook Error:', err);
          this.isConfirmationToShareFacebookOpen = false;
          this.facebook_share_loading = false;
          // Handle the error here
        }
      );

      // https://tinyurl.com/8wa5w2o;
    });
  }

  stripandFilterText() {
    let strippedString = this.post.value.replace(/(<([^>]+)>)/gi, '');
    const max_len = 200;

    // Concatenate the strings with a line break in between
    let new_string = `${this.title.value}\n\n${strippedString}\n`;

    // Truncate the string if it is longer than the maximum length
    if (new_string.length > max_len) {
      new_string = new_string.slice(0, max_len);
    }

    // Add the hashtag
    return new_string;
  }

  changeGroup() {
    if (this.group_id.value !== null) {
      // this.bll.get_group_members(this.group_id.value,this.auth.token)
      let url = `group?group_member=true&member_type=creator&group_id=${this.group_id.value}`;
      this.get(url).subscribe((res) => {
        if (res) {
          if (res.data.length > 0) {
            this.members = [];
            res.data.map((member) => {
              this.members.push(member);
            });
            if (this.members.length > 0 && this.article_id !== '-1') {
              let assigneeurl = `group?content_assignee=true&content_id=${this.article_id}&group_id=${this.group_id.value}`;
              this.get(assigneeurl).subscribe((res) => {
                if (res) {
                  if (res.data.length > 0) {
                    this.assignee_id.setValue(res.data[0].user_id);
                    const foundObject = this.members.find(
                      (obj) => obj.user_id === res.data[0].user_id
                    );
                    if (!foundObject) {
                      this.members.push(res.data[0]);
                    }
                    this.dummy_Assignee = res.data[0].id;
                  }
                }
              });
            }
          }
        }
      });
      // setTimeout(()=>{
      //   if(this.article_id){
      //     this.bll.get_content_assignee(this.article_id,this.group_id.value,this.auth.token)
      //   this.assignee$=this.bll.assignee$
      //   this.assignee$.subscribe((data:any)=>{
      //     if(data){
      //       console.log('data from assignee here',data);
      //       if(data.length>0){
      //         this.assignee_id.setValue(data[0].user_id);
      //         this.dummy_Assignee=data[0].id
      //       }

      //     }

      //    })
      //   }

      //  },3000)
    }
  }

  divVisible = false;

  showDiv() {
    this.divVisible = true;
  }

  hideDiv() {
    this.divVisible = false;
  }

  applyGroup() {
    if (this.selected_group !== null) {
      this.group_id.setValue(this.selected_group.id);
      this.assignee_id.setValue(null);
      this.changeGroup();
    } else {
      this.group_id.setValue(null);
    }
  }

  // _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();

  //   return this.options.filter(option => option.toLowerCase().includes(filterValue));
  // }

  removeGroup() {
    this.group_id.setValue(null);
    this.selected_group = null;
  }

  assignedGroupCheck(group) {
    return this.groups.some((g) => g.content_assigned && g !== group);
  }

  assignGroupToContent_(event, group) {
    if (event.detail.checked) {
      // Disable other checkboxes when the current one is checked
      this.groups.forEach((g) => {
        if (g !== group) {
          g.content_assigned = false;
        }
      });
      //   this.GroupContentAddObj={
      //     group_id: group.id,
      //     content_id: [this.article_id],
      //     group_content: true
      // }
      this.group_id.setValue(group.id);
      this.changeGroup();
      //  this.bll.add_group_content(obj,this.auth.token);
      this.content_groups = [];
      group.group_id = group.id;
      this.content_groups.push(group);
      // setTimeout(()=>{
      //   this.get_groups()
      // },2000)
    } else if (!this.groups.some((g) => g.content_assigned)) {
      // let deleteArr=[
      //   this.article_id
      // ]
      // this.delete_group_id=group.id
      this.group_id.setValue(null);
      //this.bll.delete_group_contents(deleteArr,group.id,this.auth.token)
      this.content_groups = [];

      // setTimeout(()=>{
      //   this.get_groups()
      // },2000)
      // Enable all checkboxes when no checkbox is checked
      this.groups.forEach((g) => {
        g.content_assigned = false;
      });
    }
  }

  getProfileDetails() {
    if (this.profileDetails !== null) {
      this.get_groups();
      if (this.article_id !== '-1') {
        this.get_article();
      }
    } else if (this.profileDetails === null) {
      let url = 'user?profile=true';
      this.auth.get(url, this.auth.token).subscribe(
        (data: any) => {
          this.profileDetails = data.data[0];

          this.get_groups();
          if (this.article_id !== '-1') {
            this.get_article();
          }
        },
        (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            //this.auth.refreshtoken();
            setTimeout(() => {
              this.getProfileDetails();
            }, 1000);
          }
          console.log('err', err);
        }
      );
    }
  }

  displayWith(value: any) {
    return value ? value.name : '';
  }
  active_immersive_view() {
    this.immersive_view = !this.immersive_view;
    // this.immersive_view = !this.immersive_view;
    this.transform_origin = 'top center';
    this.transformStr = 'scale(1)';
    this.edit_IV = true;
    if (this.scrollContainer && this.scrollContainer !== null) {
      this.scrollContainer.scrollTop = 0;
    }
    this.scrolling = false;

    this.zoom_per = 100;
    this.zoom_val = 10;

    let container = document.getElementById('container');
    if (container !== null) {
      container.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  transformStr: string = 'scale(1)';
  transform_origin: string = 'top center';
  transformStr2: string = 'scale(1)';
  transform_origin2: string = '0% 0%';
  transformed_title: string = '20';
  transformed_display_text: string = '20';
  transformed_height: string = 'full';
  scrolling: boolean = false;
  // Inside the PagesPage class
  // ...
  zoom_per = 100;
  setZoom(num, zoom) {
    const el = document.getElementsByClassName('immersive_container')[0];
    //const childElements = el.getElementsByClassName('block-box');

    let transformOrigin = [0, 0];
    const s = 'scale(' + zoom + ')';
    const oString =
      transformOrigin[0] * 100 + '% ' + transformOrigin[1] * 100 + '%';

    // for (let i = 0; i < childElements.length; i++) {
    //   const childEl = childElements[i] as HTMLElement;
    //   childEl.style.transform = s;
    //   childEl.style.transformOrigin = oString;
    // }

    if (num === 2) {
      this.transformStr = s;
    } else if (num === 1) {
      this.transformStr2 = s;
    }

    if (this.scrollContainer !== null) {
      const isAtBottom =
        this.scrollContainer.scrollHeight - this.scrollContainer.scrollTop ===
        this.scrollContainer.clientHeight;
      const isAtTop = this.scrollContainer.scrollTop <= 512;

      if (isAtBottom && this.scrolling === true) {
        this.transform_origin = 'bottom center';
      } else if (isAtTop) {
        this.transform_origin = 'top center';
      }
      const scrollTop = this.scrollContainer.scrollTop;
      const scrollHeight = this.scrollContainer.scrollHeight;

      // Calculate percentage of scroll position
      const scrollPercentage = scrollTop / scrollHeight;

      // Map scroll percentage to desired range for transform origin
      const horizontalOrigin = '50%';
      const verticalOrigin = `${scrollPercentage * 100}%`;

      // Set the dynamic transform origin
      this.transform_origin = `${horizontalOrigin} ${verticalOrigin}`;
    }
  }

  showVal() {
    this.ZoomScale = Number(this.zoom_val) / 10;
    this.zoom_per = this.ZoomScale * 100;
    if (this.ZoomScale === 1) {
      this.transformed_title = '20';
      this.transformed_display_text = '15';
      this.transformed_height = 'full!important';
    } else {
      this.transformed_title = '10';
      this.transformed_display_text = '7';
      this.transformed_height = '200px!important';
    }
    this.setZoom(2, this.ZoomScale);
  }

  dragIndex;
  inndexx = -1;
  dropzone: boolean = false;
  add_new_block_connector: boolean = false;
  onDragStarted(event, index) {
    this.dragIndex = index;
    this.dropzone = true;

    // if (this.ZoomScale === 1) {
    //   this.transformed_title = '20';
    //   this.transformed_display_text = '15';
    //   this.transformed_height = 'full!important';
    // } else {
    //   this.transformed_title = '10';
    //   this.transformed_display_text = '7';
    //   this.transformed_height = '200px!important';
    // }
    // this.setZoom(1, this.ZoomScale);
  }

  onDragEnded(event) {
    this.dragIndex = -1;
    this.inndexx = -1;
    // if (this.ZoomScale === 1) {
    //   this.transformed_title = '20';
    //   this.transformed_display_text = '15';
    //   this.transformed_height = 'full!important';
    // } else {
    //   this.transformed_title = '10';
    //   this.transformed_display_text = '7';
    //   this.transformed_height = '200px!important';
    // }
    //this.setZoom(1, 1);
  }

  zoomLevel = 1; // Initial zoom level

  zoomIn(): void {
    this.zoomLevel += 0.1; // Adjust the zoom increment as desired
    this.setZoomLevel();
  }

  zoomOut(): void {
    if (this.zoomLevel >= 0.5) {
      this.zoomLevel -= 0.1; // Adjust the zoom decrement as desired

      this.setZoomLevel();
    }
  }

  private setZoomLevel(): void {
    if (this.zoomLevel < 0.5) {
      this.zoomLevel = 0.5; // Set a minimum zoom level to avoid excessive zoom out
    } else if (this.zoomLevel > 2) {
      this.zoomLevel = 2; // Set a maximum zoom level to avoid excessive zoom in
    }
  }

  changeView(event) {
    const resizeEvent = new UIEvent('resize');

    window.dispatchEvent(resizeEvent);
  }
  kickContentViewer() {
    let l = this.article_id;
    let url = `content_viewer`;
    this.delete(url, l, this.auth.token)
      .pipe(
        filter((data) => data !== null && data.length !== 0),
        take(1)
      )
      .subscribe(
        async (res) => {
          const t = await this.toast.create({
            message: `${this.contentViewer.email} kicked successfully.`,
            position: 'bottom',
            duration: 5000,
            color: 'secondary',
          });
          await t.present();
          this.contentViewer = null;
          this.isConfirmationToKickUserOpen = false;
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            //this.auth.refreshtoken();
            setTimeout(() => {
              this.kickContentViewer();
            }, 1000);
          }
          console.log(err);
          await this.showErr(err.error.message);
        }
      );
  }

  kickOutContentViewer() {
    let l = {
      id: 0,
      user_id: 0,
      delete: true,
    };
    l.id = parseInt(this.article_id);

    l.user_id = this.contentViewer.user_id;
    let url = `content_viewer`;
    this.put(url, l, this.auth.token)
      .pipe(
        filter((data) => data !== null && data.length !== 0),
        take(1)
      )
      .subscribe(
        async (res) => {
          const t = await this.toast.create({
            message: `${this.contentViewer.email} kicked successfully.`,
            position: 'bottom',
            duration: 5000,
            color: 'secondary',
          });
          await t.present();
          this.contentViewer = null;
          this.isConfirmationToKickUserOpen = false;
        },
        async (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            //this.auth.refreshtoken();
            setTimeout(() => {
              this.kickOutContentViewer();
            }, 1000);
          }
          console.log(err);
          await this.showErr(err.error.message);
        }
      );
  }

  delete(url: string, id: any, token: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      }),
      body: {
        id: id,
      },
    };

    return this.http.delete(environment.api_url + url, options);
  }

  put(url: string, body, token: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      }),
    };

    return this.http.put(environment.api_url + url, body, options);
  }

  async showErr(msg) {
    const t = await this.toast.create({
      header: 'Error',
      message: msg,
      position: 'bottom',
      duration: 5000,
      color: 'danger',
    });
    await t.present();
  }

  addBlock() {
    this.INX = null;
    this.isEditWidget = false;
    this.widgetType = null;
    this.widgetSource = null;
    this.numberSource = '';
    this.socialCommentSource = 'Select Social Url Type';
    this.name = '';
    this.Widget_author = '';
    this.pubname = '';
    this.ID = null;
    this.number = null;
    this.url = '';
    this.uploadImage = false;
    this._meta_description = null;
    this._keywords = null;
    this.description = '';
    this.image_keyword = '';
    this.searchLinkVar2 = '';
    this.searchLinkVar = '';
    this._image_url = null;
    this.images = [];
    this.files = [];
    this.widgetCardType = null;
    this.dateObj = null;

    this.playerStatsInfo = {
      data: [],
      page: 1,
      size: 10,
      totalRecords: 0,
    };

    this.playerStatsObj = {
      data: [],
      page: 1,
      size: 10,
      totalRecords: 0,
    };
    this.playerKeyword = '';
    this.playerKeyword1 = null;
    this.playerKeyword2 = null;
    this.playerKey1 = false;
    this.playerKey2 = false;
    this.playerInfo = null;
    this.playerName = null;
    this.playerNumber = null;
    this.playerTeam = null;
    this.playerLeague = null;
    this.playerTeam_color_1 = null;
    this.playerTeam_color_2 = null;
    this.playerHeight = null;
    this.playerWeight = null;
    this.playerPosition = null;
    this.playerAge = null;
    this.playerBirth = null;

    this.teamKeyword = '';
    this.teamInfo = null;
    this.teamName = null;
    this.teamFounded = null;
    this.teamTotalTitles = null;
    this.teamLastTitle = null;
    this.teamStadium = null;
    this.teamLoc = null;
    this.teamCapacity = null;
    this.teamHeadCoach = null;

    this.leagueKeyword = '';
    this.leagueInfo = null;
    this.leagueName = null;
    this.leagueFounded = null;
    this.leagueTotalTitles = null;
    this.leagueLastTitle = null;
    this.leagueRunnerUp = null;
    this.leagueResults = null;

    this.divisionKeyword = '';
    this.divisionInfo = null;
    this.divisionName = null;
    this.divisionFounded = null;
    this.divisionTotalTitles = null;
    this.divisionLastTitle = null;

    this.is_spotlight = false;
    this.spotlightSelection = 'select spotlight';
    this.spotlightPlayerSlug = '';
    this.chartShow = false;
    this.imageType = 'gallery';
    this.widgetStatType = 'Select Content Block Stats';
    this.widgetCardType = 'Select Block Card Type';
    this.gamePropertyVal = 'Select Sort';
    this.gameTypeVal = 'Select Stat Type';
    this.leagueVal = 'Select League';
    this.divisionVal = 'Select Division';
    this.conferenceVal = 'Select Conference';
    this.teamVal = 'Select Team';
    this.playerVal1 = 'Select Player 1';
    this.playerVal2 = 'Select Player 2';
    this.gameSortVal = 'desc';
    this.leagueStatVal = null;
    this.standings = {
      data: [],
    };
    this.upcomings = {
      data: [],
    };
    this.sportsKey = null;
    this.leagueGameType = 'Select League Game Type';
    this.widgetUpcomingType = 'Select Content Block Upcoming';
    this.widgetStandingType = 'Select Content Block Standing';
    this.OBJID = null;
    this.size = 10;
    this.lmt = 15;
    this.bll.reset_imagn_data();
    this.bll.reset_search();
    this.edit_IV = false;
    this.showAddBlock = true;
  }

  tab_blocks_loading: boolean = false;
  SelectBlockType(block) {
    this.blocktypeSelected = block;

    if (block === 'new') {
      this.ionLoaderService.simpleLoader('Loading ...');
      this.immersiveViewWidgetArr = [];
      this.widgetTypeArr.map((widget) => {
        this.immersiveViewWidgetArr.push(widget);
      });
      setTimeout(() => {
        this.ionLoaderService.dismissLoader();
      }, 500);
    }
    if (block === 'library') {
      this.tab_blocks_loading = true;
      this.get_blocks(1, 10, this.auth.token, '').subscribe(
        (block) => {
          block.data = block.data.filter((element: any) => {
            return element.main_content !== null;
          });
          block.data.forEach((element: any) => {
            if (element.type === 'social_comment') {
              element.id = null;
            }

            if (element.type == 'twitter') {
              if (element.main_content) {
                let twitter = { twitter: element.main_content };
                element['main_content'] = twitter;
              }
            }
            if (element.type == 'video') {
              if (element.main_content) {
                let video = { video: element.main_content };
                element['main_content'] = video;
              }
            }

            if (element.type == 'image') {
              if (element.main_content) {
                let image = { image: element.main_content };
                element['main_content'] = image;
              }
            }

            if (element.type == 'person') {
              if (element.main_content) {
                if (element.main_content) {
                  let person = { person: element.main_content };
                  element['main_content'] = person;
                }
              }
            }
            if (element.type == 'organization') {
              if (element.main_content) {
                let organization = { organization: element.main_content };
                element['main_content'] = organization;
              }
            }

            if (element.type == 'other') {
              if (element.main_content) {
                let other = { other: element.main_content };
                element['main_content'] = other;
              }
            }
            if (element.type == 'social_comment') {
              if (element.main_content) {
                let social_comment = {
                  social_comment: element.main_content,
                };
                element['main_content'] = social_comment;
              }
            }
            if (element.type == 'number') {
              if (element.main_content) {
                let number = { number: element.main_content };
                element['main_content'] = number;
              }
            }

            if (element.type == 'quote') {
              if (element.main_content) {
                let quote = { quote: element.main_content };
                element['main_content'] = quote;
              }
            }
            if (element.type == 'text') {
              if (element.main_content) {
                let text = { text: element.main_content };
                element['main_content'] = text;
              }
            }
            if (element.type == 'map') {
              if (element.main_content) {
                let map = { map: element.main_content };
                element['main_content'] = map;
              }
            }
            if (element.type == 'list') {
              if (element.main_content) {
                let list = { list: element.main_content };
                element['main_content'] = list;
              }
            }
            if (element.type == 'player_card') {
              if (element.main_content) {
                element.main_content.flip = 'inactive';
                let player_card = { player_card: element.main_content };
                element['main_content'] = player_card;
              }
            }

            if (element.type == 'info') {
              if (element.main_content) {
                let info = { info: element.main_content };
                element['main_content'] = info;
              }
            }
            if (element.type == 'stats') {
              if (element.main_content) {
                let stats = { stats: element.main_content };
                element['main_content'] = stats;
              }
            }
            if (element.type == 'standings') {
              if (element.main_content) {
                let standings = { standings: element.main_content };
                element['main_content'] = standings;
              }
            }
            if (element.type == 'chart') {
              if (element.main_content) {
                let chart = { chart: element.main_content };
                element['main_content'] = chart;
              }
            }
            if (element.type == 'datagrid') {
              if (element.main_content) {
                let datagrid = { datagrid: element.main_content };
                element['main_content'] = datagrid;
              }
            }
            if (element.type == 'upcoming_games') {
              if (element.main_content) {
                let upcoming_games = {
                  upcoming_games: element.main_content,
                };
                element['main_content'] = upcoming_games;
              }
            }
          });

          this.immersiveViewWidgetArr = [];
          if (block.data.length > 0) {
            block.data.map((widget) => {
              this.immersiveViewWidgetArr.push(widget);
            });
          }
          this.totalRecordsImmersiveBlockArr = parseInt(block.totalRecords);
          setTimeout(() => {
            this.tab_blocks_loading = false;
          }, 1000);
        },
        (err) => {
          this.tab_blocks_loading = false;
          if (err instanceof HttpErrorResponse && err.status === 401) {
            //this.auth.refreshtoken();
            setTimeout(() => {
              this.SelectBlockType(block);
            }, 1000);
          }
          console.log('err', err);
        }
      );
    }
    if (block === 'templates') {
      this.ionLoaderService.simpleLoader('Loading ...');
      this.get_templates(1, 10, this.auth.token, '').subscribe(
        (res) => {
          this.immersiveViewWidgetArr = [];
          if (res.data.length > 0) {
            res.data.map((t) => {
              const groupedData = {};
              t.template.blocks.forEach((block) => {
                const { type, ...rest } = block;
                // Check if the type exists in the groupedData object
                if (!groupedData[type]) {
                  groupedData[type] = {
                    type: type,
                    blocks: [
                      {
                        type,
                        ...rest,
                        slug: t.template.title.toLowerCase().replace(/ /g, '-'),
                      },
                    ],
                  };
                } else {
                  groupedData[type].blocks.push({ type, ...rest });
                }
              });
              const resultArray = Object.values(groupedData);

              this.immersiveViewWidgetArr.push({
                template: t.template.title,
                tree: resultArray,
              });
            });
          }
          this.ionLoaderService.dismissLoader();
        },
        (err) => {
          this.ionLoaderService.dismissLoader();
          if (err instanceof HttpErrorResponse && err.status === 401) {
            //this.auth.refreshtoken();
            setTimeout(() => {
              this.kickOutContentViewer();
            }, 1000);
          }
          console.log('err', err);
        }
      );
    }
    if (block === 'removed') {
      this.immersiveViewWidgetArr = [];
      this.removedBlocksArr.map((block) => {
        this.immersiveViewWidgetArr.push(block);
      });
    }
  }

  loadMoreLibraryBlocks(index) {
    let blockSearchKeyword = '';
    if (this.blockkeyword && this.blockkeyword !== '') {
      blockSearchKeyword = this.blockkeyword.toLowerCase();
    }

    this.ionLoaderService.simpleLoader('Loading Please Wait ...');
    this.get_blocks(1, index, this.auth.token, blockSearchKeyword).subscribe(
      (block) => {
        if (block.data.length > 0) {
          block.id = null;
          block.data = block.data.filter((element: any) => {
            return element.main_content !== null;
          });
          block.data.forEach((element: any) => {
            if (element.type === 'social_comment') {
              element.id = null;
            }
            if (element.type == 'twitter') {
              if (element.main_content) {
                let twitter = { twitter: element.main_content };
                element['main_content'] = twitter;
              }
            }
            if (element.type == 'video') {
              if (element.main_content) {
                let video = { video: element.main_content };
                element['main_content'] = video;
              }
            }

            if (element.type == 'image') {
              if (element.main_content) {
                let image = { image: element.main_content };
                element['main_content'] = image;
              }
            }

            if (element.type == 'person') {
              if (element.main_content) {
                if (element.main_content) {
                  let person = { person: element.main_content };
                  element['main_content'] = person;
                }
              }
            }
            if (element.type == 'organization') {
              if (element.main_content) {
                let organization = { organization: element.main_content };
                element['main_content'] = organization;
              }
            }

            if (element.type == 'other') {
              if (element.main_content) {
                let other = { other: element.main_content };
                element['main_content'] = other;
              }
            }
            if (element.type == 'social_comment') {
              if (element.main_content) {
                let social_comment = {
                  social_comment: element.main_content,
                };
                element['main_content'] = social_comment;
              }
            }
            if (element.type == 'number') {
              if (element.main_content) {
                let number = { number: element.main_content };
                element['main_content'] = number;
              }
            }

            if (element.type == 'quote') {
              if (element.main_content) {
                let quote = { quote: element.main_content };
                element['main_content'] = quote;
              }
            }
            if (element.type == 'text') {
              if (element.main_content) {
                let text = { text: element.main_content };
                element['main_content'] = text;
              }
            }
            if (element.type == 'player_card') {
              if (element.main_content) {
                element.main_content.flip = 'inactive';
                let player_card = { player_card: element.main_content };
                element['main_content'] = player_card;
              }
            }
            if (element.type == 'info') {
              if (element.main_content) {
                let info = { info: element.main_content };
                element['main_content'] = info;
              }
            }
            if (element.type == 'stats') {
              if (element.main_content) {
                let stats = { stats: element.main_content };
                element['main_content'] = stats;
              }
            }
            if (element.type == 'standings') {
              if (element.main_content) {
                let standings = { standings: element.main_content };
                element['main_content'] = standings;
              }
            }
            if (element.type == 'chart') {
              if (element.main_content) {
                let chart = { chart: element.main_content };
                element['main_content'] = chart;
              }
            }
            if (element.type == 'datagrid') {
              if (element.main_content) {
                let datagrid = { datagrid: element.main_content };
                element['main_content'] = datagrid;
              }
            }
            if (element.type == 'map') {
              if (element.main_content) {
                let map = { map: element.main_content };
                element['main_content'] = map;
              }
            }
            if (element.type == 'list') {
              if (element.main_content) {
                let list = { list: element.main_content };
                element['main_content'] = list;
              }
            }
            if (element.type == 'upcoming_games') {
              if (element.main_content) {
                let upcoming_games = {
                  upcoming_games: element.main_content,
                };
                element['main_content'] = upcoming_games;
              }
            }
          });
          block.data.forEach((element) => {
            let found = this.immersiveViewWidgetArr.some(
              (el) => el.id === element.id
            );
            if (!found) return this.immersiveViewWidgetArr.push(element);
          });
        }
        this.totalRecordsImmersiveBlockArr = parseInt(block.totalRecords);
        this.ionLoaderService.dismissLoader();
      },
      (err) => {
        this.ionLoaderService.dismissLoader();
        if (err instanceof HttpErrorResponse && err.status === 401) {
          //this.auth.refreshtoken();
          setTimeout(() => {
            this.loadMoreLibraryBlocks(index);
          }, 1000);
        }
        console.log('err', err);
      }
    );
  }

  searchForblock() {
    if (this.blocktypeSelected === null) {
      return this.showToast(
        'Please select block origin before you search anything !'
      );
    }
    if (this.blockkeyword === null) {
      this.ionLoaderService.simpleLoader('Loading ...');
      if (this.blocktypeSelected === 'new') {
        this.immersiveViewWidgetArr = [];
        this.widgetTypeArr.map((widget) => {
          this.immersiveViewWidgetArr.push(widget);
        });
        setTimeout(() => {
          this.ionLoaderService.dismissLoader();
        }, 500);
      }
      if (this.blocktypeSelected === 'library') {
        this.get_blocks(1, 10, this.auth.token, '').subscribe(
          (block) => {
            this.immersiveViewWidgetArr = [];
            if (block.data.length > 0) {
              block.data = block.data.filter((element: any) => {
                return element.main_content !== null;
              });
              block.data.forEach((element: any) => {
                if (element.type === 'social_comment') {
                  element.id = null;
                }
                if (element.type == 'twitter') {
                  if (element.main_content) {
                    let twitter = { twitter: element.main_content };
                    element['main_content'] = twitter;
                  }
                }
                if (element.type == 'video') {
                  if (element.main_content) {
                    let video = { video: element.main_content };
                    element['main_content'] = video;
                  }
                }

                if (element.type == 'image') {
                  if (element.main_content) {
                    let image = { image: element.main_content };
                    element['main_content'] = image;
                  }
                }

                if (element.type == 'person') {
                  if (element.main_content) {
                    if (element.main_content) {
                      let person = { person: element.main_content };
                      element['main_content'] = person;
                    }
                  }
                }
                if (element.type == 'organization') {
                  if (element.main_content) {
                    let organization = { organization: element.main_content };
                    element['main_content'] = organization;
                  }
                }

                if (element.type == 'other') {
                  if (element.main_content) {
                    let other = { other: element.main_content };
                    element['main_content'] = other;
                  }
                }
                if (element.type == 'social_comment') {
                  if (element.main_content) {
                    let social_comment = {
                      social_comment: element.main_content,
                    };
                    element['main_content'] = social_comment;
                  }
                }
                if (element.type == 'number') {
                  if (element.main_content) {
                    let number = { number: element.main_content };
                    element['main_content'] = number;
                  }
                }

                if (element.type == 'quote') {
                  if (element.main_content) {
                    let quote = { quote: element.main_content };
                    element['main_content'] = quote;
                  }
                }
                if (element.type == 'text') {
                  if (element.main_content) {
                    let text = { text: element.main_content };
                    element['main_content'] = text;
                  }
                }
                if (element.type == 'player_card') {
                  if (element.main_content) {
                    element.main_content.flip = 'inactive';
                    let player_card = { player_card: element.main_content };
                    element['main_content'] = player_card;
                  }
                }
                if (element.type == 'info') {
                  if (element.main_content) {
                    let info = { info: element.main_content };
                    element['main_content'] = info;
                  }
                }
                if (element.type == 'stats') {
                  if (element.main_content) {
                    let stats = { stats: element.main_content };
                    element['main_content'] = stats;
                  }
                }
                if (element.type == 'standings') {
                  if (element.main_content) {
                    let standings = { standings: element.main_content };
                    element['main_content'] = standings;
                  }
                }
                if (element.type == 'chart') {
                  if (element.main_content) {
                    let chart = { chart: element.main_content };
                    element['main_content'] = chart;
                  }
                }
                if (element.type == 'datagrid') {
                  if (element.main_content) {
                    let datagrid = { datagrid: element.main_content };
                    element['main_content'] = datagrid;
                  }
                }
                if (element.type == 'map') {
                  if (element.main_content) {
                    let map = { map: element.main_content };
                    element['main_content'] = map;
                  }
                }
                if (element.type == 'list') {
                  if (element.main_content) {
                    let list = { list: element.main_content };
                    element['main_content'] = list;
                  }
                }
                if (element.type == 'upcoming_games') {
                  if (element.main_content) {
                    let upcoming_games = {
                      upcoming_games: element.main_content,
                    };
                    element['main_content'] = upcoming_games;
                  }
                }
              });
              block.data.map((widget) => {
                this.immersiveViewWidgetArr.push(widget);
              });
            }
            this.totalRecordsImmersiveBlockArr = parseInt(block.totalRecords);
            this.ionLoaderService.dismissLoader();
          },
          (err) => {
            this.ionLoaderService.dismissLoader();
            if (err instanceof HttpErrorResponse && err.status === 401) {
              //this.auth.refreshtoken();
              setTimeout(() => {
                this.searchForblock();
              }, 1000);
            }
            console.log('err', err);
          }
        );
      }
      if (this.blocktypeSelected === 'templates') {
        this.get_templates(1, 10, this.auth.token, '').subscribe(
          (res) => {
            this.immersiveViewWidgetArr = [];
            if (res.data.length > 0) {
              res.data.map((t) => {
                const groupedData = {};
                t.template.blocks.forEach((block) => {
                  const { type, ...rest } = block;
                  // Check if the type exists in the groupedData object
                  if (!groupedData[type]) {
                    groupedData[type] = {
                      type: type,
                      blocks: [{ type, ...rest }],
                    };
                  } else {
                    groupedData[type].blocks.push({ type, ...rest });
                  }
                });
                const resultArray = Object.values(groupedData);

                this.immersiveViewWidgetArr.push({
                  template: t.template.title,
                  tree: resultArray,
                });
              });
            }
            this.ionLoaderService.dismissLoader();
          },
          (err) => {
            this.ionLoaderService.dismissLoader();
            if (err instanceof HttpErrorResponse && err.status === 401) {
              //this.auth.refreshtoken();
              setTimeout(() => {
                this.searchForblock();
              }, 1000);
            }
            console.log('err', err);
          }
        );
      }
      if (this.blocktypeSelected === 'removed') {
        setTimeout(() => {
          this.ionLoaderService.dismissLoader();
        }, 500);
      }
    }
    if (this.blockkeyword !== null && this.blocktypeSelected !== null) {
      let blockSearchKeyword = this.blockkeyword.toLowerCase();
      this.ionLoaderService.simpleLoader('Loading ...');
      if (this.blocktypeSelected === 'new') {
        let filteredArray = this.widgetTypeArr.filter((obj) => {
          return obj.toLowerCase().includes(blockSearchKeyword);
        });
        this.immersiveViewWidgetArr = [];
        filteredArray.map((widget) => {
          this.immersiveViewWidgetArr.push(widget);
        });
        setTimeout(() => {
          this.ionLoaderService.dismissLoader();
        }, 500);
      }
      if (this.blocktypeSelected === 'library') {
        this.get_blocks(1, 10, this.auth.token, blockSearchKeyword).subscribe(
          (block) => {
            this.immersiveViewWidgetArr = [];
            if (block.data.length > 0) {
              block.data = block.data.filter((element: any) => {
                return element.main_content !== null;
              });
              block.data.forEach((element: any) => {
                if (element.type === 'social_comment') {
                  element.id = null;
                }
                if (element.type == 'twitter') {
                  if (element.main_content) {
                    let twitter = { twitter: element.main_content };
                    element['main_content'] = twitter;
                  }
                }
                if (element.type == 'video') {
                  if (element.main_content) {
                    let video = { video: element.main_content };
                    element['main_content'] = video;
                  }
                }

                if (element.type == 'image') {
                  if (element.main_content) {
                    let image = { image: element.main_content };
                    element['main_content'] = image;
                  }
                }

                if (element.type == 'person') {
                  if (element.main_content) {
                    if (element.main_content) {
                      let person = { person: element.main_content };
                      element['main_content'] = person;
                    }
                  }
                }
                if (element.type == 'organization') {
                  if (element.main_content) {
                    let organization = { organization: element.main_content };
                    element['main_content'] = organization;
                  }
                }

                if (element.type == 'other') {
                  if (element.main_content) {
                    let other = { other: element.main_content };
                    element['main_content'] = other;
                  }
                }
                if (element.type == 'social_comment') {
                  if (element.main_content) {
                    let social_comment = {
                      social_comment: element.main_content,
                    };
                    element['main_content'] = social_comment;
                  }
                }
                if (element.type == 'number') {
                  if (element.main_content) {
                    let number = { number: element.main_content };
                    element['main_content'] = number;
                  }
                }

                if (element.type == 'quote') {
                  if (element.main_content) {
                    let quote = { quote: element.main_content };
                    element['main_content'] = quote;
                  }
                }
                if (element.type == 'text') {
                  if (element.main_content) {
                    let text = { text: element.main_content };
                    element['main_content'] = text;
                  }
                }
                if (element.type == 'player_card') {
                  if (element.main_content) {
                    element.main_content.flip = 'inactive';
                    let player_card = { player_card: element.main_content };
                    element['main_content'] = player_card;
                  }
                }
                if (element.type == 'info') {
                  if (element.main_content) {
                    let info = { info: element.main_content };
                    element['main_content'] = info;
                  }
                }
                if (element.type == 'stats') {
                  if (element.main_content) {
                    let stats = { stats: element.main_content };
                    element['main_content'] = stats;
                  }
                }
                if (element.type == 'standings') {
                  if (element.main_content) {
                    let standings = { standings: element.main_content };
                    element['main_content'] = standings;
                  }
                }
                if (element.type == 'chart') {
                  if (element.main_content) {
                    let chart = { chart: element.main_content };
                    element['main_content'] = chart;
                  }
                }
                if (element.type == 'datagrid') {
                  if (element.main_content) {
                    let datagrid = { datagrid: element.main_content };
                    element['main_content'] = datagrid;
                  }
                }
                if (element.type == 'map') {
                  if (element.main_content) {
                    let map = { map: element.main_content };
                    element['main_content'] = map;
                  }
                }
                if (element.type == 'list') {
                  if (element.main_content) {
                    let list = { list: element.main_content };
                    element['main_content'] = list;
                  }
                }
                if (element.type == 'upcoming_games') {
                  if (element.main_content) {
                    let upcoming_games = {
                      upcoming_games: element.main_content,
                    };
                    element['main_content'] = upcoming_games;
                  }
                }
              });
              block.data.map((widget) => {
                this.immersiveViewWidgetArr.push(widget);
              });
            }
            this.totalRecordsImmersiveBlockArr = parseInt(block.totalRecords);
            this.ionLoaderService.dismissLoader();
          },
          (err) => {
            this.ionLoaderService.dismissLoader();
            if (err instanceof HttpErrorResponse && err.status === 401) {
              //this.auth.refreshtoken();
              setTimeout(() => {
                this.searchForblock();
              }, 1000);
            }
            console.log('err', err);
          }
        );
      }
      if (this.blocktypeSelected === 'templates') {
        this.get_templates(
          1,
          10,
          this.auth.token,
          blockSearchKeyword
        ).subscribe(
          (res) => {
            this.immersiveViewWidgetArr = [];
            if (res.data.length > 0) {
              res.data.map((t) => {
                const groupedData = {};
                t.template.blocks.forEach((block) => {
                  const { type, ...rest } = block;
                  // Check if the type exists in the groupedData object
                  if (!groupedData[type]) {
                    groupedData[type] = {
                      type: type,
                      blocks: [{ type, ...rest }],
                    };
                  } else {
                    groupedData[type].blocks.push({ type, ...rest });
                  }
                });
                const resultArray = Object.values(groupedData);

                this.immersiveViewWidgetArr.push({
                  template: t.template.title,
                  tree: resultArray,
                });
              });
            }
            this.ionLoaderService.dismissLoader();
          },
          (err) => {
            this.ionLoaderService.dismissLoader();
            if (err instanceof HttpErrorResponse && err.status === 401) {
              //this.auth.refreshtoken();
              setTimeout(() => {
                this.searchForblock();
              }, 1000);
            }
            console.log('err', err);
          }
        );
      }
    }
  }

  selectWidgetFromService(block) {
    this.widgetType = block;
  }
  selectBlockFromService(w, type?: any) {
    if (
      this.blocktypeSelected === 'library' ||
      this.blocktypeSelected === 'removed' ||
      this.blocktypeSelected === 'templates'
    ) {
      if (this.blocktypeSelected === 'removed') {
        // const innerObject = Object.values(w.main_content)[0];
        // w.main_content = innerObject;
      }

      this.widgetType = w.type;
      this.image_keyword = '';
      this.bll.reset_imagn_data();
      this.is_title = w.is_title;
      this.is_text = w.is_text;
      this.isEdit = false;

      if (w.source === 'wikipedia') {
        this.widgetType = w.source;
        this.widgetSource = w.type;
      } else {
        this.widgetType = w.type;
        this.widgetSource = w.source;
      }

      if (w.type === 'image') {
        if (w.main_content.image !== null) {
          var imagesArr = [];
          this.images = [];
          this.files = [];
          this.localimages = [];
          this.showCaptions = w.main_content?.image.showCaptions;
          imagesArr = w.main_content?.image.image;
          this.imageType = w.main_content?.image.type;
          imagesArr.map((img) => {
            let a = {
              previewUrl: img.url,
              caption: img.description,
              credit: img.source,
              position: null,
            };
            this.images.push(a);
            this.localimages.push(a);
          });
        }
      }

      this.description = w.display_text ? this.regex(w.display_text) : '';

      if (w.type === 'social_comment') {
        this.socialCommentSource = w.main_content.social_comment.source;
        if (w.main_content.social_comment.date.includes('Z')) {
          const date = new Date(w.main_content.social_comment.date);

          // Extract date and time components
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const day = date.getDate().toString().padStart(2, '0');
          const hours = date.getHours().toString().padStart(2, '0');
          const minutes = date.getMinutes().toString().padStart(2, '0');
          const seconds = date.getSeconds().toString().padStart(2, '0');

          // Format components into datetime-local string
          const formattedString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

          // Output formatted datetime string

          this.dateObj = formattedString;
        } else {
          this.dateObj = w.main_content.social_comment.date;
        }

        if (w.source === 'reddit') {
          this.description = w.main_content.social_comment.comment
            ? w.main_content.social_comment.comment
            : '';
        } else {
          this.description = w.main_content.social_comment.comment
            ? w.main_content.social_comment.comment
            : '';
        }
        this.url = w.main_content.social_comment.url;
      }

      if (w.type === 'map') {
        this.googleMapService.lat = w.main_content.map.latitude;
        this.googleMapService.long = w.main_content.map.longitude;
        this.googleMapService.zoom = w.main_content.map.zoom;
        this.googleMapService.mapTypeId = w.main_content.map.mapTypeId
          ? w.main_content.map.mapTypeId
          : google.maps.MapTypeId.ROADMAP;
      }

      if (w.type === 'number') {
        this.number = w.main_content.number.number;
      }

      if (w.type === 'info' && w.main_content.info.type === 'player') {
        this.playerInfo = w.main_content.info;
        this.playerTeam = w.main_content.info.team;
        this.playerTeam_color_1 = w.main_content.info.team_color_1;
        this.playerTeam_color_2 = w.main_content.info.team_color_2;
        this.playerName = w.main_content.info.name;
        this.playerNumber = w.main_content.info.number;
        this.playerHeight = this.regex(w.main_content.info.height);
        this.playerWeight = w.main_content.info.weight;
        this.playerPosition = w.main_content.info.position;
        this.playerBirth = w.main_content.info.dob
          ? moment(w.main_content.info.dob).local().format('MM-DD-YYYY')
          : null;
        this.widgetCardType = 'player_card';
      }

      if (w.type === 'info' && w.main_content.type === 'team') {
        this.teamInfo = w.main_content.info;
        this.teamName = w.main_content.info.name;
        this.teamFounded = w.main_content.info.date_found;
        this.teamTotalTitles = w.main_content.info.championships;
        this.teamLastTitle = w.main_content.info.last_championship;
        this.teamStadium = w.main_content.info.stadium;
        this.teamLoc = w.main_content.info.city;
        this.teamCapacity = w.main_content.info.stadium_capacity;
        this.teamHeadCoach = w.main_content.info.head_coach;
        this.widgetCardType = 'team_card';
      }

      if (w.type === 'info' && w.main_content.info.type === 'league') {
        this.leagueInfo = w.main_content.info;
        this.leagueName = w.main_content.info.name;
        this.leagueFounded = w.main_content.info.date_found;
        this.leagueTotalTitles = w.main_content.info.most_league_titles;
        this.leagueLastTitle = w.main_content.info.champion;
        this.leagueRunnerUp = w.main_content.info.runner_up;
        this.leagueResults = w.main_content.info.last_league_result;
        this.widgetCardType = 'league_card';
      }

      if (w.type === 'info' && w.main_content.info.type === 'division') {
        this.divisionInfo = w.main_content.info;
        this.divisionName = w.main_content.info.name;
        this.divisionFounded = w.main_content.info.date_found;
        this.divisionTotalTitles = w.main_content.info.most_titles;
        this.divisionLastTitle = w.main_content.info.champion;
        this.widgetCardType = 'division_card';
      }

      if (w.type === 'info' && w.main_content.info.type === 'conference') {
        this.conferenceInfo = w.main_content.info;
        this.conferenceName = w.main_content.info.name;
        this.conferenceFounded = w.main_content.info.date_found;
        this.conferenceLastTitle = w.main_content.info.champion;
        this.conferenceRunnerUp = w.main_content.info.runner_up;
        this.conferenceResult = w.main_content.info.last_conference_result;
        this.widgetCardType = 'conference_card';
      }
      this.checkedList = [];

      if (w.type === 'stats') {
        this.playerStatsInfo.data = [];
        this.playerStatsObj.data = [];
        w.main_content.stats.obj.data.map((obj) => {
          this.playerStatsObj.data.push(obj);
        });

        if (this.playerStatsObj.data) {
          // this.seasonVal = this.playerStatsObj.data[0].type;
          if (this.playerStatsObj.data[0].type !== undefined) {
            // const delimiterPos =
            //   this.playerStatsObj.data[0].type.lastIndexOf('-');
            // this.yearVal = this.playerStatsObj.data[0].type.substring(
            //   0,
            //   delimiterPos
            // );
            // this.seasonVal = this.playerStatsObj.data[0].type.substring(
            //   delimiterPos + 1
            // );
          }
        }
        // w.main_content.stats.obj.data.map(obj => {
        //   this.playerStatsInfo.data.push(obj);
        // });
        // w.main_content.stats.obj.data.map((obj)=>{
        //   console.log('obj',obj)
        //   this.playerStatsInfo.data.push(obj);
        //   this.playerStatsObj.data.push(obj);
        // })
        this.widgetStatType = w.main_content.stats.stat_type;

        this.playerStatsInfo.totalRecords =
          w.main_content.stats.obj.data.length;
        this.playerStatsObj.totalRecords = w.main_content.stats.obj.data.length;

        this.leagueVal = w.main_content.stats.league;
        this.gameTypeVal = w.main_content.stats.type;
        this.gamePropertyVal = w.main_content.stats.property;
        this.checkedList = w.main_content.stats.filters;
        this.size = w.main_content.stats.size ? w.main_content.stats.size : 10;

        if (this.widgetStatType === 'player_stats') {
          w.main_content.stats.obj.data.map((player, index) => {
            if (index === 0) {
              this.playerKeyword1 = player.player_name;
              this.playerKey1 = true;
            } else {
              this.playerKeyword2 = player.player_name;
              this.playerKey2 = true;
            }
          });
        }
        if (this.widgetStatType === 'team_stats') {
          this.teamVal = w.main_content.stats.team_id;
          this.OBJID = w.main_content.stats.team_id;
          if (this.leagueVal === 'NFL') {
            this.leagueID = 55;
            this.getTeamList(null);
          }
          if (this.leagueVal === 'NHL') {
            this.leagueID = 40;
            this.getTeamList(null);
          }
          if (this.leagueVal === 'NBA') {
            this.leagueID = 42;
            this.getTeamList(null);
          }
          if (this.leagueVal === 'MLB') {
            this.leagueID = 43;
            this.getTeamList(null);
          }

          this.is_spotlight = w.main_content.stats.is_spotlight;
          this.spotlightSelection = w.main_content.stats.spotlightSelection;
          this.spotlightPlayerSlug = w.main_content.stats.spotlightSlug;
        }
        if (this.widgetStatType === 'division_stats') {
          this.divisionVal = w.main_content.stats.division_id;
          this.OBJID = w.main_content.stats.division_id;
          this.getTeamList(null);
          this.is_spotlight = w.main_content.stats.is_spotlight;
          this.spotlightSelection = w.main_content.stats.spotlightSelection;
          this.spotlightPlayerSlug = w.main_content.stats.spotlightSlug;
        }
        if (this.widgetStatType === 'conference_stats') {
          this.conferenceVal = w.main_content.stats.conference_id;
          this.OBJID = w.main_content.stats.conference_id;
          this.getTeamList(null);
          this.is_spotlight = w.main_content.is_spotlight;
          this.spotlightSelection = w.main_content.stats.spotlightSelection;
          this.spotlightPlayerSlug = w.main_content.stats.spotlightSlug;
        }

        if (this.widgetStatType === 'league_stats') {
          this.is_spotlight = w.main_content.stats.is_spotlight;
          this.spotlightSelection = w.main_content.stats.spotlightSelection;
          this.spotlightPlayerSlug = w.main_content.stats.spotlightSlug;
        }

        // this.widgetStatType= w.main_content.stattype
        if (this.leagueVal === 'NBA' && this.gameTypeVal === 'offense') {
          this.NBACheckboxes.map((obj) => {
            if (this.checkedList.includes(obj.name)) {
              obj.checked = true;
            } else {
              obj.checked = false;
            }
          });
        }
        if (this.leagueVal === 'NHL' && this.gameTypeVal === 'goaltending') {
          this.NHLCheckboxes1.map((obj) => {
            if (this.checkedList.includes(obj.name)) {
              obj.checked = true;
            } else {
              obj.checked = false;
            }
          });
        }
        if (this.leagueVal === 'NHL' && this.gameTypeVal === 'scoring') {
          this.NHLCheckboxes2.map((obj) => {
            if (this.checkedList.includes(obj.name)) {
              obj.checked = true;
            } else {
              obj.checked = false;
            }
          });
        }

        if (this.leagueVal === 'NFL' && this.gameTypeVal === 'passing') {
          this.NFLCheckboxes1.map((obj) => {
            if (this.checkedList.includes(obj.name)) {
              obj.checked = true;
            } else {
              obj.checked = false;
            }
          });
        }

        if (this.leagueVal === 'NFL' && this.gameTypeVal === 'rushing') {
          this.NFLCheckboxes2.map((obj) => {
            if (this.checkedList.includes(obj.name)) {
              obj.checked = true;
            } else {
              obj.checked = false;
            }
          });
        }
        if (this.leagueVal === 'NFL' && this.gameTypeVal === 'receiving') {
          this.NFLCheckboxes3.map((obj) => {
            if (this.checkedList.includes(obj.name)) {
              obj.checked = true;
            } else {
              obj.checked = false;
            }
          });
        }

        if (this.leagueVal === 'NFL' && this.gameTypeVal === 'scoring') {
          this.NFLCheckboxes4.map((obj) => {
            if (this.checkedList.includes(obj.name)) {
              obj.checked = true;
            } else {
              obj.checked = false;
            }
          });
        }

        if (
          this.leagueVal === 'NFL' &&
          (this.gameTypeVal === 'defense' ||
            this.gameTypeVal === 'tackles' ||
            this.gameTypeVal === 'interceptions' ||
            this.gameTypeVal === 'fumbles' ||
            this.gameTypeVal === 'tackles,interceptions,fumbles')
        ) {
          this.NFLCheckboxes5.map((obj) => {
            if (this.checkedList.includes(obj.name)) {
              obj.checked = true;
            } else {
              obj.checked = false;
            }
          });
        }

        if (this.leagueVal === 'MLB' && this.gameTypeVal === 'batting') {
          this.MLBCheckboxes1.map((obj) => {
            if (this.checkedList.includes(obj.name)) {
              obj.checked = true;
            } else {
              obj.checked = false;
            }
          });
        }

        if (this.leagueVal === 'MLB' && this.gameTypeVal === 'pitching') {
          this.MLBCheckboxes2.map((obj) => {
            if (this.checkedList.includes(obj.name)) {
              obj.checked = true;
            } else {
              obj.checked = false;
            }
          });
        }

        if (
          w.main_content.stats.is_spotlight === true &&
          w.main_content.stats.spotlightSelection === 'league-leader'
        ) {
          this.getPlayersForStats3();
        }
      }

      if (w.type === 'standings') {
        this.widgetStandingType = w.main_content.standings.standing_type;
        this.leagueVal = w.main_content.standings.league;
        this.conferenceVal = w.main_content.standings.league_conference;
        this.divisionVal = w.main_content.standings.division;
        this.teamVal = w.main_content.standings.team;
        this.standings = w.main_content.standings.standing;
        if (this.leagueVal === 'NFL') {
          this.leagueID = 55;
          this.getTeamList(null);
        }
        if (this.leagueVal === 'NHL') {
          this.leagueID = 40;
          this.getTeamList(null);
        }
        if (this.leagueVal === 'NBA') {
          this.leagueID = 42;
          this.getTeamList(null);
        }
        if (this.leagueVal === 'MLB') {
          this.leagueID = 43;
          this.getTeamList(null);
        }
      }

      if (w.type === 'upcoming_games') {
        this.widgetUpcomingType = w.main_content.upcoming_games.upcoming_type;
        this.leagueGameType = w.main_content.upcoming_games.league_game_type;
        this.leagueVal = w.main_content.upcoming_games.league;
        this.conferenceVal = w.main_content.upcoming_games.league_conference;
        this.divisionVal = w.main_content.upcoming_games.division;
        this.teamVal = w.main_content.upcoming_games.team;
        this.sportsKey = w.main_content.upcoming_games.my_sports_key;
        this.upcomings = w.main_content.upcoming_games.upcomings;
        if (this.leagueVal === 'NFL') {
          this.leagueID = 55;
          this.getTeamList(null);
        }
        if (this.leagueVal === 'NHL') {
          this.leagueID = 40;
          this.getTeamList(null);
        }
        if (this.leagueVal === 'NBA') {
          this.leagueID = 42;
          this.getTeamList(null);
        }
        if (this.leagueVal === 'MLB') {
          this.leagueID = 43;
          this.getTeamList(null);
        }
      }

      if (w.type === 'chart') {
        this.chartVal = w.main_content.chart.charttype;
        this.leagueVal = w.main_content.chart.league;
        this.gameTypeVal = w.main_content.chart.type;
        this.gamePropertyVal = w.main_content.chart.property;
        this.graphValues = w.main_content.chart.dataobj;
        this.graphNames = w.main_content.chart.nameobj;
        this.size = w.main_content.chart.size ? w.main_content.chart.size : 10;
        this.chartOptions = w.main_content.chart.chartOptions;

        this.tableData = w.main_content.chart.nameobj.map((name, index) => ({
          name,
          score: w.main_content.chart.dataobj[index],
        }));

        setTimeout(() => {
          this.checkPost();
        }, 1000);
      }

      this.name = w.title ? this.regex(w.title) : '';
      this.Widget_author = w.author ? this.regex(w.author) : '';

      if (
        w.type === 'social_comment' &&
        w.main_content.social_comment.source === 'twitter'
      ) {
        this.Widget_author = w.main_content.social_comment.username
          ? w.main_content.social_comment.username
          : '';
      }
      if (
        w.type === 'social_comment' &&
        w.main_content.social_comment.source === 'reddit'
      ) {
        this.Widget_author = w.main_content.social_comment.name
          ? w.main_content.social_comment.name
          : '';
      }

      if (w.type === 'quote') {
        this.description = w.main_content.quote.text
          ? this.regex(w.main_content.quote.text)
          : '';
        if (w.main_content.quote.speaker) {
          this.Widget_author = w.main_content.quote.speaker
            ? this.regex(w.main_content.quote.speaker)
            : '';
        }
        if (w.main_content.quote.pubName) {
          this.pubname = w.main_content.quote.pubName
            ? this.regex(w.main_content.quote.pubName)
            : '';
        }
        if (w.main_content.quote.url) {
          this.url = w.main_content.quote.url;
        }
      }
      if (w.type === 'text') {
        this.description = w.main_content.text.text
          ? this.regex(w.main_content.text.text)
          : '';
      }

      if (w.type === 'twitter') {
        if (w.main_content.twitter.id) {
          this.url = w.main_content.twitter.url;
        }
      }
      if (w.type === 'video') {
        if (w.main_content.video.url) {
          this.url = w.main_content.video.url;
        }
        if (w.main_content.video.title) {
          this.name = w.main_content.video.title;
        }
        if (w.main_content.video) {
          this.youtubeObj = w.main_content.video;
        }
      }

      this._meta_description = w.meta_description;
      this._keywords = w.keywords;
      this.content_id = w.content_id;
      this._image_url = w.image_url;

      // let checkCurrentTime = new Date(w.created_at);
      // let momentCurrentTime = moment(checkCurrentTime).local().format('YYYY-MM-DD HH:mm:ss');
      // this.created_at = checkCurrentTime;
      // this.created_at = moment(w.created_at).format();
      // console.log('created_at',this.created_at);
      // let checkCurrentTime = new Date(w.created_at);
      // console.log('timezone check time: ', checkCurrentTime);
      // console.log('moment converted time: ', momentCurrentTime);
      this.modified_at = w.modified_at;
      this._status = w.status;

      if (this.click_block) {
        this.isEditWidget = true;
        this.widget_id = this.new_obj.id;
      }

      this.lmt = 15;
      this.bll.reset_imagn_data();
    }
  }
  closeWidgetMenu() {
    if (
      (this.validation_obj.is_extract !== this.is_extract ||
        this.validation_obj.wiki_extract !== this.wiki_extract ||
        this.validation_obj.wiki_url !== this.wiki_url ||
        this.validation_obj.wiki_source_title !== this.wiki_source_title ||
        JSON.stringify(this.validation_obj.wiki_sections) !==
          JSON.stringify(this.wiki_sections) ||
        this.validation_obj.wiki_keyword !== this.wiki_keyword ||
        this.validation_obj.isWidgetSelectOpen !== this.isWidgetSelectOpen ||
        this.validation_obj.keywordToSearchTeam !== this.keywordToSearchTeam ||
        JSON.stringify(this.validation_obj.twitter_teams_arr) !==
          JSON.stringify(this.twitter_teams_arr) ||
        this.validation_obj.twitter_search_box !== this.twitter_search_box ||
        this.validation_obj.wiki_title !== this.wiki_title ||
        this.validation_obj.wiki_thumbnail !== this.wiki_thumbnail ||
        JSON.stringify(this.validation_obj.wiki_dropdown_data) !==
          JSON.stringify(this.wiki_dropdown_data) ||
        this.validation_obj.wiki_dropdown_show !== this.wiki_dropdown_show ||
        this.validation_obj.color_column !== this.color_column ||
        this.validation_obj.name !== this.name ||
        this.validation_obj.Widget_author !== this.Widget_author ||
        this.validation_obj.pubname !== this.pubname ||
        this.validation_obj.number !== this.number ||
        this.validation_obj.url !== this.url ||
        this.validation_obj.listView !== this.listView ||
        this.validation_obj.image_keyword !== this.image_keyword ||
        this.validation_obj._meta_description !== this._meta_description ||
        this.validation_obj._keywords !== this._keywords ||
        this.validation_obj.description !== this.description ||
        this.validation_obj.selectedImg !== this.selectedImg ||
        this.validation_obj.searchLinkVar2 !== this.searchLinkVar2 ||
        this.validation_obj.searchLinkVar !== this.searchLinkVar ||
        this.validation_obj._image_url !== this._image_url ||
        JSON.stringify(this.validation_obj.images) !==
          JSON.stringify(this.images) ||
        JSON.stringify(this.validation_obj.files) !==
          JSON.stringify(this.files) ||
        this.validation_obj.lat !== this.googleMapService.lat ||
        this.validation_obj.long !== this.googleMapService.long ||
        this.validation_obj.zoom !== this.googleMapService.zoom ||
        this.validation_obj.editItemActive !== this.editItemActive ||
        this.validation_obj.selectedText !== this.selectedText ||
        this.validation_obj.item_obj !== this.item_obj ||
        JSON.stringify(this.validation_obj.list) !==
          JSON.stringify(this.list) ||
        this.validation_obj.dateObj !== this.dateObj ||
        JSON.stringify(this.validation_obj.playerStatsInfo) !==
          JSON.stringify(this.playerStatsInfo) ||
        JSON.stringify(this.validation_obj.playerStatsObj) !==
          JSON.stringify(this.playerStatsObj) ||
        this.validation_obj.playerKeyword !== this.playerKeyword ||
        this.validation_obj.playerKeyword1 !== this.playerKeyword1 ||
        this.validation_obj.playerKeyword2 !== this.playerKeyword2 ||
        this.validation_obj.playerKey1 !== this.playerKey1 ||
        this.validation_obj.playerKey2 !== this.playerKey2 ||
        this.validation_obj.playerName !== this.playerName ||
        this.validation_obj.playerNumber !== this.playerNumber ||
        this.validation_obj.playerTeam !== this.playerTeam ||
        this.validation_obj.playerLeague !== this.playerLeague ||
        this.validation_obj.playerHeight !== this.playerHeight ||
        this.validation_obj.playerWeight !== this.playerWeight ||
        this.validation_obj.playerPosition !== this.playerPosition ||
        this.validation_obj.playerAge !== this.playerAge ||
        this.validation_obj.playerBirth !== this.playerBirth ||
        this.validation_obj.teamKeyword !== this.teamKeyword ||
        this.validation_obj.teamInfo !== this.teamInfo ||
        this.validation_obj.teamName !== this.teamName ||
        this.validation_obj.teamFounded !== this.teamFounded ||
        this.validation_obj.teamTotalTitles !== this.teamTotalTitles ||
        this.validation_obj.teamLastTitle !== this.teamLastTitle ||
        this.validation_obj.teamStadium !== this.teamStadium ||
        this.validation_obj.teamLoc !== this.teamLoc ||
        this.validation_obj.teamCapacity !== this.teamCapacity ||
        this.validation_obj.teamHeadCoach !== this.teamHeadCoach ||
        this.validation_obj.leagueKeyword !== this.leagueKeyword ||
        this.validation_obj.leagueInfo !== this.leagueInfo ||
        this.validation_obj.leagueName !== this.leagueName ||
        this.validation_obj.leagueFounded !== this.leagueFounded ||
        this.validation_obj.leagueTotalTitles !== this.leagueTotalTitles ||
        this.validation_obj.leagueLastTitle !== this.leagueLastTitle ||
        this.validation_obj.leagueRunnerUp !== this.leagueRunnerUp ||
        this.validation_obj.leagueResults !== this.leagueResults ||
        this.validation_obj.divisionKeyword !== this.divisionKeyword ||
        this.validation_obj.divisionInfo !== this.divisionInfo ||
        this.validation_obj.divisionName !== this.divisionName ||
        this.validation_obj.divisionFounded !== this.divisionFounded ||
        this.validation_obj.divisionTotalTitles !== this.divisionTotalTitles ||
        this.validation_obj.divisionLastTitle !== this.divisionLastTitle ||
        this.validation_obj.is_spotlight !== this.is_spotlight ||
        this.validation_obj.spotlightPlayerSlug !== this.spotlightPlayerSlug ||
        this.validation_obj.gamePropertyVal !== this.gamePropertyVal ||
        this.validation_obj.gameTypeVal !== this.gameTypeVal ||
        this.validation_obj.leagueVal !== this.leagueVal ||
        this.validation_obj.divisionVal !== this.divisionVal ||
        this.validation_obj.conferenceVal !== this.conferenceVal ||
        this.validation_obj.teamVal !== this.teamVal ||
        this.validation_obj.playerVal1 !== this.playerVal1 ||
        this.validation_obj.playerVal2 !== this.playerVal2 ||
        this.validation_obj.gameSortVal !== this.gameSortVal ||
        this.validation_obj.leagueStatVal !== this.leagueStatVal ||
        JSON.stringify(this.validation_obj.standings) !==
          JSON.stringify(this.standings) ||
        JSON.stringify(this.validation_obj.upcomings) !==
          JSON.stringify(this.upcomings) ||
        this.validation_obj.sportsKey !== this.sportsKey ||
        this.validation_obj.leagueGameType !== this.leagueGameType ||
        this.validation_obj.widgetUpcomingType !== this.widgetUpcomingType ||
        this.validation_obj.widgetStandingType !== this.widgetStandingType) &&
      this.isEditWidget
    ) {
      // ) {

      this.isConfirmationToEditPerOpen = true;
      // }
    } else {
      this.openWidgetMenu('discard');
    }
  }

  closeWidgetMenu2() {
    if (
      this.validation_obj.is_extract !== this.is_extract ||
      this.validation_obj.wiki_extract !== this.wiki_extract ||
      this.validation_obj.wiki_url !== this.wiki_url ||
      this.validation_obj.wiki_source_title !== this.wiki_source_title ||
      JSON.stringify(this.validation_obj.wiki_sections) !==
        JSON.stringify(this.wiki_sections) ||
      this.validation_obj.wiki_keyword !== this.wiki_keyword ||
      this.validation_obj.isWidgetSelectOpen !== this.isWidgetSelectOpen ||
      this.validation_obj.keywordToSearchTeam !== this.keywordToSearchTeam ||
      JSON.stringify(this.validation_obj.twitter_teams_arr) !==
        JSON.stringify(this.twitter_teams_arr) ||
      this.validation_obj.twitter_search_box !== this.twitter_search_box ||
      this.validation_obj.wiki_title !== this.wiki_title ||
      this.validation_obj.wiki_thumbnail !== this.wiki_thumbnail ||
      JSON.stringify(this.validation_obj.wiki_dropdown_data) !==
        JSON.stringify(this.wiki_dropdown_data) ||
      this.validation_obj.wiki_dropdown_show !== this.wiki_dropdown_show ||
      this.validation_obj.color_column !== this.color_column ||
      this.validation_obj.name !== this.name ||
      this.validation_obj.Widget_author !== this.Widget_author ||
      this.validation_obj.pubname !== this.pubname ||
      this.validation_obj.number !== this.number ||
      this.validation_obj.url !== this.url ||
      this.validation_obj.listView !== this.listView ||
      this.validation_obj.image_keyword !== this.image_keyword ||
      this.validation_obj._meta_description !== this._meta_description ||
      this.validation_obj._keywords !== this._keywords ||
      this.validation_obj.description !== this.description ||
      this.validation_obj.selectedImg !== this.selectedImg ||
      this.validation_obj.searchLinkVar2 !== this.searchLinkVar2 ||
      this.validation_obj.searchLinkVar !== this.searchLinkVar ||
      this.validation_obj._image_url !== this._image_url ||
      JSON.stringify(this.validation_obj.images) !==
        JSON.stringify(this.images) ||
      JSON.stringify(this.validation_obj.files) !==
        JSON.stringify(this.files) ||
      this.validation_obj.lat !== this.googleMapService.lat ||
      this.validation_obj.long !== this.googleMapService.long ||
      this.validation_obj.zoom !== this.googleMapService.zoom ||
      this.validation_obj.editItemActive !== this.editItemActive ||
      this.validation_obj.selectedText !== this.selectedText ||
      this.validation_obj.item_obj !== this.item_obj ||
      JSON.stringify(this.validation_obj.list) !== JSON.stringify(this.list) ||
      this.validation_obj.dateObj !== this.dateObj ||
      JSON.stringify(this.validation_obj.playerStatsInfo) !==
        JSON.stringify(this.playerStatsInfo) ||
      JSON.stringify(this.validation_obj.playerStatsObj) !==
        JSON.stringify(this.playerStatsObj) ||
      this.validation_obj.playerKeyword !== this.playerKeyword ||
      this.validation_obj.playerKeyword1 !== this.playerKeyword1 ||
      this.validation_obj.playerKeyword2 !== this.playerKeyword2 ||
      this.validation_obj.playerKey1 !== this.playerKey1 ||
      this.validation_obj.playerKey2 !== this.playerKey2 ||
      this.validation_obj.playerName !== this.playerName ||
      this.validation_obj.playerNumber !== this.playerNumber ||
      this.validation_obj.playerTeam !== this.playerTeam ||
      this.validation_obj.playerLeague !== this.playerLeague ||
      this.validation_obj.playerHeight !== this.playerHeight ||
      this.validation_obj.playerWeight !== this.playerWeight ||
      this.validation_obj.playerPosition !== this.playerPosition ||
      this.validation_obj.playerAge !== this.playerAge ||
      this.validation_obj.playerBirth !== this.playerBirth ||
      this.validation_obj.teamKeyword !== this.teamKeyword ||
      this.validation_obj.teamInfo !== this.teamInfo ||
      this.validation_obj.teamName !== this.teamName ||
      this.validation_obj.teamFounded !== this.teamFounded ||
      this.validation_obj.teamTotalTitles !== this.teamTotalTitles ||
      this.validation_obj.teamLastTitle !== this.teamLastTitle ||
      this.validation_obj.teamStadium !== this.teamStadium ||
      this.validation_obj.teamLoc !== this.teamLoc ||
      this.validation_obj.teamCapacity !== this.teamCapacity ||
      this.validation_obj.teamHeadCoach !== this.teamHeadCoach ||
      this.validation_obj.leagueKeyword !== this.leagueKeyword ||
      this.validation_obj.leagueInfo !== this.leagueInfo ||
      this.validation_obj.leagueName !== this.leagueName ||
      this.validation_obj.leagueFounded !== this.leagueFounded ||
      this.validation_obj.leagueTotalTitles !== this.leagueTotalTitles ||
      this.validation_obj.leagueLastTitle !== this.leagueLastTitle ||
      this.validation_obj.leagueRunnerUp !== this.leagueRunnerUp ||
      this.validation_obj.leagueResults !== this.leagueResults ||
      this.validation_obj.divisionKeyword !== this.divisionKeyword ||
      this.validation_obj.divisionInfo !== this.divisionInfo ||
      this.validation_obj.divisionName !== this.divisionName ||
      this.validation_obj.divisionFounded !== this.divisionFounded ||
      this.validation_obj.divisionTotalTitles !== this.divisionTotalTitles ||
      this.validation_obj.divisionLastTitle !== this.divisionLastTitle ||
      this.validation_obj.is_spotlight !== this.is_spotlight ||
      this.validation_obj.spotlightPlayerSlug !== this.spotlightPlayerSlug ||
      this.validation_obj.gamePropertyVal !== this.gamePropertyVal ||
      this.validation_obj.gameTypeVal !== this.gameTypeVal ||
      this.validation_obj.leagueVal !== this.leagueVal ||
      this.validation_obj.divisionVal !== this.divisionVal ||
      this.validation_obj.conferenceVal !== this.conferenceVal ||
      this.validation_obj.teamVal !== this.teamVal ||
      this.validation_obj.playerVal1 !== this.playerVal1 ||
      this.validation_obj.playerVal2 !== this.playerVal2 ||
      this.validation_obj.gameSortVal !== this.gameSortVal ||
      this.validation_obj.leagueStatVal !== this.leagueStatVal ||
      JSON.stringify(this.validation_obj.standings) !==
        JSON.stringify(this.standings) ||
      JSON.stringify(this.validation_obj.upcomings) !==
        JSON.stringify(this.upcomings) ||
      this.validation_obj.sportsKey !== this.sportsKey ||
      this.validation_obj.leagueGameType !== this.leagueGameType ||
      this.validation_obj.widgetUpcomingType !== this.widgetUpcomingType ||
      this.validation_obj.widgetStandingType !== this.widgetStandingType
    ) {
      // ) {

      this.isConfirmationToEditPerOpen = true;
      // }
    } else {
      this.openWidgetMenu('discard');
    }
  }

  parseInt(index) {
    return parseInt(index);
  }

  onEditorReady(element) {
    element.ui
      .getEditableElement()
      .parentElement.insertBefore(
        element.ui.view.toolbar.element,
        element.ui.getEditableElement()
      );
    element.mediaEmbed = { previewsInData: true };
    this.myCustomUploadAdapterPlugin(element);
  }

  myCustomUploadAdapterPlugin(editor) {
    return (editor.plugins.get('FileRepository').createUploadAdapter = (
      loader
    ) => {
      return new BlobUploadForEditorService(loader);
    });
  }

  selectImageSourceTab(param) {
    this.bll.reset_imagn_data();
    this.imageSourceTab = param;
    if (param === 'upload') {
      this.files = [];
      this.uploadImage = true;
    } else {
      this.files = [];
      this.uploadImage = false;
    }
  }

  selectTwitterSourceTab(param) {
    this.bll.reset_search();
    this.twitter_teams_arr = [];
    this.keywordToSearchTeam = '';
    this.twitterSourceTab = param;
    if (param === 'top') {
      this.twitter_search_box = true;
    } else {
      this.twitter_search_box = false;
    }
  }

  checkGroup(event) {
    if (event.target !== null) {
      this.bll.get_groups_(event.target.value, this.auth.token);
    }
  }

  selectBullet(bullet) {
    this.bulletTypeSelected = bullet.id;
    if (this.editItemActive === true) {
      const result = this.findObjectById(
        this.bulletTypeSelected,
        this.orderedlistStyles,
        this.unorderedlistStyles
      );
      let updatedObj = {
        bulletId: this.item_obj.bulletId,
        id: result.id,
        text: this.selectedText,
        color: result.color,
        background: result.background,
        icon_name: result.icon_name ? result.icon_name : null,
        shape: result.shape ? result.shape : null,
        type: result.type,
      };

      const updatedItems = this.updateItemById(
        this.list,
        this.item_obj.bulletId,
        updatedObj
      );
      this.list = updatedItems;
    }
  }
  AddTextToList() {
    if (this.selectedText !== '') {
      const result = this.findObjectById(
        this.bulletTypeSelected,
        this.orderedlistStyles,
        this.unorderedlistStyles
      );
      this.list.push({
        bulletId: this.getRandomNumber(1, 9999),
        id: result.id,
        text: this.selectedText,
        color: result.color,
        background: result.background,
        icon_name: result.icon_name ? result.icon_name : null,
        shape: result.shape ? result.shape : null,
        type: result.type,
      });

      this.selectedText = '';
    }
  }
  getRandomNumber(min, max) {
    // Generate a random floating-point number between 0 and 1
    const randomFraction = Math.random();

    // Scale the randomFraction to the desired range
    const randomNumber = min + randomFraction * (max - min);

    // Use Math.floor or Math.round if you want an integer result
    return randomNumber; // This will be a number in the [min, max) range
  }

  findObjectById(id, array1, array2) {
    // Search in the first array
    const objectInArray1 = array1.find((obj) => obj.id === id);

    // If found in the first array, return it
    if (objectInArray1) {
      return objectInArray1;
    }

    // Search in the second array
    const objectInArray2 = array2.find((obj) => obj.id === id);

    // If found in the second array, return it
    if (objectInArray2) {
      return objectInArray2;
    }

    // If not found in either array, return null or handle it as needed
    return null;
  }

  editItem(item) {
    this.item_obj = item;
    this.selectedText = this.regex(item.text);
    this.listTypeSelected = item.type;
    this.bulletTypeSelected = item.id;
    this.editItemActive = true;
  }
  deleteItem(id) {
    this.list = this.list.filter((item) => item.bulletId !== id);
  }
  updateItemToList() {
    if (this.selectedText !== '') {
      const result = this.findObjectById(
        this.bulletTypeSelected,
        this.orderedlistStyles,
        this.unorderedlistStyles
      );
      let updatedObj = {
        bulletId: this.item_obj.bulletId,
        id: result.id,
        text: this.selectedText,
        color: result.color,
        background: result.background,
        icon_name: result.icon_name ? result.icon_name : null,
        shape: result.shape ? result.shape : null,
        type: result.type,
      };

      const updatedItems = this.updateItemById(
        this.list,
        this.item_obj.bulletId,
        updatedObj
      );
      this.list = updatedItems;

      this.selectedText = '';
      this.editItemActive = false;
      this.item_obj = null;
    }
  }

  selectListType(event) {
    if (this.listTypeSelected) {
      if (this.listTypeSelected === 'ordered') {
        this.bulletTypeSelected = '10571';
      } else {
        this.bulletTypeSelected = '10671';
      }
    }
  }

  updateItemById(items, idToUpdate, updatedData) {
    return items.map((item) => {
      if (item.bulletId === idToUpdate) {
        // Merge the existing item with the updated data
        return { ...item, ...updatedData };
      }
      return item;
    });
  }

  openListView() {
    this.edit_IV = false;
    this.showAddBlock = false;
    this.listView = true;
  }
  closeListView() {
    this.edit_IV = true;

    this.listView = false;
  }

  calculateAge(birthdate): number {
    let age;
    if (birthdate.includes('/') || birthdate.includes('-')) {
      const currentDate = new Date();
      const birthYear = new Date(birthdate).getFullYear();
      const currentYear = currentDate.getFullYear();

      age = currentYear - birthYear;

      // Check if birthday has occurred this year
      const birthdateThisYear = new Date(currentDate);
      birthdateThisYear.setFullYear(birthYear);

      if (birthdateThisYear > currentDate) {
        age--;
      }
    } else {
      age = birthdate;
    }

    return age;
  }
}
