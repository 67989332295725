import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { MenuController, PopoverController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AuthState } from './services/auth/auth.model';
import { AuthService } from './services/auth/auth.service';
import { pageView } from './services/menu/menu.pages';
import { MenuService } from './services/menu/menu.service';
import { Router } from '@angular/router';
import { DetailServiceService } from './views/articles/detail/services/detailService/detail-service.service';
import { environment } from '../environments/environment';
import markerSDK from '@marker.io/browser';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  pages$: Observable<pageView[]>;
  auth$: Observable<AuthState>;

  Sidepages: any = [];
  showChildren = -1;

  token: any = '';

  notifications$: Observable<any[]>;
  unRead;
  profileDetails: any = null;
  notificationloading$: any;
  staging: boolean = false;
  favIcon: any;
  public environment = environment;
  help_center_icon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-240q21 0 35.5-14.5T530-290q0-21-14.5-35.5T480-340q-21 0-35.5 14.5T430-290q0 21 14.5 35.5T480-240Zm-36-154h74q0-36 8-53t34-43q35-35 49.5-58.5T624-602q0-53-36-85.5T491-720q-55 0-93.5 27T344-618l66 26q7-27 28-43.5t49-16.5q27 0 45 14.5t18 38.5q0 17-11 36t-37 42q-33 29-45.5 55.5T444-394ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/></svg>`;
  constructor(
    private menuCrtl: MenuController,
    private menu: MenuService,
    public auth: AuthService,
    public router: Router,
    public detailService: DetailServiceService,
    private popover: PopoverController,
    private changeDetection: ChangeDetectorRef
  ) {
    this.favIcon = document.getElementById('favicon') as HTMLLinkElement;

    if (!environment.production) {
      this.staging = true;
      this.favIcon.href = './assets/icon/favicon2.png';
    } else {
      this.favIcon.href = './assets/icon/favicon.png';
    }
    this.auth$ = this.auth.auth$;
    this.notificationloading$ = this.auth.notificationsLoading$;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (
      urlParams.get('old_email') !== undefined &&
      urlParams.get('old_email') !== null &&
      (urlParams.get('new_email') && urlParams.get('new_email') !== null) !==
        undefined
    ) {
      this.auth
        .updateEmailInDB(
          urlParams.get('old_email'),
          urlParams.get('new_email'),
          urlParams.get('token')
        )
        .then(
          (data) => {
            console.log('updated email res', data);
          },
          (err) => {
            console.log('err', err);
          }
        );
    }
    this.menu.pages$.subscribe((pages) => {
      console.log('pages', pages);
      this.auth$.subscribe((user) => {
        this.token = user.token;
        if (this.token != null) {
          this.auth.getNotifications(this.token);
          // this.getProfileDetails();
        }

        this.Sidepages = [];
        if (user.role !== null) {
          if (user.role.includes('super_admin')) {
            this.Sidepages = pages;
          }
          if (!user.role.includes('super_admin')) {
            this.Sidepages.push(pages[0]);
            this.Sidepages.push(pages[3]);
            if (user.role.includes('admin')) {
              this.Sidepages.push(pages[1]);

              //this.Sidepages.push(pages[4]);
              this.Sidepages.push(pages[5]);
              this.Sidepages.push(pages[6]);
              this.Sidepages.push(pages[7]);
              this.Sidepages.push(pages[8]);
              this.Sidepages.push(pages[9]);
              this.Sidepages.push(pages[10]);
              this.Sidepages.push(pages[11]);

              this.Sidepages.push(pages[13]);
              this.Sidepages.push(pages[14]);
              this.Sidepages.push(pages[15]);
            }
            if (
              user.role.includes('editor') ||
              user.role.includes('creator') ||
              user.role.includes('member')
            ) {
              if (user.role !== 'member') {
                this.Sidepages.push(pages[6]);
                //  this.Sidepages.push(pages[7]);
              } else {
                this.Sidepages.push(pages[8]);
              }
            }
          }
        }
      });
    });
  }

  getEmailChars(emailAddress): string {
    let str = emailAddress.toUpperCase().substr(0, 6) + '...';
    return str;
  }
  navigateToPage(page: any) {
    if (
      page.id !== 'analytics' &&
      page.id !== 'settings' &&
      page.id !== 'assets' &&
      page.id !== 'groups'
    ) {
      this.showChildren = -1;
      this.menu.changePage(page.url);
    } else {
      if (!page.selected) {
        page.selected = true;
        if (page.url) {
          this.menu.changePage(page.url);
        }
      } else {
        page.selected = false;
        this.showChildren = -1;
      }
    }
  }

  getProfileDetails() {
    if (this.detailService.profileDetails !== null) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 1000);
    } else {
      let url = 'user?profile=true';
      this.auth.get(url, this.token).subscribe(
        (data: any) => {
          this.detailService.profileDetails = data.data[0];
          setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
          }, 1000);
        },
        (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.auth.logout();
            setTimeout(() => {
              this.getProfileDetails();
            }, 4000);
          }
          console.log('err', err);
        }
      );
    }
  }

  navigateToChild(page: any) {
    this.menu.changePage(page.url);
    this.Sidepages.map((Page) => {
      if (page.url.includes(Page.id)) {
      } else {
        Page.selected = false;
        if (Page.children) {
          Page.children.map((child) => {
            child.selected = false;
          });
        }
      }
    });
  }

  closeMenu() {
    if (this.isLargeScreen()) {
      this.menu.hideMenu();
    } else {
      this.menu.collapseMenu();
    }
  }

  isLargeScreen(): boolean {
    return window.innerWidth >= 1024; // Adjust the breakpoint as per your requirements
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    // Call change detection to update the class binding when the window is resized
    // This will ensure that the correct class is applied based on the screen size
    this.changeDetection.detectChanges();
  }

  logout() {
    this.popover.dismiss();
    this.auth.logout();
    this.detailService.profileDetails = null;
    this.auth.profileDetails = null;
  }

  ngOnInit(): void {
    this.notifications$ = this.auth.notifications$;
    this.loadMarkerWidget();
    this.auth.unread$.subscribe((data) => {
      this.unRead = data;
    });
  }

  async loadMarkerWidget() {
    const widget = await markerSDK.loadWidget({
      project: '6523d2b11eec04693c781b10',
    });

    const reportBugButton = document.querySelector('.report-bug-button');

    reportBugButton.addEventListener('click', () => {
      widget.capture('fullscreen'); // <- Trigger a capture and open the widget inside your app.
    });
  }

  read(id, read) {
    this.auth.read(id, read, this.token);
  }
  dismiss(id) {
    this.auth.dismiss_notifications(id, this.token);
  }

  go(notification, link: any) {
    this.popover.dismiss();
    if (notification.is_read === false) {
      this.read(notification.id, true);
    }
    let url = link.split('sportswriters.com')[1];
    this.router.navigate([url]);
  }
  viewAll() {
    this.popover.dismiss();
    this.router.navigate(['/all-notifications']);
  }
  openProfile() {
    this.popover.dismiss();
    this.router.navigate(['/profile']);
  }
}
