import { NgModule } from '@angular/core';
import { PicInitialsPipe } from './pic-initials/pic-initials.pipe';
import { DomSanitizerPipe } from './dom-sanitizer/dom-sanitizer.pipe';
import { UrlSanitizerPipe } from './url-sanitizer/url-sanitizer.pipe';

@NgModule({
  imports: [],
  declarations: [ 
    PicInitialsPipe,
    DomSanitizerPipe,
    UrlSanitizerPipe
  ],
  exports: [
    PicInitialsPipe,
    DomSanitizerPipe,
    UrlSanitizerPipe
  ]
})
export class CustomPipesModule {}