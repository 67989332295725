import { Injectable } from '@angular/core';
import {
  Auth,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  verifyBeforeUpdateEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthState, Login } from './auth.model';
import { SignInPage } from '../../views/modal/sign-in/sign-in.page';

const initialAuthState = {
  isLoggedIn: false,
  isEmailVerified: false,
  id: null,
  email: null,
  name: null,
  phone_number: null,
  token: null,
  display_picture: null,
  role: null,
};

import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { environment } from '../../../environments/environment';
import { ApiService } from '../api/api.service';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { updatePassword } from 'firebase/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isLoading = new BehaviorSubject<boolean>(false);
  public readonly _authState = new BehaviorSubject<AuthState>(initialAuthState);

  /** AuthState as an Observable */
  readonly auth$ = this._authState.asObservable();
  readonly isLoading$ = this.isLoading.asObservable();

  title = 'af-notification';

  public notifications: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  readonly notifications$: Observable<any> = this.notifications.asObservable();

  public notificationsLoading: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  readonly notificationsLoading$: Observable<any> =
    this.notificationsLoading.asObservable();

  private unread: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  readonly unread$: Observable<any> = this.unread.asObservable();
  message: any = null;
  token: any;
  profileDetails: any = null;
  dashboardLoading = false;
  constructor(
    public auth: Auth,
    private router: Router,
    private toast: ToastController,
    private route: ActivatedRoute,
    private http: HttpClient,
    private modalCtrl: ModalController
  ) {
    this.listen();
    this.auth.onAuthStateChanged(async (user: any) => {
      if (!user) {
        this.isLoading.next(false);
        this._authState.next(initialAuthState);
      } else {
        if (user) {
          user.getIdToken(true).then((token) => {
            let a: any = null;
            this.token = token;
            localStorage.setItem('accessToken', token);
            let url = 'user?profile=true';
            this.dashboardLoading = true;
            this.get(url, this.token).subscribe(
              (data: any) => {
                a = data.data[0];
                this.profileDetails = data.data[0];
                let checkUserRole = false;
                if (a.role.includes('consumer') && a.role.length === 1) {
                  checkUserRole = false; // Only "consumer" is present
                } else {
                  checkUserRole = true; // Other roles are present with or without "consumer"
                }
                if (checkUserRole === true) {
                  if (a !== null || a !== undefined) {
                    this.isLoading.next(false);
                    this._authState.next({
                      isEmailVerified: user.emailVerified,
                      id: user.uid,
                      email: user.email,
                      name: user.displayName,
                      phone_number: user.phoneNumber,
                      display_picture: user.photoURL,
                      token: token,
                      role: a.role,
                    });
                    this.dashboardLoading = false;
                  } else {
                    this.dashboardLoading = false;
                    this.isLoading.next(false);
                    // this._authState.next(initialAuthState);
                    // signOut(this.auth);
                  }
                } else {
                  this.isLoading.next(false);
                  this.dashboardLoading = false;
                  this._authState.next(initialAuthState);
                  signOut(this.auth);
                }
              },
              (err) => {
                this.isLoading.next(false);
                this.dashboardLoading = false;
                // this._authState.next(initialAuthState);
                // signOut(this.auth);
                // this.logout();
              }
            );
          });
        } else {
          this.isLoading.next(false);
          this.dashboardLoading = false;
          // this._authState.next(initialAuthState);
          // signOut(this.auth);
          // this.logout();
        }
      }
      if (user === null) {
        // this.logout();
      }
    });
  }

  async openModal(url: any, email: any) {
    const modal = await this.modalCtrl.create({
      component: SignInPage,
      backdropDismiss: false,
      componentProps: {
        url: url,
        id: 'modal-one',
        email: email,
      },
    });
    modal.onDidDismiss().then(() => this.modalCtrl.dismiss());
    modal.present();
  }

  public login({ email, password }: Login, url?: any) {
    this.isLoading.next(true);

    return signInWithEmailAndPassword(this.auth, email, password).then(
      async (data: any) => {
        if (this.modalCtrl) {
          this.modalCtrl.dismiss();
        }

        let a = this.parseJwt(data.user.stsTokenManager.accessToken);
        let checkUserRole = false;
        if (a.role.includes('consumer') && a.role.length === 1) {
          checkUserRole = false; // Only "consumer" is present
        } else {
          checkUserRole = true; // Other roles are present with or without "consumer"
        }
        if (checkUserRole === true) {
          localStorage.setItem('user', JSON.stringify(data.user));
          if (url) {
            this.router.navigate([url]);
          } else {
            this.router.navigate(['/dashboard'], { replaceUrl: true });
          }
        } else {
          const toast = await this.toast.create({
            message: 'Authentication Failed ! User is Invalid',
            position: 'bottom',
            duration: 3000,
          });
          await toast.present();
          this.isLoading.next(false);
        }
      },
      async (err) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          // this.logout();
        }
        const toast = await this.toast.create({
          message: err.message,
          position: 'bottom',
          duration: 3000,
        });
        await toast.present();
        this.isLoading.next(false);
      }
    );
  }

  public LoginWithToken(token: any) {
    let userObj = this.parseJwt(token);

    this._authState.next({
      isEmailVerified: userObj.emailVerified,
      id: userObj.uid,
      email: userObj.email,
      name: userObj.displayName,
      phone_number: userObj.phoneNumber,
      display_picture: userObj.photoURL,
      token: token,
      role: userObj.role,
    });
  }

  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  check(): Observable<boolean> {
    // Check if the user is logged in

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    if (this.accessToken) {
      return of(true);
    }

    // Check the access token expire date
    // if (AuthUtils.isTokenExpired(this.accessToken)) {
    //     return of(false);
    // }

    // If the access token exists and it didn't expire, sign in using it
    // return this.signInUsingToken();
  }

  public refreshtoken() {
    this.auth.currentUser.getIdToken(true).then((token) => {
      let a: any = null;
      this.token = token;
      console.log('token', token);
      let url = 'user?profile=true';
      this.get(url, this.token).subscribe(
        (data: any) => {
          a = data.data[0];
          this.profileDetails = data.data[0];
          let checkUserRole = false;
          if (a.role.includes('consumer') && a.role.length === 1) {
            checkUserRole = false; // Only "consumer" is present
          } else {
            checkUserRole = true; // Other roles are present with or without "consumer"
          }
          if (checkUserRole === true) {
            if (a !== null || a !== undefined) {
              this.isLoading.next(false);
              this._authState.next({
                isEmailVerified: this.auth.currentUser.emailVerified,
                id: this.auth.currentUser.uid,
                email: this.auth.currentUser.email,
                name: this.auth.currentUser.displayName,
                phone_number: this.auth.currentUser.phoneNumber,
                display_picture: this.auth.currentUser.photoURL,
                token: token,
                role: a.role,
              });
              this.requestPermission(this.auth.currentUser.uid, token);
            } else {
              this.isLoading.next(false);
              this._authState.next(initialAuthState);
              signOut(this.auth);
            }
          } else {
            this.isLoading.next(false);
            this._authState.next(initialAuthState);
            signOut(this.auth);
          }
        },
        (err) => {
          this.isLoading.next(false);
          this._authState.next(initialAuthState);
          signOut(this.auth);
          this.logout();
        }
      );
    });
  }

  async getIdToken() {
    try {
      const token = await this.auth.currentUser.getIdToken(true);
      return token;
    } catch (error) {
      throw error;
    }
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  public async logout() {
    await signOut(this.auth);
    localStorage.removeItem('user');
    this.router.navigate(['auth/login'], { replaceUrl: true });
  }

  public forgotPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  public changeLoaderState(state: boolean) {
    this.isLoading.next(state);
  }

  requestPermission(user_id, token) {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          this.addNotification_key(user_id, currentToken, token);
        } else {
          console.log(
            'No registration token available. Request permission to generate one.'
          );
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      this.message = payload;
      this.presentToast(payload);
      this.getNotifications(this.token);
    });
  }

  getNotifications(token) {
    this.notificationsLoading.next(true);
    let url = 'notification_key?notification=true&size=5&is_active=true';
    url = url + '&small_notification=true';
    this.get(url, token).subscribe(
      (data: any) => {
        this.notificationsLoading.next(false);
        if (data.data.length > 0) {
          this.notifications.next(data.data);
          this.unread.next(data.data[0].unread);
        } else {
          this.notifications.next([]);
          this.unread.next([]);
        }
      },
      (err) => {
        this.notificationsLoading.next(false);
        this.notifications.next([]);
        this.unread.next([]);
      }
    );
  }

  dismiss_notifications(id, token) {
    let obj = {
      id: id,
      is_active: false,
      is_active_notification: true,
    };
    let url = 'notification_key';
    this.put(url, obj, token).subscribe((data: any) => {
      this.getNotifications(token);
    });
  }

  read(id, read, token) {
    let obj = {
      id: parseInt(id),
      is_read: read,
      is_read_notification: true,
    };
    // let obj = {
    //   id: id,
    //   is_active: true,
    //   is_active_notification: true,
    // };
    let url = 'notification_key';
    this.put(url, obj, token).subscribe((data: any) => {
      this.getNotifications(token);
    });
  }

  async presentToast(data) {
    const toast = await this.toast.create({
      header: data.notification.title,
      message: data.data.message,
      duration: 5000,
      color: 'light',
      position: 'top',
      buttons: [
        {
          text: 'Go',
          handler: () => {
            let url = data.data.link.split('sportswriters.com')[1];
            this.router.navigate([url]);
            // Insert navigation code here
          },
        },
        {
          text: 'Dismiss',
          handler: () => {
            this.toast.dismiss();
            // Insert navigation code here
          },
        },
      ],
      icon: 'notifications-circle',
    });
    toast.present();
  }

  postUser(url: string, body: any, token: any): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    return this.http.post(environment.api_url + url, body, {
      headers: new HttpHeaders(headers),
    });
  }

  put(url: string, body: any, token: any): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    return this.http.put(environment.api_url + url, body, {
      headers: new HttpHeaders(headers),
    });
  }

  get(url, token) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    return this.http.get(environment.api_url + url, {
      headers: new HttpHeaders(headers),
    });
  }

  async errToast(msg) {
    const t = await this.toast.create({
      header: 'Error',
      message: msg,
      position: 'bottom',
      duration: 5000,
      color: 'danger',
    });
    await t.present();
  }

  addNotification_key(user_id, currentToken, token) {
    let url = 'notification_key';
    let obj = { user_id: user_id, notification_key: currentToken };

    this.postUser(url, obj, token).subscribe(
      (data) => {},
      (err) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          this.refreshtoken();
        }
        console.log('err', err);
      }
    );
  }
  async sendVerificationBeforeUpdateEmail(oldemail: any, email: any, token) {
    try {
      const user = this.auth.currentUser;
      let codeSettings = null;
      codeSettings = environment.actionCodeSettings;
      codeSettings.url =
        codeSettings.url +
        '?old_email=' +
        oldemail +
        '&new_email=' +
        email +
        '&token=' +
        token;
      if (user) {
        await verifyBeforeUpdateEmail(user, email, codeSettings);

        const toast = await this.toast.create({
          message: 'Verification email sent. Please check your inbox.',
          duration: 3000,
          position: 'top',
        });
        toast.present();
        this.logout();
      }
    } catch (error) {
      console.error('Error changing email:', error);
      const toast = await this.toast.create({
        message: 'An error occurred. Please try again later.',
        duration: 3000,
        position: 'top',
      });
      toast.present();
    }
  }
  updateEmailInDB(old_email, new_email, token) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `user`;
        let body = {
          email: new_email,
          old_email: old_email,
          email_update: true,
        };

        const data = await this.put(url, body, token).toPromise();

        const toast = await this.toast.create({
          message: 'Your Email is Updated! Try Login with the new email',
          duration: 3000,
          position: 'bottom',
        });
        toast.present();

        resolve(data); // Resolve the Promise with the data
      } catch (error) {
        console.log('error', error);
        reject(error); // Reject the Promise with the error
      }
    });
  }
  checkCurrentPassword(oldPassword) {
    return new Promise((resolve, reject) => {
      var credential = EmailAuthProvider.credential(
        this.auth.currentUser.email,
        oldPassword
      );

      // Prompt the user to re-provide their sign-in credentials

      reauthenticateWithCredential(this.auth.currentUser, credential)
        .then((data) => {
          resolve(data); // Resolve with the data
        })
        .catch((error) => {
          reject(error); // Reject with the error
        });
    });
  }

  updatePassword(newPassword) {
    return new Promise((resolve, reject) => {
      updatePassword(this.auth.currentUser, newPassword)
        .then((data) => {
          // Update successful.
          resolve(data);
        })
        .catch((error) => {
          // An error occurred
          reject(error);
          // ...
        });
    });
  }
}
