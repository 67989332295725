import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import {
  IonicModule,
  IonicRouteStrategy,
  iosTransitionAnimation,
} from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CustomPipesModule } from './pipes/pipes.module';
import { DragulaModule } from 'ng2-dragula';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorModule } from '@angular/material/paginator';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { SwiperModule } from 'swiper/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { GoogleMapsModule } from '@angular/google-maps';
import { AgmCoreModule } from '@agm/core';
import { AuthInterceptor } from './services/auth/auth.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
    }),
    GooglePlaceModule,
    GoogleMapsModule,
    NgxYoutubePlayerModule.forRoot(),
    HttpClientModule,
    CustomPipesModule,
    NgApexchartsModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    NgxDropzoneModule,
    NgxDatatableModule,
    SwiperModule,
    NgxIntlTelInputModule,
    DragulaModule.forRoot(),
    AppRoutingModule,
    ScrollingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBkJcpXLWBADjofSzB47NbO3ap1uMntGWM',
      libraries: ['places'],
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    this.addGlobalStyle(); // Function to add the global styles dynamically
  }
  addGlobalStyle() {
    let primary_color = '';
    let secondary_color = '';
    let sidebar_color = '';
    if (environment.production) {
      primary_color = '#087A3F';
      secondary_color = '#FFC52D';
      sidebar_color = '#0E172A';
    } else {
      primary_color = '#087A3F';
      secondary_color = '#FFC52D';
      sidebar_color = '#314c88';
    }
    document.documentElement.style.setProperty(
      '--ion-color-primary',
      primary_color
    );
    document.documentElement.style.setProperty(
      '--ion-color-secondary',
      secondary_color
    );
    document.documentElement.style.setProperty(
      '--ion-color-medium',
      sidebar_color
    );
  }
}
