import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { ApiService } from "src/app/services/api/api.service";
import { author, author_data, team, team_data } from "./teams.model";



@Injectable({
    providedIn: 'root'
})
export class TeamsBLL {

    private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly isLoading$: Observable<boolean> = this.isLoading.asObservable();

    private isAuthorsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly isAuthorsLoading$: Observable<boolean> = this.isAuthorsLoading.asObservable();

    private total_records: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    readonly total_records$: Observable<number> = this.total_records.asObservable();

    private teams: BehaviorSubject<team[]> = new BehaviorSubject<team[]>([]);
    readonly teams$: Observable<team[]> = this.teams.asObservable();

    private authors: BehaviorSubject<author[]> = new BehaviorSubject<author[]>([]);
    readonly authors$: Observable<author[]> = this.authors.asObservable();

    constructor(
        private api: ApiService,
        private toast: ToastController
    ){}

    get_teams(page: number, size: number, keyword: string, sort_order: string, sort_by: string, is_active: boolean, status: string, author: string, league_id: number,
        conference_id: number,token:any ){
        this.isLoading.next(true);

        let url = `team?list=true&page=${page}&size=${size}`;
        
        if(keyword){
            url += `&keyword=${keyword}`;
        }

        if(sort_order && sort_by){
            url += `&sort_order=${sort_order}&sort_by=${sort_by}`;
        }

        if(league_id){
            url += `&league_id=${league_id}`;
        }
        if(conference_id){
            url += `&conference_id=${conference_id}`;
        }

        if(is_active){
            url += `&is_active=${is_active}`;
        }

        if(status) {
            url += `&status=${status}`;
        }

        if(author) {
            url += `&author=${author}`;
        }

        this.api.get(url,token).pipe(take(1)).subscribe((res: team_data) => {
            this.teams.next(res.data);
            this.total_records.next(res.totalRecords);
            this.isLoading.next(false);
        }, (err) => {
            this.isLoading.next(false);
        });
    }

    quick_edit(t: team, page: number, size: number, keyword: string, sort_order: string, sort_by: string, is_active: boolean, status: string, author: string,token:any){
        this.isLoading.next(true);

        let url = `team`;
        let leagueID, conferenceID: number
        let update = {
            id: t.id,
            status: t.status,
            team_status: true
        };
        this.api.put(url, update,token).pipe(take(1)).subscribe(async (res) => {
            const t = await this.toast.create({
                header: 'Team Updated',
                message: `Team has been edited successfully.`,
                position: 'bottom',
                duration: 5000,
                color: 'secondary'
            });
            await t.present();
            this.get_teams(page, size, keyword, sort_order, sort_by, is_active, status, author, leagueID, conferenceID,token);
        }, async (err) => {
            const t = await this.toast.create({
                header: 'Error',
                message: 'There was an issue editing this team. Please try again shortly.',
                position: 'bottom',
                duration: 5000,
                color: 'danger'
            });
            await t.present();
            this.isLoading.next(false);
        });
    }

    get_authors(keyword: string,token:any){
        this.isAuthorsLoading.next(true);

        let url = `user?authors=true&keyword=${keyword}&entity=teams`;
        this.api.get(url,token).pipe(take(1)).subscribe((res: author_data) => {
            this.authors.next(res.data);
            this.isAuthorsLoading.next(false);
        }, (err) => {
            this.isAuthorsLoading.next(false);
        })
    }

    set_authors_empty(){
        this.authors.next([]);
    }   

    set_teams_empty(){
        this.teams.next([]);
    }
}