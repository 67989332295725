import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'urlSanitizer'
})
export class UrlSanitizerPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ){}

  transform(url: string): SafeResourceUrl {

    let sanitizedURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return sanitizedURL;
  }

 
}
