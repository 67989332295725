// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: 'AIzaSyBkny305z0o5vnSmCGWynXEDg_3fo-iOAE',
    authDomain: 'sportswriters-qa.firebaseapp.com',
    projectId: 'sportswriters-qa',
    storageBucket: 'sportswriters-qa.appspot.com',
    messagingSenderId: '917917935777',
    appId: '1:917917935777:web:e549cb1ccbfd6792a8a8e0',
    vapidKey:
      'BGYkf-GGqlVywTx9QzdzqHNCDaJMUFI4QwdurZklbDsZ9bVskeOmOHfQf2M51kcxzaYgAiY0Y9lcjo5bkl4ZG7g',
  },
  consumerUrl: 'https://ambitious-rock-01fb52f10.1.azurestaticapps.net/',
  baseURL: 'https://sportswriters-qa.azurewebsites.net',
  api_url: 'https://sportswriters-qa.azurewebsites.net/api/',
  azure_blob: {
    blob_credentials:
      'https://sportswritersmedia.blob.core.windows.net/?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2029-12-31T20:18:36Z&st=2022-09-05T12:18:36Z&spr=https&sig=6eQnEK6tfD7puM46uPw8dgVV5Xp7wYIC%2BDA%2FvJqu0WQ%3D',
    container_name: 'admindocuments',
    blob_image_base_url:
      'https://sportswritersmedia.blob.core.windows.net/admindocuments/',
  },
  actionCodeSettings: {
    handleCodeInApp: true,
    //url: 'http://localhost:4200/dashboard',
    url: 'https://qa.sportswriters.com/dashboard',
    android: {
      packageName: 'com.sportswriters.io',
    },
  },
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
